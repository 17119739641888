import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { parse } from "papaparse";
import _ from "lodash";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGetDoc } from "../hooks/useGetDoc";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserDetailsContext } from "../context/UserDetailsContext";
import { useAddDocs } from "../hooks/useAddDocs";
import { useSetDoc } from "../hooks/useSetDoc";
import { useFirebaseIncrement } from "../hooks/useFirebaseIncrement";
import { useAnalytics } from "../hooks/useAnalytics";
import { increment } from "firebase/firestore";
import moment from "moment";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function CSVFile() {
  const navigate = useNavigate();
  const [customError, setCustomError] = useState(null);
  const [mcError, setMcError] = useState([]);
  const [mcOK, setMcOK] = useState([]);
  const { getSingleDocWithQuery, isPending, error } = useGetDoc();

  useEffect(() => {
    setMcError([])
    setMcOK([])
  }, [])
  

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({ maxFiles: 1, accept: { "text/csv": [] } });

  useEffect(async () => {
    acceptedFiles.forEach(async (file) => {
      const text = await file.text();
      const result = parse(text.replace(/[\u200B-\u200D\uFEFF]/g, ""), {
        header: true,
        skipEmptyLines: true,
        newline: "\n",
      });

      // console.log(result.data);

      const keyFields = [
        "マイクロチップの識別番号",
        "暗証記号", //"Symbol",
        "登録証明書_号", //"Registration Certificate_",
        "所有者の情報_所有者種別_一般/動物取扱業区別", //"Owner information_Owner type_General / Animal handling business distinction",
        "所有者の情報_個人又は法人の別", //"Owner Information_Individual or Corporate",
        "所有者の情報_氏名_姓／法人名", //"Owner information_Name_Last name / Corporate name",
        "所有者の情報_氏名_名", //"Owner information_name_name",
        "所有者の情報_氏名_姓／法人名（カナ）", //"Owner information_Name_Last name / Corporate name (Kana)",
        "所有者の情報_氏名_名（カナ）", //"Owner information_name_name (kana)",
        "所有者の情報_代表者の氏名_姓", //"Owner information_Representative's name_Last name",
        "所有者の情報_代表者の氏名_名", //"Owner Information_Representative Name_Name",
        "所有者の情報_郵便番号", //"Owner Information_Zip Code",
        "所有者の情報_都道府県", //"Owner information_prefectures",
        "所有者の情報_市区町村", //"Owner Information_City",
        "所有者の情報_丁目以降・建物名", //"Owner information_chome and after / building name",
        "所有者の情報_電話番号", //"Owner information_phone number",
        "所有者の情報_携帯電話番号", //"Owner information_mobile number",
        "所有者の情報_電子メールアドレス", //"Owner Information_Email Address",
        "動物の情報_名", //"Animal Information_Name",
        "動物の情報_犬又は猫の別", //"Animal Information_Dog or Cat",
        "動物の情報_品種", //"Animal Information_Variety",
        "動物の情報_品種_その他", //"Animal Information_Variety_Others",
        "動物の情報_毛色", //"Animal Information_Coat Color",
        "動物の情報_毛色_その他", //"Animal Information_Coat Color_Others",
        "動物の情報_生年月日", //"Animal Information_Date of Birth",
        "動物の情報_性別", //"Animal Information_Gender",
        "動物の情報_動物の所在地_郵便番号", //"Animal Information_Animal Location_Zip Code",
        "動物の情報_動物の所在地_都道府県", //"Animal Information_Animal Location_Prefectures",
        "動物の情報_動物の所在地_市区町村", //"Animal Information_Animal Location_City",
        "動物の情報_動物の所在地_丁目以降・建物名", //"Animal information_Animal location_Chome and beyond / Building name",
        "動物の情報_特徴となるべき事項(備考)", //"Animal Information_Matters to be Characteristic (Remarks)",
        "動物の情報_鑑札_登録年月日", //"Animal information_tag_registration date",
        "動物の情報_鑑札_登録番号", //"Animal information_tag_registration number",
        "動物取扱業関連情報_第一種_動物取扱業の情報_販売", //"Information related to animal handling business_Type 1_Information on animal handling business_Sales",
        "動物取扱業関連情報_第一種_動物取扱業の情報_保管", //"Information related to animal handling business_Type 1_Information on animal handling business_Storage",
        "動物取扱業関連情報_第一種_動物取扱業の情報_貸出し", //"Information related to animal handling business_Type 1_Information on animal handling business_Lending",
        "動物取扱業関連情報_第一種_動物取扱業の情報_訓練", //"Information related to animal handling business_Type 1_Information on animal handling business_Training",
        "動物取扱業関連情報_第一種_動物取扱業の情報_展示", //"Information related to animal handling business_Type 1_Information on animal handling business_Exhibition",
        "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業", //"Information related to animal handling business_Type 1_Information on animal handling business_Auction mediation business",
        "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業", //"Information related to animal handling business_Type 1_Information on animal handling business_Transfer breeding business",
        "動物取扱業関連情報_第二種_動物取扱業の情報_譲渡し", //"Information related to animal handling business_Type 2_Information on animal handling business_Transfer",
        "動物取扱業関連情報_第二種_動物取扱業の情報_保管", //"Information related to animal handling business_Type 2_Information on animal handling business_Storage",
        "動物取扱業関連情報_第二種_動物取扱業の情報_貸出し", //"Information related to animal handling business_Type 2_Information on animal handling business_Lending",
        "動物取扱業関連情報_第二種_動物取扱業の情報_訓練", //"Information related to animal handling business_Type 2_Information on animal handling business_Training",
        "動物取扱業関連情報_第二種_動物取扱業の情報_展示", //"Information related to animal handling business_Type 2_Information on animal handling business_Exhibition",
        "動物取扱業関連情報_親の雌犬又は雌猫の所有時期", //"Animal Handling Business Related Information_When to Own a Parent's Bitch or Female Cat",
        "動物取扱業関連情報_親の雌犬又は雌猫に装着されているマイクロチップの識別番号", //"Animal Handling Business Related Information_Identification Number of Microchip Attached to Parental Bitch or Female Cat",
        "動物取扱業関連情報_親の雌犬又は雌猫に装着されているマイクロチップの識別番号_未入力理由", //"Animal handling business related information_Identification number of the microchip attached to the parent bitch or bitch_Reason for not entering",
        "管理項目\r", //"Management items",
      ];

      if (
        result.data.length <= 0 ||
        _.difference(Object.keys(result.data[0]), keyFields).length > 0
      ) {
        setCustomError("環境省登録済みCSVデータのみをアップロードしてください");
      } else {
        setCustomError(null);
        result.data.map(async (mcData, i) => {
          await getSingleDocWithQuery("microchip", [
            ["mccode", "==", mcData["マイクロチップの識別番号"]],
          ]).then(async (res) => {
            if (!res) {
              let type = mcData["所有者の情報_所有者種別_一般/動物取扱業区別"];
              let owner_type = mcData["所有者の情報_個人又は法人の別"];
              let ownership = "";
              let animal_handler_business_type = "1";
              let animal_handle_1 = [];
              let animal_handle_2 = [];
              if (type === "1") {
                ownership = "1";
              } else if (type === "2") {
                ownership = "2";
                animal_handler_business_type = "1";
              } else if (type === "3") {
                ownership = "2";
                animal_handler_business_type = "2";
              }

              if (animal_handler_business_type == "1") {
                if (mcData["動物取扱業関連情報_第一種_動物取扱業の情報_販売"]) {
                  animal_handle_1.push("1");
                }
                if (mcData["動物取扱業関連情報_第一種_動物取扱業の情報_保管"]) {
                  animal_handle_1.push("2");
                }
                if (
                  mcData["動物取扱業関連情報_第一種_動物取扱業の情報_貸出し"]
                ) {
                  animal_handle_1.push("3");
                }
                if (mcData["動物取扱業関連情報_第一種_動物取扱業の情報_訓練"]) {
                  animal_handle_1.push("4");
                }
                if (mcData["動物取扱業関連情報_第一種_動物取扱業の情報_展示"]) {
                  animal_handle_1.push("5");
                }
                if (
                  mcData[
                    "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業"
                  ]
                ) {
                  animal_handle_1.push("6");
                }
                if (
                  mcData[
                    "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業"
                  ]
                ) {
                  animal_handle_1.push("7");
                }
              } else if (animal_handler_business_type == "2") {
                if (
                  mcData[
                    "動物取扱業関連情報_第二種_動物取扱業の情報_譲渡し"
                  ] !== "0"
                ) {
                  animal_handle_2.push("1");
                }
                if (
                  mcData["動物取扱業関連情報_第二種_動物取扱業の情報_保管"] !==
                  "0"
                ) {
                  animal_handle_2.push("2");
                }
                if (
                  mcData[
                    "動物取扱業関連情報_第二種_動物取扱業の情報_貸出し"
                  ] !== "0"
                ) {
                  animal_handle_2.push("3");
                }
                if (
                  mcData["動物取扱業関連情報_第二種_動物取扱業の情報_訓練"] !==
                  "0"
                ) {
                  animal_handle_2.push("4");
                }
                if (
                  mcData["動物取扱業関連情報_第二種_動物取扱業の情報_展示"] !==
                  "0"
                ) {
                  animal_handle_2.push("5");
                }
              }

              let initialValues = {
                oldMC: true,
                PIN: mcData["暗証記号"],
                ownership: ownership,
                owner_type: owner_type,
                lastName:
                  owner_type === "1"
                    ? mcData["所有者の情報_氏名_姓／法人名"]
                    : "",
                firstName:
                  owner_type === "1" ? mcData["所有者の情報_氏名_名"] : "",
                lastKana:
                  owner_type === "1"
                    ? mcData["所有者の情報_氏名_姓／法人名（カナ）"]
                    : "",
                firstKana:
                  owner_type === "1"
                    ? mcData["所有者の情報_氏名_名（カナ）"]
                    : "",
                corporation_name:
                  owner_type === "2"
                    ? mcData["所有者の情報_氏名_姓／法人名"]
                    : "",
                corporation_kana_name:
                  owner_type === "2"
                    ? mcData["所有者の情報_氏名_姓／法人名（カナ）"]
                    : "",
                corporation_representative_firstName:
                  owner_type === "2"
                    ? mcData["所有者の情報_代表者の氏名_名"]
                    : "",

                corporation_representative_lastName:
                  owner_type === "2"
                    ? mcData["所有者の情報_代表者の氏名_姓"]
                    : "",
                owner_location_zip3:
                  mcData["所有者の情報_郵便番号"].split("-")[0],
                owner_location_zip4:
                  mcData["所有者の情報_郵便番号"].split("-")[1],
                owner_location_prefecture: mcData["所有者の情報_都道府県"],
                owner_location_addressLine1: mcData["所有者の情報_市区町村"],
                owner_location_addressLine2:
                  mcData["所有者の情報_丁目以降・建物名"],
                owner_location_addressLine3: "",
                owner_fixed_phone: mcData["所有者の情報_電話番号"],
                owner_phone: mcData["所有者の情報_携帯電話番号"],
                owner_email: mcData["所有者の情報_電子メールアドレス"],

                mccode: mcData["マイクロチップの識別番号"],
                animal_name: mcData["動物の情報_名"],
                animal_group:
                  mcData["動物の情報_犬又は猫の別"] === "1" ? "犬" : "猫",
                animal_gender:
                  mcData["動物の情報_性別"] === "1" ? "オス" : "メス",
                animal_breed: parseInt(mcData["動物の情報_品種"]).toString(),
                animal_breed_other: mcData["動物の情報_品種_その他"],
                animal_color: parseInt(mcData["動物の情報_毛色"]).toString(),
                animal_color_other: mcData["動物の情報_毛色_その他"],
                animal_birthDay: mcData["動物の情報_生年月日"],
                animalAddressCheck: [],
                animal_location_zip3:
                  mcData["動物の情報_動物の所在地_郵便番号"].split("-")[0],
                animal_location_zip4:
                  mcData["動物の情報_動物の所在地_郵便番号"].split("-")[1],
                animal_location_prefecture:
                  mcData["動物の情報_動物の所在地_都道府県"],
                animal_location_addressLine1:
                  mcData["動物の情報_動物の所在地_市区町村"],
                animal_location_addressLine2:
                  mcData["動物の情報_動物の所在地_丁目以降・建物名"],
                animal_location_addressLine3: "",
                animal_note: mcData["動物の情報_特徴となるべき事項(備考)"],
                animal_handler_business_type: animal_handler_business_type,
                animal_handle_1: animal_handle_1,
                animal_handle_2: animal_handle_2,
                animal_handler_business_type1_slot1:
                  mcData["動物取扱業関連情報_第一種_動物取扱業の情報_販売"],
                animal_handler_business_type1_slot2:
                  mcData["動物取扱業関連情報_第一種_動物取扱業の情報_保管"],
                animal_handler_business_type1_slot3:
                  mcData["動物取扱業関連情報_第一種_動物取扱業の情報_貸出し"],
                animal_handler_business_type1_slot4:
                  mcData["動物取扱業関連情報_第一種_動物取扱業の情報_訓練"],
                animal_handler_business_type1_slot5:
                  mcData["動物取扱業関連情報_第一種_動物取扱業の情報_展示"],
                animal_handler_business_type1_slot6:
                  mcData[
                    "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業"
                  ],
                animal_handler_business_type1_slot7:
                  mcData[
                    "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業"
                  ],
                animal_mother_time:
                  mcData["動物取扱業関連情報_親の雌犬又は雌猫の所有時期"],
                animal_mother_mccode:
                  mcData[
                    "動物取扱業関連情報_親の雌犬又は雌猫に装着されているマイクロチップの識別番号"
                  ],
                animal_mother_mccode_not_reason:
                  mcData[
                    "動物取扱業関連情報_親の雌犬又は雌猫に装着されているマイクロチップの識別番号_未入力理由"
                  ],
              };

              let key = mcData["マイクロチップの識別番号"];
              let value = {
                status: true,
                data: initialValues,
              };
              setMcOK((prev) => prev.concat({ ...value, key }));

            } else {
              let key = mcData["マイクロチップの識別番号"];
              let value = {
                status: false,
                mgs: "このマイクロチップの番号はすでに登録されています",
              };

              setMcError((prev) => prev.concat({ ...value, key }));

            }
          });
        });
      }
    });
  }, [acceptedFiles]);

 

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();

  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();
  const { firebaseSetDoc, isPending: isPending3, error: error4 } = useSetDoc();
  const { firebaseIncrementMany } = useFirebaseIncrement();
  const { userAnalytics, isPending: isPending4 } = useAnalytics();

  const mcregistrationHandler = async () => {
    await Promise.all(
      mcOK.map(async (item, i) => {
        let microchipData = item.data;

        await firebaseSetDoc(
          "microchip",
          {
            ...microchipData,
            currentOwnerUID: user.uid,
            registeredUID: user.uid,
            owners: [
              {
                uid: user.uid,
                name: `${userData.firstName} ${userData.lastName}`,
                group: userData.group,
                time: new Date(),
              },
            ],
          },
          microchipData.mccode.toString()
        ).then(() => {
          const ownersData = {
            mccode: microchipData.mccode.toString(),
            relation: "OWNER",
            mcData: _.omit(microchipData, ["PIN"]),
            PIN: microchipData["PIN"],
          };
          addADoc(`users/${user.uid}/microchip`, ownersData);

          //increase the totalMC in users collenction
          firebaseIncrementMany("users", user.uid, {
            totalMC: increment(1),
          });

          const date = moment();
          const yyyy = moment(date).format("YYYY");
          const mm = moment(date).format("MM");
          const dd = moment(date).format("DD");

          userAnalytics(
            user.uid,
            _.omit(microchipData, ["PIN", "oldMC"]),
            yyyy,
            mm,
            dd,
            "R"
          );

          //save update trigger
          const updateList =
            JSON.parse(localStorage.getItem("MY_DATA_1_Up")) || [];
          updateList.push(microchipData.mccode);
          localStorage.setItem("MY_DATA_1_Up", JSON.stringify(updateList));
        });
      })
    );
    //redirect to success page
    navigate("/mypage/csvfilecomplete");
  };

  return (
    <section className="container">
      {fileRejections.length > 0 && (
        <Alert variant="danger" className="">
          {fileRejections.map(({ file, errors }) => (
            <li key={file.path}>
            {file.path} - {file.size} bytes
            <ol>
              {errors.map(e => (
                <li key={e.code}>{
                  e.code === 'file-invalid-type' ? 'ファイル形式は.csvに限ります。' :
                  e.code === 'file-too-large' ? 'ファイルが1MB以上である。' :
                  e.message
                  }</li>
              ))}
            </ol>
          </li>
          ))}
        </Alert>
      )}
      {customError && (
        <Alert variant="danger" className="text-center">
          {customError}
        </Alert>
      )}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="m-0">
          ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択します。
        </p>
      </div>
      <aside className="mt-5">
        {isPending && (
          <div className="text-center">
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}

        {!isPending && (
          <>
            {(mcOK.length > 0 || mcError.length > 0) && (
              <>
                {!isPending2 && !isPending3 && !isPending4 && (
                  <Button
                    size="lg"
                    onClick={mcregistrationHandler}
                    disabled={mcOK.length === 0}
                  >
                    アップロードデータ数：{mcOK.length}
                  </Button>
                )}

                {(isPending2 || isPending3 || isPending4) && (
                  <Button size="lg" disabled>
                    アップロードデータ数：{mcOK.length}{" "}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </>
            )}
            <br />
            <br />
            {mcError.length > 0 && (
              <Button size="lg" variant="danger" disabled>
                エラーデータ数：{mcError.length}
              </Button>
            )}
          </>
        )}
      </aside>
    </section>
  );
}

export default CSVFile;
