import React, { useState } from "react";
import * as yup from "yup";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  useAccordionButton
} from "react-bootstrap";
import { Formik } from "formik";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import _ from "lodash";
import MCInputVet from "../mcregistration/MCInputVet";
import MCInputAnimal from "../mcregistration/MCInputAnimal";
import MCInputOwner from "../mcregistration/MCInputOwner";
import MCInputOwnerDetails from "../mcregistration/MCInputOwnerDetails";
import MCInputAnimalMother from "../mcregistration/MCInputAnimalMother";
import MCInputSchema from "../mcregistration/MCInputSchema";
import MCInputSchemaAnimal from "../mcregistration/MCInputSchemaAnimal";

export default function MCEdit() {
  const navigate = useNavigate();
  const { userData } = useUserDetailsContext();

  //   const [data, setData] = useState(
  //     JSON.parse(localStorage.getItem("mccode")) || false
  //   );

  const [vetLocationZipError, setVetLocationZipError] = useState(true);
  const [ownerLocationZipError, setOwnerLocationZipError] = useState(true);
  const [animalLocationZipError, setAnimalLocationZipError] = useState(true);

  const [sectionError1, setSectionError1] = useState(false);
  const [sectionError2, setSectionError2] = useState(false);
  const [sectionError3, setSectionError3] = useState(false);
  const [sectionError4, setSectionError4] = useState(false);
  const [sectionError5, setSectionError5] = useState(false);

  let initialValues = { ...JSON.parse(localStorage.getItem("mcDataEdit")) };

  let PIN =
    localStorage.getItem("PIN") === "false"
      ? false
      : localStorage.getItem("PIN");
  let fullData = localStorage.getItem("mcFullDataEdit")
    ? JSON.parse(localStorage.getItem("mcFullDataEdit"))
    : false;

  let { schema } = fullData.unregistered
    ? MCInputSchemaAnimal(animalLocationZipError)
    : MCInputSchema(
        vetLocationZipError,
        ownerLocationZipError,
        animalLocationZipError,
        initialValues.oldMC
      );

  if (PIN) {
    if (!initialValues.hasOwnProperty("overseas")) {
      initialValues = { ...initialValues, overseas: "" };
    }
    schema = schema.shape({
      overseas: yup
        .string()
        .required("この欄は必須です")
        .oneOf(["1", "2"], "海外出国をお選びください")
    });

    //  // console.log(initialValues, schema)
  }

  const handleSubmit = (values) => {
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("mcDataEdit", serializedState);
      navigate("/mypage/mceditcheck");
    } catch (err) {
      return undefined;
    }
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <div className="text-center mt-4">
        <Button
          className="mt-1"
          variant="primary"
          type="button"
          onClick={decoratedOnClick}
        >
          {children}
        </Button>
      </div>
    );
  }

  const SectionErrorMgs = () => {
    return (
      <Badge className="ms-2" bg="danger">
        エラー
      </Badge>
    );
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={1}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ values, handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Accordion
                    defaultActiveKey={
                      fullData.unregistered ? "2" : `${PIN ? "2" : "1"}`
                    }
                  >
                    {!fullData.unregistered && !PIN && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          マイクロチップ装着証明書の入力{" "}
                          {sectionError1 && (
                            <>
                              <SectionErrorMgs />
                            </>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <MCInputVet
                            setVetLocationZipError={setVetLocationZipError}
                            sectionError={setSectionError1}
                          />
                          <CustomToggle eventKey="2">
                            <b>
                              次へ{" "}
                              <FontAwesomeIcon icon={faCircleChevronRight} />
                            </b>
                          </CustomToggle>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        犬・猫の情報入力{" "}
                        {sectionError2 && (
                          <>
                            <SectionErrorMgs />
                          </>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <MCInputAnimal
                          setAnimalLocationZipError={setAnimalLocationZipError}
                          sectionError={setSectionError2}
                          PIN={PIN}
                          unregistered={fullData.unregistered}
                        />

                        {!fullData.unregistered ? (
                          <CustomToggle eventKey="3">
                            <b>
                              次へ{" "}
                              <FontAwesomeIcon icon={faCircleChevronRight} />
                            </b>
                          </CustomToggle>
                        ) : (
                          <div className="text-center mt-4">
                            <Button
                              className="mt-1"
                              variant="primary"
                              type="submit"
                            >
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </Button>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    {!fullData.unregistered && (
                      <>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            所有者情報の入力{" "}
                            {sectionError3 && (
                              <>
                                <SectionErrorMgs />
                              </>
                            )}
                          </Accordion.Header>
                          <Accordion.Body>
                            <MCInputOwner
                              setOwnerLocationZipError={
                                setOwnerLocationZipError
                              }
                              sectionError={setSectionError3}
                              PIN={PIN}
                            />

                            {values.ownership === "2" ? (
                              <CustomToggle eventKey="4">
                                <b>
                                  次へ{" "}
                                  <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                  />
                                </b>
                              </CustomToggle>
                            ) : (
                              <div className="text-center mt-4">
                                <Button
                                  className="mt-1"
                                  variant="primary"
                                  type="submit"
                                >
                                  <b>
                                    次へ{" "}
                                    <FontAwesomeIcon
                                      icon={faCircleChevronRight}
                                    />
                                  </b>
                                </Button>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>

                        {values.ownership === "2" && (
                          <>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                動物取扱業関連情報の入力{" "}
                                {sectionError4 && (
                                  <>
                                    <SectionErrorMgs />
                                  </>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                <MCInputOwnerDetails
                                  sectionError={setSectionError4}
                                  PIN={PIN}
                                />
                                {values.animal_handler_business_type === "1" &&
                                _.includes(values.animal_handle_1, "1") ? (
                                  <CustomToggle eventKey="5">
                                    <b>
                                      次へ{" "}
                                      <FontAwesomeIcon
                                        icon={faCircleChevronRight}
                                      />
                                    </b>
                                  </CustomToggle>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Button
                                      className="mt-1"
                                      variant="primary"
                                      type="submit"
                                    >
                                      <b>
                                        次へ{" "}
                                        <FontAwesomeIcon
                                          icon={faCircleChevronRight}
                                        />
                                      </b>
                                    </Button>
                                  </div>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                            {values.animal_handler_business_type === "1" &&
                              _.includes(values.animal_handle_1, "1") && (
                                <>
                                  <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                      メス親情報の入力{" "}
                                      {sectionError5 && (
                                        <>
                                          <SectionErrorMgs />
                                        </>
                                      )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <MCInputAnimalMother
                                        sectionError={setSectionError5}
                                      />

                                      <div className="text-center mt-4">
                                        <Button
                                          className="mt-1"
                                          variant="primary"
                                          type="submit"
                                        >
                                          <b>
                                            次へ{" "}
                                            <FontAwesomeIcon
                                              icon={faCircleChevronRight}
                                            />
                                          </b>
                                        </Button>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </>
                              )}
                          </>
                        )}

                        {fullData.sellPlatform && (
                          <>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                LOOKFOR{" "}
                                {sectionError4 && (
                                  <>
                                    <SectionErrorMgs />
                                  </>
                                )}
                              </Accordion.Header>
                              <Accordion.Body></Accordion.Body>
                            </Accordion.Item>
                          </>
                        )}
                      </>
                    )}
                  </Accordion>
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
                  <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
