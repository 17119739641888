import { createContext, useContext } from "react";

import { useGetDocSnap } from "../hooks/useGetDocSnap";
import { useAuthContext } from "../hooks/useAuthContext";
import { isValidDate } from "../utils/Utils";

const UserDetailsContext = createContext();

export function UserDetailsProvider({ children }) {
  const { user } = useAuthContext();
  const { data: userData, isPending } = useGetDocSnap(
    "users",
    user ? user.uid : "NOTING"
  );

  // Validate birthDay and assign an empty string if invalid
  const validatedUserData = {
    ...userData,
    birthDay: isValidDate(userData?.birthDay) ? userData.birthDay : "",
  };

  return (
    <UserDetailsContext.Provider
      value={{ userData: validatedUserData, isPending }}>
      {children}
    </UserDetailsContext.Provider>
  );
}

export const useUserDetailsContext = () => useContext(UserDetailsContext);
