import { useState } from "react";
import { downloadPdfDocument } from "../utils/SellingContract/SellingContractFormatter";

export const useSellingContractPdf = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const pdfMakerFn = async (data, userData, userInfo, values, method) => {
    setError(null);
    setIsPending(true);
    return downloadPdfDocument(data, userData, userInfo, values, method)
      .then((res) => {
        setIsPending(false);
        return res;
      })
      .catch((err) => {
        setIsPending(false);
        setError(err);
        // console.log(err);
      });
  };
  return { error, isPending, pdfMakerFn };
};
