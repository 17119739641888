import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet, HelmetData } from "react-helmet-async";
import { Alert, Card, Col, Container, Row, Spinner } from "react-bootstrap";

import { useVarifyEmail } from "../../hooks/useVarifyEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function RegEmailVarification() {
  const [searchParams] = useSearchParams();

  const { error, isPending, message, isConfirmed, varifyEmail } =
    useVarifyEmail();

  useEffect(() => {
    varifyEmail(searchParams.get("oobCode"));
  }, []);

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>メール確認</title>
      </Helmet>
      <Container className="my-5">
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <Card className="shadow-sm">
              <Card.Body>
                {isPending && (
                  <div className="text-center m-4">
                    <Spinner animation="border" />
                  </div>
                )}
                {error && (
                  <Alert className="text-center" variant="danger">
                    {error}
                  </Alert>
                )}

                {message && (
                  <Alert className="text-center" variant="success">
                    {message}
                  </Alert>
                )}

                <div className="text-center mt-3">
                  <Link className="btn btn-secondary" to="/login">
                    <b>ログインへ</b>　<FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
