import moment from "moment";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useUserDetailsContext } from "../context/UserDetailsContext";

function MCFilter(props) {
  const { userData } = useUserDetailsContext();
  const [initialValues, setinitialValues] = useState({
    startDate: "",
    endDate: "",
    mccode: "",
    dateType: "",
    group: [],
    gender: [],
    relation: [],
  });

  useEffect(() => {
    if (Object.keys(props.returnValue).length) {
      setinitialValues(props.returnValue);
    } else {
      setinitialValues({
        startDate: "",
        endDate: "",
        mccode: "",
        dateType: "",
        group: [],
        gender: [],
        relation: [],
      });
    }
  }, [props.returnValue]);

  const schema = yup.object().shape({
    startDate: yup.date().when("dateType", {
      is: (dateType) => dateType,
      then: yup
        .date()
        .required("この欄は必須です")
        .max(new Date(), "開始日が未来のものであってはならない"),
    }),

    endDate: yup.date().when("dateType", {
      is: (dateType) => dateType,
      then: yup
        .date()
        .required("この欄は必須です")
        .min(
          yup.ref("startDate"),
          "終了日は開始日よりも前にすることはできません"
        ),
    }),

    mccode: yup.string(),
    dateType: yup.string(),
    relation: yup.array(),
    group: yup.array(),
    gender: yup.array(),
  });
  const handleSubmit = (values) => {
    // console.log(values);
    props.handleSearch(values);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
      }) => (
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">MC番号</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>MC番号</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="半角15桁番号でご入力ください"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mccode}
                  name="mccode"
                  autoComplete="off"
                  isInvalid={touched.mccode && errors.mccode}
                  isValid={touched.mccode && !errors.mccode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mccode}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Search by date</Form.Label>
                <Form.Select
                  name="dateType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateType}
                  isInvalid={touched.dateType && errors.dateType}
                  isValid={touched.dateType && !errors.dateType}
                >
                  <option value={""}>Select date type</option>
                  <option value={"登録日"}>登録日</option>
                  {userData.group === "breederpet" && (
                    <option value={"譲渡日"}>譲渡日</option>
                  )}
                  <option value={"死亡日"}>死亡日</option>
                  <option value={"生年月日"}>生年月日</option>
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.dateType}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>開始日</Form.Label>

                    <Form.Control
                      type="date"
                      format="YYYY-MM-DD"
                      // min={moment(birthDay).add(0, "days").format("YYYY-MM-DD")}
                      max={moment().format("YYYY-MM-DD")}
                      placeholder="名"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.startDate}
                      name="startDate"
                      maxLength={100}
                      isInvalid={touched.startDate && errors.startDate}
                      isValid={touched.startDate && !errors.startDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.startDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>終了日</Form.Label>

                    <Form.Control
                      type="date"
                      format="YYYY-MM-DD"
                      // min={moment(birthDay).add(0, "days").format("YYYY-MM-DD")}
                      max={moment().format("YYYY-MM-DD")}
                      placeholder="名"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.endDate}
                      name="endDate"
                      maxLength={100}
                      isInvalid={touched.endDate && errors.endDate}
                      isValid={touched.endDate && !errors.endDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.endDate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <div>
                <Form.Check
                  id="type1"
                  name="relation"
                  label="所有"
                  type="checkbox"
                  value="所有"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.relation.includes("所有")}
                  isInvalid={touched.relation && errors.relation}
                  isValid={touched.relation && !errors.relation}
                />
                {userData.group === "breederpet" && (
                  <Form.Check
                    id="type2"
                    name="relation"
                    label="譲渡"
                    type="checkbox"
                    value="譲渡"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.relation.includes("譲渡")}
                    isInvalid={touched.relation && errors.relation}
                    isValid={touched.relation && !errors.relation}
                  />
                )}
                <Form.Check
                  id="type3"
                  name="relation"
                  label="死亡"
                  type="checkbox"
                  value="死亡"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.relation.includes("死亡")}
                  isInvalid={touched.relation && errors.relation}
                  isValid={touched.relation && !errors.relation}
                />
              </div>

              {touched.relation && errors.relation && (
                <div className="text-danger">{errors.relation}</div>
              )}

              <Row>
                <Col lg={6}>
                  <Form.Label>犬or猫</Form.Label>
                  <Form.Check
                    id="group1"
                    name="group"
                    label="犬"
                    type="checkbox"
                    value="犬"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.group.includes("犬")}
                    isInvalid={touched.group && errors.group}
                    isValid={touched.group && !errors.group}
                  />
                  <Form.Check
                    id="group2"
                    name="group"
                    label="猫"
                    type="checkbox"
                    value="猫"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.group.includes("猫")}
                    isInvalid={touched.group && errors.group}
                    isValid={touched.group && !errors.group}
                  />
                  {touched.group && errors.group && (
                    <div className="text-danger">{errors.group}</div>
                  )}
                </Col>
                <Col lg={6}>
                  <Form.Label>性別</Form.Label>
                  <div>
                    <Form.Check
                      name="gender"
                      id="gender1"
                      label="オス"
                      value="オス"
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.gender.includes("オス")}
                      isInvalid={touched.gender && errors.gender}
                      isValid={touched.gender && !errors.gender}
                    />
                    <Form.Check
                      name="gender"
                      id="gender2"
                      label="メス"
                      value="メス"
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.gender.includes("メス")}
                      isInvalid={touched.gender && errors.gender}
                      isValid={touched.gender && !errors.gender}
                    />
                  </div>

                  {touched.gender && errors.gender && (
                    <div className="text-danger">{errors.gender}</div>
                  )}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>閉じる</Button>
            {!false && (
              <Button variant="secondary" type="submit" onClick={submitForm}>
                保存 <FontAwesomeIcon icon={faCircleChevronRight} />
              </Button>
            )}
            {false && (
              <Button variant="secondary" disabled type="button">
                保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default MCFilter;
