import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
  useAccordionButton,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  email,
  zip3Schema,
  zip4Schema,
  prefectureSchema,
  addressLine1Schema,
  addressLine2Schema,
  addressLine3Schema,
  phoneSchema,
} from "../../utils/InputSchema";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useGetDoc } from "../../hooks/useGetDoc";

import ProgressStep from "../../components/ProgressStep";
import MCInputVet from "./MCInputVet";
// import CertificateImageUpload from "./CertificateImageUpload";

export default function VetMCInstallationInput() {
  const navigate = useNavigate();
  const { userData } = useUserDetailsContext();
  const { width } = useWindowDimensions();
  const { getSingleDocWithQuery, isPending, error } = useGetDoc();
  const [certificateImage, setCertificateImage] = useState([]);
  const [imagePending, setImagePending] = useState(false);

  const [vetLocationZipError, setVetLocationZipError] = useState(true);
  const [breederEmailError, setBreederEmailError] = useState(
    JSON.parse(localStorage.getItem("vetMCInstallBreederpet")) || false
  );

  const [sectionError2, setSectionError2] = useState(false);
  const [sectionError3, setSectionError3] = useState(false);

  const backData = localStorage.getItem("vetmcData");
  const certificateDownloadLink =
    localStorage.getItem("certificateDownloadLink") || false;

  const schema = yup.object().shape({
    vet_firstName: yup
      .string()
      .required("この欄は必須です")
      .max(14, "最小14全角半角混在以上にてご入力ください")
      .matches(
        /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤ヶ”、；＜＞？￥’＆　 （）＃#-]+$/,
        "全角半角混在のみを入力してください"
      ),
    vet_lastName: yup
      .string()
      .max(14, "最小14全角半角混在以上にてご入力ください")
      .matches(
        /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤ヶ”、；＜＞？￥’＆　 （）＃#-]+$/,
        "全角半角混在のみを入力してください"
      ),
    vet_institute: yup
      .string()
      .required("この欄は必須です")
      .max(30, "最小30全角半角混在以上にてご入力ください")
      .matches(
        /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤ヶ”、；＜＞？￥’＆　 （）＃#-]+$/,
        "全角半角混在のみを入力してください"
      ),
    vet_location_zip3: zip3Schema(vetLocationZipError),
    vet_location_zip4: zip4Schema(vetLocationZipError),
    vet_location_prefecture: prefectureSchema(),
    vet_location_addressLine1: addressLine1Schema(),
    vet_location_addressLine2: addressLine2Schema(),
    vet_location_addressLine3: addressLine3Schema(),
    vet_phone: phoneSchema(),
    certificateDownloadLink: yup.string(),
    // mccode: yup
    //   .string()
    //   .required("この欄は必須です")
    //   .matches(/^[0-9]{15}$/, "半角15桁番号でご入力ください")
    //   .length(15, "半角15桁番号でご入力ください"),
    mcdate: yup
      .date()
      .required("この欄は必須です")
      .max(new Date(), "未来の日付を選択できません"),
    breederpet_email: email().test(
      "breederEmailValidation",
      "このメールアドレスで登録されているユーザーはいません",
      function (value) {
        // console.log("in yup breederEmailError");
        if (
          value &&
          value.match(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          )
        ) {
          // console.log(value);
          breederEmailValidation(value);
        } else {
          setBreederEmailError(false);
        }
        return breederEmailError;
      }
    ),

    // certificate: yup
    //   .mixed()
    //   .required("この欄は必須です")
    //   .test(
    //     "fileSize",
    //     "File size too large, max file size is 1 Mb",
    //     () => {
    //     // console.log("in validate", file);
    //       return certificateImage && certificateImage.size <= 1100000;
    //     }
    //   )
    //   .test(
    //     "fileType",
    //     "Incorrect file type",
    //     () =>
    //       certificateImage && ["image/png", "image/jpg", "image/jpeg"].includes(certificateImage.type)
    //   ),
  });

  const handleSubmit = (values) => {
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("vetmcData", serializedState);
      localStorage.setItem(
        "vetMCInstallBreederpet",
        JSON.stringify(breederEmailError)
      );
      localStorage.setItem("vetMCInstallCertificateImage", certificateImage);
      navigate("/mypage/vetmcinstallinputcheck");
    } catch (err) {
      return undefined;
    }
  };

  const breederEmailValidation = (email) => {
    // check it user with this email exist and the user is owner
    // console.log("in breederEmailValidation function");
    try {
      getSingleDocWithQuery("users", [
        ["email", "==", email],
        ["group", "==", "breederpet"],
      ]).then((res) => {
        setBreederEmailError(res);
        if (res) {
          document.getElementById("breederpet_email").blur();
        }
      });
    } catch (error) {
      // console.log("error", error);
    }
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <div className="text-center mt-4">
        <Button
          className="mt-1"
          variant="primary"
          type="button"
          onClick={decoratedOnClick}
        >
          {children}
        </Button>
      </div>
    );
  }

  const SectionErrorMgs = () => {
    return (
      <Badge className="ms-2" bg="danger">
        エラー
      </Badge>
    );
  };

  let initialValues = backData
    ? {
        ...JSON.parse(backData),
      }
    : {
        mcdate: "",
        vet_institute: userData && userData.vet_institute,
        vet_lastName: userData && userData.lastName,
        vet_firstName: userData && userData.firstName,
        vet_location_zip3: userData && userData.zip3,
        vet_location_zip4: userData && userData.zip4,
        vet_location_prefecture: userData && userData.prefecture,
        vet_location_addressLine1: userData && userData.addressLine1,
        vet_location_addressLine2: userData && userData.addressLine2,
        vet_location_addressLine3: userData && userData.addressLine3,
        vet_phone: userData && userData.phone,
        breederpet_email: "",
        certificateDownloadLink: "",
      };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={2}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                touched,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        1. ブリーダー/
                        <span className="text-nowrap">ペットショップ</span>{" "}
                        {touched.breederpet_email &&
                          errors.breederpet_email && <SectionErrorMgs />}
                      </Accordion.Header>
                      <Accordion.Body>
                        {/* <MCInputVet
                          setVetLocationZipError={setVetLocationZipError}
                          sectionError={setSectionError2}
                        /> */}

                        <Row className="px-lg-3">
                          <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${
                              width < 576 && `border`
                            } ${
                              width >= 576 &&
                              `border-bottom border-top border-start `
                            } border-secondary p-2`}
                          >
                            電子メールアドレス{" "}
                            <span className="text-danger">※</span>
                          </Col>
                          <Col
                            lg={9}
                            className={`${
                              width < 576 &&
                              `border-start border-end border-bottom`
                            } ${
                              width >= 576 &&
                              `border-top border-start border-bottom border-end`
                            } p-1 border-end border-secondary`}
                          >
                            {/* <InputGroup hasValidation> */}
                            <Form.Control
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.breederpet_email}
                              name="breederpet_email"
                              id="breederpet_email"
                              maxLength={100}
                              isInvalid={
                                touched.breederpet_email &&
                                errors.breederpet_email
                              }
                              isValid={
                                touched.breederpet_email &&
                                !errors.breederpet_email
                              }
                              required
                              autoComplete="off"
                            />
                            {/* <Button
                                variant="secondary"
                                id="button-addon2"
                                disabled={errors.breederpet_email}
                                onClick={() => {
                                  breederEmailValidation(
                                    values.breederpet_email
                                  );
                                }}
                              >
                                探す
                              </Button> */}
                            <Form.Control.Feedback type="invalid">
                              {errors.breederpet_email}
                            </Form.Control.Feedback>
                            {/* </InputGroup> */}
                          </Col>
                        </Row>

                        {breederEmailError && (
                          <Row className="justify-content-center">
                            <Col lg={10}>
                              <Card
                                border="primary"
                                style={{ width: "fit-content" }}
                                className="mx-auto mt-3"
                              >
                                <Card.Header>
                                  ブリーダー/
                                  <span className="text-nowrap">
                                    ペットショップ
                                  </span>
                                  の<span className="text-nowrap">詳細</span>
                                </Card.Header>
                                <Card.Body>
                                  <>
                                    <Table
                                      borderless
                                      style={{ width: "fit-content" }}
                                      className="mx-auto my-0"
                                    >
                                      <tbody>
                                        {breederEmailError[0].type === "1" && (
                                          <tr>
                                            <th>氏名:</th>
                                            <td>
                                              <ruby>
                                                {breederEmailError[0].lastName}
                                                <rt>
                                                  {
                                                    breederEmailError[0]
                                                      .lastKana
                                                  }
                                                </rt>
                                              </ruby>{" "}
                                              <ruby>
                                                {breederEmailError[0].firstName}
                                                <rt>
                                                  {
                                                    breederEmailError[0]
                                                      .firstKana
                                                  }
                                                </rt>
                                              </ruby>
                                            </td>
                                          </tr>
                                        )}

                                        {breederEmailError[0].type === "2" && (
                                          <tr>
                                            <th>法人名:</th>
                                            <td>
                                              <ruby>
                                                {
                                                  breederEmailError[0]
                                                    .corporation_name
                                                }
                                                <rt>
                                                  {
                                                    breederEmailError[0]
                                                      .corporation_kana_name
                                                  }
                                                </rt>
                                              </ruby>
                                            </td>
                                          </tr>
                                        )}

                                        <tr>
                                          <th>
                                            携帯
                                            <span className="text-nowrap">
                                              電話番号
                                            </span>
                                            :
                                          </th>
                                          <td>
                                            <ruby>
                                              {breederEmailError[0].phone}
                                            </ruby>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        )}
                        <CustomToggle eventKey="2">
                          <b>
                            次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                          </b>
                        </CustomToggle>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        2. 所有者情報の入力{" "}
                        {sectionError2 && <SectionErrorMgs />}
                      </Accordion.Header>
                      <Accordion.Body>
                        <MCInputVet
                          setVetLocationZipError={setVetLocationZipError}
                          sectionError={setSectionError2}
                        />
                        {/* <CustomToggle eventKey="3">
                          <b>
                            次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                          </b>
                        </CustomToggle> */}

                        <div className="text-center mt-4">
                          {/* {imagePending && (
                            <Button className="mt-1" variant="primary" disabled>
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>{" "}
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          )}

                          {!imagePending && ( */}
                            <Button
                              className="mt-1"
                              variant="primary"
                              type="submit"
                              
                            >
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </Button>
                          {/* )} */}
                        </div>

                      </Accordion.Body>
                    </Accordion.Item>

                    {/* <Accordion.Item eventKey="3">
                      <Accordion.Header>3. 装着証明書</Accordion.Header>
                      <Accordion.Body>
                        <Row className="px-lg-3">
                          <Col>
                            <CertificateImageUpload
                              certificateImage={setCertificateImage}
                              imagePending={setImagePending}
                            />
                          </Col>
                        </Row>

                        {!certificateImage.length > 0 &&
                          certificateDownloadLink && (
                            <Row className="justify-content-center mb-4">
                              <Col lg={3} md={4}>
                                <img
                                  src={certificateDownloadLink}
                                  alt=""
                                  className="w-100"
                                />
                              </Col>
                            </Row>
                          )}
                        <div className="text-center mt-4">
                          {imagePending && (
                            <Button className="mt-1" variant="primary" disabled>
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>{" "}
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          )}

                          {!imagePending && (
                            <Button
                              className="mt-1"
                              variant="primary"
                              type="submit"
                              disabled={
                                certificateImage.length > 0 ? false : true
                              }
                            >
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </Button>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
