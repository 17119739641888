import * as yup from "yup";
import _ from "lodash";

import {
  allAnimalSchema
} from "../../utils/InputSchema";

export default function MCInputSchemaAnimal(animalLocationZipError) {
  let schema = yup.object().shape(allAnimalSchema(animalLocationZipError));

  return { schema };
}
