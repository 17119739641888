import React from "react";

import { Link } from "react-router-dom";
import { Alert, Col, Container, Row } from "react-bootstrap";

import ProgressStep from "../../components/ProgressStep";


function PlanComplete() {
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "互助制度の選択", link: "#" },
              { value: "プラン選択", link: "#" },
              { value: "支払", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={4}
            varient={"white"}
          />
        </Row>
        <Row className="justify-content-center my-5">
          <Col lg={6} md={8}>
            <Alert variant="success" className="text-center">
              完了しました！
            </Alert>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <div className="text-center ">
              <Link className="btn btn-secondary m-2" to="/mypage">
                マイページ
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PlanComplete;
