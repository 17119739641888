import React, { useEffect, useState } from "react";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { Button, ButtonGroup, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { UrlModal } from "./UrlModal";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAd, faAdd } from "@fortawesome/free-solid-svg-icons";
import MetaTagDeleteButton from "../../components/MetaTagDeleteButton";
import { customPaginationOptions } from "../../utils/Utils";

export default function UpdateMETA() {
  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const [datas, setDatas] = useState(false);
  const [showUrlDetailsModal, setShowUrlDetailsModal] = useState(false);
  const [showUrlAddModal, setShowUrlAddModal] = useState(false);
  const [urlModalContent, setUrlModalContent] = useState(null);
  const {
    deleteDocument,
    isPending: isPending5,
    error: error5,
  } = useDeleteDocs();

  const deleteItem = async (id) => {
    console.log(`petnext_urls`, id);
    await deleteDocument(`petnext_urls`, id);
    loadMore();
  };

  const columns = [
    {
      name: "ページ情報設定",
      selector: (row) => row.url,
      sortable: true,
      style: {
        padding: "2px 0",
      },
    },
    {
      name: "ページタイトル",
      selector: (row) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{row.title}</div>
      ),
      sortable: true,
    },

    {
      name: "ページ説明",
      selector: (row) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{row.description}</div>
      ),
      sortable: true,
    },

    {
      name: <div style={{ textAlign: "center", width: "100%" }}>OGP画像</div>,
      selector: (row) =>
        row.image ? <img src={row.image} className="img-fluid w-100" /> : "",
      maxWidth: "200px",
      style: {
        padding: "10px 0",
      },
    },

    {
      name: "",
      cell: (row) => (
        <>
          <ButtonGroup>
            <Button variant="primary" onClick={() => UrlAddModalHandler(row)}>
              編集
            </Button>
            <MetaTagDeleteButton deleteItem={deleteItem} data={row.id} />
          </ButtonGroup>
        </>
      ),
    },
  ];

  const loadMore = () => {
    getMultipleDocs({
      _collection: `petnext_urls`,
      _query: false,
    });
  };

  const UrlModalHandler = () => {
    // console.log("ok");
    setShowUrlAddModal(true);
  };

  const UrlAddModalHandler = (props) => {
    setShowUrlDetailsModal(true);
    setUrlModalContent(props);
  };

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    if (documents) {
      setDatas(documents);
    }
  }, [documents]);
  return (
    <>
      <Container>
        <div className="text-end my-2">
          <Button
            variant="secondary ml-auto"
            onClick={() => {
              UrlModalHandler();
            }}>
            <FontAwesomeIcon icon={faAdd} /> 新規追加
          </Button>
        </div>
        <div className="text-end my-2"></div>
        {datas && (
          <DataTable
            columns={columns}
            progressPending={isPending}
            progressComponent={
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            }
            data={datas}
            pagination
            paginationComponentOptions={customPaginationOptions}
          />
        )}
      </Container>

      <UrlModal
        reloadData={loadMore}
        data={urlModalContent}
        show={showUrlDetailsModal}
        onHide={() => setShowUrlDetailsModal(false)}
      />
      <UrlModal
        reloadData={loadMore}
        data={{ type: "new" }}
        show={showUrlAddModal}
        onHide={() => setShowUrlAddModal(false)}
      />
    </>
  );
}
