import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import { Formik, useFormikContext } from "formik";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCircleChevronRight,
  faCircleXmark,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import styles from "./MCStyle.module.css";
import masImage from "./img/masking.png";
import beepSound from "./sound/Barcode-scanner-beep-sound.mp3";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useSetDoc } from "../../hooks/useSetDoc";
import Scanner from "../../components/Scanner";
import { useGetDoc } from "../../hooks/useGetDoc";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";
import { useSendEmail } from "../../hooks/useSendMail";
import { getRandomString } from "../../utils/Utils";
import { useToast } from "../../hooks/useToast";
import moment from "moment";
import { useUserDetailsContext } from "../../context/UserDetailsContext";

export default function PetShopMCRegistration() {
  const { userData } = useUserDetailsContext();
  const [sound, setSound] = useState(true);
  let audio = new Audio(beepSound);
  const beep = () => {
    if (sound) {
      audio.play();
    }
  };

  useEffect(() => {
    localStorage.removeItem("mcData");
    localStorage.removeItem("mccode");
    localStorage.removeItem("userInfo");
  }, []);
  const [showInfo, setShowInfo] = useState(true);
  const [scan, setScan] = useState(false);
  const [customError, setCustomError] = useState(null);
  const [opt, setOpt] = useState(null);
  const [oobCode, setOobCode] = useState(null);

  const toast = useToast();
  const { user } = useAuthContext();
  const { deleteDocument } = useDeleteDocs();
  const { firebaseSetDoc } = useSetDoc();
  const {
    sendMail,
    isPending: mailPending,
    isConfirmed,
    error: mailError,
  } = useSendEmail();

  const { documents: dbmcode } = useCollection("temp_mc", [
    "uid",
    "==",
    user.uid,
  ]);
  const { firebaseGetDoc, getSingleDocWithQuery, isPending, error } =
    useGetDoc();
  const sundMCCode = (code) => {
    firebaseSetDoc(
      "temp_mc",
      { uid: user.uid, mccode: code, type: "R" },
      user.uid
    );
    // console.log(code)
  };

  const navigate = useNavigate();
  let initialValues = {
    mccode: "",
  };

  const schema = yup.object().shape({
    mccode: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[0-9]{15}$/, "半角15桁番号でご入力ください")
      .length(15, "半角15桁番号でご入力ください"),
  });

  //if detect then send barcode to temp_mc table to use real time database
  const _onDetected = (result) => {
    setCustomError(null);
    if (result) {
      sundMCCode(result.codeResult.code);
      beep();
      setScan(false);
    }
  };
  const handleSubmit = (values) => {
    setCustomError(null);
    try {
      //check if mc already registered in petshop or not
      getSingleDocWithQuery(`users/${user.uid}/microchip`, [
        ["mccode", "==", values.mccode.toString()],
      ]).then((res) => {
        // console.log(res);
        if (!res) {
          //check if mc already registered or not
          getSingleDocWithQuery(`microchip`, [
            ["mccode", "==", values.mccode.toString()],
          ]).then(async (res2) => {
            // console.log(res2);
            if (res2) {
              if (!res2[0].death) {
                await sendOTP(res2[0].currentOwnerUID);
                const serializedState = JSON.stringify(res2);
                localStorage.setItem("mcData", serializedState);
                deleteDocument("temp_mc", user.uid);
              } else {
                setCustomError(
                  `Pet with this MC has been died on ${moment(
                    res2[0].deathDetails.date.toDate()
                  ).format("YYYY/MM/DD")}`
                );
              }
            } else {
              setCustomError(
                `${values.mccode}マイクロチップ番号の情報はありません`
              );
            }
          });
        } else {
          setCustomError(
            `${values.mccode}マイクロチップの番号はすでに登録されています`
          );
        }
      });
    } catch (err) {
      return undefined;
    }
  };

  //send otp

  const sendOTP = async (uid) => {
    // check it user with this email exist and the user is owner
    // console.log(uid);
    return await firebaseGetDoc("users", uid).then((res) => {
      // console.log(res);
      //send otp
      if (
        res &&
        ((res.group === "1" && userData.group === "2") ||
          (res.group === "3" && userData.group === "3"))
      ) {
        const randomOTP = Math.floor(100000 + Math.random() * 900000);
        const id = getRandomString(68);
        const varifyURL = `${process.env.REACT_APP_BASE_URL}varify-email?mode=verifyOTP&oobCode=${id}`;
        setOpt(randomOTP);
        setOobCode(id);
        //saving data in localstroage
        localStorage.setItem("userInfo", JSON.stringify({ ...res, id: uid }));

        //sending mail
        sendMail({
          mailOption: {
            from: "【MCRシステム】 <info@microchip-registration.com>",
            to: res.email,
            subject: "マイクロチップ譲渡用ワンタイムパスワードのご連絡",
            text: ``,
            html: `<p>${res.firstName} ${res.lastName} 様</p><br/>
                    この度は、MCRシステムをご利用頂きまして
                    誠にありがとうございます。<br/>
                    <br/>
                    マイクロチップ情報の譲渡認証の為の
                    ワンタイムパスワードをお送りさせて頂きますので
                    どうぞよろしくお願い致します。<br/>
                    <br/>
                    ワンタイムパスワードは下記になります<br/>
                    <p><strong>${randomOTP}</strong></p>
                    <br/>
                    <a href="${varifyURL}">${varifyURL}</a><br/>
                    ワンタイムパスワードを送信<br/>
                    <br/>
                    ※自動認証は上記のボタンをクリックしてください。
              `,
          },
        });
      } else {
        if(userData.group === "2"){
          setCustomError("このマイクロチップの現在の所有者はブリーダーではありません。");
        }else if(userData.group === "3"){
          setCustomError("You can not use this page for transfer from breeder or petshop");
        }
      }
    });
  };
  const saveOTP = async () => {
    await firebaseSetDoc(
      "temp_otp",
      { uid: user.uid, otp: opt, oobCode: oobCode, isVarified: false },
      user.uid
    );
    // console.log(code)
  };
  useEffect(() => {
    if (isConfirmed) {
      toast("success", "ワンタイムパスワードは送信されました。");
      saveOTP();
      navigate("/mypage/mcotp");
    }
  }, [isConfirmed]);

  useEffect(() => {
    setCustomError(null);
  }, [dbmcode]);

  const MyField = () => {
    const { setFieldValue, handleChange, handleBlur, values, touched, errors } =
      useFormikContext();

    useEffect(() => {
      setFieldValue(
        "mccode",
        (dbmcode &&
          dbmcode[0] &&
          dbmcode[0].type === "R" &&
          dbmcode[0].mccode) ||
          values.mccode ||
          ""
      );
    }, [setFieldValue]);

    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>
            MC番号<span className="text-danger">※</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="半角15桁番号でご入力ください"
            onChange={(e) => {
              handleChange(e);
              setCustomError(null);
            }}
            onBlur={handleBlur}
            value={values.mccode}
            name="mccode"
            required
            isInvalid={(touched.mccode && errors.mccode) || customError}
            isValid={touched.mccode && !errors.mccode}
          />
          <Form.Control.Feedback type="invalid">
            {errors.mccode || customError}
          </Form.Control.Feedback>

          <div className="text-center mt-4">
            {!isPending && !mailPending && (
              <Button className="mb-3" variant="primary" type="submit">
                <b>
                  次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                </b>
              </Button>
            )}

            {(isPending || mailPending) && (
              <Button className="mb-3" variant="primary" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                <b>
                  次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                </b>
              </Button>
            )}
          </div>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "#" },
              { value: "OTP", link: "#" },
              { value: "確認", link: "#" },
              { value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={1}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <div className="text-center">
            {showInfo && (
              <Row className="justify-content-center mb-4">
                <Col lg={6} md={8}>
                  <Alert
                    className="mb-0 d-none d-lg-block"
                    variant="info"
                    onClose={() => setShowInfo(false)}
                    dismissible
                  >
                    スマートフォンからバーコードを読み込む場合はスマートフォンからマイページにログインしてください。
                  </Alert>
                </Col>
              </Row>
            )}
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => setScan(!scan)}
            >
              <b>
                {!scan ? "オープンカメラ " : "ストップカメラ "}
                {!scan ? (
                  <FontAwesomeIcon icon={faCamera} />
                ) : (
                  <FontAwesomeIcon icon={faCircleXmark} />
                )}
              </b>
            </Button>
          </div>
          {scan && (
            <>
              <Row className="justify-content-center">
                <div id={styles.soundContainer} className="text-end">
                  {sound ? (
                    <Button variant="light" onClick={() => setSound(false)}>
                      <FontAwesomeIcon icon={faVolumeHigh} />
                    </Button>
                  ) : (
                    <Button variant="light" onClick={() => setSound(true)}>
                      <FontAwesomeIcon icon={faVolumeXmark} />
                    </Button>
                  )}
                </div>
              </Row>
              <div id={styles.videoContainer}>
                <img
                  src={masImage}
                  alt="camera masking"
                  id={styles.cameraMasking}
                />
                <Scanner onDetected={(result) => _onDetected(result)} />
              </div>
            </>
          )}
        </Row>

        <p className="text-center">または</p>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <MyField />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
