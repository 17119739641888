import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import _ from "lodash";

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Helmet, HelmetData } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";

import {
  email,
  password,
  passwordConfirm,
  zip3Schema,
  zip4Schema,
  prefectureSchema,
  addressLine1Schema,
  addressLine2Schema,
  addressLine3Schema,
  phoneSchema,
  phoneSchemaBoth,
  fixedPhoneSchema,
  firstName,
  lastName,
  firstKana,
  lastKana,
  corporation_name,
  corporation_kana_name,
  corporation_representative_lastName,
  corporation_representative_firstName,
  corporation_personincharge_lastName,
  corporation_personincharge_firstName,
  firstNameVet,
  lastNameVet,
  instituteVet,
  username
} from "../../utils/InputSchema";

//component
import ProgressStep from "../../components/ProgressStep";
import SignupInput from "./SignupInput";
import SignupInputVet from "./SignupInputVet";
import TramsText from "../home/TramsText";
import { useGetDoc } from "../../hooks/useGetDoc";

export default function Signup() {
  const { getSingleDocWithQuery } = useGetDoc();
  const [zipError, setZipError] = useState(true);
  const backData = localStorage.getItem("state");

  const [searchParams] = useSearchParams();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let initialValues = {
    type: "1",
    firstName: "",
    lastName: "",
    firstKana: "",
    lastKana: "",
    corporation_name: "",
    corporation_kana_name: "",
    corporation_representative_lastName: "",
    corporation_representative_firstName: "",
    corporation_personincharge_lastName: "",
    corporation_personincharge_firstName: "",
    email: "",
    lookforusername: "",
    emailConfirm: "",
    password: "",
    passwordConfirm: "",
    zip3: "",
    zip4: "",
    prefecture: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    fixed_phone: "",
    phone: "",
    birthDay: "",
    gender: "",
    group: "",
    animal_handler_business_type: "1",
    animal_handle_1: [],
    animal_handle_2: [],
    animal_handler_business_type1_slot1: "",
    animal_handler_business_type1_slot2: "",
    animal_handler_business_type1_slot3: "",
    animal_handler_business_type1_slot4: "",
    animal_handler_business_type1_slot5: "",
    animal_handler_business_type1_slot6: "",
    animal_handler_business_type1_slot7: ""
  };

  let initialValuesVet = {
    vet_institute: "",
    firstName: "",
    lastName: "",
    firstKana: "",
    lastKana: "",
    email: "",
    emailConfirm: "",
    password: "",
    passwordConfirm: "",
    zip3: "",
    zip4: "",
    prefecture: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    phone: "",
    birthDay: "",
    gender: "",
    group: ""
  };

  if (backData && searchParams.get("group") !== "vet") {
    initialValues = {
      ...JSON.parse(localStorage.getItem("state")),
      password: "",
      passwordConfirm: ""
    };
  } else if (backData && searchParams.get("group") === "vet") {
    initialValuesVet = {
      ...JSON.parse(localStorage.getItem("state")),
      password: "",
      passwordConfirm: ""
    };
  }

  const navigate = useNavigate();

  const schema = yup.object().shape({
    type: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["1", "2"], "個人または法人をお選びください"),

    firstName: firstName("type"),
    lastName: lastName("type"),
    firstKana: firstKana("type"),
    lastKana: lastKana("type"),
    corporation_name: corporation_name("type"),
    corporation_kana_name: corporation_kana_name("type"),
    corporation_representative_lastName:
      corporation_representative_lastName("type"),
    corporation_representative_firstName:
      corporation_representative_firstName("type"),

    corporation_personincharge_lastName:
      corporation_personincharge_lastName("type"),
    corporation_personincharge_firstName:
      corporation_personincharge_firstName("type"),
    email: email(),
    lookforusername: username(8).test(
      "is-username-unique",
      "ご登録ユーザーネームがすでに登録済み、もしくは使用できない情報です。",
      async (value) => {
        if (value) {
          let user = await getSingleDocWithQuery("users", [["lookforusername", "==", value]]);
          return !user;
        }
        return true;
      }
    ),
    emailConfirm: yup
      .string()
      .required("この欄は必須です")
      .oneOf([yup.ref("email"), null], "Eメールアドレスが一致しません"),

    password: password(),
    passwordConfirm: passwordConfirm(yup.ref("password")),
    zip3: zip3Schema(zipError),
    zip4: zip4Schema(zipError),
    prefecture: prefectureSchema(),
    addressLine1: addressLine1Schema(),
    addressLine2: addressLine2Schema(),
    addressLine3: addressLine3Schema(),
    fixed_phone: fixedPhoneSchema(),
    phone: phoneSchema(),
    birthDay: yup
      .date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "18歳以上の方に限ります"
      )
      .min(new Date(1850, 0, 1), "有効な日付を入力してください"),

    gender: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["男性", "女性", "指定なし"], "性別をお選びください"),

    animal_handle_1: yup.array().when(["animal_handler_business_type"], {
      is: (animal_handler_business_type) =>
        animal_handler_business_type === "1" &&
        searchParams.get("group") === "breederpet",
      then: yup
        .array()
        .min(1, "1種類以上は入力")
        .of(yup.string().required())
        .required()
    }),
    animal_handle_2: yup.array().when(["animal_handler_business_type"], {
      is: (animal_handler_business_type) =>
        animal_handler_business_type === "2" &&
        searchParams.get("group") === "breederpet",
      then: yup
        .array()
        .min(1, "1種類以上は入力")
        .of(yup.string().required())
        .required()
    }),
    animal_handler_business_type: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["1", "2"], "動物取扱業をお選びください"),

    animal_handler_business_type1_slot1: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "1") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください")
      }),
    animal_handler_business_type1_slot2: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "2") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください")
      }),
    animal_handler_business_type1_slot3: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "3") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください")
      }),
    animal_handler_business_type1_slot4: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "4") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください")
      }),
    animal_handler_business_type1_slot5: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "5") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください")
      }),
    animal_handler_business_type1_slot6: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "6") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください")
      }),
    animal_handler_business_type1_slot7: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "7") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください")
      })
  });

  const schemaVet = yup.object().shape({
    vet_institute: instituteVet(),
    firstName: firstNameVet(),
    lastName: lastNameVet(),
    firstKana: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください"),
    lastKana: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください"),
    email: email(),
    emailConfirm: yup
      .string()
      .required("この欄は必須です")
      .oneOf([yup.ref("email"), null], "Eメールアドレスが一致しません"),

    password: password(),
    passwordConfirm: yup
      .string()
      .required("この欄は必須です")
      .oneOf([yup.ref("password"), null], "パスワードが一致しません"),
    zip3: zip3Schema(zipError),
    zip4: zip4Schema(zipError),
    prefecture: prefectureSchema(),
    addressLine1: addressLine1Schema(),
    addressLine2: addressLine2Schema(),
    addressLine3: addressLine3Schema(),
    phone: phoneSchemaBoth(),
    birthDay: yup
      .date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "18歳以上の方に限ります"
      )
      .min(new Date(1850, 0, 1), "有効な日付を入力してください"),
    gender: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["男性", "女性", "指定なし"], "性別をお選びください")
  });
  const handleSubmit = (values) => {
    // console.log("asdf", values);
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("state", serializedState);
      navigate("/signupcheck");
    } catch (err) {
      return undefined;
    }
  };
  const helmetData = new HelmetData({});

  useEffect(() => {
    //save return url
    if (
      searchParams.get("returnurl") &&
      searchParams.get("returnsite") === "koinu"
    ) {
      localStorage.setItem("returnurl", searchParams.get("returnurl"));
      localStorage.setItem("returnsite", searchParams.get("returnsite"));
    } else {
      localStorage.removeItem("returnurl");
      localStorage.removeItem("returnsite");
    }

    if (
      !(
        searchParams.get("group") === "vet" ||
        searchParams.get("group") === "owner" ||
        searchParams.get("group") === "breederpet"
      )
    ) {
      navigate("/404");
    }
  }, []);

  return (
    <>
      <Helmet helmetData={helmetData}>
        {searchParams.get("group") === "vet" && (
          <>
            <title>新規ご登録 | 獣医師</title>
            <meta property="og:title" content="新規ご登録 | 獣医師" />
            <meta name="description" content="新規ご登録 | 獣医師" />
          </>
        )}
        {searchParams.get("group") === "owner" && (
          <>
            <title>新規ご登録 | 飼い主</title>
            <meta property="og:title" content="新規ご登録 | 飼い主" />
            <meta name="description" content="新規ご登録 | 飼い主" />
          </>
        )}
        {searchParams.get("group") === "breederpet" && (
          <>
            <title>新規ご登録 | ブリーダー/ペットショップ</title>
            <meta
              property="og:title"
              content="新規ご登録 | ブリーダー/ペットショップ"
            />
            <meta
              name="description"
              content="新規ご登録 | ブリーダー/ペットショップ"
            />
          </>
        )}
      </Helmet>
      <div className="main pt-5">
        <Container className="mb-5">
          <h1 className="text-center pb-3">新規ご登録</h1>

          <Row className="justify-content-md-center ">
            <Col lg={9} md={10}>
              <Card className="pt-4">
                <p className="text-center">
                  下記の内容をご入力の上、お進みください。
                  <span className="text-nowrap">
                    <span className="text-danger">※</span> 必須項目
                  </span>
                </p>
                <Row className="justify-content-center mb-4">
                  <ProgressStep
                    props={[
                      { value: "入力", link: "#" },
                      {
                        value: "確認",
                        link: backData ? "/signupcheck" : "#"
                      },
                      { value: "終了", link: "#" }
                    ]}
                    activeNo={1}
                  />
                </Row>
                <Formik
                  initialValues={
                    searchParams.get("group") === "vet"
                      ? initialValuesVet
                      : initialValues
                  }
                  validationSchema={
                    searchParams.get("group") === "vet" ? schemaVet : schema
                  }
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, values, setFieldValue }) => (
                    <Form noValidate>
                      <Container>
                        {searchParams.get("group") === "vet" && (
                          <SignupInputVet
                            zipError={setZipError}
                            ownership={"3"}
                          />
                        )}
                        {searchParams.get("group") === "owner" && (
                          <SignupInput zipError={setZipError} ownership={"1"} />
                        )}
                        {searchParams.get("group") === "breederpet" && (
                          <SignupInput zipError={setZipError} ownership={"2"} />
                        )}
                      </Container>

                      <div className="text-center mt-4">
                        <Button
                          size="lg"
                          className="mb-3"
                          variant="primary"
                          onClick={handleShow}
                        >
                          確認
                        </Button>
                      </div>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        size="lg"
                        scrollable
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            会員規約および
                            <br className="d-block d-sm-none" />
                            個人情報保護方針
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <TramsText />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            閉じる
                          </Button>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={() => {
                              handleSubmit();
                              handleClose();
                              setFieldValue("group", searchParams.get("group"));
                            }}
                          >
                            同意する <FontAwesomeIcon icon={faCheckCircle} />
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
