import React, { useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  doc,
  getDocs,
  Timestamp,
  updateDoc,
  writeBatch
} from "firebase/firestore";

import { db } from "../../../firebase/config";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { useMCList } from "../../../hooks/useMCList";
import { useCollectionDocs } from "../../../hooks/useCollectionDocs";
import { useToast } from "../../../hooks/useToast";
import { useSetDoc } from "../../../hooks/useSetDoc";

const DraggableItem = ({
  id,
  animalID,
  index,
  dragData,
  petDetails,
  setModalData,
  handleDelete
}) => {
  // // console.log({ id, animalID, index, dragData, petDetails });
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="drag-item">
          {petDetails && (
            <>
              <Row>
                <Col xs="4">
                  <img
                    src={
                      petDetails.images[0]
                        ? petDetails.images[0]
                        : "/assets/img/art/p2.png"
                    }
                    alt="profile"
                    className={` rounded`}
                    style={{
                      width: "6rem",
                      height: "6rem",
                      objectFit: "cover"
                    }}
                  />
                </Col>
                <Col xs="8">
                  <p className="mb-0">
                    <span className="fw-bold">{petDetails.animalID}</span> ー{" "}
                    <span className=" fw-bold">{index + 1}</span>番目
                  </p>
                  {moment(
                    new Timestamp(
                      dragData.start_date.seconds,
                      dragData.start_date.nanoseconds
                    ).toDate()
                  ).format("YYYY/MM/DD")}{" "}
                  ~{" "}
                  {moment(
                    new Timestamp(
                      dragData.end_date.seconds,
                      dragData.end_date.nanoseconds
                    ).toDate()
                  ).format("YYYY/MM/DD")}
                  <br />
                  <ButtonGroup size="sm" className="mt-2">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setModalData(dragData);
                      }}>
                      <FontAwesomeIcon icon={faPencil} /> 編集
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(dragData)}>
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> 削除
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </Draggable>
  );
};

const DraggableList = ({
  items,
  moveItem,
  moveItems,
  setModalData,
  handleDelete
}) => {
  // console.log("moveItems", moveItems);
  return (
    <Droppable droppableId="droppable">
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="drag-list">
          {items.map((item, index) => (
            <DraggableItem
              key={item.id}
              id={item.id}
              index={index}
              animalID={item.animalID}
              dragData={item}
              petDetails={moveItems[item.animalID]}
              setModalData={setModalData}
              handleDelete={handleDelete}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const ManageRecommendedMC = ({ setModalData }) => {
  const [items, setItems] = useState([]);
  const [moveItems, setMoveItems] = useState([]);

  const { documents, isPending, getMCList: getRecommends } = useMCList();
  const { documents: moveItem, isPending2, getMCList } = useMCList();
  const toast = useToast();
  const { firebaseSetDoc } = useSetDoc();

  const loadData = async () => {
    getRecommends({
      _collection: `recommended`,
      _query: [
        ["RECOMMEND", "==", true],
        ["showADMIN", "==", true]
      ],
      orderByField: "recommend_position",
      orderByOption: "asc"
    });
  };

  const loadData2 = (array = []) => {
    // console.log("loadData2", array);
    if (array.length) {
      getMCList({
        _collection: `microchip`,
        _query: [["animalID", "in", array]]
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (documents) {
      setItems(documents);
      loadData2(_.map(documents, "animalID"));
    }
  }, [documents]);

  useEffect(() => {
    if (documents && moveItem) {
      let mi = [];
      moveItem.forEach((i) => {
        mi[i.animalID] = i;
      });
      setMoveItems(mi);
    }
  }, [moveItem]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = Array.from(items);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    // // console.log(updatedItems);

    setItems(updatedItems);

    // Update Firestore collection
    const batch = writeBatch(db);
    updatedItems.forEach(async (item, index) => {
      const itemRef = doc(db, "recommended", item.id);
      batch.set(itemRef, { recommend_position: index + 1 }, { merge: true });
    });

    await batch.commit();
  };

  const handleDelete = async (data) => {
    await firebaseSetDoc(
      "recommended",
      {
        display_places: _.pull(data.display_places, "RECOMMEND"),
        RECOMMEND: false
      },
      data.id,
      true
    );
    toast("success", "完了しました！");
  };

  return (
    <>
      <h5>おすすめのワンちゃん一覧</h5>

      <div className="border bg-secondary-subtle rounded">
        {!isPending && !isPending2 && items && moveItems && (
          <DragDropContext onDragEnd={onDragEnd}>
            <DraggableList
              items={items}
              moveItems={moveItems}
              setModalData={setModalData}
              handleDelete={handleDelete}
            />
          </DragDropContext>
        )}
      </div>
    </>
  );
};

export default ManageRecommendedMC;
