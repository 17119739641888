import React, { useEffect, useState } from "react";
import { Button, Form, FormControl, FormGroup, FormLabel, Image, Modal } from "react-bootstrap";
import UrlModalForm from "./UrlModalForm";

export const UrlModal = (props) => {
  const hideModal = () => {
    props.onHide();
  };

  return (
    <>
      {props.data && <UrlModalForm show={props.show} onHide={props.onHide} reloadData={props.reloadData} hideModal={hideModal} data={props.data} />}
    </>
  );
};
