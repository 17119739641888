import React, { Fragment, useEffect } from "react";

import _ from "lodash";
import { Col, Container, Placeholder, Row } from "react-bootstrap";

import PlanItem from "./PlanItem";
import styles from "./Plans.module.css";
import ProgressStep from "../../components/ProgressStep";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
function Plans() {
  const { documents: plans, isPending, getMultipleDocs } = useCollectionDocs();

  useEffect(() => {
    getMultipleDocs({
      _collection: "plans",
      _query: false,
      orderByField: "monthly",
      orderByOption: "asc",
    });
  }, []);

  return (
    <>
      <div className={`h-100`}>
        <Container className="position-relative">
          <Row className="justify-content-center mb-4 mt-5 mt-md-0">
            <ProgressStep
              props={[
                { value: "互助制度の選択", link: "#" },
                { value: "プラン選択", link: "#" },
                { value: "支払", link: "#" },
                { value: "終了", link: "#" },
              ]}
              activeNo={2}
              varient={"white"}
            />
          </Row>
          <Row>
            <Col lg={12} className="">
              <Row className="justify-content-center">
                {isPending &&
                  [
                    [1, 2, 3, 4],
                    [1, 2, 3, 4, 5],
                    [1, 2, 3, 4, 5, 6],
                  ].map((phi, i) => (
                    <Col
                      xxl={3}
                      lg={4}
                      md={7}
                      xs={11}
                      className=" mt-5"
                      key={i}>
                      <div className={`${styles.plan} shadow rounded border`}>
                        <div className={`${styles.planHeader} text-center p-3`}>
                          <h4 className="placeholder-glow fs-2 ">
                            <span className="placeholder col-7 rounded"></span>
                          </h4>
                          <p>
                            <span className="fs-2">¥</span>
                            <span className="fs-1">
                              <strong className="placeholder-glow">
                                <span className="placeholder col-5 rounded"></span>
                              </strong>
                            </span>
                            <span className="fs-4">/月(税込)</span>
                          </p>
                          <Placeholder.Button
                            xs={4}
                            variant="secondary"
                            aria-hidden="true"
                          />
                        </div>
                        <div className={`${styles.planBody} p-3`}>
                          <ul className="fs-6">
                            {phi.map((item, i) => (
                              <Fragment key={i}>
                                <li className="placeholder-glow">
                                  <strong>
                                    <span
                                      className={`placeholder col-${_.random(
                                        5,
                                        11
                                      )}  fs-3 rounded`}></span>
                                  </strong>
                                  {_.random(0, 1) ? (
                                    <>
                                      <br />
                                      <span
                                        className={`placeholder col-${_.random(
                                          5,
                                          12
                                        )}  rounded`}></span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </li>
                                {phi.length - 1 !== i && <hr />}
                              </Fragment>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  ))}
                {!isPending &&
                  plans &&
                  plans.map((plan, i) => (
                    <Col
                      xxl={3}
                      lg={4}
                      md={7}
                      xs={11}
                      className=" mt-5"
                      key={i}>
                      <PlanItem planData={plan} />
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <div className="text-center mt-4">
          <Link className="btn btn-secondary m-2" to="/mypage/plan/aid">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
          </Link>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}

export default Plans;
