import React, { useState } from "react";
import * as yup from "yup";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { Formik } from "formik";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import _ from "lodash";
import MCInputVet from "./MCInputVet";
import MCInputAnimal from "./MCInputAnimal";
import MCInputOwner from "./MCInputOwner";
import MCInputOwnerDetails from "./MCInputOwnerDetails";
import MCInputAnimalMother from "./MCInputAnimalMother";
import MCInputSchema from "./MCInputSchema";
import { getRandomString } from "../../utils/Utils";

export default function MCRegistrationFromPDFinput() {
  const navigate = useNavigate();
  const { userData } = useUserDetailsContext();

//   const [data, setData] = useState(
//     JSON.parse(localStorage.getItem("mccode")) || false
//   );

  const [vetLocationZipError, setVetLocationZipError] = useState(true);
  const [ownerLocationZipError, setOwnerLocationZipError] = useState(true);
  const [animalLocationZipError, setAnimalLocationZipError] = useState(true);

  const [sectionError1, setSectionError1] = useState(false);
  const [sectionError2, setSectionError2] = useState(false);
  const [sectionError3, setSectionError3] = useState(false);
  const [sectionError4, setSectionError4] = useState(false);
  const [sectionError5, setSectionError5] = useState(false);

  let initialValues = {
    mcdate: "",
    animalID:  getRandomString(8),
    vet_institute: "",
    vet_lastName: "",
    vet_firstName: "",
    vet_location_zip3: "",
    vet_location_zip4: "",
    vet_location_prefecture: "",
    vet_location_addressLine1: "",
    vet_location_addressLine2: "",
    vet_location_addressLine3: "",
    vet_phone: "",
    certificateDownloadLink: "",

    ownership: "2",
    owner_type: userData.type,
    lastName: userData.type === "1" ? userData.lastName : "",
    firstName: userData.type === "1" ? userData.firstName : "",
    lastKana: userData.type === "1" ? userData.lastKana : "",
    firstKana: userData.type === "1" ? userData.firstKana : "",
    corporation_name: userData.type === "2" ? userData.corporation_name : "",
    corporation_kana_name:
      userData.type === "2" ? userData.corporation_kana_name : "",
    corporation_representative_firstName:
      userData.type === "2"
        ? userData.corporation_representative_firstName
        : "",
    corporation_representative_lastName:
      userData.type === "2" ? userData.corporation_representative_lastName : "",
    owner_location_zip3: userData.zip3,
    owner_location_zip4: userData.zip4,
    owner_location_prefecture: userData.prefecture,
    owner_location_addressLine1: userData.addressLine1,
    owner_location_addressLine2: userData.addressLine2,
    owner_location_addressLine3: userData.addressLine3,
    owner_fixed_phone: userData.fixed_phone,
    owner_phone: userData.phone,
    owner_email: userData.email,

    animal_name: "",
    animal_group: "",
    animal_gender: "",
    animal_breed: "",
    animal_breed_other: "",
    animal_color: "",
    animal_color_other: "",
    animal_birthDay: "",
    animalAddressCheck: [],
    animal_location_zip3: "",
    animal_location_zip4: "",
    animal_location_prefecture: "",
    animal_location_addressLine1: "",
    animal_location_addressLine2: "",
    animal_location_addressLine3: "",
    animal_note: "",
    animal_handler_business_type: userData.animal_handler_business_type,
    animal_handle_1: [...userData.animal_handle_1],
    animal_handle_2: [...userData.animal_handle_2],
    animal_handler_business_type1_slot1:
      userData.animal_handler_business_type1_slot1
        ? userData.animal_handler_business_type1_slot1
        : "",
    animal_handler_business_type1_slot2:
      userData.animal_handler_business_type1_slot2
        ? userData.animal_handler_business_type1_slot2
        : "",
    animal_handler_business_type1_slot3:
      userData.animal_handler_business_type1_slot3
        ? userData.animal_handler_business_type1_slot3
        : "",
    animal_handler_business_type1_slot4:
      userData.animal_handler_business_type1_slot4
        ? userData.animal_handler_business_type1_slot4
        : "",
    animal_handler_business_type1_slot5:
      userData.animal_handler_business_type1_slot5
        ? userData.animal_handler_business_type1_slot5
        : "",
    animal_handler_business_type1_slot6:
      userData.animal_handler_business_type1_slot6
        ? userData.animal_handler_business_type1_slot6
        : "",
    animal_handler_business_type1_slot7:
      userData.animal_handler_business_type1_slot7
        ? userData.animal_handler_business_type1_slot7
        : "",
    animal_mother_time: "",
    animal_mother_mccode: "",
    animal_mother_mccode_not_reason: "",
    oldMC: true,
    ...JSON.parse(localStorage.getItem("mcData"))
  };
  let PIN = true;


  let { schema } = MCInputSchema(
    vetLocationZipError,
    ownerLocationZipError,
    animalLocationZipError,
    initialValues.oldMC
  );


  if(PIN){
    if(!initialValues.hasOwnProperty('overseas')){
      initialValues = {...initialValues, overseas: ""}
    }
    schema = schema.shape({
      overseas: yup
        .string()
        .required("この欄は必須です")
        .oneOf(["1", "2"], "海外出国をお選びください"),

      PIN: yup
        .string()
        .required("この欄は必須です"),
   })

  //  // console.log(initialValues, schema)
  }

  const handleSubmit = (values) => {
    // console.log("here")
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("mcData", serializedState);
      navigate("/mypage/mcregistrationfrompdfcheck");
    } catch (err) {
      return undefined;
    }
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <div className="text-center mt-4">
        <Button
          className="mt-1"
          variant="primary"
          type="button"
          onClick={decoratedOnClick}
        >
          {children}
        </Button>
      </div>
    );
  }

  const SectionErrorMgs = () => {
    return (
      <Badge className="ms-2" bg="danger">
        エラー
      </Badge>
    );
  };


  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "登録証明書", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={2}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ values, handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Accordion defaultActiveKey="2">
                    
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                       犬・猫の情報入力{" "}
                        {sectionError2 && (
                          <>
                            <SectionErrorMgs />
                          </>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <MCInputAnimal
                          setAnimalLocationZipError={setAnimalLocationZipError}
                          sectionError={setSectionError2}
                          PIN={false}
                          cerPDF={true}
                        />

                        <CustomToggle eventKey="3">
                          <b>
                            次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                          </b>
                        </CustomToggle>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                       所有者情報の入力{" "}
                        {sectionError3 && (
                          <>
                            <SectionErrorMgs/>
                          </>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <MCInputOwner
                          setOwnerLocationZipError={setOwnerLocationZipError}
                          sectionError={setSectionError3}
                          PIN={false}
                          cerPDF={true}
                        />

                        {values.ownership === "2" ? (
                          <CustomToggle eventKey="4">
                            <b>
                              次へ{" "}
                              <FontAwesomeIcon icon={faCircleChevronRight} />
                            </b>
                          </CustomToggle>
                        ) : (
                          <div className="text-center mt-4">
                            <Button
                              className="mt-1"
                              variant="primary"
                              type="submit"
                            >
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </Button>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>

                    {values.ownership === "2" && (
                      <>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                           動物取扱業関連情報の入力{" "}
                            {sectionError4 && (
                              <>
                                <SectionErrorMgs />
                              </>
                            )}
                          </Accordion.Header>
                          <Accordion.Body>
                            <MCInputOwnerDetails
                              sectionError={setSectionError4}
                              PIN={false}
                              cerPDF={true}
                            />
                            {values.animal_handler_business_type === "1" &&
                            _.includes(values.animal_handle_1, "1") ? (
                              <CustomToggle eventKey="5">
                                <b>
                                  次へ{" "}
                                  <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                  />
                                </b>
                              </CustomToggle>
                            ) : (
                              <div className="text-center mt-4">
                                <Button
                                  className="mt-1"
                                  variant="primary"
                                  type="submit"
                                >
                                  <b>
                                    次へ{" "}
                                    <FontAwesomeIcon
                                      icon={faCircleChevronRight}
                                    />
                                  </b>
                                </Button>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                        {values.animal_handler_business_type === "1" &&
                          _.includes(values.animal_handle_1, "1") && (
                            <>
                              <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                 メス親情報の入力{" "}
                                  {sectionError5 && (
                                    <>
                                      <SectionErrorMgs />
                                    </>
                                  )}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <MCInputAnimalMother
                                    sectionError={setSectionError5}
                                  />

                                  <div className="text-center mt-4">
                                    <Button
                                      className="mt-1"
                                      variant="primary"
                                      type="submit"
                                    >
                                      <b>
                                        次へ{" "}
                                        <FontAwesomeIcon
                                          icon={faCircleChevronRight}
                                        />
                                      </b>
                                    </Button>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </>
                          )}
                      </>
                    )}
                  </Accordion>
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
                  <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
