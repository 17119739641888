import React from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import styles from "./Company.module.css";

function Company() {
    const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>運営会社</title>
        <meta property="og:title" content="CKC" />
        <meta name="description" content="CKC" />
      </Helmet>
      <div className="main ">
        <div className={styles.aboutUs}>
          <div className={styles.title}>
            <p>Company</p>
            <span>運営会社</span>
          </div>

          <div className="container">
            <img
              src="/assets/img/asset10.png"
              alt=""
              className={`${styles.cat} ms-auto ms-lg-0 pe-3 d-block`}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-sm-11">
                <div className={styles["about-card"]}>
                  <div className={`row ${styles.bb}`}>
                    <div className="col-12 col-sm-3 align-self-center text-start ">
                      <label className=" ms-sm-5 ms-0">販売業者</label>
                    </div>

                    <div className="col-12 col-sm-9 ps-auto ps-sm-4">
                      <p className="mb-0">株式会社PETNEXT</p>
                    </div>
                  </div>
                  <div className={`row ${styles.bb}`}>
                    <div className="col-12 col-sm-3 align-self-center text-start ">
                      <label className=" ms-sm-5 ms-0">責任者</label>
                    </div>

                    <div className="col-12 col-sm-9 ps-auto ps-sm-4">
                      <p className="mb-0">代表取締役　首藤慶一郎</p>
                    </div>
                  </div>
                  <div className={`row ${styles.bb}`}>
                    <div className="col-12 col-sm-3 align-self-center text-start ">
                      <label className=" ms-sm-5 ms-0">住所</label>
                    </div>

                    <div className="col-12 col-sm-9 ps-auto ps-sm-4">
                      <p className="mb-0">
                        〒104-0033
                        <br />
                        東京都中央区新川２-20-5
                      </p>
                    </div>
                  </div>
                  <div className={`row ${styles.bb}`}>
                    <div className="col-12 col-sm-3 align-self-center text-start ">
                      <label className=" ms-sm-5 ms-0">電話番号</label>
                    </div>

                    <div className="col-12 col-sm-9 ps-auto ps-sm-4">
                      <p className="mb-0">
                        TEL:03-6794-0967
                        <br />
                        受付時間 13:00-17:00（土日祝を除く）
                        <br />
                        <br />
                        ※受付時間外の場合は、メールにてお問い合わせください。
                      </p>
                    </div>
                  </div>

                  <div className={`row ${styles.bb}`}>
                    <div className="col-12 col-sm-3 align-self-center text-start ">
                      <label className=" ms-sm-5 ms-0">メールアドレス</label>
                    </div>

                    <div className="col-12 col-sm-9 ps-auto ps-sm-4">
                      <p className="mb-0">Petnext01@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/assets/img/asset11.png"
              alt=""
              className={`${styles.cat} ms-lg-auto ms-lg-auto mt-5 pe-3 d-block`}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}

export default Company;
