import {
  faFileCsv,
  faFilter,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Table,
  ToastContainer,
  Toast,
  Row,
  Col,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import sjcl from "sjcl";
import DeathReportModal from "../components/DeathReportModal";
import MCDetailsModal from "../components/MCDetailsModal";
import MCFilter from "../components/MCFilter";
import MCListItems from "../components/MCListItems";
import PaymentDetailsModal from "../components/PaymentDetailsModal";
import PINModal from "../components/PINModal";
import { useUserDetailsContext } from "../context/UserDetailsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCollectionDocs } from "../hooks/useCollectionDocs";
import useCsvDownload from "../hooks/useCsvDownload";
import { useGetDoc } from "../hooks/useGetDoc";
import { mc_reg_headers, info_change_headers } from "../utils/CSVFormate";
import FavListModal from "../components/FavListModal";
import ReviewModal from "../components/ReviewModal";
function MCList() {
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();

  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const { firebaseGetDoc, getSingleDocWithQuery } = useGetDoc();
  const [mcs, setMcs] = useState(false);
  const [showMCDetailsModal, setShowMCDetailsModal] = useState(false);
  const [showFavModal, setShowFavModal] = useState(false);
  const [showDeathsModal, setShowDeathModal] = useState(false);
  const [showPINsModal, setShowPINModal] = useState(false);
  const [showMCFilterModal, setShowMCFilterModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const [MCModalContent, setMCModalContent] = useState(null);
  const [favContent, setFavContent] = useState(null);
  const [deathContent, setDeathContent] = useState(null);
  const [PINContent, setPINContent] = useState(null);
  const [paymentModalContent, setPaymentModalContent] = useState(null);
  const [filters, setFilters] = useState(false);
  const [reviewContent, setReviewContent] = useState(false);

  // const limit = false;

  const key = process.env.REACT_APP_SJCL_KEY;
  const mc_datas = localStorage.getItem("MY_DATA_1");
  const mc_datas_ex = localStorage.getItem("MY_DATA_1_Ex");
  const mc_datas_up = JSON.parse(localStorage.getItem("MY_DATA_1_Up"));

  useEffect(() => {
    if (documents) {
      let newmcs = mcs;
      documents.forEach((doc) => {
        newmcs = { ...newmcs, [doc.id]: doc };
      });
      setMcs(newmcs);
      const stringified = JSON.stringify(newmcs);

      const data = sjcl.encrypt(key, stringified);

      localStorage.setItem("MY_DATA_1", data);
      localStorage.setItem("MY_DATA_1_Ex", moment().format("YYYYMMDD"));
    }
  }, [documents]);

  useEffect(() => {
    localStorage.removeItem("mcDataEdit");
    localStorage.removeItem("mcDataEditId");

    let d_mc_datas;
    if (
      mc_datas &&
      mc_datas_ex &&
      parseInt(mc_datas_ex) === parseInt(moment().format("YYYYMMDD"))
    ) {
      d_mc_datas = JSON.parse(sjcl.decrypt(key, mc_datas));

      if (mc_datas_up) {
        let new_d_mc_datas = mcs || d_mc_datas;
        mc_datas_up.forEach(async (mccode) => {
          let data_Up = await getUpdatedMCDatabyMCCode(mccode);
          if (new_d_mc_datas[data_Up.id]) {
            new_d_mc_datas = {
              ...new_d_mc_datas,
              [data_Up.id]: { ...data_Up, id: data_Up.id },
            };
          } else {
            new_d_mc_datas = {
              [data_Up.id]: { ...data_Up, id: data_Up.id },
              ...new_d_mc_datas,
            };
          }
          setMcs(new_d_mc_datas);
          const stringified = JSON.stringify(new_d_mc_datas);
          const data = sjcl.encrypt(key, stringified);
          localStorage.setItem("MY_DATA_1", data);
        });
        localStorage.removeItem("MY_DATA_1_Up");
      } else {
        // console.log("d_mc_datas", d_mc_datas);
        setMcs(d_mc_datas);
      }
      // console.log("+++++++++local+++++++++");
    } else {
      // console.log("---------server--------");
      setMcs([]);
      loadMore();
    }
  }, []);

  const getUpdatedMCDatabyID = async (id) => {
    const data = await firebaseGetDoc(`users/${user.uid}/microchip/`, id);
    // console.log("newmcsdata", data);

    let newmcs = { ...mcs, [id]: { ...data, id: id } };
    setMcs(newmcs);
    // console.log("newmcs", newmcs);

    const stringified = JSON.stringify(newmcs);
    const new_data = sjcl.encrypt(key, stringified);
    localStorage.setItem("MY_DATA_1", new_data);
  };

  const getUpdatedMCDatabyMCCode = async (mccode) => {
    const data = await getSingleDocWithQuery(`users/${user.uid}/microchip/`, [
      ["mccode", "==", mccode.toString()],
    ]);

    return data[0];
  };

  const MCModalHandler = (props) => {
    setShowMCDetailsModal(true);
    setMCModalContent(props);
  };
  const FavModalHandler = (props) => {
    setShowFavModal(true);
    setFavContent(props);
  };

  const paymentModalHandler = (props) => {
    setPaymentDetailsModal(true);
    setPaymentModalContent(props);
  };

  const deathModalHandler = (props) => {
    setShowDeathModal(true);
    setDeathContent(props);
  };

  const pinModalHandler = (props) => {
    setShowPINModal(true);
    setPINContent(props);
  };

  const reviewModalHandler = (props) => {
    setShowReviewModal(true);
    setReviewContent(props);
  };

  const [mcInfo, setMCInfo] = useState([]);
  const [pinCheck, setPinCheck] = useState(true);
  const batchCsvHandler = (e) => {
    if (e.target.checked) {
      setMCInfo([...mcInfo, e.target.value]);
    } else {
      setMCInfo(mcInfo.filter((mc) => mc !== e.target.value));
    }
    if (e.target.dataset.pin.toString() === "true") {
      setPinCheck(true);
    } else {
      setPinCheck(false);
    }
  };

  const loadMore = (ld) => {
    getMultipleDocs({
      _collection: `users/${user.uid}/microchip`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "desc",
    });
  };

  const handleSearch = async (values) => {
    setFilters(values);
    setShowMCFilterModal(false);
  };

  useEffect(() => {
    if (filters) {
      const mcs_in_local = JSON.parse(sjcl.decrypt(key, mc_datas));
      let f1 = {};
      if (filters && filters.mccode) {
        Object.values(mcs_in_local).forEach((doc) => {
          if (doc.mccode.includes(filters.mccode)) {
            f1 = { ...f1, [doc.id]: doc };
          }
        });
      }

      let f2 = {};
      if (filters && filters.relation) {
        Object.values(mcs_in_local).forEach((doc) => {
          if (
            filters.relation.includes("所有") &&
            doc.relation === "OWNER" &&
            !doc.death
          ) {
            f2 = { ...f2, [doc.id]: doc };
          }

          if (
            filters.relation.includes("譲渡") &&
            doc.relation === "TRANSFER"
          ) {
            f2 = { ...f2, [doc.id]: doc };
          }

          if (
            filters.relation.includes("死亡") &&
            doc.relation === "OWNER" &&
            doc.death
          ) {
            f2 = { ...f2, [doc.id]: doc };
          }
        });
      }

      let f3 = {};
      if (filters && filters.group) {
        Object.values(mcs_in_local).forEach((doc) => {
          if (filters.group.includes("犬") && doc.mcData.group === "犬") {
            f3 = { ...f3, [doc.id]: doc };
          }

          if (filters.group.includes("猫") && doc.mcData.group === "猫") {
            f3 = { ...f3, [doc.id]: doc };
          }
        });
      }

      let f4 = {};
      if (filters && filters.gender) {
        Object.values(mcs_in_local).forEach((doc) => {
          if (filters.gender.includes("オス") && doc.mcData.gender === "オス") {
            f4 = { ...f4, [doc.id]: doc };
          }

          if (filters.gender.includes("メス") && doc.mcData.gender === "メス") {
            f4 = { ...f4, [doc.id]: doc };
          }
        });
      }

      let f5 = {};
      if (filters && filters.dateType && filters.startDate && filters.endDate) {
        Object.values(mcs_in_local).forEach((doc) => {
          let startDate = moment(filters.startDate).add(9, "hours");
          let endDate = moment(filters.endDate).add(33, "hours");

          // 登録日
          if (filters.dateType === "登録日") {
            let comDate = moment(
              new Timestamp(
                doc.createdAt.seconds,
                doc.createdAt.nanoseconds
              ).toDate()
            );

            // console.table(
            //   comDate.isSameOrAfter(startDate),
            //   comDate.isSameOrBefore(endDate)
            // );
            // console.table(
            //   startDate.toJSON(),
            //   comDate.toJSON(),
            //   endDate.toJSON()
            // );
            if (
              comDate.isSameOrAfter(startDate) &&
              comDate.isSameOrBefore(endDate)
            ) {
              f5 = { ...f5, [doc.id]: doc };
            }
          }

          // 譲渡日
          if (filters.dateType === "譲渡日" && doc.relation === "TRANSFER") {
            let comDate = moment(
              new Timestamp(
                doc.transferAt.seconds,
                doc.transferAt.nanoseconds
              ).toDate()
            );

            // console.table(
            //   comDate.isSameOrAfter(startDate),
            //   comDate.isSameOrBefore(endDate)
            // );
            // console.table(
            //   startDate.toJSON(),
            //   comDate.toJSON(),
            //   endDate.toJSON()
            // );
            if (
              comDate.isSameOrAfter(startDate) &&
              comDate.isSameOrBefore(endDate)
            ) {
              f5 = { ...f5, [doc.id]: doc };
            }
          }

          // 死亡日
          if (filters.dateType === "死亡日" && doc.death) {
            let comDate = moment(
              new Timestamp(
                doc.deathDetails.date.seconds,
                doc.deathDetails.date.nanoseconds
              ).toDate()
            );

            // console.table(
            //   comDate.isSameOrAfter(startDate),
            //   comDate.isSameOrBefore(endDate)
            // );
            // console.table(
            //   startDate.toJSON(),
            //   comDate.toJSON(),
            //   endDate.toJSON()
            // );
            if (
              comDate.isSameOrAfter(startDate) &&
              comDate.isSameOrBefore(endDate)
            ) {
              f5 = { ...f5, [doc.id]: doc };
            }
          }

          // 生年月日

          if (filters.dateType === "生年月日") {
            let comDate = moment(doc.mcData.birthDay);

            // console.table(
            //   comDate.isSameOrAfter(startDate),
            //   comDate.isSameOrBefore(endDate)
            // );
            // console.table(
            //   startDate.toJSON(),
            //   comDate.toJSON(),
            //   endDate.toJSON()
            // );
            if (
              comDate.isSameOrAfter(startDate) &&
              comDate.isSameOrBefore(endDate)
            ) {
              f5 = { ...f5, [doc.id]: doc };
            }
          }
        });
      }

      const allowed = intersectingKeys(
        Object.keys(f1).length ? f1 : mcs_in_local,
        Object.keys(f2).length ? f2 : mcs_in_local,
        Object.keys(f3).length ? f3 : mcs_in_local,
        Object.keys(f4).length ? f4 : mcs_in_local,
        Object.keys(f5).length || filters.dateType ? f5 : mcs_in_local
      );
      const filtered = Object.keys(mcs_in_local)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = mcs_in_local[key];
          return obj;
        }, {});
      setMcs(filtered);

      if (filters && !(filters.mccode || filters.relation)) {
        setMcs(mcs_in_local);
      }
    }
  }, [filters]);

  const clearFilter = (key, value = "") => {
    if (key) {
      setFilters({ ...filters, [key]: value });
      // console.log('clearFilter', Object.values(_.omit(filters, ['startDate', 'endDate'])).length);
    } else {
      setFilters({});
    }
  };

  function intersectingKeys(...objects) {
    return objects
      .map((object) => Object.keys(object))
      .sort((a, b) => a.length - b.length)
      .reduce((a, b) => a.filter((key) => b.includes(key)));
  }

  let items = [];
  for (let i = 0; i < 10; i++) {
    items.push(
      <tr key={i} className="placeholder-glow">
        <td className="align-middle">
          <span className="placeholder col-8"></span>
        </td>
        <td className="text-center align-middle">
          <span className="placeholder col-8"></span>
        </td>
        <td className="text-center align-middle">
          <span className="placeholder col-2"></span>
        </td>
        <td className="text-center">
          <ButtonGroup className="me-2 col-5" aria-label="First group">
            <Button
              variant="secondary"
              className="placeholder"
              disabled></Button>{" "}
            <Button variant="danger" className="placeholder" disabled></Button>{" "}
            <Button variant="success" className="placeholder" disabled></Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
  const { getCSVData } = useCsvDownload();
  const [csvData, setCsvData] = useState([]);
  //csvdata
  const getCsvData = async () => {
    const mcs_in_local = JSON.parse(sjcl.decrypt(key, mc_datas));

    await getCSVData(
      Object.values(mcs_in_local).filter(
        (doc) => _.includes(mcInfo, doc.id) && doc
      ),
      pinCheck
    ).then((res) => {
      setCsvData(res);
      setMCInfo([]);
    });
  };
  return (
    <>
      <Container>
        <div className="text-end my-2">
          <Button
            variant="secondary"
            onClick={() => setShowMCFilterModal(true)}>
            <FontAwesomeIcon icon={faFilter} /> 条件付き検索
          </Button>
        </div>

        {filters && (
          <h4>
            {filters.mccode && (
              <Badge pill bg="secondary mx-1">
                {filters.mccode}{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => clearFilter("mccode")}
                />
              </Badge>
            )}

            {filters.relation && filters.relation.includes("所有") && (
              <Badge pill bg="secondary mx-1">
                所有{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() =>
                    clearFilter(`relation`, _.without(filters.relation, "所有"))
                  }
                />
              </Badge>
            )}

            {filters.relation && filters.relation.includes("譲渡") && (
              <Badge pill bg="secondary mx-1">
                譲渡{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() =>
                    clearFilter(`relation`, _.without(filters.relation, "譲渡"))
                  }
                />
              </Badge>
            )}

            {filters.relation && filters.relation.includes("死亡") && (
              <Badge pill bg="secondary mx-1">
                死亡{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() =>
                    clearFilter(`relation`, _.without(filters.relation, "死亡"))
                  }
                />
              </Badge>
            )}

            {filters.group && filters.group.includes("犬") && (
              <Badge pill bg="secondary mx-1">
                犬{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() =>
                    clearFilter(`group`, _.without(filters.group, "犬"))
                  }
                />
              </Badge>
            )}

            {filters.group && filters.group.includes("猫") && (
              <Badge pill bg="secondary mx-1">
                猫{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() =>
                    clearFilter(`group`, _.without(filters.group, "猫"))
                  }
                />
              </Badge>
            )}

            {filters.gender && filters.gender.includes("オス") && (
              <Badge pill bg="secondary mx-1">
                オス{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() =>
                    clearFilter(`gender`, _.without(filters.gender, "オス"))
                  }
                />
              </Badge>
            )}

            {filters.gender && filters.gender.includes("メス") && (
              <Badge pill bg="secondary mx-1">
                メス{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() =>
                    clearFilter(`gender`, _.without(filters.gender, "メス"))
                  }
                />
              </Badge>
            )}

            {filters.dateType && (
              <Badge pill bg="secondary mx-1">
                {`${filters.dateType}: ${filters.startDate} ${filters.endDate}`}{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => clearFilter(`dateType`)}
                />
              </Badge>
            )}

            {(filters.mccode ||
              (filters.relation && filters.relation[0]) ||
              (filters.group && filters.group[0]) ||
              (filters.gender && filters.gender[0]) ||
              filters.dateType) && (
              <Badge pill bg="primary" className="mx-1">
                条件を削除{" "}
                <FontAwesomeIcon icon={faTimes} onClick={() => clearFilter()} />
              </Badge>
            )}
          </h4>
        )}

        {Object.values(mcs).length > 0 && (
          <>
            <Table striped bordered responsive="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>MC番号/ペットID</th>
                  <th className="text-center">暗証記号</th>
                  <th className="text-center">譲渡数</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.values(mcs) &&
                  Object.values(mcs).map((doc, i) => {
                    return (
                      <MCListItems
                        key={i}
                        doc={doc}
                        modalFun={MCModalHandler}
                        deathModalFun={deathModalHandler}
                        pinModalFun={pinModalHandler}
                        batchCsvHandler={batchCsvHandler}
                        getUpdatedMCDatabyID={getUpdatedMCDatabyID}
                        favModal={FavModalHandler}
                        reviewModal={reviewModalHandler}
                        mcInfo={mcInfo}
                        pinCheck={pinCheck}
                      />
                    );
                  })}

                {isPending && items}
              </tbody>
            </Table>
          </>
        )}

        {!Object.values(mcs).length && !isPending && (
          <Alert variant="warning" className="text-center">
            マイクロチップ履歴がありません
          </Alert>
        )}
      </Container>

      <MCDetailsModal
        data={MCModalContent}
        show={showMCDetailsModal}
        onHide={() => setShowMCDetailsModal(false)}
        paymentModalHandler={paymentModalHandler}
      />
      <FavListModal
        data={favContent}
        show={showFavModal}
        onHide={() => setShowFavModal(false)}
      />
      <PaymentDetailsModal
        data={paymentModalContent}
        mcData={MCModalContent}
        show={paymentDetailsModal}
        onHide={() => setPaymentDetailsModal(false)}
        modalFun={MCModalHandler}
      />

      <DeathReportModal
        data={deathContent}
        show={showDeathsModal}
        getUpdatedMCDatabyID={getUpdatedMCDatabyID}
        onHide={() => setDeathContent(false)}
      />

      <PINModal
        data={PINContent}
        show={showPINsModal}
        getUpdatedMCDatabyID={getUpdatedMCDatabyID}
        onHide={() => setPINContent(false)}
      />

      <ReviewModal
        data={reviewContent}
        show={showReviewModal}
        onHide={() => setShowReviewModal(false)}
        getUpdatedMCDatabyID={getUpdatedMCDatabyID}
      />

      <MCFilter
        show={showMCFilterModal}
        handleSearch={handleSearch}
        returnValue={filters}
        onHide={() => {
          setShowMCFilterModal(false);
        }}
      />
      {mcInfo.length > 0 && !pinCheck && (
        <ToastContainer className="p-3" position="bottom-end">
          <Toast style={{ width: "unset" }}>
            <CSVLink
              className="btn btn-warning btn-lg"
              data={csvData}
              headers={mc_reg_headers}
              filename={`MC情報の登録_${moment(new Date()).format(
                "YYYYMMDD_HHMMSS"
              )}.csv`}
              enclosingCharacter={``}
              asyncOnClick={true}
              onClick={getCsvData}>
              <div className="d-flex align-middle">
                <FontAwesomeIcon icon={faFileCsv} size="3x" className="pe-2" />
                <p className="mb-0">
                  [MC情報の登録]
                  <br />
                  CSVをダウンロード
                </p>
              </div>
            </CSVLink>
          </Toast>
        </ToastContainer>
      )}

      {mcInfo.length > 0 && pinCheck && (
        <ToastContainer className="p-3" position="bottom-end">
          <Toast style={{ width: "unset" }}>
            <CSVLink
              className="btn btn-info btn-lg"
              data={csvData}
              headers={info_change_headers}
              filename={`登録項目の変更_${moment(new Date()).format(
                "YYYYMMDD_HHMMSS"
              )}.csv`}
              enclosingCharacter={``}
              asyncOnClick={true}
              onClick={getCsvData}>
              <div className="d-flex align-middle">
                <FontAwesomeIcon icon={faFileCsv} size="3x" className="pe-2" />
                <p className="mb-0">
                  [登録項目の変更]
                  <br />
                  CSVをダウンロード
                </p>
              </div>
            </CSVLink>

            {/* <Button variant="warning" onClick={csvdata}>csvdata</Button> */}
          </Toast>
        </ToastContainer>
      )}

      <div
        className="ql-snow "
        style={{ position: "absolute", left: "-9999999px" }}>
        <div className="ql-editor ql-container">
          <div id="reportDiv"></div>
        </div>
      </div>
    </>
  );
}

export default MCList;
