import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useCollectionDocs } from "../hooks/useCollectionDocs";
import { useGetDoc } from "../hooks/useGetDoc";
import DataTable from "react-data-table-component";
import { UserNameById } from "./UserNameById";

const FavListModal = (props) => {
  const { firebaseGetDoc, isPending, error } = useGetDoc();
  const [datas, setDatas] = useState([]);
  const loadMore = async () => {
    let data = await firebaseGetDoc(`microchip`, props.data);
    if (data.favourite) {
      setDatas(data.favourite);
    } else {
      setDatas([]);
    }
  };

  useEffect(() => {
    if (props.show) {
      loadMore();
    }
  }, [props]);

  return (
    props.data && (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            フェイバリットIDリスト
            <span className="text-nowrap"></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPending && (
            <div className="d-flex justify-content-center">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}

          {!isPending && (
            <Row>
              {datas.length == 0 && (
                <Col sm={12} className="text-center">
                  <p className="m-0">Empty Fav List </p>
                </Col>
              )}

              {datas.map((fav, i) => {
                return (
                  <Col sm={12} key={i}>
                    <UserNameById id={fav} />
                  </Col>
                );
              })}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>閉じる</Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default FavListModal;
