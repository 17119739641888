import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProgressStep from "../../../components/ProgressStep";

export default function SubsDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Container>
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "サービス", link: "#" },
              { value: "確認", link: "#" },
              { value: "カードを登録", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={1}
          />
        </Row>
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <h3>サポートサービス</h3>
            ブリーダーさん向けに代行できるシステムをサブスクでご用意しております。{" "}
            <br />
            以下の4つのサポートが1ヶ月/3,980円の月額料金になります。また、初回登録時は3ヶ月無料でご提供させていただきます。
            <br /> <br />
            <h5 className="mb-0">1. アカウント登録設定代行</h5>
            <div className="my-3 ms-4">アカウント登録の代行を行います。</div>
            <h5 className="mb-0">2. マイクロチップ登録代行　100件/月 </h5>
            <div className="my-3 ms-4">
              ※譲渡以降は代行不可
              <br />
              ひと月100件まで、マイクロチップ登録の代行を行います。忙しくて登録する時間が足りない方や登録件数が多くて大変な方の負担を軽減いたします。
            </div>
            <h5 className="mb-0">3. 登録証明書PDFのCSV変換システム利用</h5>
            <div className="my-3 ms-4">
              登録証明書PDFをCSVに変換するシステムが利用可能になります。大幅な時間の削減になります。
            </div>
            <h5 className="mb-0">4. 利用方法電話サポート</h5>
            <div className="my-3 ms-4">
              利用方法についてなにかご不明な点があれば電話にてサポートいたします。
            </div>
            <div className="text-center">
              <Button variant="primary text-center" onClick={handleShow}>
                申し込む <FontAwesomeIcon icon={faCircleChevronRight} />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>注意事項</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          株式会社PETNEXT（以下「当社」といいます）は、当社が運営す
          るマイクロチップ登録サイト「MCR」の運営・管理を通じてご提供
          するサービス（以下、「本サービス」といいます）の利用規約（以下
          「本規約」といいます）を以下の通り定めます。本サービスをご利
          用される方は、本規約の全ての条項を承諾していただくことが条
          件となりますので、ご登録される前に本規約を必ずお読みください。
          <br />
          <br />
          株式会社PETNEXT（以下「当社」といいます）は、当社が運営す
          るマイクロチップ登録サイト「MCR」の運営・管理を通じてご提供
          するサービス（以下、「本サービス」といいます）の利用規約（以下
          「本規約」といいます）を以下の通り定めます。本サービスをご利
          用される方は、本規約の全ての条項を承諾していただくことが条
          件となりますので、ご登録される前に本規約を必ずお読みください。
          <br />
          <br />
          株式会社PETNEXT（以下「当社」といいます）は、当社が運営す
          るマイクロチップ登録サイト「MCR」の運営・管理を通じてご提供
          するサービス（以下、「本サービス」といいます）の利用規約（以下
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
          <Link to={"/mypage/subscription-check"} className="btn-primary btn">
            同意する <FontAwesomeIcon icon={faCircleChevronRight} />
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
