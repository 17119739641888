import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { Formik, useFormikContext } from "formik";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCircleChevronRight,
  faCircleXmark,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import styles from "./MCStyle.module.css";
import masImage from "./img/masking.png";
import beepSound from "./sound/Barcode-scanner-beep-sound.mp3";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useSetDoc } from "../../hooks/useSetDoc";
import Scanner from "../../components/Scanner";
import { useGetDoc } from "../../hooks/useGetDoc";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";

export default function VetMCInstallation() {
  const [sound, setSound] = useState(true);
  let audio = new Audio(beepSound);
  const beep = () => {
    if (sound) {
      audio.play();
    }
  };

  useEffect(() => {
    localStorage.removeItem("vetmcData");
    localStorage.removeItem("vetmccode");
  }, []);

  const [showInfo, setShowInfo] = useState(true);
  const [scan, setScan] = useState(false);
  const [customError, setCustomError] = useState(null);
  const { user } = useAuthContext();
  const { deleteDocument } = useDeleteDocs();
  const { firebaseSetDoc } = useSetDoc();
  const { documents: dbmcode } = useCollection("temp_mc", [
    "uid",
    "==",
    user.uid,
  ]);
  const { getSingleDocWithQuery, isPending, error } = useGetDoc();
  const sundMCCode = (code) => {
    firebaseSetDoc("temp_mc", { uid: user.uid, mccode: code, type: "R" }, user.uid);
    // console.log(code)
  };

  const navigate = useNavigate();
  let initialValues = {
    mccode: "",
  };

  const schema = yup.object().shape({
    mccode: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[0-9]{15}$/, "半角15桁番号でご入力ください")
      .length(15, "半角15桁番号でご入力ください"),
  });

  //if detect then send barcode to temp_mc table to use real time database
  const _onDetected = (result) => {
    setCustomError(null);
    if (result) {
      sundMCCode(result.codeResult.code);
      beep();
      setScan(false);
    }
  };
  const handleSubmit = (values) => {
    setCustomError(null);
    try {
      //check if mc already registered or not
      getSingleDocWithQuery("microchip", [
        ["mccode", "==", values.mccode.toString()],
      ]).then((res) => {
        // console.log(res);
        if (!res) {
          const serializedState = JSON.stringify(values);
          localStorage.setItem("vetmccode", serializedState);
          deleteDocument("temp_mc", user.uid);
          navigate("/mypage/vetmcinstallinput");
          // console.log("here1");
        } else {
          setCustomError(
            `${values.mccode}マイクロチップの番号はすでに登録されています`
          );
          // console.log("here2");
        }
      });
    } catch (err) {
      return undefined;
    }
  };

  useEffect(() => {
    setCustomError(null);
  }, [dbmcode]);

  const MyField = () => {
    const { setFieldValue, handleChange, handleBlur, values, touched, errors } =
      useFormikContext();

    useEffect(() => {
      setFieldValue(
        "mccode",
        (dbmcode && dbmcode[0] && dbmcode[0].type === "R" && dbmcode[0].mccode) || values.mccode || ""
      );
    }, [setFieldValue]);

    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>
            MC番号<span className="text-danger">※</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="半角15桁番号でご入力ください"
            onChange={(e) => {
              handleChange(e);
              setCustomError(null);
            }}
            onBlur={handleBlur}
            value={values.mccode}
            name="mccode"
            autoComplete="off"
            required
            isInvalid={(touched.mccode && errors.mccode) || customError}
            isValid={touched.mccode && !errors.mccode}
          />
          <Form.Control.Feedback type="invalid">
            {errors.mccode || customError}
          </Form.Control.Feedback>

          <div className="text-center mt-4">
            <Button className="mb-3" variant="primary" type="submit">
              <b>
                次へ <FontAwesomeIcon icon={faCircleChevronRight} />
              </b>
            </Button>
          </div>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={1}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <div className="text-center">
            {showInfo && (
              <Row className="justify-content-center mb-4">
                <Col lg={6} md={8}>
                  <Alert
                    className="mb-0 d-none d-lg-block"
                    variant="info"
                    onClose={() => setShowInfo(false)}
                    dismissible
                  >
                    スマートフォンからバーコードを読み込む場合はスマートフォンからマイページにログインしてください。
                  </Alert>
                </Col>
              </Row>
            )}
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => setScan(!scan)}
            >
              <b>
                {!scan ? "オープンカメラ " : "ストップカメラ "}
                {!scan ? (
                  <FontAwesomeIcon icon={faCamera} />
                ) : (
                  <FontAwesomeIcon icon={faCircleXmark} />
                )}
              </b>
            </Button>
          </div>
          {scan && (
            <>
              <Row className="justify-content-center">
                <div id={styles.soundContainer} className="text-end">
                  {sound ? (
                    <Button variant="light" onClick={() => setSound(false)}>
                      <FontAwesomeIcon icon={faVolumeHigh} />
                    </Button>
                  ) : (
                    <Button variant="light" onClick={() => setSound(true)}>
                      <FontAwesomeIcon icon={faVolumeXmark} />
                    </Button>
                  )}
                </div>
              </Row>

              <div id={styles.videoContainer}>
                <img
                  src={masImage}
                  alt="camera masking"
                  id={styles.cameraMasking}
                />
                <Scanner onDetected={(result) => _onDetected(result)} />
              </div>
            </>
          )}
        </Row>

        <p className="text-center">または</p>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <MyField />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
