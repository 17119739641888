import React from "react";
import { Link } from "react-router-dom";
import styles from "./ProgressStep.module.css";
export default function ProgressStep({ props, activeNo, varient = null }) {
  return (
    <ul className={`${varient ? styles[varient] : ""} ${styles.navWizard} ${"nav"}`}>
      {props.map((prop, i) => {
        return (
          <li key={i} className={`${activeNo === i + 1 ? styles.active : ""}`}>
            <Link to={prop.link}>
              {i + 1}
              <span
                className={`${
                  activeNo === i + 1
                    ? "d-inline d-md-inline"
                    : "d-none d-md-inline"
                }`}
              >
                {". "}{prop.value}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
