import React, { useEffect } from "react";

import moment from "moment";
import valid from "card-validator";
import Cards from "react-credit-cards-2";
import { Alert } from "react-bootstrap";

import { useCreditCardInfo } from "../hooks/komoju/useCreditCardInfo";
import { getBrandName, getDottedCardNumber } from "../pages/creditcard/Util";

import "react-credit-cards-2/dist/es/styles-compiled.css";

function CardDiv({ komoju_customer_id, errorInCard = () => {}, minMonth = 0 }) {
  const { cardInfo, isPending, isConfirmed, error, data } = useCreditCardInfo();

  useEffect(() => {
    cardInfo(komoju_customer_id);
  }, []);

  useEffect(() => {
    if (data && data.source) {
      errorInCard(
        !valid.expirationDate({
          month: data.source.month,
          year: data.source.year
        }).isValid
      );
    }
  }, [data]);

  return (
    <>
      {!data && (
        <>
          {error && JSON.stringify(error)}
          <div className=" placeholder-glow text-center">
            <div className="placeholder" style={{ borderRadius: "14.5px" }}>
              <Cards
                locale={{ valid: "有効期限" }}
                name=""
                number=""
                expiry=""
                cvc=""
                preview={true}
                issuer=""
              />
            </div>
          </div>
        </>
      )}

      {data && (
        <>
          {data.err && (
            <Alert variant="danger" className="text-center">
              {data.err.message}
            </Alert>
          )}

          {!valid.expirationDate({
            month: data.source.month,
            year: data.source.year
          }).isValid && (
            <Alert variant="danger" className="text-center">
              {minMonth
                ? `カードの有効期限は${minMonth}か月以上必要です。`
                : `カードは期限切れです。`}
            </Alert>
          )}

          {data.id && (
            <Cards
              locale={{ valid: "有効期限" }}
              cvc=""
              name={data.metadata.name}
              number={`${getDottedCardNumber(data.source.brand)} ${
                data.source.last_four_digits
              }`}
              expiry={`${data.source.month.toString().padStart(2, 0)}${
                data.source.year
              }`}
              preview={true}
              issuer={getBrandName(data.source.brand)}
            />
          )}
        </>
      )}
    </>
  );
}

export default CardDiv;
