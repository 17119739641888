import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import ProgressStep from "../../../components/ProgressStep";

function AddToListComplete() {
    return (
        <>
            <Container className="mt-3">
                <Row className="justify-content-center mb-4">
                    <ProgressStep
                        props={[
                            { value: "スキャン", link: "/mypage/addtolist" },
                            { value: "入力", link: "#" },
                            { value: "確認", link: "#" },
                            { value: "終了", link: "#" },
                        ]}
                        activeNo={4}
                    />
                </Row>
                <Row className="justify-content-center my-5">
                    <Col lg={6} md={8}>
                        <Alert variant="success" className="text-center">
                            完了しました！
                        </Alert>
                    </Col>
                </Row>

                <Row className="justify-content-center mb-4">
                    <Col lg={6} md={8}>
                        <div className="text-center ">
                            <Link className="btn btn-secondary m-2" to="/mypage/mclist">
                                登録情報一覧へ
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AddToListComplete;
