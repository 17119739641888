import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useAuthContext } from "./hooks/useAuthContext";
import { UserDetailsProvider } from "./context/UserDetailsContext";

import Header from "./components/Header";
import ForgetPassword from "./pages/forgetpassword/ForgetPassword";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import SignupCheck from "./pages/signup/SignupCheck";
import Signupconfirm from "./pages/signup/Signupconfirm";
import VarifyEmail from "./pages/resetpassword/VarifyEmail";
import MyPage from "./pages/mypage/MyPage";
import ToastDiv from "./components/ToastDiv";
import Page404 from "./pages/Page404";
import CreateUser from "./pages/createuser/CreateUser";
import UserInputCheck from "./pages/createuser/UserInputCheck";
import Footer from "./components/Footer";
import Breeder from "./pages/home/breeder/Breeder";
import Veterinarian from "./pages/home/breeder/Veterinarian";
import Owner from "./pages/home/breeder/Owner";
import { useState } from "react";
import CKC from "./pages/home/CKC";
import Company from "./pages/home/Company";
import Law from "./pages/home/Law";
import Terms from "./pages/home/Terms";
import PrivacyPolicy from "./pages/home/PrivacyPolicy";
import ContactUs from "./pages/home/ContactUs";
import ContactUsCheck from "./pages/home/ContactUsCheck";
import ContactUsCheckComplete from "./pages/home/ContactUsCheckComplete";
import NewsDetailsPage from "./pages/home/NewsDetailsPage";
import News from "./pages/home/News";
import UrlListner from "./UrlListner";
import Aid from "./pages/home/Aid";
import ValidateCard from "./pages/ValidateCard";

function App() {
  const { user, authIsReady } = useAuthContext();
  const [bg, setBg] = useState(false);

  return (
    <div className={`App d-flex flex-column h-min-100 ${bg ? "bg-home" : ""}`}>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Home />
                  <Footer />
                </>
              }
            />

            <Route
              path="validate-card/*"
              element={
                user ? (
                  <>
                    <UrlListner />
                    <UserDetailsProvider>
                      <ValidateCard />
                    </UserDetailsProvider>
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>

            <Route
              path="mypage/*"
              element={
                user ? (
                  <>
                    <UrlListner />
                    <UserDetailsProvider>
                      <MyPage setBg={setBg} />
                    </UserDetailsProvider>
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>

            <Route
              path="login"
              element={
                !user ? (
                  <>
                    <UrlListner />
                    <UserDetailsProvider>
                      <Header setBg={setBg} />
                    </UserDetailsProvider>
                    <Login />
                    <Footer powDiv={false} />
                  </>
                ) : (
                  <Navigate to="/mypage" />
                )
              }
            />
            <Route
              path="createuser"
              element={
                !user ? (
                  <>
                    <UrlListner />
                    <UserDetailsProvider>
                      <Header setBg={setBg} />
                    </UserDetailsProvider>
                    <Login />
                    <Footer />
                  </>
                ) : (
                  <>
                    <UrlListner />
                    <UserDetailsProvider>
                      <Header setBg={setBg} />
                    </UserDetailsProvider>
                    <CreateUser />
                    <Footer />
                  </>
                )
              }
            />
            <Route
              path="userinputcheck"
              element={
                !user ? (
                  <>
                    <UrlListner />
                    <UserDetailsProvider>
                      <Header setBg={setBg} />
                    </UserDetailsProvider>
                    <Login />
                    <Footer />
                  </>
                ) : (
                  <>
                    <UrlListner />
                    <UserDetailsProvider>
                      <Header setBg={setBg} />
                    </UserDetailsProvider>
                    <UserInputCheck />
                    <Footer />
                  </>
                )
              }
            />
            <Route
              path="breeder"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Breeder />
                  <Footer />
                </>
              }
            />

            <Route
              path="veterinarian"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Veterinarian />
                  <Footer />
                </>
              }
            />
            <Route
              path="owner"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Owner />
                  <Footer />
                </>
              }
            />

            <Route
              path="news"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <News />
                  <Footer />
                </>
              }
            />

            <Route
              path="newsdetails/:uid"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <NewsDetailsPage />
                  <Footer />
                </>
              }
            />

            <Route
              path="ckc"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <CKC />
                  <Footer />
                </>
              }
            />

            <Route
              path="company"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Company />
                  <Footer />
                </>
              }
            />

            <Route
              path="Trams"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Terms />
                  <Footer />
                </>
              }
            />
            <Route
              path="privacy-policy"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <PrivacyPolicy />
                  <Footer />
                </>
              }
            />

            <Route
              path="law"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Law />
                  <Footer />
                </>
              }
            />

            <Route
              path="aid"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Aid />
                  <Footer />
                </>
              }
            />

            <Route
              path="contactus"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <ContactUs />
                  <Footer />
                </>
              }
            />

            <Route
              path="contactuscheck"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <ContactUsCheck />
                  <Footer powDiv={false} />
                </>
              }
            />

            <Route
              path="contactuscomplete"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <ContactUsCheckComplete />
                  <Footer powDiv={false} />
                </>
              }
            />

            <Route
              path="signup"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Signup />
                  <Footer />
                </>
              }
            />
            <Route
              path="signupcheck"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <SignupCheck />
                  <Footer />
                </>
              }
            />
            <Route
              path="signupconfirm"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Signupconfirm />
                  <Footer powDiv={false} />
                </>
              }
            />
            <Route
              path="forget-password"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <ForgetPassword />
                  <Footer powDiv={false} />
                </>
              }
            />
            <Route
              path="varify-email"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <VarifyEmail />
                  <Footer powDiv={false} />
                </>
              }
            />
            <Route
              path="404"
              element={
                <>
                  <UrlListner />
                  <UserDetailsProvider>
                    <Header setBg={setBg} />
                  </UserDetailsProvider>
                  <Page404 />
                  <Footer powDiv={false} />
                </>
              }
            />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </BrowserRouter>
      )}

      <ToastDiv />
    </div>
  );
}

export default App;
