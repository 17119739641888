import React, { useEffect, useState } from "react";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { Button, ButtonGroup, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAd, faAdd } from "@fortawesome/free-solid-svg-icons";
import SidebarBannerModal from "./SidebarBannerModal";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../firebase/config";

export default function SidebarBanner() {
  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const [datas, setDatas] = useState(false);
  const [showSidebarBannerDetailsModal, setShowSidebarBannerDetailsModal] =
    useState(false);
  const [showSidebarBannerAddModal, setShowSidebarBannerAddModal] =
    useState(false);
  const [sidebarBannerModalContent, setSidebarBannerModalContent] =
    useState(null);
  const {
    deleteDocument,
    isPending: isPending5,
    error: error5
  } = useDeleteDocs();

  const deleteItem = async (id, url) => {
    // console.log(id);
    deleteDocument(`sidebar_banners`, id).then(loadMore());
    const desertRef = ref(storage, url);
    await deleteObject(desertRef);
  };

  const columns = [
    {
      name: "リンク先",
      selector: (row) => row.url,
      sortable: true,
      style: {
        padding: "2px 0"
      }
    },
    {
      name: "メモ",
      selector: (row) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{row.memo}</div>
      ),
      sortable: false
    },

    {
      name: (
        <div style={{ textAlign: "center", width: "100%" }}>オーパーツ</div>
      ),
      selector: (row) =>
        row.image ? <img src={row.image} className="img-fluid w-100" /> : "",
      maxWidth: "300px",
      style: {
        padding: "10px 0"
      }
    },

    {
      name: "",
      cell: (row) => (
        <>
          <ButtonGroup>
            <Button
              variant="primary"
              onClick={() => sidebarBannerAddModalHandler(row)}
            >
              編集
            </Button>
            <Button
              onClick={() => {
                deleteItem(row.id, row.image);
              }}
              variant="danger"
            >
              削除
            </Button>
          </ButtonGroup>
        </>
      )
    }
  ];

  const loadMore = () => {
    getMultipleDocs({
      _collection: `sidebar_banners`,
      _query: false
    });
  };

  const SidebarBannerModalHandler = () => {
    setShowSidebarBannerAddModal(true);
  };

  const sidebarBannerAddModalHandler = (props) => {
    setShowSidebarBannerDetailsModal(true);
    setSidebarBannerModalContent(props);
  };

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    if (documents) {
      setDatas(documents);
    }
  }, [documents]);
  return (
    <>
      <Container>
        {datas.length < 3 && (
          <div className="text-end my-2">
            <Button
              variant="secondary ml-auto"
              onClick={() => {
                SidebarBannerModalHandler();
              }}
            >
              <FontAwesomeIcon icon={faAdd} /> 新バナー
            </Button>
          </div>
        )}

        {datas && (
          <DataTable
            columns={columns}
            progressPending={isPending}
            progressComponent={
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            }
            data={datas}
            pagination
          />
        )}
      </Container>

      <SidebarBannerModal
        reloadData={loadMore}
        data={sidebarBannerModalContent}
        show={showSidebarBannerDetailsModal}
        onHide={() => setShowSidebarBannerDetailsModal(false)}
      />
      <SidebarBannerModal
        reloadData={loadMore}
        data={{ type: "new" }}
        show={showSidebarBannerAddModal}
        onHide={() => setShowSidebarBannerAddModal(false)}
      />
    </>
  );
}
