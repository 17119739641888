import React, { useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useSetDoc } from "../hooks/useSetDoc";
import { useAuthContext } from "../hooks/useAuthContext";
import { useToast } from "../hooks/useToast";

function PINModal(props) {
  const { user } = useAuthContext();
  const toast = useToast();
  const [isPend, setIsPend] = useState(false);

  const { firebaseSetDoc } = useSetDoc();

  let initialValues = {
    PIN: ""
  };
  const schema = yup.object().shape({
    PIN: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[a-zA-Z0-9]+$/, "半角英数字含むご入力ください")
  });
  const handleSubmit = async (values) => {
    //update microchip collections
    setIsPend(true);
    await firebaseSetDoc(
      "microchip",
      {
        PIN: values.PIN
      },
      props.data.mccode.toString(),
      true
    ).then(async () => {
      const ownersData = {
        PIN: values.PIN
      };
      await firebaseSetDoc(
        `users/${user.uid}/microchip`,
        ownersData,
        props.data.id.toString(),
        true
      );
      props.getUpdatedMCDatabyID(props.data.id.toString());
    });

    props.onHide();
    setIsPend(false);
    toast("success", "暗証記号の設定完了");
  };
  return (
    props.data && (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                MC番号
                <span className="text-nowrap">#{props.data.mccode}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="warning">
                一度、暗証番号を入力した後、現在登録されている情報の一部は変更できなくなります。
              </Alert>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    暗証記号 <span className="text-danger">※</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.PIN}
                    name="PIN"
                    maxLength={10}
                    isInvalid={touched.PIN && errors.PIN}
                    isValid={touched.PIN && !errors.PIN}
                    required
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.PIN}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide}>閉じる</Button>

              {!isPend && (
                <Button variant="warning" onClick={handleSubmit}>
                  保存 <FontAwesomeIcon icon={faCircleChevronRight} />
                </Button>
              )}

              {isPend && (
                <Button variant="warning" disabled type="button">
                  保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    )
  );
}

export default PINModal;
