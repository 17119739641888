import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";

export const useDistributedCounter = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const dcfunction = (payload) => {
    const distributedCounter = httpsCallable(functions, "distributedCounter");
    distributedCounter(payload);
  };

  return { dcfunction };
};
