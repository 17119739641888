import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container } from "react-bootstrap";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { Link } from "react-router-dom";
import CardDiv from "../../components/CardDiv";

function CardInfo({ komoju_customer_id }) {
  return (
    <>
      <Container>
        <CardDiv komoju_customer_id={komoju_customer_id} />
        <br />
        <div className="text-center">
          <Link className="btn btn-warning mt-3" to="/mypage/changecardinfo">
            編集 <FontAwesomeIcon icon={faEdit} />
          </Link>
        </div>
      </Container>
    </>
  );
}

export default CardInfo;
