import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  Modal,
  ProgressBar
} from "react-bootstrap";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/config";
import { useUpdateDoc } from "../../hooks/useUpdateDoc";
import { useAddDocs } from "../../hooks/useAddDocs";
import { useGetDoc } from "../../hooks/useGetDoc";

const UrlModalForm = ({ data, hideModal, show, onHide, reloadData }) => {
  const [url, setUrl] = useState(data.url || "");
  const [title, setTitle] = useState(data.title || "");
  const [description, setDescription] = useState(data.description || "");
  const [image, setImage] = useState(null);
  const [img, setImg] = useState("/assets/img/no-img.png");
  const [progress, setProgress] = useState(0);
  const [warning, setWarning] = useState("");
  const type = data.type || "";
  const {
    firebaseUpdateDoc,
    isPending: isPendingUpdate,
    isConfirmed,
    error: errorUpdate
  } = useUpdateDoc();
  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();
  const { firebaseGetDoc, getSingleDocWithQuery, data2, isPending, error } =
    useGetDoc();

  const handelSubmit = async (e) => {
    e.preventDefault();
    // console.log(e);

    if (type === "new") {
      const prevData = await getSingleDocWithQuery("petnext_urls", [
        ["url", "==", url]
      ]);
      if (prevData[0] != undefined) {
        // console.log(prevData);
        setWarning("urlはすでに登録されています。");
        return;
      }
    }
    if (image) {
      const storageRef = ref(storage, `/ogimages/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 90
          );
          setProgress(percent);
        },
        (err) => // console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await updateOrAddData(url, type);
          });
        }
      );
    } else {
      setProgress(50);
      await updateOrAddData(img, type);
    }
  };

  const updateOrAddData = async (img_url, type) => {
    let inputData = { url: url, title: title, description: description };

    if (img_url != "/assets/img/no-img.png") {
      inputData.image = img_url;
    }
    if (type == "new") {
      await addADoc(`petnext_urls`, inputData).then((res) => {
        setProgress(100);
      });
    } else {
      await firebaseUpdateDoc(`petnext_urls`, inputData, data.id).then(
        (res) => {
          setProgress(100);
        }
      );
    }

    hideModal();
    reloadData();
    setProgress(0);
    setUrl("");
    setTitle("");
    setDescription("");
    setImg("/assets/img/no-img.png");
    setWarning("");
  };
  const handleImg = (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    if (data.image) {
      setImg(data.image);
    }
  }, []);

  useEffect(() => {
    if (show) {
      if (data.type != "new") {
        setUrl(data.url);
        setDescription(data.description);
        setTitle(data.title);
        if (data.image) {
          setImg(data.image);
        }
      }
    }
  }, [show]);

  const hideAction = () => {
    onHide();
    setProgress(0);
    setUrl("");
    setTitle("");
    setDescription("");
    setImg("/assets/img/no-img.png");
  };

  return (
    <Modal
      scrollable={true}
      style={{ overflow: "auto" }}
      show={show}
      onHide={hideAction}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton></Modal.Header>
      <Form onSubmit={handelSubmit} className="overflow-auto">
        <Modal.Body>
          <p className="text-center text-danger">{warning}</p>
          <FormGroup>
            <FormLabel>URL</FormLabel>
            <FormControl
              type="text"
              value={url || ""}
              required={true}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              placeholder="Url"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>タイトル</FormLabel>
            <FormControl
              type="text"
              value={title || ""}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required={false}
              placeholder="タイトル"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>ページ説明</FormLabel>
            <FormControl
              type="text"
              value={description || ""}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              required={false}
              placeholder="ページ説明"
            />
          </FormGroup>
          <div className="d-flex justify-content-center my-3">
            <Image src={img} className="img-fluid w-50" />
          </div>
          <FormGroup>
            <FormLabel>OGP画像</FormLabel>
            <FormControl
              type="file"
              onChange={handleImg}
              placeholder="OGP画像"
            />
          </FormGroup>
          <div className="my-3">
            {progress > 0 && <ProgressBar now={progress} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={hideAction}>
            閉じる
          </Button>
          <Button variant="success" type="submit">
            変更
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UrlModalForm;
