import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { increment } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { useAddDocs } from "../../hooks/useAddDocs";
import ProgressStep from "../../components/ProgressStep";
import { useAuthContext } from "../../hooks/useAuthContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useFirebaseIncrement } from "../../hooks/useFirebaseIncrement";
import useBtnClickLoader from "../../hooks/useBtnClickLoader";

export default function VetMCInstallationInputCheck() {
  const { width } = useWindowDimensions();
  const { user } = useAuthContext();
  const { addADoc, isPending, error } = useAddDocs();
  const navigate = useNavigate();
  const { firebaseIncrementMany } = useFirebaseIncrement();

  const { isLoading, startLoadBtn } = useBtnClickLoader();

  const [vetmcData, setVetmcData] = useState(
    JSON.parse(localStorage.getItem("vetmcData")) || false
  );

  const [vetMCInstallBreederpet, setVetMCInstallBreederpet] = useState(
    (JSON.parse(localStorage.getItem("vetMCInstallBreederpet")) &&
      JSON.parse(localStorage.getItem("vetMCInstallBreederpet"))[0]) ||
      false
  );

  const [certificateDownloadLink, setCertificateDownloadLink] = useState(
    localStorage.getItem("certificateDownloadLink") || false
  );

  useEffect(() => {
    if (!vetmcData || !vetMCInstallBreederpet) {
      navigate("/mypage/sochaku");
    }
  }, []);

  const mcinstallHandler = async () => {
    startLoadBtn();
    const mcCode = JSON.parse(localStorage.getItem("vetmccode")).mccode;
    const vetMCInstallData = {
      ...vetmcData,
      mccode: mcCode,
      certificateDownloadLink: certificateDownloadLink,
      breederpet: {
        firstKana: vetMCInstallBreederpet.firstKana,
        firstName: vetMCInstallBreederpet.firstName,
        lastKana: vetMCInstallBreederpet.lastKana,
        lastName: vetMCInstallBreederpet.lastName,
        phone: vetMCInstallBreederpet.phone,
        type: vetMCInstallBreederpet.type,
        id: vetMCInstallBreederpet.id,
        corporation_kana_name: vetMCInstallBreederpet.corporation_kana_name,
        corporation_name: vetMCInstallBreederpet.corporation_name,
      },
    };

    const breederpetMCInstallData = {
      ...vetmcData,
      mccode: mcCode,
      certificateDownloadLink: certificateDownloadLink,
      vet_id: user.uid,
    };

    await addADoc(`users/${user.uid}/mcinstall`, vetMCInstallData);
    await addADoc(
      `users/${vetMCInstallBreederpet.id}/mcinstall`,
      breederpetMCInstallData
    );
    localStorage.removeItem("certificateDownloadLink");
    localStorage.removeItem("vetmcData");
    localStorage.removeItem("vetMCInstallBreederpet");
    localStorage.removeItem("vetmccode");

    //increase the totalMC in users collenction
    firebaseIncrementMany("users", user.uid, {
      totalMC: increment(1),
    });

    //save update trigger
    const updateList = JSON.parse(localStorage.getItem("MY_DATA_1_Up")) || [];
    updateList.push(mcCode);
    localStorage.setItem("MY_DATA_1_Up", JSON.stringify(updateList));

    navigate("/mypage/VetMCInstallationComplete");
  };
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={3}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={8} md={8}>
            <h4 className="mt-4">
              ブリーダー/
              <span className="text-nowrap">ペットショップ</span>
            </h4>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-top border-start `
                } border-secondary p-2`}>
                電子メールアドレス
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-top border-start border-bottom border-end`
                } p-1 border-end border-secondary`}>
                {vetMCInstallBreederpet && vetMCInstallBreederpet.email}
              </Col>
            </Row>
            {vetMCInstallBreederpet.type === "1" && (
              <>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}>
                    氏名
                  </Col>
                  <Col
                    lg={9}
                    className={`pt-2  ${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}>
                    {vetMCInstallBreederpet.lastName}{" "}
                    {vetMCInstallBreederpet.firstName}
                  </Col>
                </Row>
              </>
            )}

            {vetMCInstallBreederpet.type === "2" && (
              <>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}>
                    法人名
                  </Col>
                  <Col
                    lg={9}
                    className={`pt-2 ${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}>
                    <ruby className="mt-4">
                      {vetMCInstallBreederpet.corporation_name}
                      <rt>{vetMCInstallBreederpet.corporation_kana_name}</rt>
                    </ruby>
                  </Col>
                </Row>
              </>
            )}

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                携帯<span className="text-nowrap">電話番号</span>:
              </Col>
              <Col
                lg={9}
                className={`pt-2 ${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {vetMCInstallBreederpet.phone}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={8}>
            <h4 className="mt-4">所有者情報</h4>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-top border-start `
                } border-secondary p-2`}>
                MCの装着日
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-top border-start border-bottom border-end`
                } p-1 border-end border-secondary`}>
                {vetmcData.mcdate}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                MCを装着した施設名
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {vetmcData.vet_institute}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                MCを装着した施設の
                <span className="text-nowrap">所在地</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                <>
                  <p className="mb-0">
                    〒{vetmcData.vet_location_zip3}-
                    {vetmcData.vet_location_zip4}
                  </p>
                  <p className="mb-0">
                    {vetmcData.vet_location_prefecture}{" "}
                    {vetmcData.vet_location_addressLine1}
                  </p>
                  <p className="mb-0">{vetmcData.vet_location_addressLine2}</p>
                  <p className="mb-0">{vetmcData.vet_location_addressLine3}</p>
                </>
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                MCを装着した施設の
                <span className="text-nowrap">電話番号</span>
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {vetmcData.vet_phone}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                獣医師の氏名
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                {vetmcData.vet_lastName} {vetmcData.vet_firstName}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}>
                装着証明書
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}>
                <Row className="justify-content-center mb-4">
                  <Col lg={3} md={4}>
                    <a href={vetmcData.certificateDownloadLink} target="_blank">
                      <img
                        src={vetmcData.certificateDownloadLink}
                        alt=""
                        className="w-100"
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row className="justify-content-center mb-4">
          <Col lg={8} md={8}>
            <h4 className="mt-4">装着証明書</h4>
            <Row className="justify-content-center mb-4">
              <Col lg={3} md={4}>
                <img src={certificateDownloadLink} alt="" className="w-100" />
              </Col>
            </Row>
          </Col>
        </Row> */}

        <div className="text-center mt-4">
          <Link
            className="btn btn-secondary m-2"
            to="/mypage/vetmcinstallinput">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link>

          {!isPending && !isLoading && (
            <Button className="btn btn-primary m-2" onClick={mcinstallHandler}>
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />
            </Button>
          )}

          {(isPending || isLoading) && (
            <Button className="btn btn-primary m-2" disabled>
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </div>
        <br />
        {isPending}
      </Container>
    </>
  );
}
