import React from "react";

export default function TramsText() {
  return (
    <>
      <p>
        株式会社PETNEXT（以下「当社」といいます）は、当社が運営するマイクロチップ登録サイト「MCR」の運営・管理を通じてご提供するサービス（以下、「本サービス」といいます）の利用規約（以下「本規約」といいます）を以下の通り定めます。本サービスをご利用される方は、本規約の全ての条項を承諾していただくことが条件となりますので、ご登録される前に本規約を必ずお読みください。
      </p>
      <br />
      <p className="mb-0">第1条 目的</p>
      <ul>
        <li>
          会員(第2条第1項で定義します)の所有もしくは販売する犬および猫の生体管理を行うために本サービスを利用できます。
        </li>
        <li>
          会員が環境省のマイクロチップ登録に際し、本サービスを利用し行うことができます。環境省への登録に際しては、当社指定の下記行政書士が代行します。
        </li>
      </ul>
      <p>
        行政書士山県慶宏事務所　行政書士　　山県　慶宏 <br />
        〒１０１－００２７　東京都千代田区神田平河町１番地　第３東ビル６Ｆ
      </p>
      <ul>
        <li>会員は予め本規約を確認した上で、本サービスを利用します。</li>
        <li>
          本規約はサイト上での掲載、メール等の当社が適当と判断する方法で会員に通知することにより、会員の事前の承諾なしに新たな規定を追加、変更できるものとします。
        </li>
        <li>
          本規約が変更された場合、会員は変更後の規約のみに従うものとします。
        </li>
      </ul>
      <p className="mb-0">第2条 会員登録</p>
      <ul>
        <li>
          「会員」とは、本規約の内容全てを承認いただいた上、当社所定の手続きに従い会員登録を申請し、当社がこれを承認したものをいいます。
        </li>
        <li>
          会員登録手続は、本サービスの会員登録ページから当社の指定する方法に従い、会員登録を希望する本人が行うものとします。代理による登録は一切認められません。
        </li>
        <li>
          当社は、会員登録を申請した者が以下の各号のいずれかの事由に該当する場合は、登録を拒否し、または事前通知することなく一旦なされた登録を取り消すことがあります
          <ul type="disc">
            <li>
              当社の提供する何らかのサービスに関する規約に違反をしたことなどにより会員登録の抹消等
            </li>
            <li>会員登録の申請に虚偽の事項が含まれている場合</li>
            <li>
              商品又はサービス（以下「商品等」といいます）に関する料金などの支払債務の履行遅延その他
            </li>
            <li>本規約第6条（禁止事項）の行為を行った場合</li>
            <li>その他本規約に違反した場合</li>
          </ul>
        </li>
      </ul>
      <p className="mb-0">第3条 登録内容の変更</p>
      <ul>
        <li>
          登録情報の内容の全部又は一部に関して変更が生じた場合は、会員は直ちに当社が別途指定する方法により登録内容を変更するものとします。
        </li>
        <li>
          当該通知を怠った場合には、既に登録済みの情報に基づく処理を適正・有効なものとします。
        </li>
        <li>
          また、変更登録がなかったことにより生じた損害について、当社は一切責任を負いません。
        </li>
      </ul>
      <p className="mb-0">第4条 ユーザIDおよびパスワードの管理</p>
      <ul>
        <li>
          会員は、会員登録等の際に会員本人が設定し、承認・登録されたユーザID及びパスワードの利用、管理について一切の責任を負うものとします。
        </li>
        <li>
          会員は、ユーザID及びパスワードを第三者へ譲渡、承継、名義変更、貸与、開示又は漏洩してはならないものとします。
        </li>
        <li>
          会員のユーザID及びパスワード又はクレジットカード番号の使用・管理上の問題又は第三者による不正使用等に起因する損害について当社は一切責任を負わないものとします。
        </li>
      </ul>
      <p className="mb-0">第5条 会員の退会</p>
      <ul>
        <li>
          会員が退会を希望する場合には、会員本人が会員情報の削除を行ってください。当社所定の退会手続の終了に伴い、退会となります。
        </li>
      </ul>
      <p className="mb-0">第6条 禁止事項</p>
      <ul>
        <li>
          本サービスの利用に際して、会員は以下の各号の行為を行ってはならないものとします。
        </li>
        <li>
          会員登録又は登録内容の変更の際に虚偽の内容、又は第三者の情報を入力する行為
        </li>
        <li>
          本サービスの運営を妨げ、その他本サービスに支障をきたすおそれのある行為
        </li>
        <li>
          他の会員および第三者もしくは当社の財産、プライバシー、著作権等の知的財産、その他の権利を侵害する行為、又はそれらのおそれのある行為
        </li>
        <li>
          他の会員および第三者、もしくは当社の誹謗、中傷、名誉を毀損する行為、又はそれらの恐れのある行為
        </li>
        <li>
          他の会員および第三者、もしくは当社に迷惑、不利益もしくは損害を与える行為、又はそれらの恐れのある行為
        </li>
        <li>ユーザID及びパスワードを不正に使用する行為</li>
        <li>同業者の再販など、営利目的での購入</li>
        <li>その他弊社が不適当と判断する行為</li>
      </ul>
      <p className="mb-0">第7条 著作権、商標権およびその他の知的所有権</p>
      <ul>
        <li>
          本サービスを通じて提供されるいかなるコンテンツ（文章、イラスト、デザイン、写真、画像、ロゴ、アイコン、映像、プログラム等）（以下「コンテンツ」といいます）の著作権、商標権およびその他の知的所有権は全て当社に帰属するものであり、会員および利用者はこれらの権利を侵害する行為を行わないものとします。
        </li>
        <li>
          目的の如何を問わず、本サービスのコンテンツその他掲載内容全て、または一部を許可なく使用（複製、改変、転用、転送、配布、掲示、販売、出版など）する行為は固く禁止します。
        </li>
        <li>
          本条の規定に違反して第三者との間で問題が生じた場合、会員は自己の責任と費用においてかかる問題を解決するとともに当社に何等の損害、損失又は不利益等を与えないものとします。
        </li>
      </ul>
      <p className="mb-0">第8条 決済方法</p>
      <ul>
        <li>
          マイクロチップ登録およびそれに付随する費用を決済していただきます。
        </li>
        <li>
          本サービスによって購入された商品またはサービスについてはクレジットカードのみの決済とします。
        </li>
        <li>
          クレジットカードでのお支払いの場合、会員がクレジットカード会社との間で別途契約した条件に従うものとします。なお、会員と当該クレジットカード会社等第三者との間で紛争が発生した場合は、当該当事者双方で解決するものとし、当社は一切責任を負わないものとします。
        </li>
      </ul>
      <p className="mb-0">第9条 マイクロチップ登録のキャンセル</p>
      <ul>
        <li>
          マイクロチップの登録は、お申込みいただいた当日の夜に、環境省のデータベースに登録し、翌日登録いただいたメールアドレスに「マイクロチップ登録証明書」が送付されますので、キャンセルされる場合は、当日の17時までに「ご利用ガイド」記載の「お問い合わせフォーム」にご連絡いただいた場合に限り受付けいたします。
        </li>
      </ul>
      <p className="mb-0">第10条 情報の管理</p>
      <ul>
        <li>
          当社は取得した会員情報を下記の目的に利用するものとします。また、その利用目的達成のために当社が選定した業務委託会社へ委託する場合があります。
          <ul type="disc">
            <li>本サービス会員、ユーザの管理</li>
            <li>メールマガジンの発行</li>
            <li>当社商品等の紹介、広告、宣伝、販売の勧誘</li>
            <li>商品の発送</li>
            <li>カスタマーサポート</li>
            <li>キャンペーン等企画、アンケートの実施</li>
            <li>その他本サービスに関連したコンテンツをご提供するため</li>
            <li>個人を特定できない状態に加工した後のマーケティング活動</li>
          </ul>
        </li>
        <li>
          当社は、原則として会員が会員登録手続および、その後の会員情報の変更登録において、開示した会員情報を会員の事前の同意なく第三者に対して開示・提供することはありません。ただし、以下の場合には、会員の事前の同意なく、当社はこれらの会員情報を開示・提供できるものとします。
          <ul type="disc">
            <li>統計的なデータとして、会員を識別できない状態に加工した場合</li>
            <li>法令などに基づき、会員情報の開示・提供を求められた場合</li>
          </ul>
        </li>

        <li>
          当社は、当社が利用することを承諾した上で会員が発信したコメントその他の情報について、当該情報が他の会員、第三者、もしくは当社の名誉もしくは信用を明らかに毀損、または法令に違反していると認められた場合等の理由により、当社が必要と判断した場合には会員に断りなくこれを削除、もしくは掲載場所を変更することができるものとします。
        </li>
        <li>
          前3項の他、当社は別途定める「個人情報の取り扱いについて」に則り取得した会員情報を取り扱うものとします。
        </li>
      </ul>
      <p className="mb-0">第11条 サービスの利用</p>
      <p>
        本サービスを利用するに際しては、本規約及び当社が別途定める「個人情報の取り扱いについて」に、あらかじめ同意いただくものとします。
      </p>
      <p className="mb-0">第12条 サービスの変更・廃止</p>
      <ul>
        <li>
          本サービスを提供するためのコンピュータシステム(以下「システム」といいます)のトラブル等で緊急な保守点検が必要なとき
        </li>
        <li>
          火災、停電、天災その他不可抗力によりシステムの運用が困難になった場合
        </li>
        <li>
          人為的災害（戦争､暴動､騒乱､労働争議等）によりシステムの運用が困難になった場合
        </li>
        <li>第三者による妨害行為等により、システムの運用が困難になった場合</li>
        <li>その他、やむを得ずシステムの停止が必要と当社が判断した場合</li>
      </ul>
      <p className="mb-0">第13条 その他の免責事項</p>
      <ul>
        <li>
          当社は、利用者の登録内容に従って事務を処理することにより会員に生じたいかなる損害についても、免責されるものとします。
        </li>
        <li>
          当社が相当の安全策を講じたにもかかわらず通信回線やコンピュータなどに障害が生じ、システムの中断・遅滞・中止等による損害、ウェブページが改ざんされたことにより会員に生じた損害については、当社は一切責任を負いません。
        </li>
        <li>
          当社のウェブページ・サーバ・ドメインなどから送られるメール・コンテンツに、コンピューター・ウィルスなどの有害なものが含まれていないことを保証いたしません。
        </li>
        <li>
          当社は、会員に対し、適宜情報提供やアドバイスを行うことがありますが、それにより責任を負うものではありません。
        </li>
        <li>
          当社は、会員が本規約に違反したことによって生じた損害等については、一切責任を負わないものとします。
        </li>
        <li>
          会員が、本サービスをご利用になることにより、他の会員または第三者に対して損害等を与えた場合には、当該会員は自己の責任と費用において解決し、当社には一切損害等を与えないものとします。
        </li>
      </ul>
      <p className="mb-0">第14条 準拠法、合意管轄</p>
      <p>
        本規約は日本法に基づき解釈されるものとし、本規約に関し訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所といたします。
      </p>
      <h4 className="text-center">個人情報保護方針</h4>
      <p>
        当社はお客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下の個人情報保護方針に従い、適切な取扱い及び保護に努めます。
      </p>
      <p className="mb-0">1.　個人情報の定義</p>
      <p>
        本個人情報保護方針において、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）を意味するものとします。
      </p>
      <p className="mb-0">2.　個人情報の利用目的</p>
      <p className="mb-0">
        当社は、お客様の個人情報を、以下の目的で利用いたします。
      </p>
      <ul>
        <li>当社の商品、サービス等の案内及び提供のため</li>
        <li>環境省へのマイクロチップ登録を行うため</li>
        <li>
          当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
        </li>
        <li>当社サービスに関する規約等の変更などを通知するため</li>
        <li>当社のサービスの改善、新サービスの開発等に役立てるため</li>
        <li>
          当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため
        </li>
        <li>その他、上記利用目的に付随する目的のため</li>
      </ul>
      <p className="mb-0">3.　個人情報利用目的の変更</p>
      <p>
        当社は、個人情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。
      </p>
      <p className="mb-0">4.　個人情報利用の制限</p>
      <p>
        当社は、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
      </p>
      <ol>
        <li>法令に基づく場合</li>
        <li>
          人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を　得ることが困難であるとき
        </li>
        <li>
          公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お　　客様の同意を得ることが困難であるとき
        </li>
        <li>
          国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行す　　ることに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事　　務の遂行に支障を及ぼすおそれがあるとき
        </li>
      </ol>
      <p className="mb-0 mt-4"> 5.　個人情報の適正な取得</p>
      当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
      <p className="mb-0 mt-4"> 6.　個人情報の安全管理</p>
      当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
      <p className="mb-0 mt-4"> 7.　第三者提供</p>
      当社は、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめお客様の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません
      <ol>
        <li>
          当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
        </li>
      </ol>
      <p className="mb-0 mt-4"> 8.　個人情報の開示</p>
      当社は、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。
      <p className="mb-0 mt-4"> 9.　個人情報の訂正等</p>
      当社は、お客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、お客様ご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。
      <p className="mb-0 mt-4"> 10.　個人情報の利用停止等</p>
      当社は、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨をお客様に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。
      <p className="mb-0 mt-4"> 11.　継続的改善</p>
      当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本個人情報保護方針を変更することがあります。
      <br />
      <br />
      <p>
        株式会社DETECT（以下「当社」といいます）は、当社が運営する犬猫譲渡サイト「LOOKFOR」の運営・管理を通じてご提供するサービス（以下、「本サービス」といいます）の利用規約（以下「本規約」といいます）を以下の通り定めます。
        <br></br>
        本サービスをご利用される方は、本規約の全ての条項を承諾していただくことが条件となりますので、ご登録される前に本規約を必ずお読みください。
      </p>
      <p>
        第1条 目的<br></br>
        1.会員(第2条第1項で定義します)の所有もしく販売する犬および猫を飼い主に譲渡するために本サービスを利用できます。
        <br></br>
        2.全ての会員は、子犬および子猫の販売（里親は含まれません）に際し、環境省のマイクロチップ登録による飼い主へ譲渡後、1年間以内に先天性障害が発症した場合、飼い主に対して瑕疵保証（契約不適合）の責務を負います。（ただし、販売金額を超えての責任は負いません）
      </p>
      <p>
        第2条 会員登録 1.
        「会員」とは、本規約の内容全てを承認いただいた上、当社所定の手続きに従い会員登録を申請し、当社がこれを承認したものをいいます。
        <br></br>
        2.会員登録手続は、本サービスの会員登録ページから当社の指定する方法に従い、会員登録を希望する本人が行うものとします。代理による登録は一切認められません。
        <br></br>
        3.
        本サイトは、株式会社PETNEXT社の運用管理するマイクロチップ登録サイト（以下「MCR」という。）とデータを共有して、会員の登録業務の利便性を図ります。
        <br></br>
        4.
        会員は、本サイトでの利用料および紹介料などの支払いの義務は発生しないものの、マイクロチップ登録およびそれに付随する費用（マイクロチップおよび先天性障害保証などの保証料、行政書士による代行登録費用、電子販売契約書利用料および「MCR」月額管理費など）を負担する前提で本サイトに登録していただきます。
        <br></br>
        5.
        当社は、会員登録を申請した者が以下の各号のいずれかの事由に該当する場合は、登録を拒否し、または事前通知することなく一旦なされた登録を取り消すことがあります。
        <br></br>
        •当社の提供する何らかのサービスに関する規約に違反をしたことなどにより会員登録の抹消等
        <br></br>
        •会員登録の申請に虚偽の事項が含まれている場合<br></br>
        •商品又はサービス（以下「商品等」といいます）に関する料金などの支払債務の履行遅延その他
        <br></br>
        •本規約第6条（禁止事項）の行為を行った場合<br></br>
        •その他本規約に違反した場合
      </p>
      <p>
        第3条 登録内容の変更<br></br>
        1.登録情報の内容の全部又は一部に関して変更が生じた場合は、会員は直ちに当社が別途指定する方法により登録内容を変更するものとします。
        <br></br>
        2.当該通知を怠った場合には、既に登録済みの情報に基づく処理を適正・有効なものとします。
        <br></br>
        3.また、変更登録がなかったことにより生じた損害について、当社は一切責任を負いません。
      </p>
      <p>
        第4条 ユーザIDおよびパスワードの管理<br></br>
        1.会員は、会員登録等の際に会員本人が設定し、承認・登録されたユーザID及びパスワードの利用、管理について一切の責任を負うものとします。
        <br></br>
        2.会員は、ユーザID及びパスワードを第三者へ譲渡、承継、名義変更、貸与、開示又は漏洩してはならないものとします。
        <br></br>
        3.会員のユーザID及びパスワード又はクレジットカード番号の使用・管理上の問題又は第三者による不正使用等に起因する損害について当社は一切責任を負わないものとします。
      </p>
      <p>
        第5条 会員の退会<br></br>
        1.会員が退会を希望する場合には、会員本人が会員情報の削除を行ってください。当社所定の退会手続の終了に伴い、退会となります。
      </p>
      <p>
        第6条 禁止事項<br></br>
        本サービスの利用に際して、会員は以下の各号の行為を行ってはならないものとします。
        <br></br>
        1.本サービスの利用に際して、会員は以下の各号の行為を行ってはならないものとします。
        <br></br>
        2.会員登録又は登録内容の変更の際に虚偽の内容、又は第三者の情報を入力する行為
        <br></br>
        3.本サービスの運営を妨げ、その他本サービスに支障をきたすおそれのある行為
        <br></br>
        4.他の会員および第三者もしくは当社の財産、プライバシー、著作権等の知的財産、その他の権利を侵害する行為、又はそれらのおそれのある行為
        <br></br>
        5.他の会員および第三者、もしくは当社の誹謗、中傷、名誉を毀損する行為、又はそれらの恐れのある行為
        <br></br>
        6.他の会員および第三者、もしくは当社に迷惑、不利益もしくは損害を与える行為、又はそれらの恐れのある行為
        <br></br>
        7.ユーザID及びパスワードを不正に使用する行為<br></br>
        8.同業者の再販など、営利目的での購入<br></br>
        9.その他弊社が不適当と判断する行為
      </p>
      <p>
        第7条 著作権、商標権およびその他の知的所有権
        1.本サービスを通じて提供されるいかなるコンテンツ(文章、イラスト、デザイン、写真、画像、ロゴ、アイコン、映像、プログラム等）（以下「コンテンツ」といいます）の著作権、商標権およびその他の知的所有権は全て当社に帰属するものであり、会員および利用者はこれらの権利を侵害する行為を行わないものとします。
        <br></br>
        2.目的の如何を問わず、本サービスのコンテンツその他掲載内容全て、または一部を許可なく使用（複製、改変、転用、転送、配布、掲示、販売、出版など）する行為は固く禁止します。
        <br></br>
        3.本条の規定に違反して第三者との間で問題が生じた場合、会員は自己の責任と費用においてかかる問題を解決するとともに当社に何等の損害、損失又は不利益等を与えないものとします。
      </p>
      <p>
        第8条 会員の義務<br></br>
        1.会員が本サイトで販売した子犬・子猫（マイクロチップ登録証明書付き）について、別表に定める先天性障害を、販売日より１年間の販売金額を限度に、飼い主に保証していただきます
        <br></br>
        2.会員から飼い主への販売（譲渡）を明確にするため、会員は、販売日に飼い主への所有権変更を必ず「ＭＣＲ」サイトにて行うものとします。
        <br></br>
        3.前２項を実施しなかった場合は、互助会の制度を利用していた場合でも先天性障害に係る瑕疵保証（契約不適合）を互助会ではお支払できません。
      </p>
      <p>
        第9条 情報の管理<br></br>
        1.当社は取得した会員情報を下記の目的に利用するものとします。
        <br></br>
        また、その利用目的達成のために当社が選定した業務委託会社へ委託する場合があります。
        <br></br>
        •本サービス会員、ユーザの管理<br></br>
        •メールマガジンの発行<br></br>
        •当社商品等の紹介、広告、宣伝、販売の勧誘<br></br>
        •商品の発送<br></br>
        •カスタマーサポート<br></br>
        •キャンペーン等企画、アンケートの実施<br></br>
        •その他本サービスに関連したコンテンツをご提供するため<br></br>
        •個人を特定できない状態に加工した後のマーケティング活動<br></br>
        2.当社は、原則として会員が会員登録手続および、その後の会員情報の変更登録において、開示した会員情報を会員の事前の同意なく第三者に対して開示・提供することはありません。ただし、以下の場合には、会員の事前の同意なく、当社はこれらの会員情報を開示・提供できるものとします。
        <br></br>
        •統計的なデータとして、会員を識別できない状態に加工した場合
        <br></br>
        •法令などに基づき、会員情報の開示・提供を求められた場合<br></br>
        3.当社は、当社が利用することを承諾した上で会員が発信したコメントその他の情報について、当該情報が他の会員、第三者、もしくは当社の名誉もしくは信用を明らかに毀損、または法令に違反していると認められた場合等の理由により、当社が必要と判断した場合には会員に断りなくこれを削除、もしくは掲載場所を変更することができるものとします。
        <br></br>
        4.前3項の他、当社は別途定める「個人情報の取り扱いについて」に則り取得した会員情報を取り扱うものとします。
      </p>
      <p>
        第10条 サービスの利用<br></br>
        本サービスを利用するに際しては、本規約及び当社が別途定める「個人情報の取り扱いについて」に、あらかじめ同意いただくものとします。
      </p>
      <p>
        第11条 サービスの変更・廃止<br></br>
        1.本サービスを提供するためのコンピュータシステム(以下「システム」といいます)のトラブル等で緊急な保守点検が必要なとき
        <br></br>
        2.火災、停電、天災その他不可抗力によりシステムの運用が困難になった場合
        <br></br>
        3.人為的災害（戦争､暴動､騒乱､労働争議等）によりシステムの運用が困難になった場合
        <br></br>
        4.第三者による妨害行為等により、システムの運用が困難になった場合
        <br></br>
        5.その他、やむを得ずシステムの停止が必要と当社が判断した場合
      </p>
      <p>
        第12条 その他の免責事項<br></br>
        1.当社は、利用者の登録内容に従って事務を処理することにより会員に生じたいかなる損害についても、免責されるものとします。
        <br></br>
        2.当社が相当の安全策を講じたにもかかわらず通信回線やコンピュータなどに障害が生じ、システムの中断・遅滞・中止等による損害、ウェブページが改ざんされたことにより会員に生じた損害については、当社は一切責任を負いません。
        <br></br>
        3.当社のウェブページ・サーバ・ドメインなどから送られるメール・コンテンツに、コンピューター・ウィルスなどの有害なものが含まれていないことを保証いたしません。
        <br></br>
        4.当社は、会員に対し、適宜情報提供やアドバイスを行うことがありますが、それにより責任を負うものではありません。
        <br></br>
        5.当社は、会員が本規約に違反したことによって生じた損害等については、一切責任を負わないものとします。
        <br></br>
        6.会員が、本サービスをご利用になることにより、他の会員または第三者に対して損害等を与えた場合には、当該会員は自己の責任と費用において解決し、当社には一切損害等を与えないものとします。
      </p>
      <p>
        第13条 準拠法、合意管轄<br></br>
        本規約は日本法に基づき解釈されるものとし、本規約に関し訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所といたします。
        <br></br>
      </p>
    </>
  );
}
