import { useState, useEffect } from "react";
//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import { confirmPasswordReset } from "firebase/auth";

export const useResetPassword = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const resetPassword = (oobCode, password) => {
    setError(null);
    setMessage(null);
    setIsPending(true);

    confirmPasswordReset(auth, oobCode, password)
      .then((res) => {
        setMessage("パスワードを変更しました");
        setIsPending(false);
        setIsConfirmed(true);
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
      });
  };

  return { error, isPending, message, isConfirmed, resetPassword };
};
