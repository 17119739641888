import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import { Helmet, HelmetData } from "react-helmet-async";
export default function ForgetPassword() {
  const [email, setEmail] = useState("");
  const { error, isPending, message, forgotPassword } = useForgotPassword();

  const handelSubmit = (e) => {
    e.preventDefault();
    forgotPassword(email);
    
  };

  useEffect(() => {
    setEmail("")
  }, [message, error])

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>パスワードの再発行</title>
      </Helmet>
      <Container className="my-5">
        <h1 className="text-center">パスワードの再発行</h1>
        <p className="text-center pt-3">
          新しいパスワードをメールで送信するため、メールアドレスを入力して下さい。
        </p>
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <Card className="shadow-sm">
              <Card.Body>
                {error && (
                  <Alert variant="danger" dismissible className="text-center">
                    {error && <p className="m-0">{error}</p>}
                  </Alert>
                )}
                {message && (
                  <Alert variant="success" dismissible className="text-center">
                    {message && <p className="m-0">{message}</p>}
                  </Alert>
                )}
                <Form onSubmit={handelSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Eメールアドレス <span className="text-danger">※</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value = {email} 
                      required
                    />
                  </Form.Group>
                  <div className="text-center">
                    {!isPending && (
                      <Button className="mb-3" variant="primary" type="submit">
                        <b>送信</b> <FontAwesomeIcon icon={faPaperPlane} />
                      </Button>
                    )}

                    {isPending && (
                      <Button className="mb-3" variant="primary" disabled>
                        <b>送信 </b> <FontAwesomeIcon icon={faPaperPlane} />
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    )}
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
