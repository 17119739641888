import React from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import styles from "./PrivacyPolicy.module.css";

function PrivacyPolicy() {
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>プライバシーポリシー</title>
        <meta property="og:title" content="CKC" />
        <meta name="description" content="CKC" />
      </Helmet>
      <div className="main">
        <div className={styles.personalinfo}>
          <div className="container position-relative">
            <p className={styles.titleT}>Privacy Policy</p>
            <p className={styles.subtitleT}>プライバシーポリシー</p>

            <img src="/assets/img/asset15.png" alt="" className={styles.dog} />
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-sm-11 col-lg-10">
                <div className={styles["m-card"]}>
                  <p className={styles.title}>【個人情報の利用目的の公表】</p>

                  <div className={styles.details}>
                    <p>
                      株式会社PETNEXT（以下、「当社」という。）は、個人情報を次の利用目的の範囲内で利用することを、個人情報の保護に関する法律（個人情報保護法）第18条第１項に基づき公表します。
                    </p>
                    <br />

                    <p>個人情報の利用目的</p>

                    <p>
                      （１）ご本人より直接書面等（ホームページや電子メール等によるものを含む。以下「書面」という）に記載された個人情報を取得する場合の利用目的。
                    </p>

                    <ul>
                      <li>
                        会員の個人情報は、マイクロチップ登録管理およびそれに付随するサービス利用のため
                      </li>

                      <li>
                        お取引先の個人情報は、業務連絡、新商品や新たなサービスのご案内のため
                      </li>

                      <li>
                        当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするため
                      </li>

                      <li>
                        当社従業員の個人情報は、人事労務管理、業務管理、健康管理、セキュリティ管理等のため
                      </li>

                      <li>当社への直接応募者情報は、採用業務のため</li>
                    </ul>

                    <p className="mt-2">
                      （２）ご本人より直接書面以外の方法で個人情報を取得する際の利用目的
                    </p>
                    <ul>
                      <li>会員の個人情報は、会員管理のため</li>
                      <li>
                        ハローワークや民間就職斡旋サイトからの応募者情報は、採用業務のため
                      </li>
                    </ul>
                  </div>

                  <br />
                  <br className="d-block d-sm-none"/>

                  <p className={`${styles.title} mt-2 mt-sm-5`}>
                    【保有個人データに関する事項の周知について】
                  </p>

                  <div className={styles.details}>
                    <p>
                      株式会社PETNEXT（以下、「当社」という。）の保有個人データ（当社が、利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止を行う権限を有するもの）は、以下の通りです。
                    </p>
                    <br />

                    <p>１．個人情報に関する管理者の氏名、所属及び連絡先</p>
                    <p className={styles.stext}>
                      管理者名：個人情報保護管理者　
                      <br />
                      役職名
                      <span className="d-inline d-sm-none">&nbsp; &nbsp; </span>
                      ：株式会社PETNEXT　
                      <br className="d-block d-sm-none" />
                      個人情報担当
                      <br className="d-none d-sm-block" />
                      連絡先：電話03-6794-0967
                      <br />
                    </p>
                    <br />

                    <p>2．保有個人データの利用目的</p>
                    <ul>
                      <li>
                        会員の個人情報は、会員管理、マイクロチップ登録管理およびそれに付随するサービス提供のため
                      </li>

                      <li>
                        お取引先の個人情報は、業務連絡、新商品や新たなサービスのご案内のため
                      </li>

                      <li>
                        当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするため
                      </li>

                      <li>
                        当社従業者の個人情報は、人事労務管理、業務管理、健康管理、セキュリティ管理等のため
                      </li>

                      <li>当社への直接応募者情報は、採用業務のため</li>
                    </ul>

                    <p>３．保有個人データに関する苦情の申し出先</p>
                    <p>
                      保有個人データに関するお問合せにつきましては、下記窓口で受付けております。
                    </p>
                    <br />

                    <p className={styles.stext}>
                      東京都中央区新川2－20－5
                      <br />
                      株式会社PETNEXT　個人情報お問合わせ窓口
                      <br />
                      メールアドレス：petnext01@gmail.com
                      <br />
                      TEL：03-6794-0967（受付時間　13:00～17:00）
                    </p>
                    <br />
                    <p>
                      ４．当社の加入する認定個人情報保護団体について
                      <br />
                      当社の加入する認定個人情報保護団体はありません。
                    </p>
                  </div>

                  <p className={`${styles.title} ${styles.marginTOP}`}>
                    【保有個人データの開示等に関する手続き】
                  </p>

                  <div className={styles.details}>
                    <p>
                      保有個人データについて、「利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止」（開示等）のご請求が出来ます。
                    </p>
                    <p className="mt-1">
                      当社では、次の手順で個人情報の開示等の求めに対応いたします。
                    </p>
                    <br />

                    <p className={styles.mediumtext}>１．開示等の求めの受付</p>
                    <p>（１）下記の宛先までご連絡ください。</p>
                    <p>
                      当社より「所定の用紙」を郵送いたしますので、到着後、
                      当社の「個人情報の取扱いについて」に同意後、以下の宛先まで郵送またはご持参ください。
                    </p>
                    <br />

                    <p className={styles.stext}>
                      〒104-0033 東京都中央区新川2－20－5
                      <br />
                      株式会社PETNEXT　個人情報お問合わせ窓口
                      <br />
                      メールアドレス：petnext01@gmail.com
                      <br />
                      TEL：03-6794-0967（受付時間　13:00～17:00）
                    </p>
                    <br />

                    <p>
                      （２）本人確認のため、以下の書類いずれか1通を(1)「所定の用紙」とともに郵送またはご持参ください。
                    </p>
                    <ul>
                      <li>
                        運転免許証、パスポート等の公的証明書で本人確認ができるものの写し
                        <br className="d-none d-sm-block" />
                        （開示等の求めをする本人の名前および住所が記載されているもの）
                      </li>
                      <li>
                        住民票の写し（開示等の求めをする日前３０日以内に作成されたもの）
                      </li>
                    </ul>
                    <br />

                    <p>
                      （３）代理人の方が手続きをされる場合は、（１）「所定の用紙」、（２）「本人確認書類」に加え、以下のア）のいずれか一つおよびイ）の書類も郵送またはご持参ください。
                    </p>
                    <p className={styles.stext}>ア)代理人の本人確認書類</p>
                    <ul>
                      <li>
                        代理人を証明する書類、代理人の運転免許証、パスポート等の公的証明書で代理人確認ができるものの写し
                        （開示等の求めをする代理人の名前および住所が記載されているもの）
                      </li>

                      <li>
                        代理人の住民票の写し（開示等の求めをする日前３０日以内に作成されたもの）
                      </li>
                      <li>代理人が弁護士の場合は、登録番号のわかる書類</li>
                    </ul>

                    <p className={styles.stext}>
                      ィ）代理権を証する書面（委任状など）
                    </p>
                    <ul>
                      <li>
                        本人または代理人を証明する書類に、本籍地が明示されている場合は、消去していただいて結構です。
                      </li>
                      <li>
                        本人または代理人を証明する書類は、当社が入手してから2か月以内に責任を持って廃棄いたします。
                      </li>
                    </ul>
                    <br />

                    <p className={styles.mediumtext}>
                      ２．「開示等の請求」の手数料及びその徴収方法
                    </p>
                    <p>
                      <span className="d-none d-sm-inline">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>{" "}
                      利用目的の通知又は開示の請求の場合にのみ、１回の請求につき、以下の金額（当社からの返信費を含む）を申し受けます。下記金額分の郵便定額小為替を請求書類の郵送時に同封してください。
                    </p>
                    <br />
                    <ul>
                      <li>手数料金額：1,100円（税込）</li>
                    </ul>
                    <br />

                    <p className={styles.mediumtext}>３．検討結果連絡</p>

                    <p className={styles.stext}>
                      当社内で検討した結果は、以下の方法により遅滞なくご連絡します。
                    </p>

                    <ul>
                      <li>
                        個人情報の利用目的の通知、開示の場合は、書面の郵送または手渡し
                      </li>
                      <li>
                        個人情報の内容の訂正、通知または削除、利用の停止、消去および第三者への提供の停止の場合は、電話またはメール
                      </li>
                    </ul>
                    <p className={styles.stext}>
                      書面の送付先は、以下のいずれかの適切な方法を選択し行います。
                    </p>
                    <ul>
                      <li>登録されている本人の住所に回答文面を郵送する。</li>
                      <li>登録されている本人のFAX番号に回答文面をFAXする。</li>
                      <li>
                        登録されている本人のＥメールアドレスに回答文面をメールする。
                      </li>
                      <li>
                        登録されている本人の電話番号に電話をかけ、口頭にて回答する。
                      </li>
                    </ul>

                    <p className="mt-3 mt-sm-5">以上</p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
            <br className="d-none d-sm-block" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
