import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

import { useGetDoc } from "../../../hooks/useGetDoc";
import ProgressStep from "../../../components/ProgressStep";
import styles from "./../../mcregistration/MCStyle.module.css";
import { useAuthContext } from "../../../hooks/useAuthContext";

const RecommendedMc = () => {
  const [customError, setCustomError] = useState(null);
  const { user } = useAuthContext();
  const { firebaseGetDoc, getSingleDocWithQuery, isPending, error } =
    useGetDoc();
  const [isDisable, setIsDisable] = useState(false);

  const navigate = useNavigate();
  let initialValues = {
    animalID: ""
  };

  const schema = yup.object().shape({
    animalID: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[a-zA-Z0-9]+$/, "半角英数字のみご入力ください")
      .length(8, "8文字でご入力ください。")
  });

  const handleSubmit = (values) => {
    setCustomError(null);
    setIsDisable(true);
    try {
      //check if mc already registered or not
      getSingleDocWithQuery("microchip", [
        ["animalID", "==", values.animalID.toString()]
      ]).then((res) => {
        if (!res) {
          setCustomError(`${values.animalID} ペットIDのペットの記録は見つかりませんでした。`);
        } else {
          if (res[0].sellPlatform) {
            checkIfAddedInRecommended(values.animalID.toString(), res[0]);
          } else {
            setCustomError(`${values.animalID} ペットIDのペットはLOOKFORに公開されていません`);
            setIsDisable(false);
          }
        }
      });
    } catch (err) {
      setIsDisable(false);
      return undefined;
    }
  };

  const checkIfAddedInRecommended = (code, data) => {
    getSingleDocWithQuery("recommended", [["animalID", "==", code]]).then(
      (res) => {
        if (res) {
          setCustomError(
            `${code}マイクロチップの番号はすでに登録されています。`
          );
          setIsDisable(false);
          return;
        }
        localStorage.setItem("recommended_mc_code", code);
        localStorage.setItem("recommended_mc_data", JSON.stringify(data));
        navigate("/mypage/recommended-mc/pet-details");
      }
    );
  };

  const MyField = () => {
    const { setFieldValue, handleChange, handleBlur, values, touched, errors } =
      useFormikContext();

    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>
            ペットID<span className="text-danger">※</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="半角8桁英数字のみご入力ください"
            onChange={(e) => {
              handleChange(e);
              setCustomError(null);
            }}
            onBlur={handleBlur}
            value={values.animalID}
            name="animalID"
            autoComplete="off"
            required
            isInvalid={(touched.animalID && errors.animalID) || customError}
            isValid={touched.animalID && !errors.animalID}
          />
          <Form.Control.Feedback type="invalid">
            {errors.animalID || customError}
          </Form.Control.Feedback>

          <div className="text-center mt-4">
            <Button
              disabled={isPending}
              className="mb-3"
              variant="primary"
              type="submit"
            >
              <b>
                次へ <FontAwesomeIcon icon={faCircleChevronRight} />
              </b>
            </Button>
          </div>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "ペットID", link: "#" },
              { value: "ペットの詳細", link: "#" },
              { value: "設定", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={1}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <MyField />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RecommendedMc;
