import { useState } from "react";

import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where
} from "firebase/firestore";

import { db, firebaseErrors } from "../firebase/config";

export const useSetDoc = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const firebaseSetDoc = async (c, docs, id, merge = false) => {
    setIsPending(true);
    setError(null);
    setIsConfirmed(false);
    let document = null;
    if (!merge) {
      document = { ...docs, createdAt: serverTimestamp() };
    } else {
      document = { ...docs, updateAt: serverTimestamp() };
    }
    let ref = doc(db, c, id);
    // console.log(document, ref)
    await setDoc(ref, { ...document }, { merge: merge }).then(
      () => {
        setIsPending(false);
        setIsConfirmed(true);
      },
      (err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(firebaseErrors[err.code] || err.message);
      }
    );
  };

  const firebaseSetDocQuery = async (c, docs, q, merge = false) => {
    setIsPending(true);
    setError(null);
    setIsConfirmed(false);

    let ref = collection(db, c);
    if (q) {
      q.forEach((qitem) => {
        ref = query(ref, where(...qitem));
      });
    }

    const docSnap = await getDocs(ref);
    let results = [];
    docSnap.forEach(async (doc) => {
      results.push({ ...doc.data(), id: doc.id });
      await firebaseSetDoc(c, docs, doc.id, merge);
    });

    return results;
  };

  return { firebaseSetDocQuery, firebaseSetDoc, isPending, isConfirmed, error };
};
