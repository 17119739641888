import Toasts from './Toast';
import { useToastStateContext } from '../context/ToastContext';
import { ToastContainer } from "react-bootstrap";
import styles from "./ToastDiv.module.css";
import useWindowDimensions from '../hooks/useWindowDimensions';

export default function ToastDiv() {
  const { toasts } = useToastStateContext();
  const { width } = useWindowDimensions();

  return (
    <ToastContainer className={`${styles.z1080} p-3 mt-5`} position={width < 576 ? "bottom-center" : "top-end"} >
        {toasts &&
          toasts.map((toast) => (
            <Toasts
              id={toast.id}
              key={toast.id}
              type={toast.type}
              message={toast.message}
            />
          ))}
      </ToastContainer>
  );
}
