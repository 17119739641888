import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import { Formik, useFormikContext } from "formik";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCircleChevronRight,
  faCircleXmark,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import styles from "../mcregistration/MCStyle.module.css";
import masImage from "../mcregistration/img/masking.png";
import beepSound from "../mcregistration/sound/Barcode-scanner-beep-sound.mp3";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useSetDoc } from "../../hooks/useSetDoc";
import Scanner from "../../components/Scanner";
import { useGetDoc } from "../../hooks/useGetDoc";
import moment from "moment";

function Transfer() {
  const [sound, setSound] = useState(true);
  let audio = new Audio(beepSound);
  const beep = () => {
    if (sound) {
      audio.play();
    }
  };

  useEffect(() => {
    localStorage.removeItem("mcData");
    localStorage.removeItem("mccode");
    localStorage.removeItem("userInfo");
  }, []);

  const [showInfo, setShowInfo] = useState(true);
  const [scan, setScan] = useState(false);
  const [customError, setCustomError] = useState(null);
  const { user } = useAuthContext();
  const { firebaseSetDoc } = useSetDoc();
  const { documents: dbmcode } = useCollection("temp_mc", [
    "uid",
    "==",
    user.uid,
  ]);
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  const sundMCCode = (code) => {
    firebaseSetDoc("temp_mc", { uid: user.uid, mccode: code, type: "T" }, user.uid);
    // console.log(code)
  };

  const navigate = useNavigate();
  let initialValues = {
    mccode: "",
  };

  const schema = yup.object().shape({
    mccode: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[0-9]{15}$/, "半角15桁番号でご入力ください")
      .length(15, "半角15桁番号でご入力ください"),
  });

  //if detect then send barcode to temp_mc table to use real time database
  const _onDetected = (result) => {
    if (result) {
      sundMCCode(result.codeResult.code);
      beep()
      setScan(false);
    }
  };
  const handleSubmit = (values) => {
    // console.log(values);
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("mccode", serializedState);
      getSingleDocWithQuery(`users/${user.uid}/microchip`, [
        ["mccode", "==", values.mccode.toString()],
        ["relation", "==", "OWNER"],
      ]);
    } catch (err) {
      return undefined;
    }
  };

  useEffect(() => {
    setCustomError(null)
    if (data) {
      if(data[0].hasOwnProperty("death")){
        // console.log(data[0].deathDetails.date);
        setCustomError(`Pet with this MC has been died on ${moment(data[0].deathDetails.date.toDate()).format("YYYY/MM/DD")}` )
        return;
      }else if(!data[0].hasOwnProperty("PIN")){
        setCustomError(`This MC can not be tranfered because of PIN is not set yet` )
        return;
      }else{
        localStorage.setItem("mcData", JSON.stringify(data[0]));
        navigate("/mypage/transferinput");
      }
    }
  }, [data]);

  const MyField = (props) => {
    const { setFieldValue, handleChange, handleBlur, values, touched, errors } =
      useFormikContext();

    useEffect(() => {
      setFieldValue(
        "mccode",
        (dbmcode && dbmcode[0] && dbmcode[0].type === "T" && dbmcode[0].mccode) || values.mccode || ""
      );
    }, [setFieldValue]);

    return (
      <>
        {error && (
          <Alert variant="danger" className="text-center">
            {error === "NO_DOC" && "このマイクロチップ番号は存在しません。"}
          </Alert>
        )}
        <Form.Group className="mb-3">
          <Form.Label>
            MC番号<span className="text-danger">※</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="半角15桁番号でご入力ください"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mccode}
            name="mccode"
            required
            isInvalid={touched.mccode && (errors.mccode || customError)}
            isValid={touched.mccode && !(errors.mccode || customError)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.mccode || customError}
          </Form.Control.Feedback>

          <div className="text-center mt-4">
            {!isPending && (
              <Button className="mb-3" variant="primary" type="submit">
                <b>
                  次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                </b>
              </Button>
            )}

            {isPending && (
              <Button className="mb-3" variant="primary" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                <b>
                  次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                </b>
              </Button>
            )}
          </div>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/transferstart" },
              { value: "メール", link: "#" },
              { value: "OTP", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              //{ value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={1}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <div className="text-center">
            {showInfo && (
              <Row className="justify-content-center mb-4">
                <Col lg={6} md={8}>
                  <Alert
                    className="mb-0 d-none d-lg-block"
                    variant="info"
                    onClose={() => setShowInfo(false)}
                    dismissible
                  >
                    スマートフォンからバーコードを読み込む場合はスマートフォンからマイページにログインしてください。
                  </Alert>
                </Col>
              </Row>
            )}
            <Button
              className="mb-3"
              variant="secondary"
              onClick={() => setScan(!scan)}
            >
              <b>
                {!scan ? "オープンカメラ " : "ストップカメラ "}
                {!scan ? (
                  <FontAwesomeIcon icon={faCamera} />
                ) : (
                  <FontAwesomeIcon icon={faCircleXmark} />
                )}
              </b>
            </Button>
          </div>
          {scan && (
            <>
              <Row className="justify-content-center">
                <div id={styles.soundContainer} className="text-end">
                  {sound ? (
                    <Button variant="light" onClick={() => setSound(false)}>
                      <FontAwesomeIcon icon={faVolumeHigh} />
                    </Button>
                  ) : (
                    <Button variant="light" onClick={() => setSound(true)}>
                      <FontAwesomeIcon icon={faVolumeXmark} />
                    </Button>
                  )}
                </div>
              </Row>
              <div id={styles.videoContainer}>
                <img
                  src={masImage}
                  alt="camera masking"
                  id={styles.cameraMasking}
                />
                <Scanner onDetected={(result) => _onDetected(result)} />
              </div>
            </>
          )}
        </Row>

        <p className="text-center">または</p>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <MyField />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Transfer;
