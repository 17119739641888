const dog_breed = [
  { value: "1", label: "アイリッシュ・ウルフハウンド" },
  { value: "2", label: "アイリッシュ・セッター" },
  { value: "3", label: "秋田犬" },
  { value: "4", label: "アフガン・ハウンド" },
  { value: "5", label: "アメリカン・コッカー・スパニエル" },
  { value: "6", label: "アラスカン・マラミュート" },
  { value: "7", label: "イタリアン・グレーハウンド" },
  { value: "8", label: "イングリッシュ・コッカー・スパニエル" },
  { value: "9", label: "イングリッシュ・スプリンガー・スパニエル" },
  { value: "10", label: "イングリッシュ・セター" },
  { value: "11", label: "イングリッシュ・ポインター" },
  { value: "12", label: "ウィペット" },
  { value: "13", label: "ウエスト・ハイランド・ホワイト・テリア" },
  { value: "14", label: "ウェルシュ・コーギー・カーディガン" },
  { value: "15", label: "ウェルシュ・コーギー・ペンブローク" },
  { value: "16", label: "ウェルシュ・テリア" },
  { value: "17", label: "エアデール・テリア" },
  { value: "18", label: "オーストラリアン・キャトル・ドッグ" },
  { value: "19", label: "オーストラリアン・シェパード" },
  { value: "20", label: "オールド・イングリシュ・シープドッグ" },
  { value: "21", label: "甲斐犬" },
  { value: "22", label: "カニンヘン・ダックスフンド" },
  { value: "23", label: "キースホンド" },
  { value: "24", label: "紀州犬" },
  { value: "25", label: "キャバリア・キング・チャールズ・スパニエル" },
  { value: "26", label: "グレート・デーン" },
  { value: "27", label: "グレート・ピレニーズ" },
  { value: "28", label: "ケアーン・テリア" },
  { value: "29", label: "ケリーブルー・テリア" },
  { value: "30", label: "コーイケルホンディエ" },
  { value: "31", label: "ゴールデン・レトリーバー" },
  { value: "32", label: "サモエド" },
  { value: "33", label: "サルーキ" },
  { value: "34", label: "シー・ズー" },
  { value: "35", label: "シェットランド・シープドッグ" },
  { value: "36", label: "四国犬" },
  { value: "37", label: "柴犬" },
  { value: "38", label: "シベリアン・ハスキー" },
  { value: "39", label: "シャー・ペイ" },
  { value: "40", label: "ジャーマン・シェパード・ドッグ" },
  { value: "41", label: "ジャーマン・ピンシャー" },
  { value: "42", label: "ジャイアント・シュナウザー" },
  { value: "43", label: "ジャック・ラッセル・テリア" },
  { value: "44", label: "シルキー・テリア" },
  { value: "45", label: "スキッパーキ" },
  { value: "46", label: "スコティッシュ・テリア" },
  { value: "47", label: "スピッツ" },
  { value: "48", label: "スモール・ミュンスターレンダー" },
  { value: "49", label: "セント・バーナード" },
  { value: "50", label: "ダックスフンド（スタンダード）" },
  { value: "51", label: "ダルメシアン" },
  { value: "52", label: "チベタン・スパニエル" },
  { value: "53", label: "チベタン・テリア" },
  { value: "54", label: "チャイニーズ・クレステッド・ドッグ" },
  { value: "55", label: "チャウ・チャウ" },
  { value: "56", label: "チワワ" },
  { value: "57", label: "トイ・プードル" },
  { value: "58", label: "トイ・マンチェスター・テリア" },
  { value: "59", label: "土佐犬" },
  { value: "60", label: "ドーベルマン" },
  { value: "61", label: "狆（チン）" },
  { value: "62", label: "日本テリア" },
  { value: "63", label: "ニューファンドランド" },
  { value: "64", label: "ノーフォーク・テリア" },
  { value: "65", label: "ノーリッチ・テリア" },
  { value: "66", label: "ノルウェジアン・エルクハウンド" },
  { value: "67", label: "バーニーズ・マウンテン・ドッグ" },
  { value: "68", label: "パグ" },
  { value: "69", label: "バセット・ハウンド" },
  { value: "70", label: "バセンジー" },
  { value: "71", label: "ハバニーズ" },
  { value: "72", label: "パピヨン" },
  { value: "73", label: "ビアデッド・コリー" },
  { value: "74", label: "ビーグル" },
  { value: "75", label: "ビション・フリーゼ" },
  { value: "76", label: "プードル（スタンダード）" },
  { value: "77", label: "ブービエ・デ・フランダース" },
  { value: "78", label: "ワイアー・フォックス・テリア" },
  { value: "79", label: "プチ・ブラバンソン" },
  { value: "80", label: "フラットコーテッド・レトリーバー" },
  { value: "81", label: "ブリュッセル・グリフォン" },
  { value: "82", label: "ブル・テリア" },
  { value: "83", label: "ブル・マスティフ" },
  { value: "84", label: "ブリタニー・スパニエル" },
  { value: "85", label: "ブルドッグ" },
  { value: "86", label: "フレンチ・ブルドッグ" },
  { value: "87", label: "ペキニーズ" },
  { value: "88", label: "ベドリントン・テリア" },
  { value: "89", label: "ベルジアン・グリフォン" },
  { value: "90", label: "ベルジアン・シェパード・ドッグ・グローネンダール" },
  { value: "91", label: "ベルジアン・シェパード・ドッグ・マリノア" },
  { value: "92", label: "ベルジアン・タービュレン" },
  { value: "93", label: "ボーダー・コリー" },
  { value: "94", label: "ボーダー・テリア" },
  { value: "95", label: "ポーチュギーズ・ウォーター・ドッグ" },
  { value: "96", label: "ボクサー" },
  { value: "97", label: "ボストン・テリア" },
  { value: "98", label: "北海道犬" },
  { value: "99", label: "ポメラニアン" },
  { value: "100", label: "ポリッシュ・ローランド・シープドッグ" },
  { value: "101", label: "ボルゾイ" },
  { value: "102", label: "ボルドー・マスティフ" },
  { value: "103", label: "ボロニーズ" },
  { value: "104", label: "ホワイト・スイス・シェパード・ドッグ" },
  { value: "105", label: "マスティフ" },
  { value: "106", label: "マルチーズ" },
  { value: "107", label: "マレンマ・シープドッグ" },
  { value: "108", label: "マンチェスター・テリア" },
  { value: "109", label: "ミニチュア・シュナウザー" },
  { value: "110", label: "ミニチュア・ダックスフンド" },
  { value: "111", label: "ミニチュア・ピンシャー" },
  { value: "112", label: "ミニチュア・プードル" },
  { value: "113", label: "ミニチュア・ブル・テリア" },
  { value: "114", label: "ヨークシャー・テリア" },
  { value: "115", label: "ラサ・アプソ" },
  { value: "116", label: "ラフ・コリー" },
  { value: "117", label: "ラブラドール・レトリーバー" },
  { value: "118", label: "レークランド・テリア" },
  { value: "119", label: "レオンベルガー" },
  { value: "120", label: "ロットワイラー" },
  { value: "121", label: "ワイマラナー" },
  { value: "900", label: "ミックス犬" },
  { value: "999", label: "その他" }
];
const dog_color = [
  { value: "1", label: "白" },
  { value: "2", label: "薄茶" },
  { value: "3", label: "栗色" },
  { value: "4", label: "赤" },
  { value: "5", label: "赤茶" },
  { value: "6", label: "茶" },
  { value: "7", label: "黒" },
  { value: "8", label: "白・薄茶" },
  { value: "9", label: "白・茶" },
  { value: "10", label: "白・黒・灰" },
  { value: "11", label: "白・黒" },
  { value: "12", label: "茶・黒" },
  { value: "13", label: "茶・灰" },
  { value: "14", label: "薄茶・灰" },
  { value: "15", label: "黒・灰" },
  { value: "16", label: "虎毛" },
  { value: "17", label: "胡麻" },
  { value: "18", label: "褐色" },
  { value: "19", label: "アプリコット" },
  { value: "20", label: "イエロー" },
  { value: "21", label: "イザベラ" },
  { value: "22", label: "ウィートン" },
  { value: "23", label: "ウルフ" },
  { value: "24", label: "オレンジ" },
  { value: "25", label: "グリズル" },
  { value: "26", label: "クリーム" },
  { value: "27", label: "グレー" },
  { value: "28", label: "ゴールド" },
  { value: "29", label: "シルバー" },
  { value: "30", label: "シルバーグレー" },
  { value: "31", label: "スチールブルー・タン" },
  { value: "32", label: "セーブル" },
  { value: "33", label: "セーブル・ホワイト" },
  { value: "34", label: "ソルト・ペッパー" },
  { value: "35", label: "ダップル" },
  { value: "36", label: "チョコレート" },
  { value: "37", label: "チョコレート・タン" },
  { value: "38", label: "トライカラー（白・黒・茶）" },
  { value: "39", label: "パーティカラー（白・他の色）" },
  { value: "40", label: "バフ" },
  { value: "41", label: "フォーン" },
  { value: "42", label: "ブラック・タン" },
  { value: "43", label: "ブリンドル" },
  { value: "44", label: "ブルー" },
  { value: "45", label: "ブルー・タン" },
  { value: "46", label: "ブルーマール" },
  { value: "47", label: "ブレンハイム" },
  { value: "48", label: "ベージュ" },
  { value: "49", label: "ホワイト・セーブル" },
  { value: "50", label: "ホワイトグレー" },
  { value: "51", label: "マホガニー" },
  { value: "52", label: "レッドマール" },
  { value: "53", label: "レバー" },
  { value: "54", label: "レモン" },
  { value: "55", label: "ワイルドボア" },
  { value: "98", label: "不明" },
  { value: "99", label: "その他" }
];
const cat_breed = [
  { value: "1", label: "アビシニアン" },
  { value: "2", label: "アメリカンカール" },
  { value: "3", label: "アメリカンショートヘアー" },
  { value: "4", label: "アメリカンボブテイル" },
  { value: "5", label: "アメリカンワイヤーヘアー" },
  { value: "6", label: "エキゾチック" },
  { value: "7", label: "エジプシャンマウ" },
  { value: "8", label: "オシキャット" },
  { value: "9", label: "オリエンタルズ" },
  { value: "10", label: "カラーポイントショートヘアー" },
  { value: "11", label: "コーニッシュレックス" },
  { value: "12", label: "コラット" },
  { value: "13", label: "サイベリアン" },
  { value: "14", label: "ジャパニーズ" },
  { value: "15", label: "ジャパニーズボブテール" },
  { value: "16", label: "シャム（サイアミーズ）" },
  { value: "17", label: "シャルトリュー" },
  { value: "18", label: "シンガプーラ" },
  { value: "19", label: "スキフトーイボブテール（トイボブ）" },
  { value: "20", label: "スコティッシュフォールド" },
  { value: "21", label: "スノーシュー" },
  { value: "22", label: "スフィンクス" },
  { value: "23", label: "セルカークレックス" },
  { value: "24", label: "ソマリ" },
  { value: "25", label: "ターキッシュ・アンゴラ(トルコアンゴラ)" },
  { value: "26", label: "ターキッシュ・ヴァン(トルコネコ)" },
  { value: "27", label: "デボンレックス" },
  { value: "28", label: "トンキニーズ" },
  { value: "29", label: "日本猫" },
  { value: "30", label: "ノルウェージャンフォレストキャット" },
  { value: "31", label: "バーマン" },
  { value: "32", label: "バーミーズ" },
  { value: "33", label: "バーミラ" },
  { value: "34", label: "ハバナブラウン" },
  { value: "35", label: "バリニーズ" },
  { value: "36", label: "ヒマラヤン" },
  { value: "37", label: "ブリティッシュ・ショートヘアー" },
  { value: "38", label: "ペルシャ" },
  { value: "39", label: "ベンガル" },
  { value: "40", label: "ボンベイ" },
  { value: "41", label: "マンクス" },
  { value: "42", label: "マンチカン" },
  { value: "43", label: "ミヌエット（ナポレオン）" },
  { value: "44", label: "メインクーンキャット" },
  { value: "45", label: "ヨーロピアンバーミーズ" },
  { value: "46", label: "ラ・パーマ" },
  { value: "47", label: "ラガ・マフィン" },
  { value: "48", label: "ラグドール" },
  { value: "49", label: "ロシアンブルー" },
  { value: "900", label: "ミックス" },
  { value: "999", label: "その他" }
];
const cat_color = [
  { value: "1", label: "白" },
  { value: "2", label: "クリーム" },
  { value: "3", label: "シルバー" },
  { value: "4", label: "ゴールデン" },
  { value: "5", label: "茶" },
  { value: "6", label: "黒" },
  { value: "7", label: "白・茶" },
  { value: "8", label: "白・黒" },
  { value: "9", label: "灰白" },
  { value: "10", label: "灰茶（ブルークリーム）" },
  { value: "11", label: "黒茶（錆）" },
  { value: "12", label: "茶トラ（レッドタビ―）" },
  { value: "13", label: "キジトラ（ブラウンタビ―）" },
  { value: "14", label: "サバトラ（シルバータビー）" },
  { value: "15", label: "スポテッドタビー（斑）" },
  { value: "16", label: "三毛（キャリコ）" },
  { value: "17", label: "フォーン" },
  { value: "18", label: "ブルー" },
  { value: "19", label: "ポイントカラー（シールポイント）" },
  { value: "20", label: "ポイントカラー（その他）" },
  { value: "21", label: "ルディ" },
  { value: "22", label: "レッド" },
  { value: "98", label: "不明" },
  { value: "99", label: "その他" }
];

const pedigree_issue_org_names_dog = [
  { value: "JKC", label: "JKC" },
  { value: "KC", label: "KC" },
  { value: "日本犬保存会", label: "日本犬保存会" },
  { value: "その他", label: "その他" }
];

const pedigree_issue_org_names_cat = [
  { value: "ACC", label: "ACC" },
  { value: "ICC", label: "ICC" },
  { value: "JCC", label: "JCC" },
  { value: "TICA", label: "TICA" },
  { value: "CFA", label: "CFA" },
  { value: "その他", label: "その他" }
];

export {
  dog_breed,
  dog_color,
  cat_breed,
  cat_color,
  pedigree_issue_org_names_dog,
  pedigree_issue_org_names_cat,
};
