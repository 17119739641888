import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { initializeFirestore } from "firebase/firestore"
// import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};


const firebaseErrors = {
  'auth/user-not-found': 'このメールアドレスで登録されているユーザーはいません。',
  'auth/wrong-password' : "ログイン出来ません",
  'auth/email-already-in-use': 'ご登録内容がすでに登録済み、もしくは使用できない情報です。',
  'auth/email-already-exists': 'ご登録内容がすでに登録済み、もしくは使用できない情報です。',
  'auth/expired-action-code': 'URLが無効かすでにご利用済みにより認証できませんでした。',
  'auth/invalid-action-code': 'URLが無効かすでにご利用済みにより認証できませんでした。',
  'auth/network-request-failed': "ネットワーク接続を確認してください",
  'auth/requires-recent-login': "セキュリティのため、再度ログインしてお試しください。",
  'auth/user-token-expired': "セッションが切れました。再度ログインしてお試しください。",
  'auth/too-many-requests': "temporary locked"
};

// initializeApp(firebaseConfig);


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

//init firebase Auth
const auth = getAuth()
const db = initializeFirestore(app, { useFetchStreams: false });
const storage = getStorage()

export { auth, db, firebaseErrors, storage } 