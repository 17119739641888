const mc_reg_headers = [
    { label: "マイクロチップの識別番号", key: "mccode" },
    { label: "MC装着証明書_マイクロチップの装着日", key: "mcdate" },
    {
      label: "MC装着証明書_マイクロチップを装着した施設名",
      key: "vet_institute",
    },
    {
      label: "MC装着証明書_マイクロチップを装着した施設の所在地_郵便番号",
      key: "vet_location_zip",
    },
    {
      label: "MC装着証明書_マイクロチップを装着した施設の所在地_都道府県",
      key: "vet_location_prefecture",
    },
    {
      label: "MC装着証明書_マイクロチップを装着した施設の所在地_市区町村",
      key: "vet_location_addressLine1",
    },
    {
      label: "MC装着証明書_マイクロチップを装着した施設の所在地_丁目以降",
      key: "vet_location_addressLine2",
    },
    {
      label: "MC装着証明書_マイクロチップを装着した施設の電話番号",
      key: "vet_phone",
    },
    {
      label: "MC装着証明書_マイクロチップを装着した獣医師の氏名",
      key: "vet_name",
    },
    { label: "所有者の情報_所有者種別_一般/動物取扱業区別", key: "ownership" },
    { label: "所有者の情報_個人又は法人の別", key: "owner_type" },
    { label: "所有者の情報_氏名_姓／法人名", key: "lastName" },
    { label: "所有者の情報_氏名_名", key: "firstName" },
    { label: "所有者の情報_氏名_姓／法人名（カナ）", key: "lastKana" },
    { label: "所有者の情報_氏名_名（カナ）", key: "firstKana" },
    {
      label: "所有者の情報_代表者の氏名_姓",
      key: "corporation_representative_lastName",
    },
    {
      label: "所有者の情報_代表者の氏名_名",
      key: "corporation_representative_firstName",
    },
    { label: "所有者の情報_郵便番号", key: "owner_location_zip" },
    { label: "所有者の情報_都道府県", key: "owner_location_prefecture" },
    { label: "所有者の情報_市区町村", key: "owner_location_addressLine1" },
    {
      label: "所有者の情報_丁目以降・建物名",
      key: "owner_location_addressLine2",
    },
    { label: "所有者の情報_電話番号", key: "owner_phone" },
    { label: "所有者の情報_携帯電話番号", key: "owner_phone_2" },
    { label: "所有者の情報_電子メールアドレス", key: "owner_email" },
    { label: "動物の情報_名", key: "animal_name" },
    { label: "動物の情報_犬又は猫の別", key: "animal_group" },
    { label: "動物の情報_品種", key: "animal_breed" },
    { label: "動物の情報_品種_その他", key: "animal_breed_other" },
    { label: "動物の情報_毛色", key: "animal_color" },
    { label: "動物の情報_毛色_その他", key: "animal_color_other" },
    { label: "動物の情報_生年月日", key: "animal_birthDay" },
    { label: "動物の情報_性別", key: "animal_gender" },
    { label: "動物の情報_動物の所在地_郵便番号", key: "animal_location_zip" },
    {
      label: "動物の情報_動物の所在地_都道府県",
      key: "animal_location_prefecture",
    },
    {
      label: "動物の情報_動物の所在地_市区町村",
      key: "animal_location_addressLine1",
    },
    {
      label: "動物の情報_動物の所在地_丁目以降・建物名",
      key: "animal_location_addressLine2",
    },
    { label: "動物の情報_特徴となるべき事項(備考)", key: "animal_note" },
    { label: "動物の情報_鑑札_登録年月日", key: "dog_registration_date" },
    { label: "動物の情報_鑑札_登録番号", key: "dog_registration_number" },
    {
      label: "動物取扱業関連情報_第一種_動物取扱業の情報_販売",
      key: "animal_handler_business_type1_slot1",
    },
    {
      label: "動物取扱業関連情報_第一種_動物取扱業の情報_保管",
      key: "animal_handler_business_type1_slot2",
    },
    {
      label: "動物取扱業関連情報_第一種_動物取扱業の情報_貸出し",
      key: "animal_handler_business_type1_slot3",
    },
    {
      label: "動物取扱業関連情報_第一種_動物取扱業の情報_訓練",
      key: "animal_handler_business_type1_slot4",
    },
    {
      label: "動物取扱業関連情報_第一種_動物取扱業の情報_展示",
      key: "animal_handler_business_type1_slot5",
    },
    {
      label: "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業",
      key: "animal_handler_business_type1_slot6",
    },
    {
      label: "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業",
      key: "animal_handler_business_type1_slot7",
    },
    {
      label: "動物取扱業関連情報_第二種_動物取扱業の情報_譲渡し",
      key: "animal_handler_business_type2_slot1",
    },
    {
      label: "動物取扱業関連情報_第二種_動物取扱業の情報_保管",
      key: "animal_handler_business_type2_slot2",
    },
    {
      label: "動物取扱業関連情報_第二種_動物取扱業の情報_貸出し",
      key: "animal_handler_business_type2_slot3",
    },
    {
      label: "動物取扱業関連情報_第二種_動物取扱業の情報_訓練",
      key: "animal_handler_business_type2_slot4",
    },
    {
      label: "動物取扱業関連情報_第二種_動物取扱業の情報_展示",
      key: "animal_handler_business_type2_slot5",
    },
    {
      label: "動物取扱業関連情報_親の雌犬又は雌猫の所有時期",
      key: "animal_mother_time",
    },
    {
      label:
        "動物取扱業関連情報_親の雌犬又は雌猫に装着されているマイクロチップの識別番号",
      key: "animal_mother_mccode",
    },
    {
      label:
        "動物取扱業関連情報_親の雌犬又は雌猫に装着されているマイクロチップの識別番号_未入力理由",
      key: "animal_mother_mccode_not_reason",
    },
    { label: "管理項目", key: "kanrikomoku" },
  ];



const info_change_headers = [
  { label: "マイクロチップの識別番号", key: "mccode" },
  { label: "暗証記号", key: "secretcode" },
  { label: "所有者の情報_所有者種別_一般/動物取扱業区別", key: "ownership" },
  { label: "所有者の情報_個人又は法人の別", key: "owner_type" },
  { label: "所有者の情報_氏名_姓／法人名", key: "lastName" },
  { label: "所有者の情報_氏名_名", key: "firstName" },
  { label: "所有者の情報_氏名_姓／法人名（カナ）", key: "lastKana" },
  { label: "所有者の情報_氏名_名（カナ）", key: "firstKana" },
  { label: "所有者の情報_代表者の氏名_姓", key: "corporation_representative_lastName" },
  { label: "所有者の情報_代表者の氏名_名", key: "corporation_representative_firstName" },
  { label: "所有者の情報_郵便番号", key: "owner_location_zip" },
  { label: "所有者の情報_都道府県", key: "owner_location_prefecture" },
  { label: "所有者の情報_市区町村", key: "owner_location_addressLine1" },
  { label: "所有者の情報_丁目以降・建物名", key: "owner_location_addressLine2" },
  { label: "所有者の情報_電話番号", key: "owner_phone" },
  { label: "所有者の情報_携帯電話番号", key: "owner_phone_2" },
  { label: "所有者の情報_電子メールアドレス", key: "owner_email" },
  { label: "動物の情報_名", key: "animal_name" },
  { label: "動物の情報_犬又は猫の別", key: "animal_group" },
  { label: "動物の情報_品種", key: "animal_breed" },
  { label: "動物の情報_品種_その他", key: "animal_breed_other" },
  { label: "動物の情報_毛色", key: "animal_color" },
  { label: "動物の情報_毛色_その他", key: "animal_color_other" },
  { label: "動物の情報_生年月日", key: "animal_birthDay" },
  { label: "動物の情報_性別", key: "animal_gender" },
  { label: "動物の情報_動物の所在地_郵便番号", key: "animal_location_zip" },
  { label: "動物の情報_動物の所在地_都道府県", key: "animal_location_prefecture" },
  { label: "動物の情報_動物の所在地_市区町村", key: "animal_location_addressLine1" },
  { label: "動物の情報_動物の所在地_丁目以降・建物名", key: "animal_location_addressLine2" },
  { label: "動物の情報_特徴となるべき事項(備考)", key: "animal_note" },
  { label: "動物の情報_鑑札_登録年月日", key: "dog_registration_date" },
  { label: "動物の情報_鑑札_登録番号", key: "dog_registration_number" },
  { label: "動物取扱業関連情報_第一種_動物取扱業の情報_販売", key: "animal_handler_business_type1_slot1" },
  { label: "動物取扱業関連情報_第一種_動物取扱業の情報_保管", key: "animal_handler_business_type1_slot2" },
  { label: "動物取扱業関連情報_第一種_動物取扱業の情報_貸出し", key: "animal_handler_business_type1_slot3" },
  { label: "動物取扱業関連情報_第一種_動物取扱業の情報_訓練", key: "animal_handler_business_type1_slot4" },
  { label: "動物取扱業関連情報_第一種_動物取扱業の情報_展示", key: "animal_handler_business_type1_slot5" },
  { label: "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業", key: "animal_handler_business_type1_slot6" },
  { label: "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業", key: "animal_handler_business_type1_slot7" },
  { label: "動物取扱業関連情報_第二種_動物取扱業の情報_譲渡し", key: "animal_handler_business_type2_slot1" },
  { label: "動物取扱業関連情報_第二種_動物取扱業の情報_保管", key: "animal_handler_business_type2_slot2" },
  { label: "動物取扱業関連情報_第二種_動物取扱業の情報_貸出し", key: "animal_handler_business_type2_slot3" },
  { label: "動物取扱業関連情報_第二種_動物取扱業の情報_訓練", key: "animal_handler_business_type2_slot4" },
  { label: "動物取扱業関連情報_第二種_動物取扱業の情報_展示", key: "animal_handler_business_type2_slot5" },
  { label: "海外出国", key: "overseas" },
  { label: "管理項目", key: "kanrikomoku" },
];

// const info_change_csvData = [
//     {
//         mccode: "392149703999999",
//         secretcode: "rwfswRc4Tf",
//         ownership: "1",
//         owner_type: "1",
//         lastName: "Sarkers",
//         firstName: "Shuvashishs",
//         lastKana: "サー",
//         firstKana: "シュヴァシ",
//         corporation_representative_lastName: "",
//         corporation_representative_firstName: "",
//         owner_location_zip: "121-0062",
//         owner_location_prefecture: "東京都",
//         owner_location_addressLine1: "足立区南花畑",
//         owner_location_addressLine2: "2-2-10 #701",
//         owner_phone: "03-4860-6300",
//         owner_phone_2: "",
//         owner_email: "shuvashish.sarker@gmail.com",
//         animal_name: "すす",
//         animal_group: "1",
//         animal_breed: "1",
//         animal_breed_other: "",
//         animal_color: "5",
//         animal_color_other: "",
//         animal_birthDay: "2020/12/21",
//         animal_gender: "2",
//         animal_location_zip: "121-0062",
//         animal_location_prefecture: "東京都",
//         animal_location_addressLine1: "足立区南花畑",
//         animal_location_addressLine2: "2-2-10 ",
//         animal_note: "some text",
//         dog_registration_date: "",
//         dog_registration_number: "",
//         animal_handler_business_type1_slot1: "",
//         animal_handler_business_type1_slot2: "",
//         animal_handler_business_type1_slot3: "",
//         animal_handler_business_type1_slot4: "",
//         animal_handler_business_type1_slot5: "",
//         animal_handler_business_type1_slot6: "",
//         animal_handler_business_type1_slot7: "",
//         animal_handler_business_type2_slot1: "0",
//         animal_handler_business_type2_slot2: "0",
//         animal_handler_business_type2_slot3: "0",
//         animal_handler_business_type2_slot4: "0",
//         animal_handler_business_type2_slot5: "0",
//         overseas: "1",
//         kanrikomoku: "",
//     }
// ];



const owner_change_headers = [
    { label: "マイクロチップの識別番号", key: "mccode" },
    { label: "暗証記号", key: "secretcode" },
    { label: "所有者の情報_所有者種別_一般/動物取扱業区別", key: "ownership" },
    { label: "所有者の情報_個人又は法人の別", key: "owner_type" },
    { label: "所有者の情報_氏名_姓／法人名", key: "lastName" },
    { label: "所有者の情報_氏名_名", key: "firstName" },
    { label: "所有者の情報_氏名_姓／法人名（カナ）", key: "lastKana" },
    { label: "所有者の情報_氏名_名（カナ）", key: "firstKana" },
    { label: "所有者の情報_代表者の氏名_姓", key: "corporation_representative_lastName" },
    { label: "所有者の情報_代表者の氏名_名", key: "corporation_representative_firstName" },
    { label: "所有者の情報_郵便番号", key: "owner_location_zip" },
    { label: "所有者の情報_都道府県", key: "owner_location_prefecture" },
    { label: "所有者の情報_市区町村", key: "owner_location_addressLine1" },
    { label: "所有者の情報_丁目以降・建物名", key: "owner_location_addressLine2" },
    { label: "所有者の情報_電話番号", key: "owner_phone" },
    { label: "所有者の情報_携帯電話番号", key: "owner_phone_2" },
    { label: "所有者の情報_電子メールアドレス", key: "owner_email" },
    { label: "動物の情報_名", key: "animal_name" },
    { label: "動物の情報_犬又は猫の別", key: "animal_group" },
    { label: "動物の情報_品種", key: "animal_breed" },
    { label: "動物の情報_品種_その他", key: "animal_breed_other" },
    { label: "動物の情報_毛色", key: "animal_color" },
    { label: "動物の情報_毛色_その他", key: "animal_color_other" },
    { label: "動物の情報_生年月日", key: "animal_birthDay" },
    { label: "動物の情報_性別", key: "animal_gender" },
    { label: "動物の情報_動物の所在地_郵便番号", key: "animal_location_zip" },
    { label: "動物の情報_動物の所在地_都道府県", key: "animal_location_prefecture" },
    { label: "動物の情報_動物の所在地_市区町村", key: "animal_location_addressLine1" },
    { label: "動物の情報_動物の所在地_丁目以降・建物名", key: "animal_location_addressLine2" },
    { label: "動物の情報_特徴となるべき事項(備考)", key: "animal_note" },
    { label: "動物の情報_鑑札_登録年月日", key: "dog_registration_date" },
    { label: "動物の情報_鑑札_登録番号", key: "dog_registration_number" },
    { label: "動物取扱業関連情報_第一種_動物取扱業の情報_販売", key: "animal_handler_business_type1_slot1" },
    { label: "動物取扱業関連情報_第一種_動物取扱業の情報_保管", key: "animal_handler_business_type1_slot2" },
    { label: "動物取扱業関連情報_第一種_動物取扱業の情報_貸出し", key: "animal_handler_business_type1_slot3" },
    { label: "動物取扱業関連情報_第一種_動物取扱業の情報_訓練", key: "animal_handler_business_type1_slot4" },
    { label: "動物取扱業関連情報_第一種_動物取扱業の情報_展示", key: "animal_handler_business_type1_slot5" },
    { label: "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業", key: "animal_handler_business_type1_slot6" },
    { label: "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業", key: "animal_handler_business_type1_slot7" },
    { label: "動物取扱業関連情報_第二種_動物取扱業の情報_譲渡し", key: "animal_handler_business_type2_slot1" },
    { label: "動物取扱業関連情報_第二種_動物取扱業の情報_保管", key: "animal_handler_business_type2_slot2" },
    { label: "動物取扱業関連情報_第二種_動物取扱業の情報_貸出し", key: "animal_handler_business_type2_slot3" },
    { label: "動物取扱業関連情報_第二種_動物取扱業の情報_訓練", key: "animal_handler_business_type2_slot4" },
    { label: "動物取扱業関連情報_第二種_動物取扱業の情報_展示", key: "animal_handler_business_type2_slot5" },
    { label: "管理項目", key: "kanrikomoku" },
  ];
  
  // const owner_change_csvData = [
  //     {
  //         mccode: "392149703999999",
  //         secretcode: "rwfswRc4Tf",
  //         ownership: "2",
  //         owner_type: "2",
  //         lastName: "株式会社シュガーテイスト",
  //         firstName: "",
  //         lastKana: "カブシキカイサシュガーテイスト",
  //         firstKana: "",
  //         corporation_representative_lastName: "佐藤",
  //         corporation_representative_firstName: "奈緒子",
  //         owner_location_zip: "121-0073",
  //         owner_location_prefecture: "東京都",
  //         owner_location_addressLine1: "足立区保塚町",
  //         owner_location_addressLine2: "2-6-10 #101",
  //         owner_phone: "",
  //         owner_phone_2: "090-4860-6317",
  //         owner_email: "shuvashish108@gmail.com",
  //         animal_name: "すす",
  //         animal_group: "1",
  //         animal_breed: "1",
  //         animal_breed_other: "",
  //         animal_color: "5",
  //         animal_color_other: "",
  //         animal_birthDay: "2020/12/21",
  //         animal_gender: "2",
  //         animal_location_zip: "121-0062",
  //         animal_location_prefecture: "東京都",
  //         animal_location_addressLine1: "足立区南花畑",
  //         animal_location_addressLine2: "2-2-10 ",
  //         animal_note: "some text",
  //         dog_registration_date: "",
  //         dog_registration_number: "",
  //         animal_handler_business_type1_slot1: "12345",
  //         animal_handler_business_type1_slot2: "",
  //         animal_handler_business_type1_slot3: "",
  //         animal_handler_business_type1_slot4: "",
  //         animal_handler_business_type1_slot5: "",
  //         animal_handler_business_type1_slot6: "",
  //         animal_handler_business_type1_slot7: "",
  //         animal_handler_business_type2_slot1: "0",
  //         animal_handler_business_type2_slot2: "0",
  //         animal_handler_business_type2_slot3: "0",
  //         animal_handler_business_type2_slot4: "0",
  //         animal_handler_business_type2_slot5: "0",
  //         kanrikomoku: "",
  //     }
  // ];





  export { mc_reg_headers, info_change_headers, owner_change_headers };


//   const info_change_headers = [
//     { label: "マイクロチップの識別番号", key: "mccode" },
//     { label: "暗証記号", key: "secretcode" },
//     { label: "所有者の情報_所有者種別_一般/動物取扱業区別", key: "ownership" },
//     { label: "所有者の情報_個人又は法人の別", key: "owner_type" },
//     { label: "所有者の情報_氏名_姓／法人名", key: "lastName" },
//     { label: "所有者の情報_氏名_名", key: "firstName" },
//     { label: "所有者の情報_氏名_姓／法人名（カナ）", key: "lastKana" },
//     { label: "所有者の情報_氏名_名（カナ）", key: "firstKana" },
//     { label: "所有者の情報_代表者の氏名_姓", key: "corporation_representative_lastName" },
//     { label: "所有者の情報_代表者の氏名_名", key: "corporation_representative_firstName" },
//     { label: "所有者の情報_郵便番号", key: "owner_location_zip" },
//     { label: "所有者の情報_都道府県", key: "owner_location_prefecture" },
//     { label: "所有者の情報_市区町村", key: "owner_location_addressLine1" },
//     { label: "所有者の情報_丁目以降・建物名", key: "owner_location_addressLine2" },
//     { label: "所有者の情報_電話番号", key: "owner_phone" },
//     { label: "所有者の情報_携帯電話番号", key: "owner_phone_2" },
//     { label: "所有者の情報_電子メールアドレス", key: "owner_email" },
//     { label: "動物の情報_名", key: "animal_name" },
//     { label: "動物の情報_犬又は猫の別", key: "animal_group" },
//     { label: "動物の情報_品種", key: "animal_breed" },
//     { label: "動物の情報_品種_その他", key: "animal_breed_other" },
//     { label: "動物の情報_毛色", key: "animal_color" },
//     { label: "動物の情報_毛色_その他", key: "animal_color_other" },
//     { label: "動物の情報_生年月日", key: "animal_birthDay" },
//     { label: "動物の情報_性別", key: "animal_gender" },
//     { label: "動物の情報_動物の所在地_郵便番号", key: "animal_location_zip" },
//     { label: "動物の情報_動物の所在地_都道府県", key: "animal_location_prefecture" },
//     { label: "動物の情報_動物の所在地_市区町村", key: "animal_location_addressLine1" },
//     { label: "動物の情報_動物の所在地_丁目以降・建物名", key: "animal_location_addressLine2" },
//     { label: "動物の情報_特徴となるべき事項(備考)", key: "animal_note" },
//     { label: "動物の情報_鑑札_登録年月日", key: "dog_registration_date" },
//     { label: "動物の情報_鑑札_登録番号", key: "dog_registration_number" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_販売", key: "animal_handler_business_type1_slot1" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_保管", key: "animal_handler_business_type1_slot2" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_貸出し", key: "animal_handler_business_type1_slot3" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_訓練", key: "animal_handler_business_type1_slot4" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_展示", key: "animal_handler_business_type1_slot5" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業", key: "animal_handler_business_type1_slot6" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業", key: "animal_handler_business_type1_slot7" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_譲渡し", key: "animal_handler_business_type2_slot1" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_保管", key: "animal_handler_business_type2_slot2" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_貸出し", key: "animal_handler_business_type2_slot3" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_訓練", key: "animal_handler_business_type2_slot4" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_展示", key: "animal_handler_business_type2_slot5" },
//     { label: "海外出国", key: "overseas" },
//     { label: "管理項目", key: "kanrikomoku" },
//   ];
  


//   const info_change_csvData = [
//       {
//           mccode: "392149703999999",
//           secretcode: "rwfswRc4Tf",
//           ownership: "1",
//           owner_type: "1",
//           lastName: "Sarker",
//           firstName: "Shuvashish",
//           lastKana: "サーカー",
//           firstKana: "シュヴァシシュ",
//           corporation_representative_lastName: "",
//           corporation_representative_firstName: "",
//           owner_location_zip: "111-0036",
//           owner_location_prefecture: "東京都",
//           owner_location_addressLine1: "足立区松が谷",
//           owner_location_addressLine2: "2-2-10 #701",
//           owner_phone: "03-4860-6300",
//           owner_phone_2: "",
//           owner_email: "shuvashish.sarker@gmail.com",
//           animal_name: "すすす",
//           animal_group: "1",
//           animal_breed: "2",
//           animal_breed_other: "",
//           animal_color: "5",
//           animal_color_other: "",
//           animal_birthDay: "2020/12/22",
//           animal_gender: "2",
//           animal_location_zip: "111-0041",
//           animal_location_prefecture: "東京都",
//           animal_location_addressLine1: "台東区元浅草",
//           animal_location_addressLine2: "3-9-13 #221",
//           animal_note: "some text",
//           dog_registration_date: "2021/02/02",
//           dog_registration_number: "第ア００11s#",
//           animal_handler_business_type1_slot1: "",
//           animal_handler_business_type1_slot2: "",
//           animal_handler_business_type1_slot3: "",
//           animal_handler_business_type1_slot4: "",
//           animal_handler_business_type1_slot5: "",
//           animal_handler_business_type1_slot6: "",
//           animal_handler_business_type1_slot7: "",
//           animal_handler_business_type2_slot1: "0",
//           animal_handler_business_type2_slot2: "0",
//           animal_handler_business_type2_slot3: "0",
//           animal_handler_business_type2_slot4: "0",
//           animal_handler_business_type2_slot5: "0",
//           overseas: "1",
//           kanrikomoku: "",
//       }
//   ];

//   const owner_change_headers = [
//     { label: "マイクロチップの識別番号", key: "mccode" },
//     { label: "暗証記号", key: "secretcode" },
//     { label: "所有者の情報_所有者種別_一般/動物取扱業区別", key: "ownership" },
//     { label: "所有者の情報_個人又は法人の別", key: "owner_type" },
//     { label: "所有者の情報_氏名_姓／法人名", key: "lastName" },
//     { label: "所有者の情報_氏名_名", key: "firstName" },
//     { label: "所有者の情報_氏名_姓／法人名（カナ）", key: "lastKana" },
//     { label: "所有者の情報_氏名_名（カナ）", key: "firstKana" },
//     { label: "所有者の情報_代表者の氏名_姓", key: "corporation_representative_lastName" },
//     { label: "所有者の情報_代表者の氏名_名", key: "corporation_representative_firstName" },
//     { label: "所有者の情報_郵便番号", key: "owner_location_zip" },
//     { label: "所有者の情報_都道府県", key: "owner_location_prefecture" },
//     { label: "所有者の情報_市区町村", key: "owner_location_addressLine1" },
//     { label: "所有者の情報_丁目以降・建物名", key: "owner_location_addressLine2" },
//     { label: "所有者の情報_電話番号", key: "owner_phone" },
//     { label: "所有者の情報_携帯電話番号", key: "owner_phone_2" },
//     { label: "所有者の情報_電子メールアドレス", key: "owner_email" },
//     { label: "動物の情報_名", key: "animal_name" },
//     { label: "動物の情報_犬又は猫の別", key: "animal_group" },
//     { label: "動物の情報_品種", key: "animal_breed" },
//     { label: "動物の情報_品種_その他", key: "animal_breed_other" },
//     { label: "動物の情報_毛色", key: "animal_color" },
//     { label: "動物の情報_毛色_その他", key: "animal_color_other" },
//     { label: "動物の情報_生年月日", key: "animal_birthDay" },
//     { label: "動物の情報_性別", key: "animal_gender" },
//     { label: "動物の情報_動物の所在地_郵便番号", key: "animal_location_zip" },
//     { label: "動物の情報_動物の所在地_都道府県", key: "animal_location_prefecture" },
//     { label: "動物の情報_動物の所在地_市区町村", key: "animal_location_addressLine1" },
//     { label: "動物の情報_動物の所在地_丁目以降・建物名", key: "animal_location_addressLine2" },
//     { label: "動物の情報_特徴となるべき事項(備考)", key: "animal_note" },
//     { label: "動物の情報_鑑札_登録年月日", key: "dog_registration_date" },
//     { label: "動物の情報_鑑札_登録番号", key: "dog_registration_number" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_販売", key: "animal_handler_business_type1_slot1" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_保管", key: "animal_handler_business_type1_slot2" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_貸出し", key: "animal_handler_business_type1_slot3" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_訓練", key: "animal_handler_business_type1_slot4" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_展示", key: "animal_handler_business_type1_slot5" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_競りあっせん業", key: "animal_handler_business_type1_slot6" },
//     { label: "動物取扱業関連情報_第一種_動物取扱業の情報_譲受飼養業", key: "animal_handler_business_type1_slot7" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_譲渡し", key: "animal_handler_business_type2_slot1" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_保管", key: "animal_handler_business_type2_slot2" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_貸出し", key: "animal_handler_business_type2_slot3" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_訓練", key: "animal_handler_business_type2_slot4" },
//     { label: "動物取扱業関連情報_第二種_動物取扱業の情報_展示", key: "animal_handler_business_type2_slot5" },
//     { label: "管理項目", key: "kanrikomoku" },
//   ];
  
//   const owner_change_csvData = [
//       {
//           mccode: "392149703999999",
//           secretcode: "rwfswRc4Tf",
//           ownership: "2",
//           owner_type: "2",
//           lastName: "株式会社シュガーテイスト",
//           firstName: "",
//           lastKana: "カブシキカイサシュガーテイスト",
//           firstKana: "",
//           corporation_representative_lastName: "佐藤",
//           corporation_representative_firstName: "奈緒子",
//           owner_location_zip: "121-0073",
//           owner_location_prefecture: "東京都",
//           owner_location_addressLine1: "足立区保塚町",
//           owner_location_addressLine2: "2-6-10 #101",
//           owner_phone: "",
//           owner_phone_2: "090-4860-6317",
//           owner_email: "shuvashish108@gmail.com",
//           animal_name: "すす",
//           animal_group: "1",
//           animal_breed: "1",
//           animal_breed_other: "",
//           animal_color: "5",
//           animal_color_other: "",
//           animal_birthDay: "2020/12/22",
//           animal_gender: "2",
//           animal_location_zip: "121-0062",
//           animal_location_prefecture: "東京都",
//           animal_location_addressLine1: "足立区南花畑",
//           animal_location_addressLine2: "2-2-10 ",
//           animal_note: "some text",
//           dog_registration_date: "",
//           dog_registration_number: "",
//           animal_handler_business_type1_slot1: "12345",
//           animal_handler_business_type1_slot2: "",
//           animal_handler_business_type1_slot3: "",
//           animal_handler_business_type1_slot4: "",
//           animal_handler_business_type1_slot5: "",
//           animal_handler_business_type1_slot6: "",
//           animal_handler_business_type1_slot7: "",
//           animal_handler_business_type2_slot1: "0",
//           animal_handler_business_type2_slot2: "0",
//           animal_handler_business_type2_slot3: "0",
//           animal_handler_business_type2_slot4: "0",
//           animal_handler_business_type2_slot5: "0",
//           kanrikomoku: "",
//       }
//   ];