import React from "react";
import { Link } from "react-router-dom";
import styles from "./Common.module.css";

export default function Owner() {
  return (
    <div className="main">
      <div className={styles.Breeder}>
        <div className="container" style={{overflow: "hidden"}}>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6">
              <div className={`${styles.circle} ${styles["bg-orange"]}`}>
                <img src="assets/img/art/avater-c.png" />
                <div className={styles.text}>
                  <p className={styles.title}>飼い主</p>
                  <p className={styles.subtitle}>For Pet Owner</p>
                </div>
              </div>

              <p className={styles.subtext}>
                保証書等の重要データの
                <br />
                保存・管理もできます！
              </p>

              <div className={styles.orangeList}>
                <ul>
                  <li>保証の詳細(金額・期間)が管理できる！</li>
                  <li>譲渡以外のMC情報の変更は無料です</li>
                  <li>マイページで各種データの保存・管理</li>
                </ul>
              </div>
            </div>

            <div className="col-8 offset-6 offset-sm-0 col-sm-4">
              <img src="assets/img/art/Owner.png" className={styles[`d-img`]} />
            </div>
          </div>

          <div className={`${styles.path} mb-5`}>
            <div className="row">
              <div className="col-12 d-flex align-items-center flex-column">
                <Link to={"/login?group=owner"} className={styles.pathBtn}>
                  ログイン
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
