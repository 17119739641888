import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import MCDetailsTable from "./MCDetailsTable";
import PaymentListItem from "./PaymentListItem";

function MCDetailsModal(props) {
  const currentOwnerName =
    props.data &&
    props.data.paymentsData &&
    props.data.paymentsData[props.data.paymentsData.length - 1].next_owner_name;
  return (
    props.data && (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {!props.data.unregistered ? "MC番号" : "ペットID"}
            <span className="text-nowrap">#{props.data.mccode || props.data.mcData.mccode}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Table striped bordered responsive="sm">
            <tbody>
              {props.data.death && (
                <tr>
                  <td colSpan={2} className="text-center">
                    <span className="p-1 border border-danger rounded text-danger">
                      死亡
                    </span>
                  </td>
                </tr>
              )}
              <tr>
                <th>登録日時</th>
                <td>
                  {moment(
                    new Timestamp(
                      props.data.createdAt.seconds,
                      props.data.createdAt.nanoseconds
                    ).toDate()
                  ).format("YYYY/MM/DD HH:mm")}
                </td>
              </tr>
              {props.data.relation === "TRANSFER" && (
                <>
                  <tr>
                    <th>譲渡日時</th>
                    <td>
                      {moment(
                        new Timestamp(
                          props.data.transferAt.seconds,
                          props.data.transferAt.nanoseconds
                        ).toDate()
                      ).format("YYYY/MM/DD HH:mm")}
                    </td>
                  </tr>
                  {currentOwnerName && (
                    <tr>
                      <th>一般所有の名前</th>
                      <td>{currentOwnerName}</td>
                    </tr>
                  )}
                </>
              )}
              
            </tbody>
          </Table> */}

          <MCDetailsTable
            data={props.data.mcData}
            death={props.data.death}
            deathDetails={props.data.deathDetails}
            transfer={props.data.transfer}
            fullData={props.data}
          />

          {props.data.paymentsData && (
            <>
              <h5>Payments:</h5>
              <Table striped responsive="sm">
                {props.data.paymentsData.map((doc, i) => {
                  return (
                    <PaymentListItem
                      key={i}
                      doc={{
                        ...doc,
                        createdAt: props.data.transferAt,
                      }}
                      modalFun={props.paymentModalHandler}
                      hideOther={props.onHide}
                    />
                  );
                })}
              </Table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>閉じる</Button>
        </Modal.Footer>
      </Modal>
    )
  );
}

export default MCDetailsModal;
