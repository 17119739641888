import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/config";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  justifyContent: "center",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function CertificateImageUpload({ certificateImage, imagePending, downloadLink}) {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  // const [customError, setCustomError] = useState(null);
  const [dl, setDl] = useState(null)

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);


  useEffect(() => {
    if(dl){
      downloadLink(dl)
    }
  }, [dl]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    accept: { "image/*": [".jpeg", ".png", ".jpg"] },
    maxSize: 3300000,
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles);
      let filess = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      certificateImage(acceptedFiles);
      setFiles(filess);
      const mccode = JSON.parse(localStorage.getItem("vetmccode")) || JSON.parse(localStorage.getItem("mccode"));
      // console.log(mccode.mccode);

      await Promise.all(
        acceptedFiles.map((img) => {
          setIsPending(true);
          imagePending(true);
          setError(null);
          setIsConfirmed(false);

          const imgRef = ref(
            storage,
            `installCertificate/${mccode.mccode}.${img.type.split("/")[1]}`
          );

          uploadBytes(imgRef, img, "install_certificate").then(async () => {
            const downloadLink = await getDownloadURL(imgRef).then(
              (res) => {
                if (!isCanceled) {
                  setIsPending(false);
                  imagePending(false);
                  setIsConfirmed(true);
                  // console.log(res);
                  localStorage.setItem("certificateDownloadLink", res);
                  setDl(res)
                }
              },
              (err) => {
                if (!isCanceled) {
                  setIsPending(false);
                  imagePending(false);
                  setIsConfirmed(false);
                  setError(err.message);
                }
              }
            );

            
          });
        })
      );
    },
  });

  const thumbs = files.map((file, i) => (
    <Row className="justify-content-center mb-4" key={i}>
      <Col lg={3} md={4}>
        <img
          src={file.preview}
          alt=""
          className="w-100"
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </Col>
    </Row>
  ));

  useEffect(() => {
    
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    return () => setIsCanceled(true);
  }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <section className="container">
      {fileRejections.length > 0 && (
        <Alert variant="danger" className="">
          {fileRejections.map(({ file, errors }) => (
            <li key={file.path}>
              {file.path} - {file.size} bytes
              <ol>
                {errors.map((e) => (
                  <li key={e.code}>
                    {e.code === "file-invalid-type"
                      ? "ファイル形式は、「png」「jpg」「jpeg」のみとなります。"
                      : e.code === "file-too-large"
                      ? "ファイルが3MB以上である。"
                      : e.message}
                  </li>
                ))}
              </ol>
            </li>
          ))}
        </Alert>
      )}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="m-0">
          ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択します。
        </p>
      </div>
      <aside style={thumbsContainer}>
        {isPending && (
          <div className="text-center">
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}
        {!isPending && thumbs}
      </aside>
    </section>
  );
}

export default CertificateImageUpload;
