import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NewsContent from "../../components/NewsContent";
import { useGetDoc } from "../../hooks/useGetDoc";
import styles from "./NewsDetailsPage.module.css";


export default function NewsDetailsPage() {
  const params = useParams();

  const [uewsDetails, setUewsDetails] = useState(null);
  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } =
    useGetDoc();
  useEffect(() => {
    async function fetchData() {
      await firebaseGetDoc("news", params.uid).then((res) => {
        setUewsDetails(res);
      });
    }
    fetchData();
  }, []);
  return (
    <>
      <div className="main">
        <div className={styles.newsDetails}>
          <div className={styles.container}>
            <div className={styles.title}>
              <p>NEWS</p>
              <span>お知らせ</span>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-11 col-sm-10 col-lg-9">
              <div className={styles["m-card"]}>
              {uewsDetails && <NewsContent data={uewsDetails} />}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
