import React, { useEffect, useState } from "react";

import moment from "moment";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Placeholder,
  Row,
  Spinner
} from "react-bootstrap";

import { Timestamp, deleteField } from "firebase/firestore";

import { usePlan } from "../../../hooks/usePlan";
import CardDiv from "../../../components/CardDiv";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { useToast } from "../../../hooks/useToast";

const ActivePlanDetails = () => {
  const toast = useToast();
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();
  const { planDetails, isPending: isPlanPending, planDetail } = usePlan();
  const {
    firebaseSetDoc,
    firebaseSetDocQuery,
    isPending: isUpdatePending,
    error: updateError
  } = useSetDoc();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (userData && userData.plan) {
      planDetails(userData.plan);
    }
  }, []);

  const planStopHandler = async () => {
    // create data to update
    const setData = {
      planStop: true,
      planStopAt: new Date(),
      planStopBy: "STOP_BY_USER"
    };

    //stop the auto task for plan pay
    await firebaseSetDocQuery(
      "tasks",
      {
        status: "stoped",
        stopBy: "STOP_BY_USER"
      },
      [
        ["status", "==", "scheduled"],
        ["worker", "==", "subscriptionPayment"],
        ["option.userID", "==", user.uid]
      ],
      true
    );
    //update the user collection
    await firebaseSetDoc("users", setData, user.uid, true);

    setShow(false);
  };
  const planResumeHandler = async () => {
    // create data to update
    const setData = {
      planStop: false,
      planStopAt: deleteField(),
      planStopBy: deleteField()
    };

    //stop the auto task for plan pay
    await firebaseSetDocQuery(
      "tasks",
      {
        status: "scheduled",
        stopBy: deleteField()
      },
      [
        ["status", "==", "stoped"],
        ["worker", "==", "subscriptionPayment"],
        ["option.userID", "==", user.uid]
      ],
      true
    );

    //set plan and expiry date
    await firebaseSetDoc("users", setData, user.uid, true);

    toast("success", "RESUME完了しました！");
  };

  return (
    <>
      <Container className="mt-3">
        {userData && userData.planStop && (
          <Row className="justify-content-center">
            <Col xl={10}>
              <Alert variant="danger">
                <Alert.Heading>
                  プランのお支払いがキャンセルされました
                </Alert.Heading>
                <p>
                  プランの有効期限は
                  {userData.plan && isPlanPending && (
                    <Placeholder animation="glow">
                      <Placeholder className="rounded" xs={2} />
                    </Placeholder>
                  )}
                  {userData.plan && !isPlanPending && (
                    <>
                      {moment(
                        new Timestamp(
                          userData.planExpiryDate.seconds,
                          userData.planExpiryDate.nanoseconds
                        ).toDate()
                      )
                        .subtract(1, "day")
                        .format("YYYY年MM月DD日")}
                    </>
                  )}
                  になります
                </p>
                {/* show RESUME btn if expairy date have min 2 days left */}
                {!isUpdatePending &&
                  moment(
                    new Timestamp(
                      userData.planExpiryDate.seconds,
                      userData.planExpiryDate.nanoseconds
                    ).toDate()
                  )
                    .subtract(2, "day")
                    .isAfter() && (
                    <>
                      <hr />

                      <Button
                        size="sm"
                        variant="success"
                        onClick={planResumeHandler}
                      >
                        Resume now!
                      </Button>
                    </>
                  )}
                {isUpdatePending && (
                  <>
                    <hr />

                    <Button size="sm" variant="success" disabled>
                      Resume now!
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  </>
                )}
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="justify-content-center">
          <Col xl={10}>
            <Row className="justify-content-center">
              <Col lg={6} className="mb-4 mb-lg-0 align-items-stretch ">
                <Card className="h-100 shadow-sm">
                  <Card.Body>
                    <span className="d-block mb-3">
                      <span>プラン</span> <br />
                      {userData.plan && isPlanPending && (
                        <Placeholder as="h4" animation="glow">
                          <Placeholder className="rounded" xs={6} />
                        </Placeholder>
                      )}
                      {userData.plan && !isPlanPending && planDetail && (
                        <span className="fs-4 fw-bold">{planDetail.name}</span>
                      )}
                    </span>
                    <span className="d-block mb-0">
                      <span>料金</span> <br />
                      {userData.plan && isPlanPending && (
                        <Placeholder as="h4" animation="glow">
                          <Placeholder className="rounded" xs={5} />
                        </Placeholder>
                      )}
                      {userData.plan && !isPlanPending && planDetail && (
                        <>
                          <span className="fs-5">¥</span>
                          <span className="fs-4">
                            <strong>
                              {parseInt(planDetail.monthly).toLocaleString()}
                            </strong>
                          </span>
                          <span className="fs-5">/月 (税込)</span>
                        </>
                      )}
                    </span>
                    <span>
                      <p className="mb-1">
                        {userData && userData.planStop ? (
                          <span className="text-danger">有効期限:</span>
                        ) : (
                          "次回決済日:"
                        )}
                        {userData.plan && isPlanPending && (
                          <Placeholder animation="glow">
                            <Placeholder className="rounded" xs={4} />
                          </Placeholder>
                        )}
                        {userData.plan && !isPlanPending && (
                          <>
                            {moment(
                              new Timestamp(
                                userData.planExpiryDate.seconds,
                                userData.planExpiryDate.nanoseconds
                              ).toDate()
                            )
                              .subtract(1, "day")
                              .format("YYYY年MM月DD日")}
                          </>
                        )}
                      </p>
                    </span>
                  </Card.Body>
                  {userData.plan && isPlanPending && (
                    <Card.Footer>
                      <Placeholder.Button
                        className="btn-sm"
                        variant={userData.planStop ? `success` : `danger`}
                        xs={2}
                        aria-hidden="true"
                      />
                    </Card.Footer>
                  )}

                  {userData.plan && !isPlanPending && (
                    <>
                      {userData.planStop ? (
                        <>
                          {/* show RESUME btn if expairy date have min 2 days left */}
                          {!isUpdatePending &&
                            moment(
                              new Timestamp(
                                userData.planExpiryDate.seconds,
                                userData.planExpiryDate.nanoseconds
                              ).toDate()
                            )
                              .subtract(2, "day")
                              .isAfter() && (
                              <Card.Footer>
                                <Button
                                  size="sm"
                                  variant="success"
                                  onClick={planResumeHandler}
                                >
                                  Resume now!
                                </Button>
                              </Card.Footer>
                            )}
                          {isUpdatePending && (
                            <Card.Footer>
                              <Button size="sm" variant="success" disabled>
                                Resume now!
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                            </Card.Footer>
                          )}
                        </>
                      ) : (
                        <>
                          {/* show STOP btn if expairy date have min 1 days left */}
                          {moment(
                            new Timestamp(
                              userData.planExpiryDate.seconds,
                              userData.planExpiryDate.nanoseconds
                            ).toDate()
                          )
                            .subtract(1, "day")
                            .isAfter() && (
                            <Card.Footer>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => setShow(true)}
                              >
                                解約する
                              </Button>
                            </Card.Footer>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-100 shadow-sm">
                  <Card.Header>
                    <Row>
                      <Col>
                        <span>お支払方法</span>
                      </Col>
                      <Col className="justify-content-end text-end">
                        <Link
                          className="btn btn-sm btn-warning"
                          to="/mypage/changecardinfo"
                        >
                          編集する
                        </Link>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body className="">
                    <CardDiv komoju_customer_id={userData.komoju_customer_id} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>注意事項</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          株式会社PETNEXT（以下「当社」といいます）は、当社が運営す
          るマイクロチップ登録サイト「MCR」の運営・管理を通じてご提供
          するサービス（以下、「本サービス」といいます）の利用規約（以下
          「本規約」といいます）を以下の通り定めます。本サービスをご利
          用される方は、本規約の全ての条項を承諾していただくことが条
          件となりますので、ご登録される前に本規約を必ずお読みください。
          <br />
          <br />
          株式会社PETNEXT（以下「当社」といいます）は、当社が運営す
          るマイクロチップ登録サイト「MCR」の運営・管理を通じてご提供
          するサービス（以下、「本サービス」といいます）の利用規約（以下
          「本規約」といいます）を以下の通り定めます。本サービスをご利
          用される方は、本規約の全ての条項を承諾していただくことが条
          件となりますので、ご登録される前に本規約を必ずお読みください。
          <br />
          <br />
          株式会社PETNEXT（以下「当社」といいます）は、当社が運営す
          るマイクロチップ登録サイト「MCR」の運営・管理を通じてご提供
          するサービス（以下、「本サービス」といいます）の利用規約（以下
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            閉じる
          </Button>
          {!isUpdatePending && (
            <Button variant="danger" onClick={planStopHandler}>
              解約する
            </Button>
          )}

          {isUpdatePending && (
            <Button variant="danger" disabled>
              解約する
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActivePlanDetails;
