import _ from "lodash";
import jsPDF from "jspdf";
import moment from "moment";
import html2canvas from "html2canvas";

import { getName } from "../Utils";
import sc1 from "./template/sp1.jpg";
import sc2 from "./template/sp2.jpg";
import { pedigree_issue_org_names, veterinarians } from "../CatDogData";

export const downloadPdfDocument = async (
  data,
  userData,
  userInfo,
  values,
  method = "DATAURL"
) => {
  if (data && userData) {
    const input = document.getElementById("reportDiv");
    const pdf = new jsPDF("p", "px", "a4");
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    pdf.addImage(sc1, "JPEG", 30, 20, width - 60, (width - 60) * 0.2034);

    let iframe = document.createElement("div");
    // iframe.style.visibility = "hidden";
    iframe.style.width = "22.5cm";
    iframe.style.height = "28.5cm";
    iframe.innerHTML = userData.sellingContractTemplatePage1;
    input.appendChild(iframe);

    let canvas = await html2canvas(iframe, {});
    let imgData = canvas.toDataURL("image/png");
    input.removeChild(iframe);

    pdf.addImage(
      imgData,
      "JPEG",
      30,
      15 + (width - 60) * 0.2034,
      width - 60,
      height - 40 - (width - 60) * 0.2034
    );
    pdf.addPage("a4", "p");
    pdf.addImage(sc2, "JPEG", 30, 20, width - 60, height - 40);

    pdf.addFont("NotoSansJP-Regular-normal.ttf", "NotoSansJP-Regular");
    pdf.setFont("NotoSansJP-Regular");
    pdf.setFontSize(10);
    pdf.text(values.animal_breed, 170, 72, {
      align: "center",
      maxWidth: 175
    });
    pdf.setFontSize(10);
    pdf.text(values.birthday, 365, 76, null, null, "right");
    // pdf.ellipse(396, 79, 12, 5, "S");
    pdf.setLineWidth(1);

    //gender
    if (values.animal_gender === "メス") {
      pdf.roundedRect(383, 63, 26, 10, 4, 4);
    } else {
      pdf.roundedRect(383, 74, 26, 10, 4, 4);
    }
    //gender

    pdf.setFontSize(8.5);
    pdf.text(values.animal_color, 67, 122.5);

    //breeder name
    pdf.setFontSize(12);
    pdf.text(getName(userData), 160, 98, {
      align: "center",
      maxWidth: 110
    });

    pdf.setFontSize(11);
    pdf.text(userData.animal_handler_business_type1_slot1, 350, 100, {
      align: "center",
      maxWidth: 110
    });
    //breeder name

    //medical_history
    if (values.medical_history === "無") {
      pdf.circle(214, 120.5, 5);
    } else {
      pdf.circle(229, 120.5, 5);
      pdf.setFontSize(10);
      pdf.text(values.medical_history_details, 243, 123);
    }
    //medical_history

    //store_owner
    if (_.includes(values.store_owner, "当店")) {
      pdf.roundedRect(335, 115, 19, 10, 4, 4);
    } else {
      pdf.text(values.other_owner, 362, 123);
    }
    //store_owner

    //kg, cm, nen
    pdf.text(`${values.weight_from}`, 168, 137, null, null, "right");
    pdf.text(`${values.weight_to}`, 204.5, 137, null, null, "right");

    pdf.text(`${values.height_from}`, 263.4, 137, null, null, "right");
    pdf.text(`${values.height_to}`, 303.4, 137, null, null, "right");

    pdf.text(`${values.life_expectancy_from}`, 366.4, 137, null, null, "right");
    pdf.text(`${values.life_expectancy_to}`, 401.5, 137, null, null, "right");
    //kg, cm, nen

    //infertility_castration
    if (values.infertility_castration === "実施済み") {
      pdf.roundedRect(96, 143, 26, 10, 4, 4);
    } else {
      pdf.roundedRect(130, 143, 34, 10, 4, 4);
    }
    //infertility_castration

    pdf.text(values.mccode, 400, 151, null, null, "right");

    pdf.text(`${values.feeding_amount}`, 401.5, 167, null, null, "right");

    pdf.text(values.genetic_disease || "", 135, 164, {
      maxWidth: 170
    });

    if (values.deworming_medicine_date_1) {
      pdf.text(
        moment(values.deworming_medicine_date_1).format("MM月DD日"),
        152 + 7.5,
        185.6,
        null,
        null,
        "right"
      );
      if (values.deworming_medicine_1 === "済") {
        pdf.circle(167, 183, 5);
      } else if (values.deworming_medicine_1 === "未") {
        pdf.circle(182, 183, 5);
      }

      if (values.deworming_medicine_type_1 === "+") {
        pdf.circle(197 + 7.5, 183, 5);
      } else if (values.deworming_medicine_type_1 === "-") {
        pdf.circle(218 + 7.5, 183, 5);
      }
    }
    if (values.deworming_medicine_date_2) {
      pdf.text(
        moment(values.deworming_medicine_date_2).format("MM月DD日"),
        152 + 7.5,
        197.6,
        null,
        null,
        "right"
      );
      if (values.deworming_medicine_2 === "済") {
        pdf.circle(159.5 + 7.5, 195, 5);
      } else if (values.deworming_medicine_2 === "未") {
        pdf.circle(174.5 + 7.5, 195, 5);
      }
      if (values.deworming_medicine_type_2 === "+") {
        pdf.circle(197 + 7.5, 195, 5);
      } else if (values.deworming_medicine_type_2 === "-") {
        pdf.circle(218 + 7.5, 195, 5);
      }
    }

    if (values.vaccine_date_1) {
      pdf.text(
        moment(values.vaccine_date_1).format("MM月DD日"),
        152 + 7.5,
        211,
        null,
        null,
        "right"
      );
      if (values.vaccine_1 === "済") {
        pdf.circle(159.5 + 7.5, 209, 5);
      } else if (values.vaccine_1 === "未") {
        pdf.circle(174.5 + 7.5, 209, 5);
      }
      pdf.text(values.vaccine_type_1, 249 + 7.5, 211, {
        align: "center",
        maxWidth: 170
      });
    }

    if (values.vaccine_date_1) {
      pdf.text(
        moment(values.vaccine_date_2).format("MM月DD日"),
        152 + 7.5,
        222,
        null,
        null,
        "right"
      );
      if (values.vaccine_2 === "済") {
        pdf.circle(159.5 + 7.5, 220, 5);
      } else if (values.vaccine_2 === "未") {
        pdf.circle(174.5 + 7.5, 220, 5);
      }
      pdf.text(values.vaccine_type_2, 249 + 7.5, 222, {
        align: "center",
        maxWidth: 170
      });
    }
    if (values.vaccine_date_1) {
      pdf.text(
        moment(values.vaccine_date_3).format("MM月DD日"),
        152 + 7.5,
        233,
        null,
        null,
        "right"
      );
      if (values.vaccine_3 === "済") {
        pdf.circle(159.5 + 7.5, 231, 5);
      } else if (values.vaccine_3 === "未") {
        pdf.circle(174.5 + 7.5, 231, 5);
      }

      pdf.text(values.vaccine_type_3, 249 + 7.5, 233, {
        align: "center",
        maxWidth: 170
      });
    }

    pdf.text(values.animal_note, 320, 184, {
      maxWidth: 95
    });

    pdf.text(values.send, 153, 291, { align: "center" });
    pdf.text(
      `${parseInt(values.sellingPrice).toLocaleString("en-US")} 円 (税込)`,
      153,
      307,
      { align: "center" }
    );
    if (values.pedigree === "血統書付き") {
      pdf.text(
        values.pedigree_issue_org_name === "その他" ? values.pedigree_issue_org_name_other : values.pedigree_issue_org_name,
        350,
        317,
        {
          align: "center",
          maxWidth: 110
        }
      );
      if (values.pedigree_date) {
        pdf.text(
          moment(values.pedigree_date).format("YYYY年MM月DD日(予定)"),
          350,
          340,
          {
            align: "center",
            maxWidth: 110
          }
        );
      }

      pdf.text(values.veterinarian, 340, 357, {
        align: "center",
        maxWidth: 140
      });
    }

    pdf.setFontSize(13);
    if (values.pedigree === "血統書付き") {
      pdf.text("✓", 275, 288.5);
    } else {
      pdf.text("✓", 275, 299);
    }
    if (values.voluntary_insurance === "加入する") {
      pdf.text("✓", 91, 336);
    } else {
      pdf.text("✓", 137, 336);
    }

    if (values.warranty === "加入する") {
      pdf.text("✓", 91, 350.5);
    } else {
      pdf.text("✓", 137, 350.5);
    }

    if(values.warranty === "加入する" && values.warranty_name) {
      pdf.setFontSize(9);
      pdf.text(values.warranty_name, 154, 365, { align: "center" });
    }

    pdf.setFontSize(9);

    pdf.text(userInfo.email, 265, 441.5);

    pdf.setFontSize(10);

    pdf.text(userInfo.fixed || "", 290, 455);
    pdf.text(userInfo.phone || "", 290, 469);

    if (values.signature) {
      pdf.addImage(values.signature, "PNG", 35.8, 432.5, 187, 187 * 0.22);
    }

    pdf.text(
      `〒${userInfo.zip3}-${userInfo.zip4}${userInfo.prefecture} ${userInfo.addressLine1} ${userInfo.addressLine2} ${userInfo.addressLine3}`,
      75,
      483,
      {
        align: "left",
        maxWidth: 330
      }
    );

    pdf.text(values.animal_name, 73, 519, {
      maxWidth: 50
    });

    pdf.text(values.sales_person_name, 164, 517, {
      align: "center",
      maxWidth: 67
    });

    if (method === "DOWNLOAD") {
      pdf.save(
        `販売契約書_${moment(new Date()).format("YYYYMMDD_HHMMSS")}.pdf`
      );

      return false;
    } else {
      const url = pdf.output("bloburl", {
        filename: `販売契約書_${moment(new Date()).format(
          "YYYYMMDD_HHMMSS"
        )}.pdf`
      });

      return url;
    }
  }
  return false;
};
