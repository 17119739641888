import { auth, firebaseErrors } from "../firebase/config";

//firebase imports
import { updateProfile } from "firebase/auth";
import { useEffect, useState } from "react";

export const useFirebaseUpdateProfile = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const firebaseUpdateProfile = async (profile) => {
    setIsPending(true);
    setError(null);
    await updateProfile(auth.currentUser, { ...profile })
      .then(() => {
        setIsPending(false);
        setIsConfirmed(true);
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(firebaseErrors[err.code] || err.message);
      });
  };

  return { firebaseUpdateProfile, isPending, isConfirmed, error };
};
