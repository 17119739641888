import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function getBrandName(value) {
  if (!value) {
    return value;
  }

  switch (value) {
    case "american_express":
      return "amex";
    case "visa":
      return "visa";
    case "jcb":
      return "jcb";
    case "master":
      return "mastercard";
    case "diners_club":
      return "dinersclub";
    default:
      return "";
  }
}

export function getDottedCardNumber(issuer) {
  switch (issuer) {
    case "american_express":
      return `•••••••••••`;
    case "visa":
      return `••••••••••••`;
    case "jcb":
      return `••••••••••••`;
    case "master":
      return `••••••••••••`;
    case "diners_club":
      return `•••••••••••`;
    default:
      return `••••••••••••`;
  }
}


export function getDottedCardNumberSmall(issuer) {
  switch (issuer) {
    case "american_express":
      return { icon: 'CcAmex', dots: `••••• ••••• •`};
    case "visa":
      return { icon: 'CcVisa', dots: `•••• •••• •••• `};
    case "jcb":
      return { icon: 'CcJcb', dots: `•••• •••• •••• `};
    case "master":
      return { icon: 'CcMastercard', dots: `•••• •••• •••• `};
    case "diners_club":
      return { icon: 'CcDinersClub', dots: `•••• •••••• •`};
    default:
      return { icon: '', dots: `•••• •••• •••• `};
  }
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (clearValue) {
    const issuer = Payment.fns.cardType(clearValue);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return maxLength;
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export function convertToFullYear(year) {
  if(year < 1000){
    return 2000 + parseInt(year)
  }
}
