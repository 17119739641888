import { useEffect, useState } from "react";
import { useToast } from "../useToast";
import { useSetDoc } from "../useSetDoc";
import { useAuthContext } from "../useAuthContext";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";

import { getKomojuError } from "../../utils/KomojuErrors";

export const useUpdateCustomer = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [data, setData] = useState(null);
  const toast = useToast();
  const { firebaseSetDoc } = useSetDoc();
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();

  const updateCardInfo = async (komoju_customer_id, patch_data) => {
    setIsPending(true);
    setError(null);
    let functionName;
    let params;
    if (komoju_customer_id) {
      functionName = `updateCustomer`;
      params = {
        komoju_customer_id: komoju_customer_id,
        patch_data: patch_data
      };
    } else {
      functionName = `createCustomer`;
      params = {
        patch_data: patch_data
      };
    }
    const fireFun = httpsCallable(functions, functionName);
    fireFun({ ...params })
      .then((res) => {
        setIsPending(false);
        setIsConfirmed(true);

        if (res.data.error) {
          setError(
            getKomojuError(res.data.error.code) || res.data.error.message
          );
        } else {
          if (!komoju_customer_id) {
            const extraData = {
              ...userData,
              komoju_customer_id: res.data.id
            };
            firebaseSetDoc("users", extraData, user.uid);
          }
          setData({ ...res.data });
          toast("success", "クレジットカードのご登録が完了しました。");
        }
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(err);
        // console.log(err);
      });
  };

  return { updateCardInfo, isPending, isConfirmed, error, data };
};
