import React, { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import { useTimer } from "react-timer-hook";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import { useToast } from "../../hooks/useToast";
import ProgressStep from "../../components/ProgressStep";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";

import "animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useUpdateDoc } from "../../hooks/useUpdateDoc";

function OTPInput() {
  const { user } = useAuthContext();
  const { firebaseUpdateDoc } = useUpdateDoc();
  const toast = useToast();
  const [OTPValue, setOTPValue] = useState(null);
  const [OTP, setOTP] = useState(null);
  const [error, setError] = useState(false);
  const [expaired, setExpaired] = useState(false);
  const [expiryTimestamp, setExpiryTimestamp] = useState(new Date());
  const { documents: otpcode } = useCollection("temp_otp", [
    "uid",
    "==",
    user.uid,
  ]);

  useEffect(() => {
    if (otpcode && otpcode[0].otp && otpcode[0].createdAt) {
      setOTP(otpcode[0].otp);

      const time = otpcode[0].createdAt.toDate();
      time.setSeconds(time.getSeconds() + 300);
      setError(false);
      setExpaired(false);
      setExpiryTimestamp(time);
      restart(time);
    }

    if (otpcode && otpcode[0].isVarified) {
      onVarify();
      // toast("success", "認証されました！");
    }
  }, [otpcode]);

  useEffect(() => {
    setError(false);
    if (OTPValue && OTPValue.toString().length === 6) {
      if (parseInt(OTPValue) === OTP) {
        onVarify();
        toast("success", "ワンタイムパスワードを認証しました。");
        // console.log("passed");
      } else {
        setError(true);
        // console.log("field", OTP, OTPValue);
      }
    }
    // console.log(OTPValue);
  }, [OTPValue]);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300);

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: () => {
      setExpaired(true);
    },
  });
  const navigate = useNavigate();
  const onVarify = async () => {
    await firebaseUpdateDoc("temp_otp", { isVarified: true }, user.uid);
    navigate("/mypage/transfermcinput");
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/transferstart" },
              { value: "メール", link: "#" },
              { value: "OTP", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              //{ value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={3}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <div className="mx-auto mt-4 " style={{ width: "fit-content" }}>
              <h4 className="text-center">ワンタイムパスワード入力</h4>

              <OtpInput
                value={OTPValue}
                onChange={setOTPValue}
                numInputs={6}
                renderSeparator={<span style={{ margin: "0 5px" }}> </span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      textAlign: "center",
                      margin: "5px",
                      width: "40px",
                      height: "45px",
                      fontSize: "1.1rem",
                      border: "1px solid",
                      borderColor: error || expaired ? "red" : "#ced4da",
                      borderRadius: "0.25rem",
                      color: "#212529",
                      backgroundColor: "#fff",
                      outline: error ? "none" : "initial",
                      boxShadow: error
                        ? "0 0 0 0.25rem rgba(255,0,0,0.25)"
                        : "0 0 0 0.25rem rgba(13,110,253,0.25)",
                    }}
                    disabled={expaired}
                  />
                )}
              />
              <p className="text-end">
                残り時間 <span>{(minutes < 10 ? "0" : "") + minutes}</span>:
                <span>{(seconds < 10 ? "0" : "") + seconds}</span>
              </p>
              {error && (
                <p className="text-danger text-center">
                  ワンタイムパスワードが違います。
                </p>
              )}
              {expaired && (
                <>
                  <p className="text-danger text-center">期限切れです</p>
                  <div className="text-center">
                    <Link
                      className="btn btn-secondary m-2"
                      to="/mypage/transferinput">
                      <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
                    </Link>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OTPInput;
