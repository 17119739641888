import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import PaymentListItem from "../../components/PaymentListItem";
import MCDetailsTableAdmin from "./MCDetailsTableAdmin";


function MCDetailsModalAdmin(props) {

  console.log(props)

  return (
    props.data && (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            ペットID
            <span className="text-nowrap">#{props.data.animalID}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* <pre>
          {JSON.stringify(props, null, 2)}
        </pre> */}

          <MCDetailsTableAdmin
            
            fullData={props.data}
          />

          {props.data.paymentsData && (
            <>
              <h5>Payments:</h5>
              <Table striped responsive="sm">
                {props.data.paymentsData.map((doc, i) => {
                  return (
                    <PaymentListItem
                      key={i}
                      doc={{
                        ...doc,
                        createdAt: props.data.transferAt,
                      }}
                      modalFun={props.paymentModalHandler}
                      hideOther={props.onHide}
                    />
                  );
                })}
              </Table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>閉じる</Button>
        </Modal.Footer>
      </Modal>
    )
  );
}

export default MCDetailsModalAdmin;
