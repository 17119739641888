import { useState } from "react";
//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";

export const useForgotPassword = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const forgotPassword = (email) => {
    setError(null);
    setMessage(null);
    setIsPending(true);

    sendPasswordResetEmail(auth, email, {
      url: "http://localhost:3000/login",
    })
      .then((res) => {
        setMessage("パスワード再設定メールを送信しました")
        setIsPending(false);
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.code);
        setIsPending(false);
      });
  };
  return { error, isPending, message, forgotPassword };
};
