import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function TransferOpt() {
  return (
    <>
      <Container className="justify-content-center text-center">
        <Link
          to="/createUser"
          target="_blank"
          className="btn btn-secondary btn-lg m-4 px-5 py-3"
        >
          <h4>
            新しい
            <br />
            一般所有 
          </h4>
        </Link>
        <Link
          to="/mypage/transferstart"
          className="btn btn-secondary btn-lg m-4 px-5 py-3"
        >
          <h4>
            既存
            <br />
            一般所有 
          </h4>
        </Link>
      </Container>
    </>
  );
}

export default TransferOpt;
