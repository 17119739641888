import React from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useLogout } from "../hooks/useLogout";
export default function Logout() {
  const { isPending, error, logout } = useLogout();
  return (
    <>
     
      <Container className="pt-3">
        <Row className="justify-content-md-center">
          <Col lg={6} md={8}>
            <Card className="text-center">
              <Card.Body>
                {error && (
                  <Alert variant="danger" dismissible className="text-center">
                    {error && <p className="m-0">{error}</p>}
                  </Alert>
                )}

                <h4>ログアウトしますか？</h4>

                {!isPending && (
                  <Button className="my-2" variant="primary" onClick={logout}>
                    ログアウト <FontAwesomeIcon icon={faSignOut} />
                  </Button>
                )}

                {isPending && (
                  <Button className="my-2" variant="primary" disabled>
                    ログアウト <FontAwesomeIcon icon={faSignOut} />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
