import React, { useEffect, useState } from "react";

import StarRatings from "react-star-ratings";
import { Button, Col, Container, Row } from "react-bootstrap";

import styles from "./Reviews.module.css";
import { useAggregation } from "../../hooks/useAggregation";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import ReviewItem from "../../components/review/ReviewItem";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import RatingProgressBar from "../../components/review/RatingProgressBar";
import { useMCList } from "../../hooks/useMCList";

function Reviews() {
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();
  const {
    averageWithQuery,
    countWithQuery,
    isPending: reviewPending
  } = useAggregation();
  // const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const { documents, isPending, lastDoc, isLast, getMCList } = useMCList();
  useEffect(() => {
    aggre();
    setReviews([]);
    loadMore();
  }, []);

  const limit = 10;
  useEffect(() => {
    // console.log(documents)
    if (documents) {
      let newreviews = reviews;
      documents.forEach((doc) => {
        newreviews = { ...newreviews, [doc.id]: doc };
      });
      setReviews(newreviews);
    }
  }, [documents]);

  const [rating, setRating] = useState(0);
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);

  

  const [reviews, setReviews] = useState([]);

  async function aggre() {
    if (userData && userData.group === "breederpet") {
      const ratingAve = await averageWithQuery(
        `users/${user.uid}/reviews/`,
        false,
        "rating"
      );

      const totalReview = await countWithQuery(
        `users/${user.uid}/reviews/`,
        false
      );

      const total1StartReview = await countWithQuery(
        `users/${user.uid}/reviews/`,
        [["rating", "==", 1]]
      );

      const total2StartReview = await countWithQuery(
        `users/${user.uid}/reviews/`,
        [["rating", "==", 2]]
      );

      const total3StartReview = await countWithQuery(
        `users/${user.uid}/reviews/`,
        [["rating", "==", 3]]
      );

      const total4StartReview = await countWithQuery(
        `users/${user.uid}/reviews/`,
        [["rating", "==", 4]]
      );

      const total5StartReview = await countWithQuery(
        `users/${user.uid}/reviews/`,
        [["rating", "==", 5]]
      );
      setRating1(total1StartReview);
      setRating2(total2StartReview);
      setRating3(total3StartReview);
      setRating4(total4StartReview);
      setRating5(total5StartReview);

      setRating(ratingAve);
      setReviewCount(totalReview);
    }
  }

  const loadMore = () => {
    getMCList({
      _collection: `users/${user.uid}/reviews`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "desc",
      _limit: limit,
      _startAfter: lastDoc || false
    });
  };

  return (
    <Container>
      <Row className={styles.RatingInfo}>
        <Col xs={5} sm={3} className="d-flex justify-content-end">
          <div className={styles.details}>
            <p>{rating ? parseFloat(rating).toFixed(1): "0.0"}</p>
            {/* <Rating value={rating} precision={0.5} readOnly /> */}
            <StarRatings
              rating={rating || 0}
              starDimension="20px"
              starSpacing="0px"
              starRatedColor="#FFC415"
            />
            <p className={styles.ratingTotalTXT}>
              口コミ数 &nbsp; <u>{reviewCount}件</u>
            </p>
          </div>
        </Col>
        <Col
          xs={7}
          sm={5}
          className="d-flex flex-column justify-content-start ps-0"
        >
          <div className={styles.ProgressBarHolder}>
            <span>5</span>
            <RatingProgressBar
              total_review={reviewCount}
              gained_review={rating5}
            />
          </div>
          <div className={styles.ProgressBarHolder}>
            <span>4</span>
            <RatingProgressBar
              total_review={reviewCount}
              gained_review={rating4}
            />
          </div>
          <div className={styles.ProgressBarHolder}>
            <span>3</span>
            <RatingProgressBar
              total_review={reviewCount}
              gained_review={rating3}
            />
          </div>
          <div className={styles.ProgressBarHolder}>
            <span>2</span>
            <RatingProgressBar
              total_review={reviewCount}
              gained_review={rating2}
            />
          </div>
          <div className={styles.ProgressBarHolder}>
            <span>1</span>
            <RatingProgressBar
              total_review={reviewCount}
              gained_review={rating1}
            />
          </div>
        </Col>
      </Row>

      <Row>
        {Object.values(reviews).length > 0 && (
          <>
            {Object.values(reviews) &&
              Object.values(reviews).map((doc, i) => {
                return <ReviewItem key={i} review={doc}/>;
              })}
          </>
        )}
      </Row>

      <div className="my-4 text-center">
        {!isLast && !isPending  && limit < reviews.length && (
          <Button variant="secondary" onClick={loadMore}>
            もっと見る
          </Button>
        )}
      </div>
    </Container>
  );
}

export default Reviews;
