import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { getName } from "../../utils/Utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import sc1 from "./template/sp1.jpg";
import sc2 from "./template/sp2.jpg";
import { callAddFont as NotoSansJP_Regular } from "./template/font/NotoSansJP-Regular-normal";
import {
  cat_breed,
  cat_color,
  dog_breed,
  dog_color,
} from "../../utils/CatDogData";
export default function SCpdf() {
  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);
  const mcData = JSON.parse(localStorage.getItem("mcData")) || false;
  const mcDataAnimalTransfer =
    JSON.parse(localStorage.getItem("mcDataAnimalTransfer")) || false;
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || false;

  const maxInsurance = 150000;

  useEffect(() => {
    if (mcData.mcData.animal_group === "犬") {
      setBreed(dog_breed);
      setColor(dog_color);
    } else if (mcData.mcData.animal_group === "猫") {
      setBreed(cat_breed);
      setColor(cat_color);
    }
  }, []);
  // jsPDF.API.events.push(['addFonts', callAddFont])
  jsPDF.API.events.push(["addFonts", NotoSansJP_Regular]);
  const { userData } = useUserDetailsContext();

  const downloadPdfDocument = async (rootElementId) => {
    if (mcData) {
      const input = document.getElementById(rootElementId);
      const values = {
        medical_history: "無",
        medical_history_details: "",
        store_owner: ["当店"],
        other_owner: "",
        weight_from: 1,
        weight_to: 2,
        height_from: 3,
        height_to: 4,
        life_expectancy_from: 5,
        life_expectancy_to: 6,
        infertility_castration: "実施済み",
        genetic_disease: "親および同腹子に係る遺伝性疾患の発生状",
        feeding_amount: 32,
        deworming_medicine_date_1: "2023-07-18T15:00:00.000Z",
        deworming_medicine_1: "済",
        deworming_medicine_type_1: "+",
        deworming_medicine_date_2: "2023-07-20T15:00:00.000Z",
        deworming_medicine_2: "済",
        deworming_medicine_type_2: "-",
        vaccine_date_1: "2023-07-14T15:00:00.000Z",
        vaccine_1: "済",
        vaccine_type_1: "ワクチン",
        vaccine_date_2: "2027-10-14T15:00:00.000Z",
        vaccine_2: "未",
        vaccine_type_2: "ワクチン",
        vaccine_date_3: "2023-04-17T15:00:00.000Z",
        vaccine_3: "済",
        vaccine_type_3: "ワクチン",
        voluntary_insurance: "加入しない",
        warranty: "加入する",
        pedigree: "血統書付き",
        pedigree_issue_org_name: "2",
        pedigree_date: "2023-07-20T15:00:00.000Z",
        veterinarian: "4",

        animal_breed:
          mcData.mcData.animal_breed !== "999"
            ? _.find(breed, ["value", mcData.mcData.animal_breed])?.label
            : mcData.mcData.animal_breed_other,
        animal_color:
          mcData.mcData.animal_color !== "99"
            ? _.find(color, ["value", mcData.mcData.animal_color])?.label
            : mcData.mcData.animal_color_other,
        animal_gender: mcDataAnimalTransfer.animal_gender,
        animal_note: mcData.mcData.animal_note,
        birthday: moment(mcData.mcData.animal_birthDay).format(
          "YYYY年MM月DD日"
        ),
        send: moment().format("YYYY年MM月DD日"),
        mccode: mcData.mccode,
        sellingPrice: mcDataAnimalTransfer.price,
        animal_name: mcDataAnimalTransfer.animal_name
      };

      if (userData) {
        const pdf = new jsPDF("p", "px", "a4");
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        // console.log(width, height);
        pdf.addImage(sc1, "JPEG", 30, 20, width - 60, (width - 60) * 0.2034);

        let iframe = document.createElement("div");
        // iframe.style.visibility = "hidden";
        iframe.style.width = "22.5cm";
        iframe.style.height = "28.5cm";
        iframe.innerHTML = userData.sellingContractTemplatePage1;
        input.appendChild(iframe);

        let canvas = await html2canvas(iframe, {});
        let imgData = canvas.toDataURL("image/png");
        input.removeChild(iframe);

        pdf.addImage(
          imgData,
          "JPEG",
          30,
          15 + (width - 60) * 0.2034,
          width - 60,
          height - 40 - (width - 60) * 0.2034
        );
        pdf.addPage("a4", "p");
        pdf.addImage(sc2, "JPEG", 30, 20, width - 60, height - 40);

        pdf.addFont("NotoSansJP-Regular-normal.ttf", "NotoSansJP-Regular");
        pdf.setFont("NotoSansJP-Regular");
        pdf.setFontSize(10);
        pdf.text(values.animal_breed, 170, 72, {
          align: "center",
          maxWidth: 175
        });
        pdf.setFontSize(10);
        pdf.text(values.birthday, 365, 76, null, null, "right");
        // pdf.ellipse(396, 79, 12, 5, "S");
        pdf.setLineWidth(1);

        //gender
        if (values.animal_gender === "メス") {
          pdf.roundedRect(383, 63, 26, 10, 4, 4);
        } else {
          pdf.roundedRect(383, 74, 26, 10, 4, 4);
        }
        //gender

        pdf.setFontSize(8.5);
        pdf.text(values.animal_color, 67, 122.5);

        //breeder name
        pdf.setFontSize(12);
        pdf.text(getName(userData), 160, 98, {
          align: "center",
          maxWidth: 110
        });

        pdf.setFontSize(11);
        pdf.text(userData.animal_handler_business_type1_slot1, 350, 100, {
          align: "center",
          maxWidth: 110
        });
        //breeder name

        //medical_history
        if (values.medical_history === "無") {
          pdf.circle(214, 120.5, 5);
        } else {
          pdf.circle(229, 120.5, 5);
          pdf.setFontSize(10);
          pdf.text(values.medical_history_details, 243, 123);
        }
        //medical_history

        //store_owner
        if (_.includes(values.store_owner, "当店")) {
          pdf.roundedRect(335, 115, 19, 10, 4, 4);
        } else {
          pdf.text(values.other_owner, 362, 123);
        }
        //store_owner

        //kg, cm, nen
        pdf.text(`${values.weight_from}`, 168, 137, null, null, "right");
        pdf.text(`${values.weight_to}`, 204.5, 137, null, null, "right");

        pdf.text(`${values.height_from}`, 263, 137, null, null, "right");
        pdf.text(`${values.height_to}`, 303, 137, null, null, "right");

        pdf.text(
          `${values.life_expectancy_from}`,
          366,
          137,
          null,
          null,
          "right"
        );
        pdf.text(
          `${values.life_expectancy_to}`,
          401.5,
          137,
          null,
          null,
          "right"
        );
        //kg, cm, nen

        //infertility_castration
        if (values.infertility_castration === "実施済み") {
          pdf.roundedRect(96, 143, 26, 10, 4, 4);
        } else {
          pdf.roundedRect(130, 143, 34, 10, 4, 4);
        }
        //infertility_castration

        // pdf.text("マイクロチップ", 309, 151, null, null, "right");
        pdf.text(values.mccode, 400, 151, null, null, "right");

        pdf.text(`${values.feeding_amount}`, 401.5, 167, null, null, "right");

        pdf.text(
          "親および同腹子に係る遺伝性疾患の発生状況などの事項",
          135,
          164,
          {
            maxWidth: 170
          }
        );

        pdf.text(
          moment(values.deworming_medicine_date_1).format("MM月DD日"),
          152 + 7.5,
          185.6,
          null,
          null,
          "right"
        );
        if (values.deworming_medicine_1 === "済") {
          pdf.circle(167, 183, 5);
        } else {
          pdf.circle(182, 183, 5);
        }

        if (values.deworming_medicine_type_1 === "+") {
          pdf.circle(197 + 7.5, 183, 5);
        } else {
          pdf.circle(218 + 7.5, 183, 5);
        }
        pdf.text(
          moment(values.deworming_medicine_date_2).format("MM月DD日"),
          152 + 7.5,
          197.6,
          null,
          null,
          "right"
        );
        if (values.deworming_medicine_2 === "済") {
          pdf.circle(159.5 + 7.5, 195, 5);
        } else {
          pdf.circle(174.5 + 7.5, 195, 5);
        }
        if (values.deworming_medicine_type_2 === "+") {
          pdf.circle(197 + 7.5, 195, 5);
        } else {
          pdf.circle(218 + 7.5, 195, 5);
        }

        pdf.text(
          moment(values.vaccine_date_1).format("MM月DD日"),
          152 + 7.5,
          211,
          null,
          null,
          "right"
        );
        if (values.vaccine_1 === "済") {
          pdf.circle(159.5 + 7.5, 209, 5);
        } else {
          pdf.circle(174.5 + 7.5, 209, 5);
        }
        pdf.text(values.vaccine_type_1, 249 + 7.5, 211, {
          align: "center",
          maxWidth: 170
        });

        pdf.text(
          moment(values.vaccine_date_2).format("MM月DD日"),
          152 + 7.5,
          222,
          null,
          null,
          "right"
        );
        if (values.vaccine_2 === "済") {
          pdf.circle(159.5 + 7.5, 220, 5);
        } else {
          pdf.circle(174.5 + 7.5, 220, 5);
        }
        pdf.text(values.vaccine_type_2, 249 + 7.5, 222, {
          align: "center",
          maxWidth: 170
        });

        pdf.text(
          moment(values.vaccine_date_3).format("MM月DD日"),
          152 + 7.5,
          233,
          null,
          null,
          "right"
        );
        if (values.vaccine_3 === "済") {
          pdf.circle(159.5 + 7.5, 231, 5);
        } else {
          pdf.circle(174.5 + 7.5, 231, 5);
        }

        pdf.text(values.vaccine_type_3, 249 + 7.5, 233, {
          align: "center",
          maxWidth: 170
        });

        pdf.text(values.animal_note, 320, 184, {
          maxWidth: 95
        });

        pdf.text(values.send, 153, 295, { align: "center" });
        pdf.text(
          `${parseInt(values.sellingPrice).toLocaleString("en-US")} 円 (税込)`,
          153,
          317,
          { align: "center" }
        );

        // const pedigree_issue_org_name = _.find(pedigree_issue_org_names, [
        //   "value",
        //   values.pedigree_issue_org_name
        // ]);
        // pdf.text(pedigree_issue_org_name.label || "", 350, 317, {
        //   align: "center",
        //   maxWidth: 110
        // });

        pdf.text(
          moment(values.pedigree_date).format("YYYY年MM月DD日(予定)"),
          350,
          340,
          {
            align: "center",
            maxWidth: 110
          }
        );

        // const veterinarian = _.find(veterinarians, [
        //   "value",
        //   values.veterinarian
        // ]);
        // pdf.text(veterinarian.label || "", 340, 357, {
        //   align: "center",
        //   maxWidth: 140
        // });

        pdf.setFontSize(13);
        if (values.pedigree === "血統書付き") {
          pdf.text("✓", 275, 288.5);
        } else {
          pdf.text("✓", 275, 299);
        }
        if (values.voluntary_insurance === "加入する") {
          pdf.text("✓", 91, 349.5);
        } else {
          pdf.text("✓", 137, 349.5);
        }

        if (values.warranty === "加入する") {
          pdf.text("✓", 91, 363.5);
        } else {
          pdf.text("✓", 137, 363.5);
        }

        pdf.setFontSize(9);

        pdf.text(userInfo.email, 265, 441.5);

        pdf.setFontSize(10);

        pdf.text(userInfo.fixed || "", 290, 455);
        pdf.text(userInfo.phone || "", 290, 469);

        pdf.text(
          `〒${userInfo.zip3}-${userInfo.zip4}${userInfo.prefecture} ${userInfo.addressLine1} ${userInfo.addressLine2} ${userInfo.addressLine3}`,
          75,
          483,
          {
            align: "left",
            maxWidth: 330
          }
        );

        pdf.text(values.animal_name, 73, 519, {
          maxWidth: 50
        });

        pdf.text("values.animal_name", 164, 517, {
          align: "center",
          maxWidth: 67
        });

        pdf.save(
          `販売契約書_${moment(new Date()).format("YYYYMMDD_HHMMSS")}.pdf`
        );
      }
    }
  };

  return (
    <>
      <Button
        variant="success"
        onClick={(e) => downloadPdfDocument("reportDiv")}
        className="m-4 "
      >
        PDFをダウンロード
      </Button>

      <div
        className="ql-snow "
        style={{ position: "absolute", left: "-9999999px" }}
      >
        <div className="ql-editor ql-container">
          <div id="reportDiv"></div>
        </div>
      </div>
    </>
  );
}
