import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useImageOCR } from "../hooks/useImageOCR";
import getCroppedImg from "../utils/Cropper";
import { getDataFromOCRData } from "../utils/OCRfunctions";
const PDFJS = require("pdfjs-dist/webpack");

export default function OCR() {
  const { imageOCR, isPending, isConfirmed, error } = useImageOCR();
  const [imgs, setImgs] = useState([]);
  const [cropImg, setCropImg] = useState();
  const [text, setText] = useState("");

  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  //param: file -> the input file (e.g. event.target.files[0])
  //return: images -> an array of images encoded in base64
  const convertPdfToImages = async (file) => {
    const images = [];
    const data = await readFileData(file);
    // const data = new Uint8Array(file);

    const pdf = await PDFJS.getDocument(data).promise;
    const canvas = document.createElement("canvas");
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 5 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    // console.log(images);

    setImgs(images);
    return images;
  };
  //OCR part
  useEffect(() => {
    if (cropImg) {
      imageOCR({ pdfImage: cropImg }).then((res) => {
        // setText(res.data.fullTextAnnotation.text);

        // console.log("OCR", res)
        if(res){
          setText(getDataFromOCRData(res.data));
        }
      });
    }
  }, [cropImg]);
  const getCI = async (img) => {
    try {
      const croppedImage = await getCroppedImg(
        img,
        {
          width: 1190,
          height: 1360,
          x: 1516,
          y: 1518,
        },
        0
      );
      // console.log("donee", { croppedImage });
      setCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCI(imgs[0]);
  }, [imgs]);

  // useEffect(() => {
  //   const result = getDataFromOCRData(textData);
  // console.log(result);
  // }, []);

  return (
    <div>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Default file input example</Form.Label>
        <Form.Control
          type="file"
          onChange={(e) => {
            convertPdfToImages(e.target.files[0]);
          }}
        />
      </Form.Group>

      <Container>
        <Row>
          <Col>
            {imgs &&
              imgs.map((img, i) => <img key={i} src={img} className="w-100" />)}
            {cropImg && (
              <>
                <h1>Crop Img</h1>
                <img src={cropImg} className="w-100" />
              </>
            )}
          </Col>
          <Col>
            <pre>{text && JSON.stringify(text, null, 2)}</pre>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
