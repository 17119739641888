import React, { useEffect, useState } from "react";

import moment from "moment";
import StarRatings from "react-star-ratings";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";

import { arrayUnion, Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

import { getName } from "../../utils/Utils";
import styles from "./ReviewItem.module.css";
import { useGetDoc } from "../../hooks/useGetDoc";
import { useSetDoc } from "../../hooks/useSetDoc";
import { useAuthContext } from "../../hooks/useAuthContext";

const ReviewItem = ({ review }) => {
  const { user } = useAuthContext();
  const { firebaseGetDoc, data, isPending, error } = useGetDoc();
  // const { addADoc, isPending: isPend, error: error2 } = useAddDocs();
  const {
    firebaseSetDocQuery,
    firebaseSetDoc,
    isPending: isPend
  } = useSetDoc();

  // const [showReply, setShowReply] = useState(false);
  // const [rating, setRating] = useState(5);
  const [owner, setOwner] = useState();
  const [replay, setReplay] = useState("");

  useEffect(() => {
    if (review.owner_id) {
      firebaseGetDoc("users", review.owner_id).then((res) => {
        setOwner(res);
      });
    }
  }, []);

  async function handleSubmit() {
    const reviewData = {
      replay: arrayUnion({
        comment: replay,
        group: "breeder",
        uid: user.uid,
        replayAt: new Date()
      })
    };

    await firebaseSetDoc(
      `users/${user.uid}/reviews`,
      reviewData,
      review.id,
      true
    )
      .then(async () => {
        await firebaseSetDoc(
          "microchip",
          { review: reviewData },
          review.mccode.toString(),
          true
        );
      })
      .then(async () => {
        await firebaseSetDocQuery(
          `users/${review.owner_id}/microchip`,
          { review: reviewData },
          [["mccode", "==", review.mccode.toString()]],
          true
        );
      });
  }

  return (
    <>
      {review && (
        <Row className={styles.review}>
          <Col sm={2} className={styles.info}>
            <div>
              {owner && (
                <p>
                  {getName(owner, 20)}様<br></br>
                </p>
              )}
            </div>
          </Col>

          <Col sm={10} className={styles.reviewDetails}>
            <div className={styles.rating}>
              <StarRatings
                rating={review.rating}
                starDimension="20px"
                starSpacing="0px"
                starRatedColor="#FFC415"
              />
            </div>

            <div className={styles.reviewTextDetails}>
              <p className={styles.reviewText}>{review.review}</p>
              <p className={styles.reviewDate}>
                {moment(
                  new Timestamp(
                    review.createdAt.seconds,
                    review.createdAt.nanoseconds
                  ).toDate()
                ).format("YYYY/MM/DD HH:mm")}
              </p>
              <>
                {!review.replay ? (
                  <>
                    <Form.Label>返信</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={(e) => {
                        setReplay(e?.target.value);
                      }}
                      value={replay}
                      className="mb-3"
                    />
                    {!isPend && (
                      <>
                        {replay ? (
                          <Button
                            className="me-auto"
                            variant="warning"
                            onClick={handleSubmit}
                          >
                            保存 <FontAwesomeIcon icon={faCircleChevronRight} />
                          </Button>
                        ) : (
                          <Button
                            className="me-auto"
                            variant="warning"
                            disabled
                            type="button"
                          >
                            保存 <FontAwesomeIcon icon={faCircleChevronRight} />
                          </Button>
                        )}
                      </>
                    )}

                    {isPend && (
                      <Button
                        className="me-auto"
                        variant="warning"
                        disabled
                        type="button"
                      >
                        保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    )}
                  </>
                ) : (
                  <ul className={styles.listItem}>
                    <li className="d-flex flex-column">
                      <div className={`w-100 ${styles.ReviewReplyText}`}>
                        <p className={`${styles.reviewText} mb-1`}>返信</p>
                        <p className={styles.reviewText}>
                          {review.replay[0]?.comment}
                        </p>
                      </div>
                      <p className={`${styles.reviewDate} ms-auto mb-0`}>
                        {moment(
                          new Timestamp(
                            review.replay[0]?.replayAt.seconds,
                            review.replay[0]?.replayAt.nanoseconds
                          ).toDate()
                        ).format("YYYY/MM/DD HH:mm")}
                      </p>
                    </li>
                  </ul>
                )}
              </>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ReviewItem;
