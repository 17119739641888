import React from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import styles from "./Law.module.css";

function Law() {
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>特定商取引法について</title>
        <meta property="og:title" content="Law" />
        <meta name="description" content="Law" />
      </Helmet>
      <div className="main">
        <div className={styles.translaw}>
          <div className="container">
            <p className={styles.title}>特定商取引法について</p>

            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-sm-11 col-lg-10">
                  <div className={styles["m-card"]}>
                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>販売業者</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">株式会社PETNEXT</p>
                      </div>
                    </div>
                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>責任者</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">首藤慶一郎</p>
                      </div>
                    </div>
                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>住所</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">
                          〒104-0033 <br />
                          東京都中央区新川2－20－5
                        </p>
                      </div>
                    </div>
                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>電話番号</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">
                          TEL:03-6794-0967
                          <br className="d-none d-sm-block" />
                          受付時間 13:00-17:00（土日祝を除く）
                          <br />
                          <br className="d-block d-sm-none" />
                          ※受付時間外の場合は、メールにてお問い合わせください。
                        </p>
                      </div>
                    </div>

                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>メールアドレス</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">
                          <a href="mailto: petnext01@gmail.com">
                            petnext01@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>

                    {/* <div className={`row ${styles.bb}`}>
                                    <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                                        <label>ホームページ</label>
                                    </div>

                                    <div className="col-8 ps-0 ps-sm-4 align-self-center">
                                        <p className="mb-0">
                                            <a href="https://www.ckc.gr.jp">https://www.ckc.gr.jp</a>
                                        </p>
                                    </div>
                                </div> */}

                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>販売価格</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">
                          マイクロチップ登録料にシステム利用料および
                          <br />
                          マイクロチップ保証料を含めた価格
                        </p>
                      </div>
                    </div>

                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>商品以外の必要料金</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">特にありません</p>
                      </div>
                    </div>

                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>支払方法</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">クレジットカード　決済のみ</p>
                      </div>
                    </div>

                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>支払時期</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">
                          クレジットカード決済：商品注文時にお支払いが確定します。
                        </p>
                      </div>
                    </div>

                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>商品の引渡時期</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">ご注文日から</p>
                      </div>
                    </div>

                    <div className={`row ${styles.bb}`}>
                      <div className="col-4 align-self-start align-self-sm-center text-start ps-lg-5">
                        <label>キャンセル 規定</label>
                      </div>

                      <div className="col-8 ps-0 ps-sm-4 align-self-center">
                        <p className="mb-0">
                          システム利用後、翌日にはマイクロチップ登録が
                          <br className="d-none d-sm-block" />
                          行われますので、利用日の１７：００までにご連絡頂いた
                          <br className="d-none d-sm-block" />
                          場合のみキャンセルが可能です。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src="/assets/img/asset9.png" alt="" className={styles.dog} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Law;
