import moment from "moment";
import React from "react";
import styles from "../pages/admin/Admin.module.css";
import { Badge, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Timestamp } from "firebase/firestore";

function UserListItem({ doc, i }) {
  return (
    <tr key={i}>
      <td>
        {doc.group === "breederpet" && (
          <Badge bg="primary" variant="sm" className="mb-1">
            ブリーダー/ペットショップ
          </Badge>
        )}
        {doc.group === "vet" && (
          <Badge bg="warning" variant="sm" className="mb-1">
            獣医師
          </Badge>
        )}
        {doc.group === "owner" && (
          <Badge bg="success" variant="sm" className="mb-1">
            飼い主
          </Badge>
        )}{" "}
        <br />
        <h5 className="mb-0">
          {doc.type === "2"
            ? `${doc.corporation_name}`
            : `${doc.lastName} ${doc.firstName}`}
        </h5>
        <span>{doc.email}</span>
        <br />
        <Badge bg="secondary" variant="sm">
          {moment(
            new Timestamp(
              doc.createdAt.seconds,
              doc.createdAt.nanoseconds
            ).toDate()
          ).format("YYYY/MM/DD HH:mm")}
        </Badge>
      </td>
      <td className=" align-middle">
        {doc.group === "vet" && (
          <h6 className="text-center">
           {parseInt(doc.totalMC ? doc.totalMC : 0)}数
          </h6>
        )}

        {doc.group !== "vet" && (
          <Table borderless className="m-0">
            <tr>
              <td>登録済み:</td>
              <td className="text-end">
                {parseInt(doc.totalMC ? doc.totalMC : 0)}数
              </td>
            </tr>

            <tr>
              <td>譲渡:</td>
              <td className="text-end">
                {" "}
                -{parseInt(doc.transferMC ? doc.transferMC : 0)}数
              </td>
            </tr>

            <tr className="text-danger">
              <td>死亡:</td>
              <td className="text-end">
                -{parseInt(doc.totalDeath ? doc.totalDeath : 0)}数
              </td>
            </tr>

            <tr className="border-top">
              <td>Total:</td>
              <td className="text-end">
                <h5 className="m-0 p-0 text-nowrap">
                  {parseInt(doc.totalMC ? doc.totalMC : 0) -
                    parseInt(doc.transferMC ? doc.transferMC : 0) -
                    parseInt(doc.totalDeath ? doc.totalDeath : 0)}
                  数
                </h5>
              </td>
            </tr>
          </Table>
        )}
      </td>
      <td className="text-center align-middle">
        <Link
          to={`/mypage/userdetails/${doc.id}`}
          className="btn btn-secondary text-nowrap"
        >
          詳細
        </Link>
      </td>
    </tr>
  );
}

export default UserListItem;
