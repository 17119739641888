import React from "react";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { Badge, Button, ButtonGroup } from "react-bootstrap";

export default function VetMCInstallationListItem({ doc, modalFun }) {
  return (
    <>
      <tr key={doc.id}>
        <td className="align-middle">
          <small>
            <Badge bg="secondary" className="text-white">
              {moment(
                new Timestamp(
                  doc.createdAt.seconds,
                  doc.createdAt.nanoseconds
                ).toDate()
              ).format("YYYY/MM/DD HH:mm")}
            </Badge>
          </small>
          <br />
          {doc.mccode}
        </td>
        <td className="text-center align-middle">
          {doc.breederpet.type === "1" && (
            <>
              <ruby>
                {doc.breederpet.lastName}
                <rt>{doc.breederpet.lastKana}</rt>
              </ruby>{" "}
              <ruby>
                {doc.breederpet.firstName}
                <rt>{doc.breederpet.firstKana}</rt>
              </ruby>
            </>
          )}

          {doc.breederpet.type === "2" && (
            <>
              <ruby>
                {doc.breederpet.corporation_name}
                <rt>{doc.breederpet.corporation_kana_name}</rt>
              </ruby>
            </>
          )}
        </td>

        <td className="text-center">
          <ButtonGroup className="me-2" aria-label="First group">
            <Button variant="secondary" onClick={() => modalFun(doc)}>
              詳細
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    </>
  );
}
