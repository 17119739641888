export function getKomojuError(code) {
  switch (code) {
    case "insufficient_funds":
      return `残高不足`;
    case "exceeds_limit":
      return `ご利用限度額を超過`;
    case "bad_verification_value":
      return `セキュリティ番号が不正`;
    case "card_expired":
      return `期限切れのカード`;
    case "card_declined":
      return `クレジットカード使用不可`;
    case "invalid_password":
      return `無効なカード`;
    default:
      return false;
  }
}

