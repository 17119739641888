import React from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import ProgressStep from "../../../components/ProgressStep";

const RecommendedConfirm = () => {
  return (
    <Container className="mt-3">
      <Row className="justify-content-center mb-4">
        <ProgressStep
          props={[
            { value: "ペットID", link: "/mypage/recommended-mc" },
            {
              value: "ペットの詳細",
              link: "/mypage/recommended-mc"
            },
            {
              value: "設定",
              link: "/mypage/recommended-mc"
            },
            { value: "終了", link: "#" }
          ]}
          activeNo={4}
        />
      </Row>
      <Row className="justify-content-center mb-4">
        <Col lg={6} md={8}>
          <div className="text-center ">
            <Link className="btn btn-secondary m-2" to="/mypage/recommended-mc">
              別のペットIDを追加する
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RecommendedConfirm;
