import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from "chart.js";
  import { Line } from "react-chartjs-2";
export default function BarChart({chartData, color}) {


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 0,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
  };
  const data = {
    labels:  chartData.map(() => ""),
    datasets: [
      {
        label: "",
        data: chartData,
        backgroundColor: `rgba(${color}, 0.1)`,
        borderColor: `rgb(${color})`,
        borderWidth: 3,
        cubicInterpolationMode: 'monotone',
        fill: true,
      },
    ],
  };
  return (
    <Line height={100} options={options} data={data} />
  )
}
