import React from "react";
import styles from "./Page404.module.css";

export default function Page404() {
  return (
    <>
      <div id={styles.notfound}>
        <div className={styles.notfound}>
          <div className={styles.notfound404}>
            <h3>Oops! Page not found</h3>
            <h1>
              <span>4</span>
              <span>0</span>
              <span>4</span>
            </h1>
          </div>
          <h2>
            お探しのページが見つかりませんでした。 <br />
            URLが間違っているか、ページが存在しません。
          </h2>
        </div>
      </div>
    </>
  );
}
