import React from "react";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useAuthContext } from "../../hooks/useAuthContext";
import moment from "moment";

function SignupInputCheck({ data, ownership, edit = false }) {
  const { width } = useWindowDimensions();
  const { user } = useAuthContext();
  
  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          個人または法人 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          {data.type === "1" ? "個人" : "法人"}
        </Col>
      </Row>
      {data.type === "1" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.lastName} {data.firstName}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              氏名（フリガナ） <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.lastKana} {data.firstKana}
            </Col>
          </Row>
        </>
      )}
      {data.type === "2" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              法人名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.corporation_name}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              法人名(フリガナ) <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.corporation_kana_name}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              代表者の氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.corporation_representative_lastName}{" "}
              {data.corporation_representative_firstName}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              ご担当者様の氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.corporation_personincharge_lastName}{" "}
              {data.corporation_personincharge_firstName}
            </Col>
          </Row>
        </>
      )}

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          住所<span className="text-danger">※</span>
          <br />
          (法人にあっては、事務所の所在地)
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <>
            <p className="mb-0">
              〒{data.zip3}-{data.zip4}
            </p>
            <p className="mb-0">
              {data.prefecture} {data.addressLine1}
            </p>
            <p className="mb-0">{data.addressLine2}</p>
            <p className="mb-0">{data.addressLine3}</p>
          </>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          性別 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.gender}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          生年月日
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.birthDay ? moment(data.birthDay).format("YYYY/MM/DD") : ""}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          電話番号 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.fixed_phone}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          携帯電話番号 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.phone}
        </Col>
      </Row>
      {!edit && (
        <Row className="px-lg-3">
          <Col
            lg={3}
            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
              width >= 576 && `border-bottom border-start `
            } border-secondary p-2`}
          >
            Eメールアドレス <span className="text-danger">※</span>
          </Col>
          <Col
            lg={9}
            className={`${
              width < 576 && `border-start border-end border-bottom`
            } ${
              width >= 576 && `border-start border-bottom border-end`
            } p-1 border-secondary`}
          >
            {data.email}
          </Col>
        </Row>
      )}

      {ownership === "2" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              動物取扱業選択
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.animal_handler_business_type === "1"
                ? "第一種動物取扱業者"
                : "第二種動物取扱業者"}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              動物取扱業の情報
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {data.animal_handler_business_type === "1" && (
                <>
                  {_.includes(data.animal_handle_1, "1") && (
                    <>
                      販売: {data.animal_handler_business_type1_slot1}
                      <br />
                    </>
                  )}

                  {_.includes(data.animal_handle_1, "2") && (
                    <>
                      保管: {data.animal_handler_business_type1_slot2}
                      <br />
                    </>
                  )}

                  {_.includes(data.animal_handle_1, "3") && (
                    <>
                      貸出し: {data.animal_handler_business_type1_slot3}
                      <br />
                    </>
                  )}

                  {_.includes(data.animal_handle_1, "4") && (
                    <>
                      訓練: {data.animal_handler_business_type1_slot4}
                      <br />
                    </>
                  )}

                  {_.includes(data.animal_handle_1, "5") && (
                    <>
                      展示: {data.animal_handler_business_type1_slot5}
                      <br />
                    </>
                  )}

                  {_.includes(data.animal_handle_1, "6") && (
                    <>
                      競りあっせん: {data.animal_handler_business_type1_slot6}
                      <br />
                    </>
                  )}

                  {_.includes(data.animal_handle_1, "7") && (
                    <>
                      譲受飼養業: {data.animal_handler_business_type1_slot7}
                      <br />
                    </>
                  )}
                </>
              )}

              {data.animal_handler_business_type === "2" && (
                <>
                  <Row>
                    {_.includes(data.animal_handle_2, "1") && (
                      <Col xs={12}>譲渡し</Col>
                    )}
                    {_.includes(data.animal_handle_2, "2") && (
                      <Col xs={12}>保管</Col>
                    )}
                    {_.includes(data.animal_handle_2, "3") && (
                      <Col xs={12}>貸出し</Col>
                    )}
                    {_.includes(data.animal_handle_2, "4") && (
                      <Col xs={12}>訓練</Col>
                    )}
                    {_.includes(data.animal_handle_2, "5") && (
                      <Col xs={12}>展示</Col>
                    )}
                  </Row>
                </>
              )}
            </Col>
          </Row>

          {user && (
            <>
              {data.img != undefined && data.img != "" && (
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    写真
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    <div className="position-relative">
                      <img src={data.img} className="w-100 shadow rounded" />
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  犬舎紹介文 <span className="text-danger">※</span>
                </Col>
                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  <div className={`ql-snow ql-container border-0`}>
                    <div
                      className={` ql-editor px-0`}
                      dangerouslySetInnerHTML={{ __html: data.about }}></div>
                  </div>  
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      <input type="hidden" name="group" value={ownership} />
    </>
  );
}

export default SignupInputCheck;
