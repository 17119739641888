import { useEffect, useState } from "react";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";
import { useSetDoc } from "./useSetDoc";
import { useSendEmail } from "./useSendMail";
import { firebaseErrors } from "../firebase/config";

export const useRegUser = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { firebaseSetDoc } = useSetDoc();
  const {
    sendMail
    // isPending:emailPending,
    // error:emailError
  } = useSendEmail();
  const regNewUser = (userData, extraData) => {
    setIsPending(true);
    setIsConfirmed(false);
    setError(null);
    const createUser = httpsCallable(functions, "createUser");
    createUser({ ...userData })
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        // console.log(res.data.uid);
        // console.log(res.data.errorInfo);
        if (res.data.errorInfo) {
          setIsPending(false);
          setIsConfirmed(false);
          setError(
            firebaseErrors[res.data.errorInfo.code] ||
              res.data.errorInfo.message
          );
          // console.log(res.data.errorInfo.message);
          // console.log(res.data.errorInfo.code);
        } else {
          // console.log("inside success");
          firebaseSetDoc("users", extraData, res.data.uid);
          // sendEmailVerification(res.data);

          sendMail({
            mailOption: {
              from: "[犬猫マイクロチップ登録サイト] <info@microchip-registration.com>",
              to: res.data.email,
              subject: "アカウント仮登録のお知らせ",
              text: `${res.data.displayName} 様

                    メールアドレスを確認するには、次のリンクをクリックしてください。
                    
                    ${res.data.link}
                    
                    このアドレスの確認を依頼していない場合は、このメールを無視してください。
                    
                    よろしくお願いいたします。
                    
                    [犬猫マイクロチップ登録サイト]運営チーム`,
              html: `<p>${res.data.displayName} 様</p>
                    <p>メールアドレスを確認するには、次のリンクをクリックしてください。</p>
                    <p><a href='${res.data.link}'>${res.data.link}</a></p>
                    <p>このアドレスの確認を依頼していない場合は、このメールを無視してください。</p>
                    <p>よろしくお願いいたします。</p>
                    <p>[犬猫マイクロチップ登録サイト]運営チーム</p>`
            }
          });

          // console.log(res.data.uid);

          setIsPending(false);
          setIsConfirmed(true);
        }
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(err);
      });
  };

  return { regNewUser, isPending, isConfirmed, error };
};
