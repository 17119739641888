import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  InputGroup,
  Alert,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useGetDoc } from "../../hooks/useGetDoc";
import { useSendEmail } from "../../hooks/useSendMail";
import { useToast } from "../../hooks/useToast";
import { useSetDoc } from "../../hooks/useSetDoc";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getRandomString, getNameForEmail, mailFooter } from "../../utils/Utils";

function TransferInput() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuthContext();
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("mccode")) || false
  );
  const [opt, setOpt] = useState(null);
  const [oobCode, setOobCode] = useState(null);
  let initialValues = {
    mccode: data.mccode,
    email: "",
  };

  const schema = yup.object().shape({
    mccode: yup
      .string()
      .required("この欄は必須です")
      .length(15, "15文字でご入力ください"),
    email: yup
      .string()
      .required("この欄は必須です")
      .email("こちらのアドレスは無効です")
      .test("ownemail", "自分のメールアドレスは使用できません", function (val) {
        return val !== user.email;
      }),
  });
  const { getSingleDocWithQuery, isPending, error } = useGetDoc();
  const {
    sendMail,
    isPending: mailPending,
    isConfirmed,
    error: mailError,
  } = useSendEmail();
  const handleSubmit = (values) => {
    // check it user with this email exist and the user is owner
    try {
      getSingleDocWithQuery("users", [
        ["email", "==", values.email],
        // ["group", "==", "3"],
      ]).then((res) => {
        // console.log("res", res);
        //send otp
        if (res) {
          const randomOTP = Math.floor(100000 + Math.random() * 900000);
          const id = getRandomString(68);
          const varifyURL = `${process.env.REACT_APP_BASE_URL}varify-email?mode=verifyOTP&oobCode=${id}`;
          setOpt(randomOTP);
          setOobCode(id);
          //saving data in localstroage
          localStorage.setItem("userInfo", JSON.stringify(res[0]));

          //sending mail
          sendMail({
            mailOption: {
              from: "【MCRシステム】 <info@microchip-registration.com>",
              to: res[0].email,
              subject: "マイクロチップ譲渡用ワンタイムパスワードのご連絡",
              text: ``,
              html: `<p>${getNameForEmail(res[0])}</p><br/>
                    この度は、MCRシステムをご利用頂きまして誠にありがとうございます。<br/>
                    <br/>
                    マイクロチップ情報の譲渡認証の為のワンタイムパスワードをお送りさせて頂きますのでどうぞよろしくお願い致します。<br/>
                    <br/>
                    ワンタイムパスワードは下記になります。<br/>
                    <p><strong>${randomOTP}</strong></p>
                    または、
                    <br/>
                    <br/>
                    <a href="${varifyURL}" style="padding: 8px 12px;
                    background: #198754;
                    border-radius: 0.375rem;
                    font-size: 1rem;
                    padding: 0.375rem 0.75rem;
                    font-weight: 400;
                    line-height: 1.5;
                    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
                    color: #ffffff; 
                    text-decoration: none;
                    ">自動認証する</a><br/>
                    <br/>
                    ※自動認証は上記のボタンをクリックしてください。
                    ${mailFooter()}
              `,
            },
          });
        }
      });
    } catch (error) {}
  };
  const { firebaseSetDoc } = useSetDoc();
  const saveOTP = async () => {
    await firebaseSetDoc(
      "temp_otp",
      { uid: user.uid, otp: opt, oobCode: oobCode, isVarified: false },
      user.uid
    );
    // console.log(code)
  };
  useEffect(() => {
    if (isConfirmed) {
      toast("success", "ワンタイムパスワードは送信されました。");
      saveOTP();
      navigate("/mypage/transferotp");
    }
  }, [isConfirmed]);

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/transferstart" },
              { value: "メール", link: "#" },
              { value: "OTP", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              //{ value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={2}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            {error && (
              <Alert variant="danger" className="text-center">
                {error === "NO_DOC" &&
                  "このメールアドレスで登録されているユーザーはいません。"}
              </Alert>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      MC番号<span className="text-danger">※</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="半角"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mccode}
                      name="mccode"
                      required
                      readOnly
                      isInvalid={touched.mccode && errors.mccode}
                      isValid={touched.mccode && !errors.mccode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mccode}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Label>
                    譲渡先のメールアドレス
                    <span className="text-danger">※</span>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      maxLength={150}
                      required
                      isInvalid={touched.email && errors.email}
                      isValid={touched.email && !errors.email}
                    />
                    {!mailPending && (
                      <Button
                        type="submit"
                        variant="secondary"
                        id="button-addon2"
                      >
                        OTPを送信
                      </Button>
                    )}
                    {mailPending && (
                      <Button variant="secondary" id="button-addon2" disabled>
                        OTPを送信{" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    )}

                    {errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form>
              )}
            </Formik>
            <div className="text-center mt-4">
              <Link
                className="btn btn-secondary m-2"
                to="/mypage/transferstart"
              >
                <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TransferInput;
