import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Table,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import MCDetailsModal from "../../components/MCDetailsModal";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import useCsvDownload from "../../hooks/useCsvDownload";
import TransferListItem from "./TransferListItem";
import { owner_change_headers } from "../../utils/CSVFormate";
import { useNavigate } from "react-router-dom";
function TransferList() {
  const navigate = useNavigate();
  const [MCModalContent, setMCModalContent] = useState(null);
  const [showMCDetailsModal, setShowMCDetailsModal] = useState(false);
  const [mcs, setMcs] = useState([]);
  useEffect(() => {
    setMcs([]);
    loadMore();
  }, []);

  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const loadMore = (ld) => {
    getMultipleDocs({
      _collection: `microchip`,
      _query: {
        1: `transferStatus,==,TRANSFERING`,
      },
      orderByField: "transferAt",
      orderByOption: "desc",
    });
  };

  useEffect(() => {
    if (documents) {
      let newmcs = mcs;
      documents.forEach((doc) => {
        newmcs = { ...newmcs, [doc.id]: doc };
      });
      setMcs(newmcs);
    }
  }, [documents]);

  const MCModalHandler = (props) => {
    setShowMCDetailsModal(true);
    setMCModalContent({ ...props, transfer: true });
  };

  const [mcInfo, setMCInfo] = useState({});
  const batchCsvHandler = (e, nextOwner) => {
    if (e.target.checked) {
      setMCInfo({ ...mcInfo, [e.target.value]: { ...nextOwner } });
    } else {
      setMCInfo(_.omit(mcInfo, e.target.value));
    }
  };

  // useEffect(() => {
  // console.log(mcInfo);
  // }, [mcInfo]);

  const { getCSVTransferData } = useCsvDownload();
  const [csvData, setCsvData] = useState([]);
  //csvdata
  const getCsvData = async () => {
    const obj = Object.values(mcs).map(
      (doc) =>
        _.has(mcInfo, doc.id) && {
          ...doc,
          nextOwner: { ..._.get(mcInfo, doc.id) },
        }
    );

    await getCSVTransferData(
      Object.values(obj).filter((doc) => _.has(mcInfo, doc.id))
    ).then((res) => {
      setCsvData(res);
      setMCInfo({});
    });
  };

  const makeWhiteList = () => {
    let whitelist = Object.values(mcs).map((doc, i) => {
      return {...doc.transferWhiteList, transferPrice: doc.transferMCData.price}
    });
    localStorage.setItem("whitelist", JSON.stringify(whitelist));
    navigate("/mypage/csvupload");
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} className="text-end">
            <Button
              variant="warning"
              className="btn btn-warning mb-3"
              onClick={makeWhiteList}
            >
              CSVアップロード
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={10}>
            {Object.values(mcs).length > 0 && (
              <>
                <Table striped bordered responsive={"lg"}>
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(mcs) &&
                      Object.values(mcs).map((doc, i) => {
                        return (
                          <TransferListItem
                            key={i}
                            data={doc}
                            modalFun={MCModalHandler}
                            batchCsvHandler={batchCsvHandler}
                            mcInfo={mcInfo}
                          />
                        );
                      })}
                  </tbody>
                </Table>
                {/* <div className="my-4 text-center">
                  {!isLast && !isPending && (
                    <Button variant="secondary" onClick={loadMore}>
                      もっと見る
                    </Button>
                  )}

                  {!isLast && isPending && (
                    <Button variant="secondary" disabled onClick={loadMore}>
                      もっと見る{" "}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  )}
                </div> */}
              </>
            )}

            {!Object.values(mcs).length && !isPending && (
              <Alert variant="warning" className="text-center">
                支払い履歴がありません
              </Alert>
            )}
          </Col>
        </Row>
      </Container>

      <MCDetailsModal
        data={MCModalContent}
        show={showMCDetailsModal}
        onHide={() => setShowMCDetailsModal(false)}
      />

      {Object.keys(mcInfo).length > 0 && (
        <ToastContainer className="p-3" position="bottom-end">
          <Toast style={{ width: "unset" }}>
            <CSVLink
              className="btn btn-info btn-lg"
              data={csvData}
              headers={owner_change_headers}
              filename={`所有者の変更登録_${moment(new Date()).format(
                "YYYYMMDD_HHMMSS"
              )}.csv`}
              enclosingCharacter={``}
              asyncOnClick={true}
              onClick={getCsvData}
            >
              <div className="d-flex align-middle">
                <FontAwesomeIcon icon={faFileCsv} size="3x" className="pe-2" />
                <p className="mb-0">
                  [所有者の変更登録]
                  <br />
                  CSVをダウンロード
                </p>
              </div>
            </CSVLink>

            {/* <Button variant="warning" onClick={csvdata}>csvdata</Button> */}
          </Toast>
        </ToastContainer>
      )}
    </>
  );
}

export default TransferList;
