import { fromGengou, format } from "kanjidate";
import _ from "lodash";

import { dog_breed, cat_breed, dog_color, cat_color } from "./CatDogData";

export function getDataFromOCRData(data) {
  const inside = (point, vs) => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

    var x = point[0],
      y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

  const regPolygon = [
    [500, 100],
    [1220, 100],
    [1220, 160],
    [500, 160],
  ];

  const regNoPolygon = [
    [10, 260],
    [1200, 260],
    [1200, 310],
    [10, 310],
  ];

  const pinPolygon = [
    [10, 450],
    [1200, 450],
    [1200, 510],
    [10, 510],
  ];

  const dogCatPolygon = [
    [10, 600],
    [1200, 600],
    [1200, 700],
    [10, 700],
  ];

  const breedPolygon = [
    [10, 770],
    [1200, 770],
    [1200, 830],
    [10, 830],
  ];

  const colorPolygon = [
    [10, 910],
    [1200, 910],
    [1200, 975],
    [10, 975],
  ];

  const hbdPolygon = [
    [10, 1050],
    [1200, 1050],
    [1200, 1110],
    [10, 1110],
  ];

  const genderPolygon = [
    [10, 1180],
    [1200, 1180],
    [1200, 1300],
    [10, 1300],
  ];

  var regData = "";
  var regNoData = "";
  var pinData = "";
  var dogCatData = "";
  var breedData = "";
  var colorData = "";
  var hbdData = "";
  var genderData = "";
  data.fullTextAnnotation.pages[0].blocks.forEach((para) => {
    para.paragraphs.forEach((wrd) => {
      wrd.words.forEach((smbl) => {
        smbl.symbols.forEach((symbol) => {
          regData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              regPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              regPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              regPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              regPolygon
            )
              ? symbol.text
              : "";
          regNoData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              regNoPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              regNoPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              regNoPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              regNoPolygon
            )
              ? symbol.text
              : "";
          pinData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              pinPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              pinPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              pinPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              pinPolygon
            )
              ? symbol.text
              : "";
          dogCatData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              dogCatPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              dogCatPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              dogCatPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              dogCatPolygon
            )
              ? symbol.text
              : "";
          breedData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              breedPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              breedPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              breedPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              breedPolygon
            )
              ? symbol.text
              : "";
          colorData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              colorPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              colorPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              colorPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              colorPolygon
            )
              ? symbol.text
              : "";
          hbdData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              hbdPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              hbdPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              hbdPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              hbdPolygon
            )
              ? symbol.text
              : "";
          genderData +=
            inside(
              [
                symbol.boundingBox.vertices[0].x,
                symbol.boundingBox.vertices[0].y,
              ],
              genderPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[1].x,
                symbol.boundingBox.vertices[1].y,
              ],
              genderPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[2].x,
                symbol.boundingBox.vertices[2].y,
              ],
              genderPolygon
            ) &&
            inside(
              [
                symbol.boundingBox.vertices[3].x,
                symbol.boundingBox.vertices[3].y,
              ],
              genderPolygon
            )
              ? symbol.text
              : "";
        });
      });
    });
  });
  const getWesternDate = (japaneseDate) => {
    let era = "";
    let date = japaneseDate.match(/\d+/g);
    let letr = japaneseDate.match(/[^\d]+/g);
    if (
      letr[0] === "令和" ||
      letr[0] === "平成" ||
      letr[0] === "昭和" ||
      letr[0] === "大正" ||
      letr[0] === "明治"
    ) {
      era = letr[0];
    } else if (_.includes(letr[0], ["令", "和"])) {
      era = "令和";
    } else if (_.includes(letr[0], ["平", "成"])) {
      era = "平成";
    } else if (_.includes(letr[0], ["昭", "和"])) {
      era = "昭和";
    } else if (_.includes(letr[0], ["大", "正"])) {
      era = "大正";
    } else if (_.includes(letr[0], ["明", "治"])) {
      era = "明治";
    } else {
      era = format("{G}", new Date());
    }
    let westernDate =
      fromGengou(era, date[0]).toString() +
      "-" +
      date[1].toString().padStart(2, "0") +
      "-" +
      date[2].toString().padStart(2, "0");
    return westernDate;
  };

  const getdogCat = (dogCatString) => {
    let arrStr;
    dogCatString = _.replace(dogCatString, "ロ", "□");
    dogCatString = _.replace(dogCatString, "め", "✓");
    if (_.includes(dogCatString, "□")) {
      arrStr = _.split(dogCatString, "□");
      arrStr = _.pull(arrStr, "");
      return arrStr.length == 1 ? "猫" : "犬";
    } else if (_.includes(dogCatString, "✓")) {
      arrStr = _.split(dogCatString, "✓");
      arrStr = _.pull(arrStr, "");
      return arrStr.length == 1 ? "犬" : "猫";
    }
  };

  const getgender = (genderString) => {
    let arrStr;
    genderString = _.replace(genderString, "ロ", "□");
    genderString = _.replace(genderString, "め", "✓");
    if (_.includes(genderString, "□")) {
      arrStr = _.split(genderString, "□");
      arrStr = _.pull(arrStr, "");
      return arrStr.length == 1 ? "メス" : "オス";
    } else if (_.includes(genderString, "✓")) {
      arrStr = _.split(genderString, "✓");
      arrStr = _.pull(arrStr, "");
      return arrStr.length == 1 ? "オス" : "メス";
    }
  };

  const getBreedCorlor = (string, group, type) => {
    let data = [];
    let other = "99";
    if (group === "犬" && type === "BREED") {
      data = dog_breed;
      other = "999";
    } else if (group === "猫" && type === "BREED") {
      data = cat_breed;
      other = "999";
    } else if (group === "犬" && type === "COLOR") {
      data = dog_color;
      other = "99";
    } else if (group === "猫" && type === "COLOR") {
      data = cat_color;
      other = "99";
    }

    let findValue = _.findLast(data, (v) => {
      let value = _.replace(v.label, "（", "(");
      value = _.replace(value, "）", ")");

      if (value == string) {
        return v.value;
      }
    });

    // console.log('getBreedCorlor', findValue)
    if (type === "BREED") {
      return findValue && findValue.value
        ? {
            animal_breed: findValue.value,
          }
        : {
            animal_breed: other,
            animal_breed_other: string,
          };
    } else if (type === "COLOR") {
      return findValue && findValue.value
        ? {
            animal_color: findValue.value,
          }
        : {
            animal_color: other,
            animal_color_other: string,
          };
    }
  };

  // getBreedCorlor('アメリカンカール', "犬", 'BREED')
  const newregData = getWesternDate(regData);
  const newhbdData = getWesternDate(hbdData);
  const newdogCatData = getdogCat(dogCatData);
  const newgenderData = getgender(genderData);
  const breedJson = getBreedCorlor(breedData, newdogCatData, "BREED");
  const colorJson = getBreedCorlor(colorData, newdogCatData, "COLOR");

  // console.log({
  //   registrationAt: regData,
  //   mccode: regNoData,
  //   PIN: pinData,
  //   animal_group: dogCatData,
  //   animal_breed: breedData,
  //   animal_color: colorData,
  //   animal_birthDay: hbdData,
  //   animal_gender: genderData,
  // });

  return {
    registrationAt: newregData,
    mccode: regNoData,
    PIN: pinData,
    animal_group: newdogCatData,
    ...breedJson,
    ...colorJson,
    animal_birthDay: newhbdData,
    animal_gender: newgenderData,
  };
}
