import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import _ from "lodash";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function MCInputOwnerDetails({ sectionError, PIN = false }) {
  const { width } = useWindowDimensions();

  const { handleChange, handleBlur, values, touched, errors } =
    useFormikContext();

  useEffect(() => {
    if (
      (touched.animal_handler_business_type &&
        errors.animal_handler_business_type) ||
      (touched.animal_handle_1 && errors.animal_handle_1) ||
      (touched.animal_handle_2 && errors.animal_handle_2) ||
      (touched.animal_handler_business_type1_slot1 &&
        errors.animal_handler_business_type1_slot1) ||
      (touched.animal_handler_business_type1_slot2 &&
        errors.animal_handler_business_type1_slot2) ||
      (touched.animal_handler_business_type1_slot3 &&
        errors.animal_handler_business_type1_slot3) ||
      (touched.animal_handler_business_type1_slot4 &&
        errors.animal_handler_business_type1_slot4) ||
      (touched.animal_handler_business_type1_slot5 &&
        errors.animal_handler_business_type1_slot5) ||
      (touched.animal_handler_business_type1_slot6 &&
        errors.animal_handler_business_type1_slot6) ||
      (touched.animal_handler_business_type1_slot7 &&
        errors.animal_handler_business_type1_slot7)
    ) {
      sectionError(true);
    } else {
      sectionError(false);
    }
  }, [errors, touched]);

  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          動物取扱業選択
          <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          {PIN ? (
            <p className="mb-0 p-1">
              {values.animal_handler_business_type === "第一種動物取扱業者"
                ? "第一種動物取扱業者"
                : "第二種動物取扱業者"}
            </p>
          ) : (
            <>
              <Form.Check
                inline
                name="animal_handler_business_type"
                id="animal_handler_business_type1"
                label="第一種動物取扱業者"
                value="1"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={
                  values.animal_handler_business_type === "1" ? true : false
                }
                isInvalid={
                  touched.animal_handler_business_type &&
                  errors.animal_handler_business_type
                }
                isValid={
                  touched.animal_handler_business_type &&
                  !errors.animal_handler_business_type
                }
              />
              <Form.Check
                inline
                name="animal_handler_business_type"
                id="animal_handler_business_type2"
                label="第二種動物取扱業者"
                value="2"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={
                  values.animal_handler_business_type === "2" ? true : false
                }
                isInvalid={
                  touched.animal_handler_business_type &&
                  errors.animal_handler_business_type
                }
                isValid={
                  touched.animal_handler_business_type &&
                  !errors.animal_handler_business_type
                }
              />
              {touched.animal_handler_business_type &&
                errors.animal_handler_business_type && (
                  <div className="text-danger">
                    {errors.animal_handler_business_type}
                  </div>
                )}
            </>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          動物取扱業の情報 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {values.animal_handler_business_type === "1" && (
            <>
              <Row
                style={{
                  backgroundColor: "#E7F1FF",
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                <Col lg={3} sm={4} xs={12} className="py-2">
                  業種
                </Col>

                <Col
                  lg={9}
                  sm={8}
                  xs={12}
                  className={`py-2 ${
                    width < 576 && `border-top border-primary`
                  } ${width >= 576 && `border-start  border-primary`}`}
                >
                  犬猫の所在地の都道府県又は政令指定都市から発行された<span className="text-nowrap">登録番号</span>
                </Col>
              </Row>

              <Row>
                <Col lg={3} sm={4} xs={12} className="py-sm-2">
                  <Form.Check
                    id="animal_handle_11"
                    name="animal_handle_1"
                    label="販売"
                    type="checkbox"
                    value="1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_1, "1")}
                    isInvalid={
                      touched.animal_handle_1 && errors.animal_handle_1
                    }
                    isValid={touched.animal_handle_1 && !errors.animal_handle_1}
                  />
                </Col>

                <Col lg={9} sm={8} xs={12}>
                  <Form.Group className="mb-1">
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        _.includes(values.animal_handle_1, "1")
                          ? values.animal_handler_business_type1_slot1
                          : ""
                      }
                      name="animal_handler_business_type1_slot1"
                      maxLength={20}
                      isInvalid={
                        touched.animal_handler_business_type1_slot1 &&
                        errors.animal_handler_business_type1_slot1
                      }
                      isValid={
                        _.includes(values.animal_handle_1, "1") &&
                        touched.animal_handler_business_type1_slot1 &&
                        !errors.animal_handler_business_type1_slot1
                      }
                      disabled={!_.includes(values.animal_handle_1, "1")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.animal_handler_business_type1_slot1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="m-0 mb-1" />
              <Row>
                <Col lg={3} sm={4} xs={12} className="py-sm-2">
                  <Form.Check
                    id="animal_handle_12"
                    name="animal_handle_1"
                    label="保管"
                    type="checkbox"
                    value="2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_1, "2")}
                    isInvalid={
                      touched.animal_handle_1 && errors.animal_handle_1
                    }
                    isValid={touched.animal_handle_1 && !errors.animal_handle_1}
                  />
                </Col>

                <Col lg={9} sm={8} xs={12}>
                  <Form.Group className="mb-1">
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        _.includes(values.animal_handle_1, "2")
                          ? values.animal_handler_business_type1_slot2
                          : ""
                      }
                      name="animal_handler_business_type1_slot2"
                      maxLength={20}
                      isInvalid={
                        touched.animal_handler_business_type1_slot2 &&
                        errors.animal_handler_business_type1_slot2
                      }
                      isValid={
                        _.includes(values.animal_handle_1, "2") &&
                        touched.animal_handler_business_type1_slot2 &&
                        !errors.animal_handler_business_type1_slot2
                      }
                      disabled={!_.includes(values.animal_handle_1, "2")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.animal_handler_business_type1_slot2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="m-0 mb-1" />
              <Row>
                <Col lg={3} sm={4} xs={12} className="py-sm-2">
                  <Form.Check
                    id="animal_handle_13"
                    name="animal_handle_1"
                    label="貸出し"
                    type="checkbox"
                    value="3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_1, "3")}
                    isInvalid={
                      touched.animal_handle_1 && errors.animal_handle_1
                    }
                    isValid={touched.animal_handle_1 && !errors.animal_handle_1}
                  />
                </Col>

                <Col lg={9} sm={8} xs={12}>
                  <Form.Group className="mb-1">
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        _.includes(values.animal_handle_1, "3")
                          ? values.animal_handler_business_type1_slot3
                          : ""
                      }
                      name="animal_handler_business_type1_slot3"
                      maxLength={20}
                      isInvalid={
                        touched.animal_handler_business_type1_slot3 &&
                        errors.animal_handler_business_type1_slot3
                      }
                      isValid={
                        _.includes(values.animal_handle_1, "3") &&
                        touched.animal_handler_business_type1_slot3 &&
                        !errors.animal_handler_business_type1_slot3
                      }
                      disabled={!_.includes(values.animal_handle_1, "3")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.animal_handler_business_type1_slot3}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="m-0 mb-1" />
              <Row>
                <Col lg={3} sm={4} xs={12} className="py-sm-2">
                  <Form.Check
                    id="animal_handle_14"
                    name="animal_handle_1"
                    label="訓練"
                    type="checkbox"
                    value="4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_1, "4")}
                    isInvalid={
                      touched.animal_handle_1 && errors.animal_handle_1
                    }
                    isValid={touched.animal_handle_1 && !errors.animal_handle_1}
                  />
                </Col>

                <Col lg={9} sm={8} xs={12}>
                  <Form.Group className="mb-1">
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        _.includes(values.animal_handle_1, "4")
                          ? values.animal_handler_business_type1_slot4
                          : ""
                      }
                      name="animal_handler_business_type1_slot4"
                      maxLength={20}
                      isInvalid={
                        touched.animal_handler_business_type1_slot4 &&
                        errors.animal_handler_business_type1_slot4
                      }
                      isValid={
                        _.includes(values.animal_handle_1, "4") &&
                        touched.animal_handler_business_type1_slot4 &&
                        !errors.animal_handler_business_type1_slot4
                      }
                      disabled={!_.includes(values.animal_handle_1, "4")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.animal_handler_business_type1_slot4}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="m-0 mb-1" />
              <Row>
                <Col lg={3} sm={4} xs={12} className="py-sm-2">
                  <Form.Check
                    id="animal_handle_15"
                    name="animal_handle_1"
                    label="展示"
                    type="checkbox"
                    value="5"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_1, "5")}
                    isInvalid={
                      touched.animal_handle_1 && errors.animal_handle_1
                    }
                    isValid={touched.animal_handle_1 && !errors.animal_handle_1}
                  />
                </Col>

                <Col lg={9} sm={8} xs={12}>
                  <Form.Group className="mb-1">
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        _.includes(values.animal_handle_1, "5")
                          ? values.animal_handler_business_type1_slot5
                          : ""
                      }
                      name="animal_handler_business_type1_slot5"
                      maxLength={150}
                      isInvalid={
                        touched.animal_handler_business_type1_slot5 &&
                        errors.animal_handler_business_type1_slot5
                      }
                      isValid={
                        _.includes(values.animal_handle_1, "5") &&
                        touched.animal_handler_business_type1_slot5 &&
                        !errors.animal_handler_business_type1_slot5
                      }
                      disabled={!_.includes(values.animal_handle_1, "5")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.animal_handler_business_type1_slot5}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="m-0 mb-1" />
              <Row>
                <Col lg={3} sm={4} xs={12} className="pt-sm-2">
                  <Form.Check
                    id="animal_handle_16"
                    name="animal_handle_1"
                    label="競りあっせん業"
                    type="checkbox"
                    value="6"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_1, "6")}
                    isInvalid={
                      touched.animal_handle_1 && errors.animal_handle_1
                    }
                    isValid={touched.animal_handle_1 && !errors.animal_handle_1}
                  />
                </Col>

                <Col lg={9} sm={8} xs={12} className="mb-1">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        _.includes(values.animal_handle_1, "6")
                          ? values.animal_handler_business_type1_slot6
                          : ""
                      }
                      name="animal_handler_business_type1_slot6"
                      maxLength={150}
                      isInvalid={
                        touched.animal_handler_business_type1_slot6 &&
                        errors.animal_handler_business_type1_slot6
                      }
                      isValid={
                        _.includes(values.animal_handle_1, "6") &&
                        touched.animal_handler_business_type1_slot6 &&
                        !errors.animal_handler_business_type1_slot6
                      }
                      disabled={!_.includes(values.animal_handle_1, "6")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.animal_handler_business_type1_slot6}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="m-0 mb-1" />
              <Row>
                <Col lg={3} sm={4} xs={12} className="pt-sm-2">
                  <Form.Check
                    id="animal_handle_17"
                    name="animal_handle_1"
                    label="譲受飼養業"
                    type="checkbox"
                    value="7"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_1, "7")}
                    isInvalid={
                      touched.animal_handle_1 && errors.animal_handle_1
                    }
                    isValid={touched.animal_handle_1 && !errors.animal_handle_1}
                  />
                </Col>

                <Col lg={9} sm={8} xs={12}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        _.includes(values.animal_handle_1, "7")
                          ? values.animal_handler_business_type1_slot7
                          : ""
                      }
                      name="animal_handler_business_type1_slot7"
                      maxLength={150}
                      isInvalid={
                        touched.animal_handler_business_type1_slot7 &&
                        errors.animal_handler_business_type1_slot7
                      }
                      isValid={
                        _.includes(values.animal_handle_1, "7") &&
                        touched.animal_handler_business_type1_slot7 &&
                        !errors.animal_handler_business_type1_slot7
                      }
                      disabled={!_.includes(values.animal_handle_1, "7")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.animal_handler_business_type1_slot7}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {touched.animal_handle_1 && errors.animal_handle_1 && (
                <>
                  <hr className="m-0 mb-1 mt-1" />
                  <div className="text-danger">{errors.animal_handle_1}</div>
                </>
              )}
            </>
          )}

          {values.animal_handler_business_type === "2" && (
            <>
              <Row>
                <Col lg={4} xs={12}>
                  <Form.Check
                    id="animal_handle_21"
                    name="animal_handle_2"
                    label="譲渡し"
                    type="checkbox"
                    value="1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_2, "1")}
                    isInvalid={
                      touched.animal_handle_2 && errors.animal_handle_2
                    }
                    isValid={touched.animal_handle_2 && !errors.animal_handle_2}
                  />
                </Col>
                <Col lg={4} xs={12}>
                  <Form.Check
                    id="animal_handle_22"
                    name="animal_handle_2"
                    label="保管"
                    type="checkbox"
                    value="2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_2, "2")}
                    isInvalid={
                      touched.animal_handle_2 && errors.animal_handle_2
                    }
                    isValid={touched.animal_handle_2 && !errors.animal_handle_2}
                  />
                </Col>
                <Col lg={4} xs={12}>
                  <Form.Check
                    id="animal_handle_23"
                    name="animal_handle_2"
                    label="貸出し"
                    type="checkbox"
                    value="3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_2, "3")}
                    isInvalid={
                      touched.animal_handle_2 && errors.animal_handle_2
                    }
                    isValid={touched.animal_handle_2 && !errors.animal_handle_2}
                  />
                </Col>
                <Col lg={4} xs={12}>
                  <Form.Check
                    id="animal_handle_24"
                    name="animal_handle_2"
                    label="訓練"
                    type="checkbox"
                    value="4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_2, "4")}
                    isInvalid={
                      touched.animal_handle_2 && errors.animal_handle_2
                    }
                    isValid={touched.animal_handle_2 && !errors.animal_handle_2}
                  />
                </Col>
                <Col lg={4} xs={12}>
                  <Form.Check
                    id="animal_handle_25"
                    name="animal_handle_2"
                    label="展示"
                    type="checkbox"
                    value="5"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={_.includes(values.animal_handle_2, "5")}
                    isInvalid={
                      touched.animal_handle_2 && errors.animal_handle_2
                    }
                    isValid={touched.animal_handle_2 && !errors.animal_handle_2}
                  />
                </Col>
              </Row>
              {touched.animal_handle_2 && errors.animal_handle_2 && (
                <div className="text-danger">{errors.animal_handle_2}</div>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default MCInputOwnerDetails;
