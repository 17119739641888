import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, useFormikContext } from "formik";
import _ from "lodash";

import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";

import {
  email,
  password,
  zip3Schema,
  zip4Schema,
  prefectureSchema,
  addressLine1Schema,
  addressLine2Schema,
  addressLine3Schema,
  phoneSchema,
  fixedPhoneSchema,
} from "../../../utils/InputSchema";

//component
import ProgressStep from "../../../components/ProgressStep";
import SignupInput from "../../signup/SignupInput";
import SignupInputVet from "../../signup/SignupInputVet";

export default function PersonalInfo() {
  const [zipError, setZipError] = useState(true);
  const backData = localStorage.getItem("stateEdit");
  const { userData } = useUserDetailsContext();
  const [initialValues, setinitialValues] = useState(
    backData
      ? JSON.parse(backData)
      : _.omit(userData, ["createdAt", "email", "totalMC", "transferMC"])
  );
  // useEffect(() => {
  // console.log(JSON.parse(backData), userData)

  // }, []);

  const navigate = useNavigate();

  const schema = yup.object().shape({
    type: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["1", "2"], "個人または法人をお選びください"),

    firstName: yup.string().when("type", {
      is: (type) => type === "1",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(14, "最小14全角半角混在以上にてご入力ください"),
    }),
    lastName: yup.string().when("type", {
      is: (type) => type === "1",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(14, "最小14全角半角混在以上にてご入力ください"),
    }),
    firstKana: yup.string().when("type", {
      is: (type) => type === "1",
      then: yup
        .string()
        .required("この欄は必須です")
        .matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください"),
    }),
    lastKana: yup.string().when("type", {
      is: (type) => type === "1",
      then: yup
        .string()
        .required("この欄は必須です")
        .matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください"),
    }),

    corporation_name: yup.string().when("type", {
      is: (type) => type === "2",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(30, "最小30全角半角混在以上にてご入力ください"),
    }),
    corporation_kana_name: yup.string().when("type", {
      is: (type) => type === "2",
      then: yup
        .string()
        .required("この欄は必須です")
        .matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください"),
    }),
    corporation_representative_lastName: yup.string().when("type", {
      is: (type) => type === "2",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(14, "最小14全角半角混在以上にてご入力ください"),
    }),
    corporation_representative_firstName: yup.string().when("type", {
      is: (type) => type === "2",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(14, "最小14全角半角混在以上にてご入力ください"),
    }),
    corporation_personincharge_lastName: yup.string().when("type", {
      is: (type) => type === "2",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(14, "最小14全角半角混在以上にてご入力ください"),
    }),
    corporation_personincharge_firstName: yup.string().when("type", {
      is: (type) => type === "2",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(14, "最小14全角半角混在以上にてご入力ください"),
    }),
    zip3: zip3Schema(zipError),
    zip4: zip4Schema(zipError),
    prefecture: prefectureSchema(),
    addressLine1: addressLine1Schema(),
    addressLine2: addressLine2Schema(),
    addressLine3: addressLine3Schema(),
    fixed_phone: fixedPhoneSchema(),
    phone: phoneSchema(),
    birthDay: yup
      .date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "18歳以上の方に限ります"
      ),
    gender: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["男性", "女性", "指定なし"], "性別をお選びください"),
    about: yup.string().when("type", {
      is: (type) => type === "1" && userData && userData.group === "breederpet",
      then: yup.string().required("この欄は必須です"),
    }),

    animal_handle_1: yup.array().when(["animal_handler_business_type"], {
      is: (animal_handler_business_type) =>
        animal_handler_business_type === "1" &&
        userData &&
        userData.group === "breederpet",
      then: yup
        .array()
        .min(1, "1種類以上は入力")
        .of(yup.string().required())
        .required(),
    }),
    animal_handle_2: yup.array().when(["animal_handler_business_type"], {
      is: (animal_handler_business_type) =>
        animal_handler_business_type === "2" &&
        userData &&
        userData.group === "breederpet",
      then: yup
        .array()
        .min(1, "1種類以上は入力")
        .of(yup.string().required())
        .required(),
    }),
    animal_handler_business_type: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["1", "2"], "動物取扱業をお選びください"),

    animal_handler_business_type1_slot1: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "1") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください"),
      }),
    animal_handler_business_type1_slot2: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "2") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください"),
      }),
    animal_handler_business_type1_slot3: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "3") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください"),
      }),
    animal_handler_business_type1_slot4: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "4") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください"),
      }),
    animal_handler_business_type1_slot5: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "5") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください"),
      }),
    animal_handler_business_type1_slot6: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "6") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください"),
      }),
    animal_handler_business_type1_slot7: yup
      .string()
      .when(["animal_handle_1", "animal_handler_business_type"], {
        is: (animal_handle_1, animal_handler_business_type) =>
          _.includes(animal_handle_1, "7") &&
          animal_handler_business_type === "1",
        then: yup
          .string()
          .required("この欄は必須です")
          .max(20, "最小20全角半角混在以上にてご入力ください"),
      }),
  });

  const schemaVet = yup.object().shape({
    vet_institute: yup
      .string()
      .required("この欄は必須です")
      .max(30, "最小30全角半角混在以上にてご入力ください")
      .matches(
        /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤ヶ”、；＜＞？￥’＆　 （）＃#-]+$/,
        "全角半角混在のみを入力してください"
      ),

    firstName: yup
      .string()
      .required("この欄は必須です")
      .max(14, "最小14全角半角混在以上にてご入力ください")
      .matches(
        /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤ヶ”、；＜＞？￥’＆　 （）＃#-]+$/,
        "全角半角混在のみを入力してください"
      ),
    lastName: yup
      .string()
      .max(14, "最小14全角半角混在以上にてご入力ください")
      .matches(
        /^[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤ヶ”、；＜＞？￥’＆　 （）＃#-]+$/,
        "全角半角混在のみを入力してください"
      ),
    firstKana: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください"),
    lastKana: yup
      .string()
      .required("この欄は必須です")
      .matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください"),

    zip3: zip3Schema(zipError),
    zip4: zip4Schema(zipError),
    prefecture: prefectureSchema(),
    addressLine1: addressLine1Schema(),
    addressLine2: addressLine2Schema(),
    addressLine3: addressLine3Schema(),
    phone: phoneSchema(),
    birthDay: yup
      .date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "18歳以上の方に限ります"
      ),
    gender: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["男性", "女性", "指定なし"], "性別をお選びください"),
  });

  const handleSubmit = (values) => {
    const img = localStorage.getItem("signUpInputImg");
    // console.log(img);
    if (img != undefined) {
      values.img = img;
      localStorage.removeItem("signUpInputImg");
      // console.log(values);
    }
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("stateEdit", serializedState);
      navigate("/mypage/personalinfocheck");
    } catch (err) {
      return undefined;
    }
  };
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-md-center">
          <Col lg={11} md={10}>
            <Row className="justify-content-center mb-4">
              <ProgressStep
                props={[
                  { value: "入力", link: "#" },
                  {
                    value: "確認",
                    link: backData ? "/mypage/personalinfocheck" : "#",
                  },
                  { value: "終了", link: "#" },
                ]}
                activeNo={1}
              />
            </Row>

            <p className="text-center">
              下記の内容をご入力の上、お進みください。
              <span className="text-danger">※</span> 必須項目
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={
                userData && userData.group === "vet" ? schemaVet : schema
              }
              onSubmit={handleSubmit}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Container>
                    {userData && userData.group === "vet" && (
                      <SignupInputVet
                        zipError={setZipError}
                        ownership={"3"}
                        edit={true}
                      />
                    )}
                    {userData && userData.group === "owner" && (
                      <SignupInput
                        zipError={setZipError}
                        ownership={"1"}
                        edit={true}
                      />
                    )}
                    {userData && userData.group === "breederpet" && (
                      <SignupInput
                        zipError={setZipError}
                        ownership={"2"}
                        edit={true}
                      />
                    )}
                  </Container>

                  <div className="text-center mt-4">
                    <Button
                      type="submit"
                      size="lg"
                      className="mb-3"
                      variant="primary">
                      確認
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
