import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Helmet, HelmetData } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useSendEmail } from "../../hooks/useSendMail";
import styles from "./ContactUs.module.css";
import { mailFooter } from "../../utils/Utils";

function ContactUsCheck() {
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("contactUsForm"))
  );

  const navigate = useNavigate();

  useEffect(() => {
    // setData(localStorage.getItem("contactUsForm"));
    if (!data) {
      navigate("/contactUs");
    }
  }, []);

  const {
    sendMail,
    isPending: mailPending,
    isConfirmed,
    error: mailError,
  } = useSendEmail();

  const handleSubmit = () => {
    //sending mail
    sendMail({
      mailOption: {
        from: "【犬と猫のマイクロチップ登録システム】 <info@microchip-registration.com>",
        to: data.email,
        cc: "petnext01@gmail.com",
        subject: "お問い合わせいただき、ありがとうございます。",
        text: ``,
        html: `
         このメールは、お問い合わせフォームよりお問い合わせをいただいた方へ、自動返信によって送信されています。<br/>
         <br/>
<hr>
<br/>
${data && data.name}様<br/>
以下の内容でお問い合わせをお受けいたしました。<br/>
改めて、担当者よりご連絡させていただきますので、今しばらくお待ちくださいませ。<br/>
<br/>
＜お問い合わせ内容＞<br/>
<table border=0>
  <tr>
    <td>企業・団体・組織名:</td>
    <td>${data && data.company}</td>
  </tr>
  <tr>
    <td>お名前:</td>
    <td>${data && data.name}</td>
  </tr>

  <tr>
    <td>メールアドレス:</td>
    <td>${data && data.email}</td>
  </tr>
  <tr>
    <td>電話番号:</td>
    <td>${data && data.phone}</td>
  </tr>
  <tr>
    <td>住所:</td>
    <td>${data && data.address}</td>
  </tr>
  <tr>
    <td>お問い合わせ内容:</td>
    <td>${data && data.message}</td>
  </tr>
</table>
<br/>
${mailFooter}`,
      },
    });
  };
  useEffect(() => {
    if (isConfirmed) {
      // toast("success", "ワンタイムパスワードは送信されました。");
      navigate("/contactuscomplete");
    }
  }, [isConfirmed]);
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>お問い合わせ</title>
        <meta property="og:title" content="CKC" />
        <meta name="description" content="CKC" />
      </Helmet>
      <div className="main">
        <div className={styles.contactUs}>
          <div className={`container position-relative`}>
            <div className={`row d-flex justify-content-center ${styles.con}`}>
              <div className="col-12 col-sm-10">
                <div className={styles["contact-card"]}>
                  <p className={styles.title}>お問い合わせフォーム</p>
                  <p className={styles.subtitle}>
                    以下の内容で送信いたします。 <br />
                    よろしければ送信ボタンをクリックしてください。
                  </p>
                  <div className={styles.form}>
                    <div className={`row ${styles.valueDivD}`}>
                      <div className="col-12 col-sm-5 d-flex">
                        <label>企業・団体・組織名</label>
                      </div>
                      <div className={`col-12 col-sm-7 ${styles.valueDiv}`}>
                        <p>{data && data.company}</p>
                      </div>
                    </div>

                    <div className={`row ${styles.valueDivD}`}>
                      <div className="col-12 col-sm-5 d-flex">
                        <label>
                          <span className={styles.req}>必須</span> お名前
                        </label>
                      </div>
                      <div className={`col-12 col-sm-7 ${styles.valueDiv}`}>
                        <p>{data && data.name}</p>
                      </div>
                    </div>

                    <div className={`row ${styles.valueDivD}`}>
                      <div className="col-12 col-sm-5 d-flex">
                        <label>
                          <span className={styles.req}>必須</span>{" "}
                          メールアドレス
                        </label>
                      </div>
                      <div className={`col-12 col-sm-7 ${styles.valueDiv}`}>
                        <p>{data && data.email}</p>
                      </div>
                    </div>

                    <div className={`row ${styles.valueDivD}`}>
                      <div className="col-12 col-sm-5 d-flex">
                        <label>
                          <span className={styles.req}>必須</span> 電話番号
                        </label>
                      </div>
                      <div className={`col-12 col-sm-7 ${styles.valueDiv}`}>
                        <p>{data && data.phone}</p>
                      </div>
                    </div>

                    <div className={`row ${styles.valueDivD}`}>
                      <div className="col-5 d-flex">
                        <label className={styles.textarealabel}>
                          <span className={styles.req}>必須</span> 住所
                        </label>
                      </div>
                      <div className={`col-12 col-sm-7 ${styles.valueDiv}`}>
                        <p>{data && data.address}</p>
                      </div>
                    </div>

                    <div className={`row ${styles.valueDivD}`}>
                      <div className="col-12 col-sm-5 d-flex">
                        <label className={styles.textarealabel}>
                          <span className={styles.req}>必須</span>{" "}
                          お問い合わせ内容
                        </label>
                      </div>
                      <div className={`col-12 col-sm-7 ${styles.valueDiv}`}>
                        <p>{data && data.message}</p>
                      </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                      <Link to={"/contactus"} className={styles.backbtn}>
                        戻る
                      </Link>
                      {!mailPending && (
                        <button
                          onClick={handleSubmit}
                          className={styles.sendbtn}
                        >
                          送信する
                        </button>
                      )}

                      {mailPending && (
                        <button className={styles.sendbtn} disabled>
                          送信する{" "}
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </button>
                      )}
                      {/* <button className={styles.sendbtn}>送信する</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src="/assets/img/asset12.png" alt="" className={styles.dog} />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsCheck;
