import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Alert,
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NewsContent from "../../components/NewsContent";
import ProgressStep from "../../components/ProgressStep";
import { useAddDocs } from "../../hooks/useAddDocs";
import { useFirebaseIncrement } from "../../hooks/useFirebaseIncrement";
import { useUpdateDoc } from "../../hooks/useUpdateDoc";

export default function NewsCheck({ edit = false }) {
  const navigate = useNavigate();
  const { addADoc, isPending, error } = useAddDocs();
  const {
    firebaseUpdateDoc,
    isPending: isPendingUpdate,
    isConfirmed,
    error: errorUpdate,
  } = useUpdateDoc();
  const { firebaseIncrement } = useFirebaseIncrement();
  const values = JSON.parse(localStorage.getItem("newsValue"));
  const newsHandler = async () => {
    if (edit) {
      let newsId = JSON.parse(localStorage.getItem("newsEditId"));
      await firebaseUpdateDoc(
        `news`,
        { ...values, dateAt: new Date(values.date) },
        newsId
      ).then((res) => {
        // console.log(res)
        navigate("/mypage/newsComplete");
      });
    } else {
      //add mc in owner list
      await addADoc(`news`, { ...values, dateAt: new Date(values.date) }).then(
        (res) => {
          // console.log(res)
          navigate("/mypage/newsComplete");
        }
      );

      await firebaseIncrement(`newsCount`, `count`, `total`, 1);
    }
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={2}
          />
        </Row>

        <div className="row d-flex justify-content-center ">
          <div className="col-12 col-lg-8 border">
            <NewsContent data={values} />
          </div>
        </div>

        <div className="text-center mt-4">
          <Link
            className="btn btn-secondary m-2"
            to={edit ? `/mypage/newsedit/` : `/mypage/createnews/`}
          >
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link>

          {!isPending && !isPendingUpdate && (
            <Button className="btn btn-primary m-2" onClick={newsHandler}>
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />
            </Button>
          )}

          {(isPending || isPendingUpdate) && (
            <Button className="btn btn-primary m-2" disabled>
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}
