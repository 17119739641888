import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  Spinner
} from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faRightToBracket,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { useLogin } from "../../hooks/useLogin";
import { Helmet, HelmetData } from "react-helmet-async";
export default function Login() {
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { error, isPending, login } = useLogin();

  const handelSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>ログイン</title>
      </Helmet>
      <div className="main pb-5">
        <Container className="mb-5">
          <h1 className="text-center py-5">ログイン</h1>
          <Row className="justify-content-md-center">
            <Col lg={6} md={8}>
              <Card className="shadow-sm">
                <Card.Body className="p-lg-5 ">
                  {error && (
                    <Alert variant="danger" dismissible className="text-center">
                      {error && <p className="m-0">{error}</p>}
                    </Alert>
                  )}
                  <Form onSubmit={handelSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Eメールアドレス <span className="text-danger">※</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        パスワード <span className="text-danger">※</span>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <InputGroup.Text
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon
                            icon={!showPassword ? faEyeSlash : faEye}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>

                    {/* <Form.Check className="mb-3" id="remember">
                      <Form.Check.Input type="checkbox" />
                      <Form.Check.Label>パスワードを記憶する</Form.Check.Label>
                    </Form.Check> */}
                    <div className="text-center">
                      {!isPending && (
                        <Button
                          className="mb-3"
                          variant="primary"
                          type="submit"
                        >
                          <FontAwesomeIcon icon={faRightToBracket} />{" "}
                          <b>ログイン</b>
                        </Button>
                      )}

                      {isPending && (
                        <Button className="mb-3" variant="primary" disabled>
                          <FontAwesomeIcon icon={faRightToBracket} />{" "}
                          <b>ログイン </b>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      )}
                    </div>
                  </Form>

                  <p className="text-center">
                    <Link to="/forget-password">
                      &gt;&gt;パスワードをお忘れの方はこちらをクリックしてください。
                    </Link>
                  </p>
                  <h5 className="text-center">
                    こちらのサイトにご登録をまだされていない方は <br />
                    下記よりご登録お願い致します。
                  </h5>
                  <div className="text-center mt-3">
                    {(searchParams.get("group") === "vet" ||
                      searchParams.get("group") === "breederpet" ||
                      searchParams.get("group") === "owner") && (
                      <Link
                        className="btn btn-secondary"
                        to={`/signup?group=${searchParams.get("group")}`}
                      >
                        <FontAwesomeIcon icon={faUserPlus} /> <b>新規ご登録</b>
                      </Link>
                    )}
                    {(searchParams.get("group") === null ||
                      !(
                        searchParams.get("group") === "vet" ||
                        searchParams.get("group") === "breederpet" ||
                        searchParams.get("group") === "owner"
                      )) && (
                      <DropdownButton
                        id="dropdown-variants-secondary"
                        variant="secondary"
                        title="新規ご登録"
                      >
                        <Link
                          className="dropdown-item"
                          to={`/signup?group=vet`}
                        >
                          獣医師
                        </Link>
                        <Dropdown.Divider />
                        <Link
                          className="dropdown-item"
                          to={`/signup?group=breederpet`}
                        >
                          ブリーダー/ペットショップ
                        </Link>
                        <Dropdown.Divider />
                        <Link
                          className="dropdown-item"
                          to={`/signup?group=owner`}
                        >
                          飼い主
                        </Link>
                      </DropdownButton>
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </div>
    </>
  );
}
