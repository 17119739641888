import { db, firebaseErrors } from "../firebase/config";

//firebase imports
import { doc, deleteDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useDeleteDocs = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const deleteDocument = async (c, id) => {
    setIsPending(true);
    setError(null);
    let ref = doc(db, c, id);
    await deleteDoc(ref).then(
      () => {
        setIsPending(false);
        setIsConfirmed(true);
      },
      (err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(firebaseErrors[err.code] || err.message);
        console.log(firebaseErrors[err.code] || err.message)
      }
    );
  };

  return { deleteDocument, isPending, isConfirmed, error };
};
