import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Helmet, HelmetData } from "react-helmet-async";
import ProgressStep from "../../components/ProgressStep";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkSquare,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";

export default function Signupconfirm() {
  const helmetData = new HelmetData({});
  const [returnsite, setReturnsite] = useState(null);
  const [returnurl, setReturnurl] = useState(null);

  useEffect(() => {
    //show return url
    if (
      localStorage.getItem("returnurl") &&
      localStorage.getItem("returnsite") === "koinu"
    ) {
      setReturnurl(localStorage.getItem("returnurl"));
      setReturnsite(localStorage.getItem("returnsite"));
    }

    localStorage.removeItem("state");
  }, []);
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>仮登録完了</title>
      </Helmet>
      <div className="main pt-5">
        <Container className="mb-5">
          <h1 className="text-center pb-3">仮登録完了</h1>

          <Row className="justify-content-md-center">
            <Col lg={6} md={10}>
              <Card className="pt-4">
                <Card.Body>
                  <Row className="justify-content-center mb-4">
                    <ProgressStep
                      props={[
                        { value: "入力", link: "/signup" },
                        { value: "確認", link: "#" },
                        { value: "終了", link: "#" }
                      ]}
                      activeNo={3}
                    />
                  </Row>
                  <h5 className="mt-5 mx-2 text-danger text-center">
                    ※まだご登録は完了しておりません。
                  </h5>
                  <p className="my-5 mx-2 text-center">
                    ご登録頂きましたメールアドレス宛にアカウントを有効にする為のメールをお送りさせていただきました。{" "}
                    <br />
                    メールをご確認の上、ご登録を完了させてください。
                    <br />
                    ※メールが届かない場合は迷惑メールボックスをご確認ください
                  </p>
                </Card.Body>
              </Card>

              <div className="text-center mt-3">
                {returnsite && returnurl && (
                  <a className="btn btn-secondary" href={returnurl}>
                    <FontAwesomeIcon icon={faExternalLinkSquare} />{" "}
                    <b>{returnsite}ヘ</b>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
