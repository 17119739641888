import React from "react";
import styles from "./CKC.module.css";
import { Helmet, HelmetData } from "react-helmet-async";

export default function CKC() {
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>CKC</title>
        <meta property="og:title" content="CKC" />
        <meta name="description" content="CKC" />
      </Helmet>
      <div className="main ">
        <div className={styles.ckc}>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-sm-10">
                <div className={styles.ckcCard}>
                  <div className={styles.infoContainer}>
                    <div className={styles.info}>
                      <p>CKC</p>
                      <img src="assets/img/ckc_logo.png" />
                    </div>
                    <span>
                      {" "}
                      中央ケネル事業
                      <br className="d-block d-sm-none" />
                      協同組合連合会{" "}
                    </span>

                    <img src="assets/img/whats.png" className={styles.whats} />
                  </div>

                  <div className={styles.mobileborder}></div>

                  <div className="row d-flex justify-content-center mt-5 mt-sm-0">
                    <div className="col-11 col-sm-5">
                      <img
                        src="assets/img/CKC_1.jpg"
                        className={`${styles.ckImg} w-100`}
                      />
                    </div>
                    <div className="col-11 col-sm-5">
                      <p className={`${styles.ckText} d-none d-sm-block`}>
                        中央ケネル事業協同組合連合会
                        <br />
                        （略称CKC）は2005年より
                        <br />
                        「農林水産省/関東農政局、経済産業
                        <br />
                        省/関東経済産業局、環境省」の
                        <br />
                        3省共管のもと、
                        <br />
                        設立された組合法人です。
                      </p>

                      <p className={`${styles.ckText} d-block d-sm-none mt-3`}>
                        中央ケネル事業協同組合連合会{" "}
                        <span className={styles.ml_n3p}>（</span>略称 <br />
                        CKC） <span className={styles.ml_n3p}>は2005</span>
                        年より <span className={styles.ml_n3p}>「</span>
                        農林水産省/関
                        <br />
                        東農政局、<span className={styles.ml_n3p}></span>
                        経済産業省/関東経済産業
                        <br />
                        局、<span className={styles.ml_n3p}></span>環境省」
                        <span className={styles.ml_n3p}></span>の3省共管のもと、
                        <br />
                        設立された組合法人です。
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mt-4 mt-sm-4 flex-column-reverse flex-sm-row align-items-center">
                    <div className="col-11 col-sm-5 mt-1">
                      <p className={`${styles.ckText} d-none d-sm-block`}>
                        人と動物の共生の中で情操豊かな
                        <br />
                        社会作りを考えるための
                        <br />
                        全国組織であり、ペットの事業に関
                        <br />
                        わるショップ、訓練士、ブリーダー等、
                        <br />
                        各分野のプロフェッショナルで
                        <br />
                        構成されています。
                      </p>

                      <p className={`${styles.ckText} d-block d-sm-none mt-2`}>
                        人と動物の共生の中で情操豊かな社
                        <br />
                        会作りを考えるための全国組織であり
                        <br />、<span className={styles.ml_n3p}></span>
                        ペットの事業に関わるショップ、訓練
                        <br />
                        士、<span className={styles.ml_n3p}></span>
                        ブリーダー等、各分野のプロフェッ
                        <br />
                        ショナルで構成されています。
                      </p>
                    </div>
                    <div className="col-11 col-sm-5 mt-1">
                      <img
                        src="assets/img/CKC_2.jpg"
                        className={`${styles.ckImg} w-100`}
                      />
                    </div>
                  </div>
                </div>
                <img src="/assets/img/asset6.png" alt="" className={styles.art} />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
