import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import styles from "./MCStyle.module.css";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useGetDoc } from "../../hooks/useGetDoc";
import { useDropzone } from "react-dropzone";

import { useImageOCR } from "../../hooks/useImageOCR";
import getCroppedImg from "../../utils/Cropper";
import { getDataFromOCRData } from "../../utils/OCRfunctions";
const PDFJS = require("pdfjs-dist/webpack");

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function MCRegistrationFromPDF() {
  const [files, setFiles] = useState([]);

  const {
    imageOCR,
    isPending: isPendingOCR,
    isConfirmed,
    error: errorOCR,
  } = useImageOCR();
  const [imgs, setImgs] = useState([]);
  const [cropImg, setCropImg] = useState();
  const [text, setText] = useState("");

  useEffect(() => {
    localStorage.removeItem("mcData");
    localStorage.removeItem("mccode");
    localStorage.removeItem("mcinstal");
  }, []);

  const [customError, setCustomError] = useState(null);
  const { user } = useAuthContext();

  const { documents: dbmcode } = useCollection("temp_mc", [
    "uid",
    "==",
    user.uid,
  ]);
  const { getSingleDocWithQuery, isPending, error } = useGetDoc();

  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setCustomError(null);
    try {
      //check if mc already registered or not
      getSingleDocWithQuery("microchip", [
        ["mccode", "==", values.mccode.toString()],
      ]).then((res) => {
        // console.log(res);
        if (!res) {
          const serializedState = JSON.stringify(values);
          localStorage.setItem("mcData", serializedState);

          navigate("/mypage/mcregistrationfrompdfinput");
          // console.log("here1");
        } else {
          setCustomError(
            `${values.mccode}マイクロチップの番号はすでに登録されています`
          );
          // console.log("here2");
        }
      });
    } catch (err) {
      return undefined;
    }
  };

  useEffect(() => {
    setCustomError(null);
  }, [dbmcode]);

  // const MyField = () => {
  //   const { setFieldValue, handleChange, handleBlur, values, touched, errors } =
  //     useFormikContext();

  //   useEffect(() => {
  //     setFieldValue(
  //       "mccode",
  //       (dbmcode && dbmcode[0] && dbmcode[0].type === "R" && dbmcode[0].mccode) || values.mccode || ""
  //     );
  //   }, [setFieldValue]);

  //   return (
  //     <>
  //       <Form.Group className="mb-3">
  //         <Form.Label>
  //           MC番号<span className="text-danger">※</span>{" "}
  //         </Form.Label>
  //         <Form.Control
  //           type="text"
  //           placeholder="半角15桁番号でご入力ください"
  //           onChange={(e) => {
  //             handleChange(e);
  //             setCustomError(null);
  //           }}
  //           onBlur={handleBlur}
  //           value={values.mccode}
  //           name="mccode"
  //           autoComplete="off"
  //           required
  //           isInvalid={(touched.mccode && errors.mccode) || customError}
  //           isValid={touched.mccode && !errors.mccode}
  //         />
  //         <Form.Control.Feedback type="invalid">
  //           {errors.mccode || customError}
  //         </Form.Control.Feedback>

  //         <div className="text-center mt-4">
  //           <Button className="mb-3" variant="primary" type="submit">
  //             <b>
  //               次へ <FontAwesomeIcon icon={faCircleChevronRight} />
  //             </b>
  //           </Button>
  //         </div>
  //       </Form.Group>
  //     </>
  //   );
  // };
  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  //param: file -> the input file (e.g. event.target.files[0])
  //return: images -> an array of images encoded in base64
  const convertPdfToImages = async (file) => {
    const images = [];
    const data = await readFileData(file);
    // const data = new Uint8Array(file);

    const pdf = await PDFJS.getDocument(data).promise;
    const canvas = document.createElement("canvas");
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 5 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    // console.log(images);

    setImgs(images);
    return images;
  };
  //OCR part
  useEffect(() => {
    if (cropImg) {
      imageOCR({ pdfImage: cropImg }).then((res) => {
        // setText(res.data.fullTextAnnotation.text);

        // console.log("OCR", res);
        if (res) {
          const values = getDataFromOCRData(res.data);
          setText(values);

          handleSubmit(values);
        }
      });
    }
  }, [cropImg]);
  const getCI = async (img) => {
    try {
      const croppedImage = await getCroppedImg(
        img,
        {
          width: 1190,
          height: 1360,
          x: 1516,
          y: 1518,
        },
        0
      );
      // console.log("donee", { croppedImage });
      setCropImg(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCI(imgs[0]);
  }, [imgs]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: { "application/pdf": [".pdf"] },
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles);
      convertPdfToImages(acceptedFiles[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject || customError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, customError]
  );
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "登録証明書", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={1}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            {fileRejections.length == 1 && (
              <Alert variant="danger" className="">
                {fileRejections.map(({ file, errors }) => (
                  <li key={file.path}>
                    {file.path} - {file.size} bytes
                    <ol>
                      {errors.map((e) => (
                        <li key={e.code}>
                          {e.code === "file-invalid-type"
                            ? "ファイル形式は、「pdf」のみとなります。"
                            : e.code === "too-many-files"
                            ? "複数のファイルはアップロードできません。"
                            : e.code}
                        </li>
                      ))}
                    </ol>
                  </li>
                ))}
              </Alert>
            )}
            {fileRejections.length > 1 && (
              <Alert variant="danger" className="">
                複数のファイルはアップロードできません。
              </Alert>
            )}

            {customError && (
              <Alert variant="danger" className="">
                {customError}
              </Alert>
            )}
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p className="m-0">
                ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択します。
              </p>
            </div>
            {/* <aside style={thumbsContainer}>
                {: && (
                  <div className="text-center">
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                )}
                {!: && thumbs}
              </aside> */}
            <br />
            {/* <pre>{text && JSON.stringify(text, null, 2)}</pre> */}

            {isPendingOCR && (
              <div className ="progress">
                <div
                  className ="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-label="Animated striped example"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{width: "100%"}}
                ></div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
