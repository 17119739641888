import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import _ from "lodash";
import AddressInput from "../../components/AddressInput";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

function SignupInputVet({ zipError, ownership, edit = false }) {
  const { width } = useWindowDimensions();

  const { handleChange, handleBlur, setFieldValue, setFieldTouched, values, touched, errors } =
    useFormikContext();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);

  return (
    <>
      <Row className="px-lg-3">
      <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          氏名 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          <Row>
            <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
              <Form.Control
                type="text"
                placeholder="姓"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                name="lastName"
                maxLength={14}
                isInvalid={touched.lastName && errors.lastName}
                isValid={touched.lastName && !errors.lastName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Col>

            <Col lg={6} className="ps-sm-1">
              <Form.Control
                type="text"
                placeholder="名"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                name="firstName"
                maxLength={14}
                isInvalid={touched.firstName && errors.firstName}
                isValid={touched.firstName && !errors.firstName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          氏名（フリガナ） <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Row>
            <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
              <Form.Control
                type="text"
                placeholder="セイ"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastKana}
                name="lastKana"
                maxLength={14}
                isInvalid={touched.lastKana && errors.lastKana}
                isValid={touched.lastKana && !errors.lastKana}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastKana}
              </Form.Control.Feedback>
            </Col>

            <Col lg={6} className="ps-sm-1">
              <Form.Control
                type="text"
                placeholder="メイ"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstKana}
                name="firstKana"
                maxLength={14}
                isInvalid={touched.firstKana && errors.firstKana}
                isValid={touched.firstKana && !errors.firstKana}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstKana}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          施設名 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.vet_institute}
            name="vet_institute"
            maxLength={30}
            isInvalid={touched.vet_institute && errors.vet_institute}
            isValid={touched.vet_institute && !errors.vet_institute}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.vet_institute}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          施設の
          <span className="text-nowrap">
            所在地<span className="text-danger">※</span>
          </span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <AddressInput prefix="" setZipError={zipError} />
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          施設の
          <span className="text-nowrap">
            電話番号<span className="text-danger">※</span>
          </span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            name="phone"
            maxLength={14}
            isInvalid={touched.phone && errors.phone}
            isValid={touched.phone && !errors.phone}
            placeholder="ハイフンあり"
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone}
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          性別 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <div>
            <Form.Check
              inline
              name="gender"
              id="gender1"
              label="男性"
              value="男性"
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.gender === "男性" ? true : false}
              isInvalid={touched.gender && errors.gender}
              isValid={touched.gender && !errors.gender}
            />
            <Form.Check
              inline
              name="gender"
              id="gender2"
              label="女性"
              value="女性"
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.gender === "女性" ? true : false}
              isInvalid={touched.gender && errors.gender}
              isValid={touched.gender && !errors.gender}
            />

            <Form.Check
              inline
              name="gender"
              id="gender3"
              label="指定なし"
              value="指定なし"
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.gender === "指定なし" ? true : false}
              isInvalid={touched.gender && errors.gender}
              isValid={touched.gender && !errors.gender}
            />
          </div>

          {touched.gender && errors.gender && (
            <div className="text-danger">{errors.gender}</div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          生年月日
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <DatePicker
            className={`form-control ${
              touched.birthDay && errors.birthDay ? "is-invalid" : ""
            } ${touched.birthDay && !errors.birthDay ? "is-valid" : ""}`}
            format="yyyy/MM/dd"
            locale="ja-JP"
            dayPlaceholder=" 日 "
            monthPlaceholder=" 月 "
            yearPlaceholder="年 "
            onChange={(val) => {
              setFieldValue("birthDay", val);
            }}
            onFocus={() => {
              setFieldTouched("birthDay", true);
            }}
            value={values.birthDay}
            name="birthDay"
          />
          <Form.Control.Feedback type="invalid">
            {errors.birthDay}
          </Form.Control.Feedback>
        </Col>
      </Row>

      {!edit && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              Eメールアドレス <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                name="email"
                maxLength={100}
                isInvalid={touched.email && errors.email}
                isValid={touched.email && !errors.email}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              Eメールアドレス(確認用) <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Control
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailConfirm}
                name="emailConfirm"
                maxLength={150}
                required
                isInvalid={touched.emailConfirm && errors.emailConfirm}
                isValid={touched.emailConfirm && !errors.emailConfirm}
              />
              <Form.Control.Feedback type="invalid">
                {errors.emailConfirm}
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              パスワード <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="半角英数字・記号・8文字以上"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  name="password"
                  maxLength={150}
                  required
                  isInvalid={touched.password && errors.password}
                  isValid={touched.password && !errors.password}
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              パスワード(確認用) <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <InputGroup>
                <Form.Control
                  type={showPasswordC ? "text" : "password"}
                  placeholder="半角英数字・記号・8文字以上"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                  name="passwordConfirm"
                  maxLength={150}
                  isInvalid={touched.passwordConfirm && errors.passwordConfirm}
                  isValid={touched.passwordConfirm && !errors.passwordConfirm}
                />

                <InputGroup.Text
                  onClick={() => setShowPasswordC(!showPasswordC)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={!showPasswordC ? faEyeSlash : faEye} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirm}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default SignupInputVet;
