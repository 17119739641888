import { useEffect, useState } from "react";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";
import { getKomojuError } from "../../utils/KomojuErrors";

export const useCreatePayment = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [data, setData] = useState(null);

  const makePayment = async (komoju_customer_id, amount) => {
    setIsPending(true);
    setError(null);
    const createPayment = httpsCallable(functions, "createPayment");
    const result = createPayment({ komoju_customer_id, amount })
      .then((res) => {
        setIsPending(false);
        setIsConfirmed(true);
        if (res.data.error) {
          setError(
            getKomojuError(res.data.error.code) || res.data.error.message
          );
        } else {
          // console.log('res', res.data);
          setData({ ...res.data });
          return { ...res.data };
        }
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(err);
      });
    // console.log(result)
    return result;
  };

  return { makePayment, isPending, isConfirmed, error, data };
};
