import React, { useState } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ContactUs.module.css";
import _ from "lodash";
import * as yup from "yup";
import { Formik } from "formik";
import { email, phoneSchemaBoth } from "../../utils/InputSchema";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import TramsText from "./TramsText";

function ContactUs() {
  const backData = localStorage.getItem("contactUsForm");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  let initialValues = {
    company: "",
    name: "",
    email: "",
    emailConfirm: "",
    address: "",
    phone: "",
    message: "",
    checkit: [],
  };

  if (backData) {
    initialValues = {
      ...JSON.parse(localStorage.getItem("contactUsForm")),
    };
  }

  const navigate = useNavigate();

  const schema = yup.object().shape({
    company: yup.string(),
    name: yup.string().required("この欄は必須です"),

    email: email(),
    emailConfirm: yup
      .string()
      .required("この欄は必須です")
      .oneOf([yup.ref("email"), null], "Eメールアドレスが一致しません"),
    address: yup.string().required("この欄は必須です"),
    phone: phoneSchemaBoth(),
    message: yup.string().required("この欄は必須です"),
    checkit: yup.array(),
  });

  const handleSubmit = (values) => {
    // console.log("asdf");
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("contactUsForm", serializedState);
      navigate("/contactUsCheck");
    } catch (err) {
      return undefined;
    }
  };

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>お問い合わせ</title>
        <meta property="og:title" content="CKC" />
        <meta name="description" content="CKC" />
      </Helmet>
      <div className="main">
        <div className={styles.contactUs}>
          <div className={`container position-relative`}>
            <div className={`row d-flex justify-content-center ${styles.con}`}>
              <div className="col-12 col-sm-10">
                <div className={styles["contact-card"]}>
                  <p className={styles.title}>お問い合わせフォーム</p>
                  <p className={styles.subtitle}>
                    下のフォームにお問い合わせ内容を記入のうえ、「確認画面へ」
                    <span style={{ marginLeft: "-1%" }}></span>
                    ボタンを押してください。
                    <br className="d-none d-sm-block" />
                    ご入力いただきましたお客様の個人情報は、
                    <span style={{ marginLeft: "-1%" }}></span>
                    本お問い合わせに関する
                    <br className="d-none d-sm-block" />
                    回答以外の目的には利用いたしません。
                  </p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      handleSubmit,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                    }) => (
                      <div className={styles.form}>
                        <Form onSubmit={handleSubmit}>
                          <div className="row mt-4 mt-sm-4">
                            <div className="col-12 col-sm-5 d-flex">
                              <label>企業・団体・組織名</label>
                            </div>
                            <div className="col-12 col-sm-7">
                              <input
                                type="text"
                                className={`${styles.textinpput} ${
                                  touched.company && errors.company
                                    ? styles.invalid
                                    : ""
                                }`}
                                placeholder="株式会社ペット大好き"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.company}
                                name="company"
                                maxLength={28}
                              />
                            </div>
                          </div>

                          <div className="row mt-4 mt-sm-4">
                            <div className="col-12 col-sm-5 d-flex">
                              <label>
                                <span className={styles.req}>必須</span> お名前
                              </label>
                            </div>
                            <div className="col-12 col-sm-7">
                              <input
                                type="text"
                                className={`${styles.textinpput} ${
                                  touched.name && errors.name
                                    ? styles.invalid
                                    : ""
                                }`}
                                placeholder="猫山　犬次郎"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                name="name"
                                maxLength={28}
                              />

                              {touched.name && errors.name && (
                                <small className="text-danger">
                                  {errors.name}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row mt-4 mt-sm-4">
                            <div className="col-12 col-sm-5 d-flex">
                              <label>
                                <span className={styles.req}>必須</span>{" "}
                                メールアドレス
                              </label>
                            </div>
                            <div className="col-12 col-sm-7">
                              <input
                                type="text"
                                className={`${styles.textinpput} ${
                                  touched.email && errors.email
                                    ? styles.invalid
                                    : ""
                                }`}
                                placeholder="mcr_catdog@aaaaaaaaaaa.co.jp"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                name="email"
                                maxLength={100}
                              />
                              {touched.email && errors.email && (
                                <small className="text-danger">
                                  {errors.email}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row mt-4 mt-sm-4">
                            <div className="col-12 col-sm-5 d-flex">
                              <label>
                                <span className={styles.req}>必須</span>{" "}
                                メールアドレス（確認用）
                              </label>
                            </div>
                            <div className="col-12 col-sm-7">
                              <input
                                type="text"
                                className={`${styles.textinpput} ${
                                  touched.emailConfirm && errors.emailConfirm
                                    ? styles.invalid
                                    : ""
                                }`}
                                placeholder="mcr_catdog@aaaaaaaaaaa.co.jp"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.emailConfirm}
                                name="emailConfirm"
                                maxLength={100}
                              />
                              {touched.emailConfirm && errors.emailConfirm && (
                                <small className="text-danger">
                                  {errors.emailConfirm}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row mt-4 mt-sm-4">
                            <div className="col-12 col-sm-5 d-flex">
                              <label>
                                <span className={styles.req}>必須</span>{" "}
                                電話番号
                              </label>
                            </div>
                            <div className="col-12 col-sm-7">
                              <input
                                type="text"
                                className={`${styles.textinpput} ${
                                  touched.phone && errors.phone
                                    ? styles.invalid
                                    : ""
                                }`}
                                placeholder="000-0000-0000"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                name="phone"
                                maxLength={100}
                              />
                              {touched.phone && errors.phone && (
                                <small className="text-danger">
                                  {errors.phone}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row mt-4 mt-sm-4">
                            <div className="col-5 d-flex">
                              <label className={styles.textarealabel}>
                                <span className={styles.req}>必須</span> 住所
                              </label>
                            </div>
                            <div className="col-12 col-sm-7">
                              <textarea
                                className={`${styles.textareainput} ${
                                  touched.phone && errors.phone
                                    ? styles.invalid
                                    : ""
                                }`}
                                placeholder="東京都千代田区神田000-000 ペットハイム505"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="address"
                                maxLength={200}
                                defaultValue={values.address}
                              ></textarea>

                              {touched.address && errors.address && (
                                <small className="text-danger">
                                  {errors.address}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="row mt-4 mt-sm-4">
                            <div className="col-12 col-sm-5 d-flex">
                              <label className={styles.textarealabel}>
                                <span className={styles.req}>必須</span>{" "}
                                お問い合わせ内容
                              </label>
                            </div>
                            <div className="col-12 col-sm-7">
                              <textarea
                                className={`${styles.textareainput} ${
                                  touched.message && errors.message
                                    ? styles.invalid
                                    : ""
                                }`}
                                placeholder="ああああああああああああああああああああああああああ"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="message"
                                maxLength={300}
                                defaultValue={values.message}
                              ></textarea>

                              {touched.message && errors.message && (
                                <small className="text-danger">
                                  {errors.message}
                                </small>
                              )}
                            </div>
                          </div>

                          {/* <div className="row">
                            <div className={styles.alertData}>
                              <p>
                                当サイトにお問い合わせいただくにあたり、
                                <br />
                                <Link to="/privacy-policy">
                                  プライバシーポリシー
                                </Link>
                                をお読みいただき、同意の上送信してください。
                              </p>

                              <label className={styles.checkBoxContainer}>
                                プライバシーポリシーの内容を確認し同意します
                                <input
                                  type="checkbox"
                                  name="checkit"
                                  value="CHECKED"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={_.includes(
                                    values.checkit,
                                    "CHECKED"
                                  )}
                                />
                                <span className={styles.checkmark}></span>
                              </label>
                            </div>
                          </div> */}
                          <div className="row d-flex justify-content-center">
                            {/* {_.includes(values.checkit, "CHECKED") && ( */}
                            <button
                              type="button"
                              className={`${styles.backbtn} mt-4`}
                              onClick={handleShow}
                            >
                              確認画面へ
                            </button>
                            {/* )} */}

                            {/* {!_.includes(values.checkit, "CHECKED") && ( 
                              <button
                                className={`${styles.backbtn} ${styles.disabled} mt-4`}
                                onClick={handleShow}
                              >
                                確認画面へ
                              </button>
                             )} */}
                          </div>

                          <Modal
                            show={show}
                            onHide={handleClose}
                            size="lg"
                            scrollable
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                会員規約および
                                <br className="d-block d-sm-none" />
                                個人情報保護方針
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <TramsText />
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                閉じる
                              </Button>
                              <Button
                                variant="primary"
                                type="submit"
                                onClick={() => {
                                  handleSubmit();
                                  handleClose();
                                }}
                              >
                                同意する{" "}
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <img src="/assets/img/asset12.png" alt="" className={styles.dog} />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
