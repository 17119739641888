import React, { useState, useEffect } from "react";
import { useMCList } from "../../hooks/useMCList";
import styles from "./Home.module.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "react-bootstrap";

export default function News() {
  const [datas, setDatas] = useState({});
  const { documents, isPending, lastDoc, isLast, getMCList } = useMCList();

  const loadMore = () => {
    getMCList({
      _collection: `news`,
      _query: false,
      orderByField: "dateAt",
      orderByOption: "desc",
      _limit: limit,
      _startAfter: lastDoc || false,
    });
  };

  const limit = 5;
  useEffect(() => {
    if (documents) {
      let newdatas = datas;
      documents.forEach((doc) => {
        newdatas = { ...newdatas, [doc.id]: doc };
      });
      // console.log(newdatas);
      setDatas(newdatas);
    }
  }, [documents]);

  useEffect(() => {
    setDatas([]);
    loadMore();
  }, []);

  return (
    <div className="main">
      <div className={`${styles.home}`}>
        <div className={`${styles.news}`}>
          <div className={`mb-3 ${styles.title}`}>
            <p>NEWS</p>
            <span>お知らせ</span>
          </div>

          <div className="container">
            <div className="row d-flex justify-content-center">
              {Object.values(datas) &&
                Object.values(datas).map((item, i) => (
                  <Link
                    to={`/newsDetails/${item.id}`}
                    className="text-decoration-none"
                  >
                    <div className={`col-10 ${styles.item} mx-auto`} key={i}>
                      <div className="col-4">
                        <div className="row d-flex align-items-center">
                          <div className="col-auto">
                            <div
                              className={
                                item.type === "お知らせ"
                                  ? styles.notice
                                  : styles.media
                              }
                            >
                              {item.type}
                            </div>
                          </div>

                          <div className="col-auto ms-1">
                            <div className={styles.date}>
                              {moment(item.date).format("YYYY.MM.DD")}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-9 col-sm-8">
                        <div className={styles.detailsN}>{item.title}</div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>

            {!isLast && (
              <div className="row d-flex justify-content-center mt-4 mt-sm-5">
                <div className="col-12 text-center mt-3">
                  <Button onClick={loadMore} className={styles.gbtn}>
                    もっと見る
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
