import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { usePaymentDetails } from "../hooks/komoju/usePaymentDetails";
import { getBrandName, getDottedCardNumber } from "../pages/creditcard/Util";

function CardDivPaymentID({ payment_id }) {
  const { paymentInfo, isPending, isConfirmed, error, data } =
    usePaymentDetails();

  useEffect(() => {
    paymentInfo(payment_id);
  }, []);

  return (
    <>
      {!data && (
        <>
          {error && JSON.stringify(error)}
          <div className=" placeholder-glow text-lg-start text-center">
            <div className="placeholder" style={{ borderRadius: "14.5px" }}>
              <Cards
                locale={{ valid: "有効期限" }}
                name=""
                number=""
                expiry=""
                cvc=""
                preview={true}
                issuer=""
              />
            </div>
          </div>
        </>
      )}

      {data && (
        <>
          {data.err && (
            <Alert variant="danger" className="text-lg-start text-center">
              {data.err.message}
            </Alert>
          )}
          {data.id && (
            <div className="float-lg-start float-none"> 
              <Cards
                className="ms-0"
                locale={{ valid: "有効期限" }}
                cvc=""
                name={data.metadata.namec || ""}
                number={`${getDottedCardNumber(data.payment_details.brand)} ${
                  data.payment_details.last_four_digits
                }`}
                expiry={`${(data.payment_details.month).toString().padStart(2,0)}${data.payment_details.year}`}
                preview={true}
                issuer={getBrandName(data.payment_details.brand)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CardDivPaymentID;
