import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ManageHotMC from "./ManageHotMC";
import ManageRecommendedMC from "./ManageRecommendedMC";
import RecommendedEditModal from "../../../components/RecommendedEditModal";

function ManageMCs() {
  const [modalData, setModalData] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const recommendedModalHandler = (data) => {
    if (data && data.start_date && data.end_date && data.display_places) {
      setModalData(data);
      setShowModal(true);
    }
  };
  return (
    <>
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <ManageHotMC setModalData={recommendedModalHandler} />
          </Col>
          <Col lg={6} md={12}>
            <ManageRecommendedMC setModalData={recommendedModalHandler} />
          </Col>
        </Row>
      </Container>
      <RecommendedEditModal
        data={modalData}
        show={showModal}
        onHide={() => {
          setModalData(false);
          setModalData(false);
        }}
      />
    </>
  );
}

export default ManageMCs;
