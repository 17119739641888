import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProgressStep from "../../../components/ProgressStep";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";

export default function SubsCheck() {
  const { userData } = useUserDetailsContext();
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "サービス", link: "#" },
              { value: "確認", link: "#" },
              { value: "カードを登録", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={2}
          />
        </Row>
        <Row className="justify-content-center my-5">
          <Col lg={6} md={8}>
            <Table striped bordered responsive="sm">
              <tbody>
                <tr>
                  <th>アカウント名</th>
                  <td>{userData.email}</td>
                </tr>
                <tr>
                  <th>氏名</th>
                  <td>
                    <ruby>
                      {userData.lastName}
                      <rt>{userData.lastKana}</rt>
                    </ruby>{" "}
                    <ruby>
                      {userData.firstName}
                      <rt>{userData.firstKana}</rt>
                    </ruby>{" "}
                    様
                  </td>
                </tr>

                <tr>
                  <th>加入年月日</th>
                  <td>{moment().format("YYYY/MM/DD")}</td>
                </tr>
                <tr>
                  <th>
                    サポート <br />
                    サービス費(月額)
                  </th>
                  <td>
                    ※3ヶ月間の無料期間後お支払いが発生いたします
                    <br />
                    <b>3,980円</b>(税込)
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="text-center mt-4">
              <Link className="btn btn-secondary m-2" to="/mypage/subscription">
                <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
              </Link>
              <Link
                className="btn btn-primary m-2"
                to="/mypage/subscription-card"
              >
                契約へ <FontAwesomeIcon icon={faCircleChevronRight} />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
