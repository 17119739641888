import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import {
  dog_breed,
  cat_breed,
  dog_color,
  cat_color,
} from "../../utils/CatDogData";
import useWindowDimensions from "../../hooks/useWindowDimensions";
function MCDetailsTableAdmin({ fullData = false }) {
  const { width } = useWindowDimensions();
  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);

  const maxInsurance = 150000;

  useEffect(() => {
    if (fullData.animal_group === "犬") {
      setBreed(dog_breed);
      setColor(dog_color);
    } else if (fullData.animal_group === "猫") {
      setBreed(cat_breed);
      setColor(cat_color);
    }
  }, []);
  return (
    <>
      {!fullData.oldMC && (
        <Row className="justify-content-center">
          <Col>
            <h4 className="mt-4"> マイクロチップ装着証明書の入力</h4>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-top border-start `
                } border-secondary p-2`}
              >
                MCの装着日
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-top border-start border-bottom border-end`
                } p-1 border-end border-secondary`}
              >
                {fullData.mcdate}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                MCを装着した施設名
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {fullData.vet_institute}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                MCを装着した施設の
                <span className="text-nowrap">所在地</span>
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                <>
                  <p className="mb-0">
                    〒{fullData.vet_location_zip3}-{fullData.vet_location_zip4}
                  </p>
                  <p className="mb-0">
                    {fullData.vet_location_prefecture}{" "}
                    {fullData.vet_location_addressLine1}
                  </p>
                  <p className="mb-0">{fullData.vet_location_addressLine2}</p>
                  <p className="mb-0">{fullData.vet_location_addressLine3}</p>
                </>
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                MCを装着した施設の
                <span className="text-nowrap">電話番号</span>
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {fullData.vet_phone}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                獣医師の氏名
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {fullData.vet_lastName} {fullData.vet_firstName}
              </Col>
            </Row>

            {fullData.certificateDownloadLink && (
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  装着証明書
                </Col>
                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  <Row className="justify-content-center mb-4">
                    <Col lg={3} md={4}>
                      <a
                        href={fullData.certificateDownloadLink}
                        target="_blank"
                      >
                        <img
                          src={fullData.certificateDownloadLink}
                          alt=""
                          className="w-100"
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}
      <Row className="justify-content-center">
        <Col>
          <h4 className="mt-4"> 所有者情報</h4>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-top border-start `
              } border-secondary p-2`}
            >
              個人または法人
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 &&
                `border-top border-start border-bottom border-end`
              } p-1 border-end border-secondary`}
            >
              {fullData.owner_type === "1" ? "個人" : "法人"}
            </Col>
          </Row>
          {fullData.owner_type === "1" && (
            <>
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  氏名
                </Col>
                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  {fullData.lastName} {fullData.firstName}
                </Col>
              </Row>

              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  氏名（フリガナ）
                </Col>
                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  {fullData.lastKana} {fullData.firstKana}
                </Col>
              </Row>
            </>
          )}
          {fullData.owner_type === "2" && (
            <>
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  法人名
                </Col>
                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  {fullData.corporation_name}
                </Col>
              </Row>

              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  法人名(フリガナ)
                </Col>
                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  {fullData.corporation_kana_name}
                </Col>
              </Row>

              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  代表者の氏名
                </Col>
                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  {fullData.corporation_representative_lastName}{" "}
                  {fullData.corporation_representative_firstName}
                </Col>
              </Row>
            </>
          )}

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              住所
              <br />
              (法人にあっては、事務所の所在地)
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <>
                <p className="mb-0">
                  〒{fullData.owner_location_zip3}-
                  {fullData.owner_location_zip4}
                </p>
                <p className="mb-0">
                  {fullData.owner_location_prefecture}{" "}
                  {fullData.owner_location_addressLine1}
                </p>
                <p className="mb-0">{fullData.owner_location_addressLine2}</p>
                <p className="mb-0">{fullData.owner_location_addressLine3}</p>
              </>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              電話番号
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {fullData.owner_phone}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              電子メールアドレス
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {fullData.owner_email}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col>
          <h4 className="mt-4"> 犬猫情報</h4>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-top border-start `
              } border-secondary p-2`}
            >
              MC番号
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 &&
                `border-top border-start border-bottom border-end`
              } p-1 border-end border-secondary`}
            >
              {fullData.mccode}
            </Col>
          </Row>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              名前
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {fullData.animal_name}
            </Col>
          </Row>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              犬or猫
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 py-2 border-secondary`}
            >
              {fullData.animal_group}
            </Col>
          </Row>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              性別
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 py-2 border-secondary`}
            >
              {fullData.animal_gender}
            </Col>
          </Row>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              品種
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {breed &&
                breed.map((item) => {
                  if (item.value == fullData.animal_breed) {
                    return item.label;
                  }
                })}

              {fullData.animal_breed === "999" && (
                <>{fullData.animal_breed_other}</>
              )}
            </Col>
          </Row>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              毛色
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {color &&
                color.map((item) => {
                  if (item.value == fullData.animal_color) {
                    return item.label;
                  }
                })}

              {fullData.animal_color === "99" && (
                <>{fullData.animal_color_other}</>
              )}
            </Col>
          </Row>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              生年月日
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {fullData.animal_birthDay}
            </Col>
          </Row>
          {fullData.death && (
            <Row className="px-lg-3 text-danger">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                死亡日
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {moment(
                  new Timestamp(
                    fullData.deathDetails.date.seconds,
                    fullData.deathDetails.date.nanoseconds
                  ).toDate()
                ).format("YYYY-MM-DD")}
              </Col>
            </Row>
          )}
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              動物の所在地
            </Col>
            <Col
              lg={9}
              className={`${width < 576 && `border-start border-end`} ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {fullData.animalAddressCheck &&
                fullData.animalAddressCheck[0] !== "on" && (
                  <>
                    <p className="mb-0">
                      〒{fullData.animal_location_zip3}-
                      {fullData.animal_location_zip4}
                    </p>
                    <p className="mb-0">
                      {fullData.animal_location_prefecture}{" "}
                      {fullData.animal_location_addressLine1}
                    </p>
                    <p className="mb-0">
                      {fullData.animal_location_addressLine2}
                    </p>
                    <p className="mb-0">
                      {fullData.animal_location_addressLine3}
                    </p>
                  </>
                )}

              {fullData.animalAddressCheck &&
                fullData.animalAddressCheck[0] === "on" && (
                  <>
                    <p className="mb-0">
                      〒{fullData.owner_location_zip3}-
                      {fullData.owner_location_zip4}
                    </p>
                    <p className="mb-0">
                      {fullData.owner_location_prefecture}{" "}
                      {fullData.owner_location_addressLine1}
                    </p>
                    <p className="mb-0">
                      {fullData.owner_location_addressLine2}
                    </p>
                    <p className="mb-0">
                      {fullData.owner_location_addressLine3}
                    </p>
                  </>
                )}
            </Col>
          </Row>
          {fullData.animal_group === "犬" && (
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                鑑札
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                登録年月日: {fullData.dog_registration_date} <br />
                登録番号: {fullData.dog_registration_number}
              </Col>
            </Row>
          )}
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              備考
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {fullData.animal_note}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              海外出国
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {fullData.overseas === "1"
                ? "国間移動無し／国内に帰国"
                : "海外へ出国"}
            </Col>
          </Row>

          {/* {fullData.price && (
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                価格
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1  border-secondary`}
              >
                {fullData.price}円(税込)
              </Col>
            </Row>
          )} */}
        </Col>
      </Row>

      <>
        {fullData.ownership === "2" && (
          <>
            <Row className="justify-content-center">
              <Col>
                <h4 className="mt-4"> 動物取扱業関連情報</h4>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-top border-start `
                    } border-secondary p-2`}
                  >
                    動物取扱業選択
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 &&
                      `border-top border-start border-bottom border-end`
                    } p-1 border-end border-secondary`}
                  >
                    {fullData.animal_handler_business_type === "1"
                      ? "第一種動物取扱業者"
                      : "第二種動物取扱業者"}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    動物取扱業の情報
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {fullData.animal_handler_business_type === "1" && (
                      <>
                        {_.includes(fullData.animal_handle_1, "1") && (
                          <>
                            販売: {fullData.animal_handler_business_type1_slot1}
                            <br />
                          </>
                        )}

                        {_.includes(fullData.animal_handle_1, "2") && (
                          <>
                            保管: {fullData.animal_handler_business_type1_slot2}
                            <br />
                          </>
                        )}

                        {_.includes(fullData.animal_handle_1, "3") && (
                          <>
                            貸出し: {fullData.animal_handler_business_type1_slot3}
                            <br />
                          </>
                        )}

                        {_.includes(fullData.animal_handle_1, "4") && (
                          <>
                            訓練: {fullData.animal_handler_business_type1_slot4}
                            <br />
                          </>
                        )}

                        {_.includes(fullData.animal_handle_1, "5") && (
                          <>
                            展示: {fullData.animal_handler_business_type1_slot5}
                            <br />
                          </>
                        )}

                        {_.includes(fullData.animal_handle_1, "6") && (
                          <>
                            競りあっせん:{" "}
                            {fullData.animal_handler_business_type1_slot6}
                            <br />
                          </>
                        )}

                        {_.includes(fullData.animal_handle_1, "7") && (
                          <>
                            譲受飼養業:{" "}
                            {fullData.animal_handler_business_type1_slot7}
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {fullData.animal_handler_business_type === "2" && (
                      <>
                        <Row>
                          {_.includes(fullData.animal_handle_2, "1") && (
                            <Col xs={12}>譲渡し</Col>
                          )}
                          {_.includes(fullData.animal_handle_2, "2") && (
                            <Col xs={12}>保管</Col>
                          )}
                          {_.includes(fullData.animal_handle_2, "3") && (
                            <Col xs={12}>貸出し</Col>
                          )}
                          {_.includes(fullData.animal_handle_2, "4") && (
                            <Col xs={12}>訓練</Col>
                          )}
                          {_.includes(fullData.animal_handle_2, "5") && (
                            <Col xs={12}>展示</Col>
                          )}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            {fullData.animal_handler_business_type === "1" &&
              _.includes(fullData.animal_handle_1, "1") && (
                <>
                  <Row className="justify-content-center">
                    <Col>
                      <h4 className="mt-4"> メス親情報</h4>
                      <Row className="px-lg-3">
                        <Col
                          lg={3}
                          className={`bg-secondary bg-opacity-10 ${
                            width < 576 && `border`
                          } ${
                            width >= 576 &&
                            `border-bottom border-top border-start `
                          } border-secondary p-2`}
                        >
                          メス親の取得時期
                        </Col>
                        <Col
                          lg={9}
                          className={`${
                            width < 576 && `border-start border-end`
                          } ${
                            width >= 576 &&
                            `border-top border-start border-bottom border-end`
                          } p-1 border-end border-secondary`}
                        >
                          {fullData.animal_mother_time === "1"
                            ? "令和4年6月1日以降に取得したメス親"
                            : "令和4年5月31日以前から所有しているメス親"}
                        </Col>
                      </Row>

                      <Row className="px-lg-3">
                        <Col
                          lg={3}
                          className={`bg-secondary bg-opacity-10 ${
                            width < 576 && `border`
                          } ${
                            width >= 576 && `border-bottom border-start `
                          } border-secondary p-2`}
                        >
                          メス親のマイクロチップ識別番号
                        </Col>
                        <Col
                          lg={9}
                          className={`${
                            width < 576 &&
                            `border-start border-end border-bottom`
                          } ${
                            width >= 576 &&
                            `border-start border-bottom border-end`
                          } p-1 border-secondary`}
                        >
                          {fullData.animal_mother_mccode}

                          {fullData.animal_mother_mccode_not_reason}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
          </>
        )}
      </>
    </>
  );
}

export default MCDetailsTableAdmin;
