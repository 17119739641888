import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AddressInput from "../../components/AddressInput";
// import { useUserDetailsContext } from "../../context/UserDetailsContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CertificateImageUpload from "./CertificateImageUpload";

function MCInputVet({ setVetLocationZipError, sectionError }) {
  const [certificateImage, setCertificateImage] = useState();
  const [downloadLink, setDownloadLink] = useState(null);
  // const { userData } = useUserDetailsContext();
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
  } = useFormikContext();

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (
      (touched.mcdate && errors.mcdate) ||
      (touched.vet_institute && errors.vet_institute) ||
      (touched.vet_phone && errors.vet_phone) ||
      (touched.vet_lastName && errors.vet_lastName) ||
      (touched.vet_firstName && errors.vet_firstName) ||
      (touched.vet_location_zip3 && errors.vet_location_zip3) ||
      (touched.vet_location_zip4 && errors.vet_location_zip4) ||
      (touched.vet_location_prefecture && errors.vet_location_prefecture) ||
      (touched.vet_location_addressLine1 && errors.vet_location_addressLine1) ||
      (touched.vet_location_addressLine2 && errors.vet_location_addressLine2) ||
      (touched.vet_location_addressLine3 && errors.vet_location_addressLine3) ||
      (touched.certificateDownloadLink && errors.certificateDownloadLink)
    ) {
      sectionError(true);
    } else {
      sectionError(false);
    }
  }, [errors, touched]);

  useEffect(() => {
    if(downloadLink){
      setFieldValue("certificateDownloadLink", downloadLink);
    }
  }, [downloadLink]);

  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          MCの装着日<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          <Form.Group>
            <Form.Control
              type="Date"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mcdate}
              name="mcdate"
              required
              isInvalid={touched.mcdate && errors.mcdate}
              isValid={touched.mcdate && !errors.mcdate}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mcdate}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          MCを装着した施設名 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.vet_institute}
            name="vet_institute"
            maxLength={30}
            isInvalid={touched.vet_institute && errors.vet_institute}
            isValid={touched.vet_institute && !errors.vet_institute}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.vet_institute}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          MCを装着した施設の
          <span className="text-nowrap">
            所在地<span className="text-danger">※</span>
          </span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <AddressInput
            prefix="vet_location_"
            setZipError={setVetLocationZipError}
          />
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          MCを装着した施設の
          <span className="text-nowrap">
            電話番号<span className="text-danger">※</span>
          </span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.vet_phone}
            name="vet_phone"
            maxLength={14}
            isInvalid={touched.vet_phone && errors.vet_phone}
            isValid={touched.vet_phone && !errors.vet_phone}
            required
            placeholder="ハイフンあり"
          />
          <Form.Control.Feedback type="invalid">
            {errors.vet_phone}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          獣医師の氏名 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Row>
            <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
              <Form.Control
                type="text"
                placeholder="姓"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.vet_lastName}
                name="vet_lastName"
                maxLength={14}
                isInvalid={touched.vet_lastName && errors.vet_lastName}
                isValid={touched.vet_lastName && !errors.vet_lastName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.vet_lastName}
              </Form.Control.Feedback>
            </Col>

            <Col lg={6} className="ps-sm-1">
              <Form.Control
                type="text"
                placeholder="名"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.vet_firstName}
                name="vet_firstName"
                maxLength={14}
                isInvalid={touched.vet_firstName && errors.vet_firstName}
                isValid={touched.vet_firstName && !errors.vet_firstName}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.vet_firstName}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          装着証明書
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <CertificateImageUpload
            certificateImage={setCertificateImage}
            imagePending={() => {}}
            downloadLink={setDownloadLink}
            fieldTouched={setFieldTouched}
          />

          <Form.Control
            type="text"
            placeholder="名"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.certificateDownloadLink}
            name="certificateDownloadLink"
            isInvalid={
              touched.certificateDownloadLink && errors.certificateDownloadLink
            }
            isValid={
              touched.certificateDownloadLink && !errors.certificateDownloadLink
            }
            style={{ display: "none" }}
          />

          {touched.certificateDownloadLink &&
            errors.certificateDownloadLink && (
              <small className="text-danger">
                {errors.certificateDownloadLink}
              </small>
            )}
        </Col>
      </Row>
    </>
  );
}

export default MCInputVet;
