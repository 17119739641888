import {
  faCircleChevronLeft,
  faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { arrayUnion } from "firebase/firestore";
import moment from "moment";
import _ from "lodash";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useAddDocs } from "../../hooks/useAddDocs";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";
import { useFirebaseIncrement } from "../../hooks/useFirebaseIncrement";
import { useSetDoc } from "../../hooks/useSetDoc";

import MCDetailsTable from "../../components/MCDetailsTable";
import ProgressStep from "../../components/ProgressStep";
import { useUserDetailsContext } from "../../context/UserDetailsContext";

function TransferConfirm() {
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();
  const { userAnalytics } = useAnalytics();

  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();

  const { firebaseSetDoc, isPending: isPending4, error: error4 } = useSetDoc();
  const { firebaseIncrement, firebaseIncrementMany } = useFirebaseIncrement();
  const { deleteDocument } = useDeleteDocs();

  const navigate = useNavigate();

  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("mcData")) || false
  );

  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo")) || false
  );

  const transferToBreederpet = async () => {
    let microchipData = JSON.parse(localStorage.getItem("mcData"));
    let mcDataAnimalTransfer = JSON.parse(
      localStorage.getItem("mcDataAnimalTransfer")
    );
    const ownersData = {
      PIN: localStorage.getItem("PIN"),
      mccode: microchipData.mccode.toString(),
      relation: "TRANSFER_OWNER", //TRANSFER_OWNER = transfer all data with pin to breederpet
      mcData: {
        ...microchipData.mcData,
        ..._.omit(mcDataAnimalTransfer, ["price", "mccode"])
      },
      prevTransferDetails: {
        prevOwnerId: user.uid,
        prevMCId: microchipData.id,
        prevOwnerName:
          userData.type === "1"
            ? `${userData.lastName} ${userData.firstName}`
            : `${userData.corporation_name}`,
        prevOwnerGroup: userData.group,
        transferAt: new Date()
      },
      purchasePrice: mcDataAnimalTransfer.price
    };
    let ownerData = JSON.parse(localStorage.getItem("userInfo"));

    //add mc in next owner list
    await addADoc(`users/${ownerData.id}/microchip`, ownersData).then(
      async (res) => {
        // console.log("inside", res);
        firebaseIncrement("users", ownerData.id, "totalMC", 1);

        //update relation to TRANSFER in prev breeder list
        await firebaseSetDoc(
          `users/${user.uid}/microchip`,
          {
            relation: "TRANSFERED",
            transferAt: new Date(),

            nextTransferDetails: {
              nextOwnerId: ownerData.id,
              nextMCId: res,
              nextOwnerName:
                ownerData.type === "1"
                  ? `${ownerData.lastName} ${ownerData.firstName}`
                  : `${ownerData.corporation_name}`,
              nextOwnerGroup: ownerData.group,
              transferAt: new Date()
            },
            sellingPrice: mcDataAnimalTransfer.price,
            sellPlatform: false
          },
          microchipData.id,
          true
        );
      }
    );

    //change Current owner in microchip collection
    await firebaseSetDoc(
      "microchip",
      {
        ...microchipData.mcData,
        ..._.omit(mcDataAnimalTransfer, ["price", "mccode"]),
        currentOwnerUID: ownerData.id,
        owners: arrayUnion({
          uid: ownerData.id,
          name:
            ownerData.type === "1"
              ? `${ownerData.lastName} ${ownerData.firstName}`
              : `${ownerData.corporation_name}`,
          group: ownerData.group,
          purchasePrice: mcDataAnimalTransfer.price,
          time: new Date()
        }),
        sellPlatform: false
      },
      microchipData.mccode.toString(),
      true
    );
    const date = moment();
    const yyyy = moment(date).format("YYYY");
    const mm = moment(date).format("MM");
    const dd = moment(date).format("DD");

    userAnalytics(
      user.uid,
      JSON.parse(localStorage.getItem("mcData")),
      yyyy,
      mm,
      dd,
      "T"
    );

    //delete
    localStorage.removeItem("mcData");
    // localStorage.removeItem("userInfo");
    localStorage.removeItem("mccode");
    localStorage.removeItem("mcDataAnimalTransfer");

    //save update trigger
    const updateList = JSON.parse(localStorage.getItem("MY_DATA_1_Up")) || [];
    updateList.push(microchipData.mccode);
    localStorage.setItem("MY_DATA_1_Up", JSON.stringify(updateList));

    //navigate
    await deleteDocument("temp_otp", user.uid).then(() => {
      navigate("/mypage/transfercomplete");
    });
  };
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={
              userInfo.group === "owner"
                ? userData.plan === "LITE"
                  ? [
                      { value: "スキャン", link: "/mypage/transferstart" },
                      { value: "メール", link: "#" },
                      { value: "OTP", link: "#" },
                      { value: "入力", link: "#" },
                      { value: "確認", link: "#" },
                      { value: "支払", link: "#" },
                      { value: "終了", link: "#" }
                    ]
                  : [
                      { value: "スキャン", link: "/mypage/transferstart" },
                      { value: "メール", link: "#" },
                      { value: "OTP", link: "#" },
                      { value: "入力", link: "#" },
                      { value: "確認", link: "#" },
                      { value: "販売契約書", link: "#" },
                      { value: "支払", link: "#" },
                      { value: "終了", link: "#" }
                    ]
                : [
                    { value: "スキャン", link: "/mypage/transferstart" },
                    { value: "メール", link: "#" },
                    { value: "OTP", link: "#" },
                    { value: "入力", link: "#" },
                    { value: "確認", link: "#" },
                    { value: "終了", link: "#" }
                  ]
            }
            activeNo={5}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={8} md={10}>
            {data && (
              <MCDetailsTable
                data={{
                  ...JSON.parse(localStorage.getItem("mcDataAnimalTransfer")),
                  owner_type: userInfo.type,
                  lastName: userInfo.lastName,
                  firstName: userInfo.firstName,
                  lastKana: userInfo.lastKana,
                  firstKana: userInfo.firstKana,
                  corporation_name: userInfo.corporation_name,
                  corporation_kana_name: userInfo.corporation_kana_name,
                  corporation_representative_lastName:
                    userInfo.corporation_representative_lastName,
                  corporation_representative_firstName:
                    userInfo.corporation_representative_firstName,
                  owner_location_zip3: userInfo.zip3,
                  owner_location_zip4: userInfo.zip4,
                  owner_location_prefecture: userInfo.prefecture,
                  owner_location_addressLine1: userInfo.addressLine1,
                  owner_location_addressLine2: userInfo.addressLine2,
                  owner_location_addressLine3: userInfo.addressLine3,
                  owner_phone: userInfo.phone,
                  owner_email: userInfo.email
                }}
                transfer={true}
              />
            )}
          </Col>
        </Row>

        <div className="text-center mt-4">
          <Link className="btn btn-secondary m-2" to="/mypage/transfermcinput">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link>
          {userInfo.group === "owner" && userData.plan === "LITE" && (
            <Link className="btn btn-primary m-2" to="/mypage/transferpay">
              お支払 <FontAwesomeIcon icon={faCircleChevronRight} />
            </Link>
          )}

          {userInfo.group === "owner" && userData.plan !== "LITE" && (
            <Link className="btn btn-primary m-2" to="/mypage/sellingcontract">
              販売契約書 <FontAwesomeIcon icon={faCircleChevronRight} />
            </Link>
          )}

          {userInfo.group === "breederpet" && (
            <>
              {!isPending2 && !isPending4 && (
                <Button
                  variant="primary"
                  className="m-2"
                  onClick={transferToBreederpet}
                >
                  譲渡する <FontAwesomeIcon icon={faCircleChevronRight} />
                </Button>
              )}

              {(isPending2 || isPending4) && (
                <Button variant="primary" className="m-2" disabled>
                  譲渡する <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}

              {(error2 || error4) && (
                <Alert variant="danger" className="text-center">
                  {error2 || error4}
                </Alert>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
}

export default TransferConfirm;
