import React, { useEffect } from "react";
import { useGetDoc } from "../../hooks/useGetDoc";

function ProcessCSV() {
  const { getSingleDocWithQuery, data, isPending, error } = useGetDoc();

  useEffect(() => {
    const ownerChangeData = JSON.parse(localStorage.getItem("ownerChangeData"));
    ownerChangeData.forEach(async (element) => {
      await getSingleDocWithQuery("microchip", [
        ["mccode", "==", element.マイクロチップの識別番号],
        ["transferStatus", "==", "TRANSFERING"],
      ]);
    });
  }, []);

  return (
    <>
      <pre>{/* {JSON.stringify(ownerChangeData, null, 2)} */}</pre>
    </>
  );
}

export default ProcessCSV;
