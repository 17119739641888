import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

export default function Footer({ powDiv = true }) {
  return (
    <div className={`${styles.footer} footer mt-auto`}>
      {powDiv && (
        <a href="#" className={styles.pow}>
          <img src="/assets/img/art/pow.png" className="w-100" />
        </a>
      )}
      <div className={` position-relative ${styles.footerContainer}`}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-sm-3 d-flex align-items-center justify-content-center jistify-content-sm-start">
              <Link to={"/"}>
                <img
                  src="/assets/img/logo.png"
                  className={`${styles.logo}  d-none d-sm-block`}
                />
              </Link>
              <Link to={"/"}>
                <img
                  src="/assets/img/logom.png"
                  className={`${styles.logo} d-block d-sm-none`}
                />
              </Link>
              <p>
                マイクロチップ
                <br />
                登録システム
              </p>
            </div>

            <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
              {/* <Link to={"/ckc"} className={styles.link1}>
                私たちについて
              </Link> */}
              <Link to={"/news"} className={styles.link1}>
                ニュース
              </Link>
              <Link to={"/contactus"} className={styles.link1}>
                お問い合わせ
              </Link>
            </div>

            <div className="col-9 d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center mt-5">
              <Link to="/company" className={`${styles.link2} ms-sm-0`}>
                運営会社
              </Link>
              <Link to="/trams" className={styles.link2}>
                会員規約および個人情報保護方針
              </Link>
              <Link to="/privacy-policy" className={styles.link2}>
                プライバシーポリシー
              </Link>
              <Link to="/law" className={styles.link2}>
                特定商取引法について
              </Link>
              <Link to="/aid" className={styles.link2}>
                互助制度について
              </Link>
            </div>

            <div className="col-12 d-flex justify-content-center mt-5">
              <p className={styles.copy}>
                Copyright &copy; Pet Next All Right Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
