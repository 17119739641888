//firebase imports
import { increment } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirebaseIncrement } from "./useFirebaseIncrement";
import { useDistributedCounter } from "./useDistributedCounter";

export const useAnalytics = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { firebaseIncrementMany } = useFirebaseIncrement();
  const { dcfunction } = useDistributedCounter();

  const userAnalytics = async (
    uid,
    mcData,
    yyyy,
    mm,
    dd,
    type = "R",
    mny = false,
    admin = true
  ) => {
    let analyticsJSON = {};
    let adminAnalytics = [];
    if (type === "R") {
      analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.mc`]: increment(1) };
      if (admin) {
        adminAnalytics.push(
          {
            collection: "analytics",
            document: `${yyyy}${mm}`,
            field: `${dd}.mc`,
            incrementBy: 1
          },
          {
            collection: "analytics",
            document: `${yyyy}${mm}`,
            field: `total.mc`,
            incrementBy: 1
          }
        );
      }
      if (mcData.animal_group === "犬" && mcData.animal_gender === "オス") {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.dm`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.dm`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.dm`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "犬" &&
        mcData.animal_gender === "メス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.df`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.df`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.df`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "猫" &&
        mcData.animal_gender === "オス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.cm`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.cm`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.cm`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "猫" &&
        mcData.animal_gender === "メス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.cf`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.cf`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.cf`,
              incrementBy: 1
            }
          );
        }
      }
    } else if (type === "T") {
      analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.mct`]: increment(1) };
      if (admin) {
        adminAnalytics.push(
          {
            collection: "analytics",
            document: `${yyyy}${mm}`,
            field: `${dd}.mct`,
            incrementBy: 1
          },
          {
            collection: "analytics",
            document: `${yyyy}${mm}`,
            field: `total.mct`,
            incrementBy: 1
          }
        );
      }
      if (mcData.animal_group === "犬" && mcData.animal_gender === "オス") {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.dmt`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.dmt`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.dmt`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "犬" &&
        mcData.animal_gender === "メス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.dft`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.dft`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.dft`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "猫" &&
        mcData.animal_gender === "オス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.cmt`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.cmt`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.cmt`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "猫" &&
        mcData.animal_gender === "メス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.cft`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.cft`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.cft`,
              incrementBy: 1
            }
          );
        }
      }
    } else if (type === "D") {
      analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.mcd`]: increment(1) };
      if (admin) {
        adminAnalytics.push(
          {
            collection: "analytics",
            document: `${yyyy}${mm}`,
            field: `${dd}.mcd`,
            incrementBy: 1
          },
          {
            collection: "analytics",
            document: `${yyyy}${mm}`,
            field: `total.mcd`,
            incrementBy: 1
          }
        );
      }
      if (mcData.animal_group === "犬" && mcData.animal_gender === "オス") {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.dmd`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.dmd`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.dmd`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "犬" &&
        mcData.animal_gender === "メス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.dfd`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.dfd`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.dfd`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "猫" &&
        mcData.animal_gender === "オス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.cmd`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.cmd`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.cmd`,
              incrementBy: 1
            }
          );
        }
      } else if (
        mcData.animal_group === "猫" &&
        mcData.animal_gender === "メス"
      ) {
        analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.cfd`]: increment(1) };
        if (admin) {
          adminAnalytics.push(
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `${dd}.cfd`,
              incrementBy: 1
            },
            {
              collection: "analytics",
              document: `${yyyy}${mm}`,
              field: `total.cfd`,
              incrementBy: 1
            }
          );
        }
      }
    }

    if (mny) {
      analyticsJSON = { ...analyticsJSON, [`${mm}${dd}.mny`]: increment(mny) };
      // if (admin) {
      adminAnalytics.push(
        {
          collection: "analytics",
          document: `${yyyy}${mm}`,
          field: `${dd}.mny`,
          incrementBy: mny
        },
        {
          collection: "analytics",
          document: `${yyyy}${mm}`,
          field: `total.mny`,
          incrementBy: mny
        }
      );
      // }
    }
    // console.log(analyticsJSON, adminAnalytics);
    await firebaseIncrementMany(
      `users/${uid}/analytics`,
      yyyy.toString(),
      analyticsJSON
    ).then((res) => {
      if (res) {
        setIsPending(false);
        setIsConfirmed(true);
      } else {
        setIsPending(false);
        setIsConfirmed(false);
        setError("FAILD");
      }
    });

    if (adminAnalytics) {
      dcfunction(adminAnalytics);
    }
    // const ref = doc(db, c, yyyy);
    // await updateDoc(ref, incrementJSON);
  };

  return { userAnalytics, isPending, isConfirmed, error };
};
