import { useState } from "react";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";

export const usePlanStart = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const planStart = async (params) => {
    setIsPending(true);
    setIsConfirmed(false);
    setError(null);
    const startSubscription = httpsCallable(functions, "startSubscription");
    return await startSubscription({ ...params })
      .then((res) => {
        // console.log(res);
        setIsPending(false);
        setIsConfirmed(false);
        if(res.data.error){
          setError(res.data.error.message || res.data.error.code);
          return false;
        }else{
          return true;
        }
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(err);
        // console.log(err);
        return false;
      });
  };

  return { planStart, isPending, isConfirmed, error };
};
