import * as yup from "yup";
import _ from "lodash";

import {
  PIN,
  email,
  zip3Schema,
  zip4Schema,
  prefectureSchema,
  addressLine1Schema,
  addressLine2Schema,
  addressLine3Schema,
  phoneSchema,
  phoneSchemaBoth,
  fixedPhoneSchema,
  firstName,
  lastName,
  firstKana,
  lastKana,
  corporation_name,
  corporation_kana_name,
  corporation_representative_lastName,
  corporation_representative_firstName,
  allAnimalSchema
} from "../../utils/InputSchema";
import MCInputSchemaAnimal from "./MCInputSchemaAnimal";

export default function MCInputSchema(
  vetLocationZipError,
  ownerLocationZipError,
  animalLocationZipError,
  oldMC = false
) {
  let schema = yup.object().shape(
    {
      ownership: yup
        .string()
        .required("この欄は必須です")
        .oneOf(["1", "2"], "所有者種別をお選びください"),
      owner_type: yup
        .string()
        .required("この欄は必須です")
        .oneOf(["1", "2"], "個人または法人をお選びください"),
      firstName: firstName("owner_type"),
      lastName: lastName("owner_type"),
      firstKana: firstKana("owner_type"),
      lastKana: lastKana("owner_type"),
      corporation_name: corporation_name("owner_type"),
      corporation_kana_name: corporation_kana_name("owner_type"),
      corporation_representative_lastName:
        corporation_representative_lastName("owner_type"),
      corporation_representative_firstName:
        corporation_representative_firstName("owner_type"),
      owner_location_zip3: zip3Schema(ownerLocationZipError),
      owner_location_zip4: zip4Schema(ownerLocationZipError),
      owner_location_prefecture: prefectureSchema(),
      owner_location_addressLine2: addressLine2Schema(),
      owner_location_addressLine3: addressLine3Schema(),
      owner_fixed_phone: fixedPhoneSchema(),
      owner_phone: phoneSchema(),

      owner_email: email(),

      mccode: yup
        .string()
        .required("この欄は必須です")
        .length(15, "15文字でご入力ください"),

      animal_handle_1: yup
        .array()
        .when(["animal_handler_business_type", "ownership"], {
          is: (animal_handler_business_type, ownership) =>
            animal_handler_business_type === "1" && ownership === "2",
          then: yup
            .array()
            .min(1, "1種類以上は入力")
            .of(yup.string().required())
            .required()
        }),
      animal_handle_2: yup
        .array()
        .when(["animal_handler_business_type", "ownership"], {
          is: (animal_handler_business_type, ownership) =>
            animal_handler_business_type === "2" && ownership === "2",
          then: yup
            .array()
            .min(1, "1種類以上は入力")
            .of(yup.string().required())
            .required()
        }),
      animal_handler_business_type: yup
        .string()
        .required("この欄は必須です")
        .oneOf(["1", "2"], "動物取扱業をお選びください"),

      animal_handler_business_type1_slot1: yup
        .string()
        .when(["animal_handle_1", "animal_handler_business_type"], {
          is: (animal_handle_1, animal_handler_business_type) =>
            _.includes(animal_handle_1, "1") &&
            animal_handler_business_type === "1",
          then: yup
            .string()
            .required("この欄は必須です")
            .max(20, "最小20全角半角混在以上にてご入力ください")
        }),
      animal_handler_business_type1_slot2: yup
        .string()
        .when(["animal_handle_1", "animal_handler_business_type"], {
          is: (animal_handle_1, animal_handler_business_type) =>
            _.includes(animal_handle_1, "2") &&
            animal_handler_business_type === "1",
          then: yup
            .string()
            .required("この欄は必須です")
            .max(20, "最小20全角半角混在以上にてご入力ください")
        }),
      animal_handler_business_type1_slot3: yup
        .string()
        .when(["animal_handle_1", "animal_handler_business_type"], {
          is: (animal_handle_1, animal_handler_business_type) =>
            _.includes(animal_handle_1, "3") &&
            animal_handler_business_type === "1",
          then: yup
            .string()
            .required("この欄は必須です")
            .max(20, "最小20全角半角混在以上にてご入力ください")
        }),
      animal_handler_business_type1_slot4: yup
        .string()
        .when(["animal_handle_1", "animal_handler_business_type"], {
          is: (animal_handle_1, animal_handler_business_type) =>
            _.includes(animal_handle_1, "4") &&
            animal_handler_business_type === "1",
          then: yup
            .string()
            .required("この欄は必須です")
            .max(20, "最小20全角半角混在以上にてご入力ください")
        }),
      animal_handler_business_type1_slot5: yup
        .string()
        .when(["animal_handle_1", "animal_handler_business_type"], {
          is: (animal_handle_1, animal_handler_business_type) =>
            _.includes(animal_handle_1, "5") &&
            animal_handler_business_type === "1",
          then: yup
            .string()
            .required("この欄は必須です")
            .max(20, "最小20全角半角混在以上にてご入力ください")
        }),
      animal_handler_business_type1_slot6: yup
        .string()
        .when(["animal_handle_1", "animal_handler_business_type"], {
          is: (animal_handle_1, animal_handler_business_type) =>
            _.includes(animal_handle_1, "6") &&
            animal_handler_business_type === "1",
          then: yup
            .string()
            .required("この欄は必須です")
            .max(20, "最小20全角半角混在以上にてご入力ください")
        }),
      animal_handler_business_type1_slot7: yup
        .string()
        .when(["animal_handle_1", "animal_handler_business_type"], {
          is: (animal_handle_1, animal_handler_business_type) =>
            _.includes(animal_handle_1, "7") &&
            animal_handler_business_type === "1",
          then: yup
            .string()
            .required("この欄は必須です")
            .max(20, "最小20全角半角混在以上にてご入力ください")
        }),
      animal_mother_time: yup
        .string()
        .when(
          ["ownership", "animal_handler_business_type", "animal_handle_1"],
          {
            is: (ownership, animal_handler_business_type, animal_handle_1) =>
              ownership === "2" &&
              animal_handler_business_type === "1" &&
              _.includes(animal_handle_1, "1"),
            then: yup
              .string()
              .required("この欄は必須です")
              .oneOf(["1", "2"], "メス親の取得時期をお選びください")
          }
        ),
      animal_mother_mccode: yup
        .string()
        .when(
          [
            "ownership",
            "animal_handler_business_type",
            "animal_handle_1",
            "animal_mother_mccode_not_reason"
          ],
          {
            is: (
              ownership,
              animal_handler_business_type,
              animal_handle_1,
              animal_mother_mccode_not_reason
            ) =>
              ownership === "2" &&
              animal_handler_business_type === "1" &&
              _.includes(animal_handle_1, "1") &&
              (!animal_mother_mccode_not_reason ||
                animal_mother_mccode_not_reason.length === 0),
            then: yup
              .string()
              // .required("少なくとも1つの項目は必須です")
              .length(15, "15文字でご入力ください")
              .when(["animal_mother_time"], {
                is: (animal_mother_time) => _.includes(animal_mother_time, "1"),
                then: yup
                  .string()
                  .required("少なくとも1つの項目は必須です")
                  .length(15, "15文字でご入力ください")
              })
          }
        ),
      animal_mother_mccode_not_reason: yup
        .string()
        .when(
          [
            "ownership",
            "animal_handler_business_type",
            "animal_handle_1",
            "animal_mother_mccode"
          ],
          {
            is: (
              ownership,
              animal_handler_business_type,
              animal_handle_1,
              animal_mother_mccode
            ) =>
              ownership === "2" &&
              animal_handler_business_type === "1" &&
              _.includes(animal_handle_1, "1") &&
              (!animal_mother_mccode || animal_mother_mccode.length === 0),
            then: yup
              .string()
              .max(100, "最小100全角半角混在以上にてご入力ください")
              .when(["animal_mother_time"], {
                is: (animal_mother_time) => _.includes(animal_mother_time, "1"),
                then: yup
                  .string()
                  .required("少なくとも1つの項目は必須です")
                  .max(100, "最小100全角半角混在以上にてご入力ください")
              })
          }
        )
    },
    ["animal_mother_mccode", "animal_mother_mccode_not_reason"]
  );

  schema = schema.shape(allAnimalSchema(animalLocationZipError));

  schema = oldMC
    ? schema.shape({})
    : schema.shape({
        mcdate: yup
          .date()
          .required("この欄は必須です")
          .max(new Date(), "未来の日付を選択できません")
          .when("animal_birthDay", {
            is: (animal_birthDay) => animal_birthDay !== undefined,
            then: yup
              .date()
              .required("この欄は必須です")
              .max(new Date(), "生年月日は未来の日付を選択できません")
              .min(
                yup.ref("animal_birthDay"),
                "MCの装着日を誕生日より前にすることはできません。"
              )
          }),
        vet_firstName: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(14, "最小14全角半角混在以上にてご入力ください")
          .matches(
            /^[一-龠ぁ-ゔァ-ヴーa-zA-Zａ-ｚＡ-Ｚ\s]+$/,
            "全角半角混在のみを入力してください"
          ),
        vet_lastName: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .max(14, "最小14全角半角混在以上にてご入力ください")
          .matches(
            /^[一-龠ぁ-ゔァ-ヴーa-zA-Zａ-ｚＡ-Ｚ\s]+$/,
            "全角半角混在のみを入力してください"
          ),
        vet_institute: yup
          .string()
          .trim("先頭と末尾の空白は使用できません")
          .strict()
          .required("この欄は必須です")
          .max(30, "最小30全角半角混在以上にてご入力ください")
          .matches(
            /^[一-龠ぁ-ゔァ-ヴーa-zA-Zａ-ｚＡ-Ｚ\s]+$/,
            "全角半角混在のみを入力してください"
          ),
        vet_location_zip3: zip3Schema(vetLocationZipError),
        vet_location_zip4: zip4Schema(vetLocationZipError),
        vet_location_prefecture: prefectureSchema(),
        vet_location_addressLine1: addressLine1Schema(),
        vet_location_addressLine2: addressLine2Schema(),
        vet_location_addressLine3: addressLine3Schema(),
        vet_phone: phoneSchemaBoth(),
        certificateDownloadLink: yup.string()
      });

  return { schema };
}
