import styles from "./Home.module.css";
import WOW from "wowjs";
import React, { useEffect } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import moment from "moment";

export default function Home() {
  const { width, height } = useWindowDimensions();
  const { documents: news, isPending, getMultipleDocs } = useCollectionDocs();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

    const isElementXPercentInViewport = function (el, percentVisible) {
      let rect = el.getBoundingClientRect(),
        windowHeight =
          window.innerHeight || document.documentElement.clientHeight;

      return !(
        Math.floor(
          100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
        ) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
          percentVisible
      );
    };

    function mapBetween(currentNum, minAllowed, maxAllowed, min, max) {
      return (
        ((maxAllowed - minAllowed) * (currentNum - min)) / (max - min) +
        minAllowed
      );
    }

    var parallax = document.getElementsByClassName("bgparallax");

    window.addEventListener("scroll", function () {
      [].slice.call(parallax).forEach(function (el, i) {
        if (isElementXPercentInViewport(el, 1)) {
          var pos = mapBetween(
            el.getBoundingClientRect().y,
            el.getAttribute("data-start"),
            el.getAttribute("data-end"),
            -el.getBoundingClientRect().height,
            height
          );
          pos = parseFloat(pos) + parseFloat(el.getAttribute("data-start"));

          var elBackgrounPos = pos + "% 50% ";
          el.style.backgroundPosition = elBackgrounPos;
        }
      });
    });

    getMultipleDocs({
      _collection: `news`,
      _query: false,
      orderByField: "dateAt",
      orderByOption: "desc",
      _limit: 3,
    });
  }, []);

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>Home</title>
      </Helmet>
      <div className="flex-shrink-0">
        <div className={styles.main}>
          <div className={styles.home}>
            <div className={styles.top}>
              <Carousel
                indicators={false}
                controls={false}
                className={`position-absolute w-100 carousel-fade ${styles.slides}`}
                style={{ zIndex: "0" }}
              >
                <Carousel.Item interval={4000}>
                  <img
                    src="/assets/img/slider/slider0.jpg"
                    className="d-none d-sm-block w-100"
                    alt=""
                  />

                  <img
                    src="/assets/img/slider/sm/slider0.jpg"
                    className="d-block d-sm-block w-100"
                    alt=""
                  />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <img
                    src="/assets/img/slider/slider1.jpg"
                    className="d-none d-sm-block w-100"
                    alt=""
                  />

                  <img
                    src="/assets/img/slider/sm/slider1.jpg"
                    className="d-block d-sm-block w-100"
                    alt=""
                  />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <img
                    src="/assets/img/slider/slider2.jpg"
                    className="d-none d-sm-block w-100"
                    alt=""
                  />

                  <img
                    src="/assets/img/slider/sm/slider2.jpg"
                    className="d-block d-sm-block w-100"
                    alt=""
                  />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <img
                    src="/assets/img/slider/slider3.jpg"
                    className="d-none d-sm-block w-100"
                    alt=""
                  />

                  <img
                    src="/assets/img/slider/sm/slider3.jpg"
                    className="d-block d-sm-block w-100"
                    alt=""
                  />
                </Carousel.Item>
              </Carousel>

              <div className={styles.yTxtCon}>
                <div className={styles.yellowTDiv}>
                  <p
                    className={`${styles.yellowT} wow slideAnimeLeftRight slide-in leftAnime`}
                    data-wow-duration="2s"
                    data-wow-delay="1s"
                  >
                    <span
                      className="slide-in_inner leftAnimeInner wow slideAnimeRightLeft"
                      data-wow-duration="2s"
                      data-wow-delay="1s"
                    >
                      人と動物が共に生きる、
                    </span>
                  </p>
                </div>
                <div className={styles.yellowTDiv}>
                  <p
                    className={`${styles.yellowT} wow slideAnimeLeftRight slide-in leftAnime`}
                    data-wow-duration="2s"
                    data-wow-delay="1.5s"
                  >
                    <span
                      className="slide-in_inner leftAnimeInner wow slideAnimeRightLeft"
                      data-wow-duration="2s"
                      data-wow-delay="1.5s"
                    >
                      豊かな社会作りのために。
                    </span>
                  </p>
                </div>
                <div className={styles.mark}>
                  <p className="d-none d-sm-block">
                    犬と猫の
                    <br />
                    マイクロチップ
                    <br />
                    登録システム
                  </p>

                  <p className="d-block d-sm-none">
                    オンラインマイクロチップ登録システム
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.details}>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <p
                      className={`${styles.infoTxt} wow fadeInUp`}
                      data-wow-offset="100"
                      data-wow-delay="1.5s"
                    >
                      マイクロチップで大切な愛犬・愛猫を守る。
                      <br />
                      ペットにも人にもやさしい、
                      <br className="d-block d-sm-none" />
                      社会を目指して。
                    </p>
                  </div>
                </div>

                <div className="row mt-1 mt-sm-5 d-flex justify-content-center">
                  <div className="col-11 col-sm-6">
                    <p className={styles.info_details}>
                      2022年6月1日より、新規に販売する
                      <br className="d-block d-sm-none" />
                      犬および猫には
                      <br className="d-none d-sm-block" />
                      マイクロチップ装着と登録の
                      <br className="d-block d-sm-none" />
                      義務付けが開始されます。
                      <br />
                      私たちはマイクロチップの登録を推進し、
                      <br />
                      愛犬や愛猫たちと飼い主の暮らしが
                      <br />
                      豊かになるように応援していきます。
                    </p>
                    <a href="https://www.sbiprism.co.jp/?gid=4001480000002000" target="_blank" rel="noopener noreferrer">
                      <img
                        src="/assets/img/banner/sbi2.png"
                        width={"60%"}
                        className="text-center mx-auto ms-lg-0 d-block"
                      />
                    </a>
                  </div>
                  <div className="col-10 col-sm-5 position-relative">
                    <img
                      src="/assets/img/art/illust1.png"
                      className={styles.illust}
                    />
                  </div>
                  {/* <div className="col-10 col-sm-11 mt-5 mt-sm-4 d-flex justify-content-center justify-content-sm-start">
                    <Link to={"ckc"} className={styles.exbtn}>
                      CKCについて
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>

            <div className={styles.manual}>
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-11 position-relative">
                    <div className={styles.cardholder}>
                      <div className="row d-flex justify-content-center">
                        <div className="col-12 col-sm-6 text-center">
                          <div className={styles.detailsM}>
                            <p>MCR</p>
                            <p>マイクロチップ登録システム</p>
                            <img src="/assets/img/whats.png" />
                          </div>
                        </div>
                      </div>

                      <div className="row d-flex justify-content-center">
                        <div
                          className={`col-10 ${styles.bgYellow} text-center ${styles.mtx}`}
                        >
                          <p>このシステムでできること</p>
                          <img src="/assets/img/art/cat.png" />
                        </div>

                        <div className="col-12 col-sm-10 text-center mt-sm-3">
                          <p
                            className={`${styles.detailTxt} d-none d-sm-block`}
                          >
                            マイクロチップをスキャンまたは番号を入力することで
                            <br />
                            大切な愛犬・愛猫の情報を登録・管理することができます
                            <br />
                            さらに、登録料のお支払いやデータの
                            <br />
                            アップロードも簡単にご利用いただけます
                          </p>
                          <p
                            className={`${styles.detailTxt} d-block d-sm-none`}
                          >
                            マイクロチップをスキャンすることで大切な
                            <br />
                            犬・猫の情報を登録・管理することができます
                            <br />
                            さらに、登録料のお支払いやデータの
                            <br />
                            アップロードも簡単にご利用いただけます
                          </p>
                        </div>
                      </div>

                      <div className="row d-flex justify-content-center mt-sm-4">
                        <div className={`col-12 col-sm-10 ${styles.items}`}>
                          <div className={styles.item}>
                            <p>
                              エラーを防ぐ
                              <br />
                              チェックシステム
                            </p>
                            <img src="/assets/img/art/1.png" />
                          </div>

                          <div className={styles.item}>
                            <p>
                              安全・安心の
                              <br />
                              決済システム
                            </p>
                            <img src="/assets/img/art/2.png" />
                          </div>

                          <div className={styles.item}>
                            <p>
                              マイページで
                              <br />
                              楽々データ管理
                            </p>
                            <img src="/assets/img/art/3.png" />
                          </div>

                          <div className={styles.item}>
                            <p>
                              重要書類も
                              <br />
                              楽々管理
                            </p>
                            <img src="/assets/img/art/4.png" />
                          </div>

                          <div className={styles.item}>
                            <p>
                              安全・安心の
                              <br />
                              1年保証つき
                            </p>
                            <img src="/assets/img/art/5.png" />
                          </div>

                          <div className={styles.item}>
                            <p>
                              簡単データ
                              <br />
                              アップロード
                            </p>
                            <img src="/assets/img/art/6.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex justify-content-center mt-sm-5">
                  <div className={`col-9 ${styles.t1}`}>
                    <p>
                      迷子や災害で飼い主さんに
                      <br />
                      会えなくなってしまう犬や猫が
                      <br />
                      いなくなるように。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bgparallaxDiv}>
              <div className={styles.bgparallaxDiv1}>
                <div className={styles.maskContainer}>
                  <div
                    className={`${styles.mask} bgparallax`}
                    data-start="45"
                    data-end="85"
                  >
                    <img src="/assets/img/art/a2.png" />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.service}>
              <div className={styles.title}>
                <p>SERVICE</p>
                <span>サービス</span>
              </div>

              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className={styles.serviceInfo}>
                    <ul>
                      <li>マイクロチップの登録、情報管理</li>
                      <li>スムーズなデータアップロード</li>
                      <li>安全・簡単な決済</li>
                      <li>所有権の譲渡</li>
                      <li>各種証明書データの保管・管理</li>
                    </ul>

                    <img
                      src="/assets/img/art/dog.png"
                      className={styles.imgd}
                    />
                    <img
                      src="/assets/img/art/av.png"
                      className={styles.imgav}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-center mt-sm-5">
                  <div className="col-sm-11 col-12 mb-4">
                    <div className={`${styles.cardT} ${styles.bgCardgreen}`}>
                      <p>Veterinarian Veterinarian</p>
                      <img src="/assets/img/art/p1.png" className={styles.df} />
                      <div className="row d-flex justify-content-center">
                        <div className="my-2 my-sm-0 col-12 col-sm-12 p-0 d-flex justify-content-center justify-content-sm-start align-items-center">
                          <img
                            src="/assets/img/art/stethoscope-c.png"
                            className={styles.art}
                          />
                          <p className={`${styles.sub} d-block d-sm-none`}>
                            獣医師
                          </p>
                        </div>
                        <div className="col-6 col-sm-4 p-0 d-none d-sm-block">
                          <p className={styles.sub}>獣医師</p>
                        </div>

                        <div className="col-12 col-sm-8">
                          <div className={styles.items}>
                            <div className={styles.item}>
                              <p>
                                マイページで
                                <br />
                                データ管理が
                                <br />
                                簡単
                              </p>
                            </div>
                            <div className={styles.item}>
                              <p>
                                装着証明書を
                                <br />
                                データで
                                <br />
                                送信できる
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row d-flex justify-content-center mt-5">
                        <div className="col-11 d-flex justify-content-end">
                          <Link to={"/veterinarian"} className={styles.link1}>
                            もっと見る
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-11 col-12 mb-4">
                    <div className={`${styles.cardT} ${styles.bgCardpink}`}>
                      <p className={styles.bgT}>
                        Breeder &<br className="d-block d-sm-none" /> Pet Store
                      </p>
                      <img src="/assets/img/art/p2.png" className={styles.df} />
                      <div className="row d-flex justify-content-center">
                        <div className="my-2 my-sm-0 col-12 col-sm-12 p-0 d-flex justify-content-center justify-content-sm-start align-items-center">
                          <img
                            src="/assets/img/art/home-c.png"
                            className={styles.art}
                          />
                          <p className={`${styles.sub} d-block d-sm-none`}>
                            ブリーダー& ペットショップ
                          </p>
                        </div>
                        <div className="col-6 col-sm-4 p-0 d-none d-sm-block">
                          <p className={styles.sub}>
                            ブリーダー&
                            <br />
                            ペットショップ
                          </p>
                        </div>

                        <div className="col-12 col-sm-8 position-relative">
                          <div className={styles.items}>
                            <div className={`${styles.item} ${styles.pos}`}>
                              <p>
                                <span className={styles.ls1}>
                                  マイクロチップの
                                </span>
                                <br />
                                登録<span className={styles.ls5}>、</span>
                                データ管理
                                <br />が
                                <span className={styles.ls1}>ラクラク</span> !
                              </p>
                            </div>
                            <div className={`${styles.item} ${styles.pos}`}>
                              <p>
                                膨大なデータも
                                <br />
                                一括管理 !
                              </p>
                            </div>

                            <div className={`${styles.item} ${styles.pos}`}>
                              <p>
                                アップロー<span className={styles.ls2}>ド</span>
                                ・
                                <br />
                                決済も簡単
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row d-flex justify-content-center">
                        <div className="col-11 d-flex justify-content-end">
                          <Link to={"/breeder"} className={styles.link2}>
                            もっと見る
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-11 col-12 mb-4"
                    style={{ position: "relative", zIndex: 2 }}
                  >
                    <div className={`${styles.cardT} ${styles.bgCardorange}`}>
                      <p>Pet Owner Pet Owner</p>
                      <img src="/assets/img/art/p3.png" className={styles.df} />
                      <div className="row d-flex justify-content-center">
                        <div className="my-2 my-sm-0 col-12 d-flex justify-content-center justify-content-sm-start align-items-center">
                          <img
                            src="/assets/img/art/avater-c.png"
                            className={styles.art}
                          />
                          <p className={`${styles.sub} d-block d-sm-none`}>
                            飼い主
                          </p>
                        </div>
                        <div className="col-12 col-sm-4 d-none d-sm-block">
                          <p className={styles.sub}>飼い主</p>
                        </div>

                        <div className="col-12 col-sm-8">
                          <div className={styles.items}>
                            <div className={`${styles.item} ${styles.pos}`}>
                              <p>
                                <span className={styles.ls1}>
                                  マイクロチップ
                                </span>
                                <br />
                                情報の変更が
                                <br />
                                かんたん !
                              </p>
                            </div>
                            <div className={`${styles.item} ${styles.pos}`}>
                              <p>
                                保証の情報も
                                <br />
                                管理できる !
                              </p>
                            </div>

                            <div className={`${styles.item} ${styles.pos}`}>
                              <p>
                                譲渡元の
                                <br />
                                身元がわかって
                                <br />
                                安心 !
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row d-flex justify-content-center">
                        <div className="col-11 d-flex justify-content-end">
                          <Link to={"/owner"} className={styles.link3}>
                            もっと見る
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row position-relative d-flex justify-content-center">
                  <div className={styles.bgparallaxDiv2}>
                    <div
                      className={`${styles.mask2} bgparallax`}
                      data-start="60"
                      data-end="99"
                    >
                      {/* <img src="/assets/img/art/a222.png" className="h-100" /> */}
                      <img src="/assets/img/art/a211.png" className="h-100" />
                    </div>
                  </div>
                  <div className="col-12">
                    <p className={styles.end}>
                      出生が明らかになる。
                      <br />
                      辛い環境で生まれ育つ子を
                      <br />
                      なくすために
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.insurance}>
              <div className={styles.title}>
                <p>WARRANTY</p>
                <span>マイクロチップ瑕疵保証</span>
              </div>

              <div className="container position-relative">
                <div
                  className={`row d-flex justify-content-center ${styles.policydiv}`}
                >
                  <div className="col-12 col-sm-10">
                    <div className={styles.policyCard}>
                      <br />
                      <br className="d-none d-sm-block" />
                      <div
                        className={`col-11 col-lg-8 my-4 my-sm-5 ${styles.bgYellow} text-center ${styles.mtx} mx-auto`}
                      >
                        <p>マイクロチップ瑕疵保証について</p>
                      </div>

                      <p className=" col-lg-8  text-center container">
                        マイクロチップ挿入によりマイクロチップが体内を移動して、神経などを傷つけたことにより健康が阻害されたもしくは死亡した場合に{" "}
                        <br />
                        お引渡し日から1年間(※)、1頭当たり販売価格50%もしくは25万円のいずれか低い額までを保証する制度です。
                        <br />
                        <small>
                          ※(お引渡し日から翌年のお引渡し日応答日の前日まで)
                        </small>
                        <br />
                        <br />
                        本アプリを利用して環境省のデータベースに登録した場合、
                        <br className="d-none d-sm-block" />
                        自動的にこの保証が付帯されます。
                      </p>

                      <img
                        src="/assets/img/warning.png"
                        className={`${styles.warning} mt-sm-5 mt-3`}
                      />
                      <p
                        className={`container ${styles.warningTxt} text-center`}
                      >
                        注意事項
                        <br />
                        <br />
                        獣医師による医療過誤（マイクロチップ装着時の過誤）
                        <br className="d-none d-sm-block" />
                        およびマイクロチップ自体の生産物賠償
                        <br className="d-none d-sm-block" />
                        （マイクロチップが体内にて壊れたことに起因する障害）は、
                        <br className="d-none d-sm-block" />
                        本保証の範囲ではありませんのでご注意願います。
                      </p>
                      <br />

                      <p className="text-center">
                        ※詳しくは販売店にお問い合わせください
                      </p>

                      <img
                        src="/assets/img/art/cat-policy.png"
                        className={styles.cat1}
                      />
                      <img
                        src="/assets/img/art/cat2.png"
                        className={styles.cat2}
                      />
                    </div>
                  </div>
                </div>
                <img
                  src="/assets/img/asset2.png"
                  alt=""
                  className={styles.art2}
                />
              </div>
            </div>

            <div className={`${styles.news}`}>
              <div className={`mb-3 ${styles.title}`}>
                <p>NEWS</p>
                <span>お知らせ</span>
              </div>

              <div className="container">
                <div className="row d-flex justify-content-center">
                  {news &&
                    news.map((item, i) => (
                      <Link key={i} to={`/newsDetails/${item.id}`} className="text-decoration-none">
                        <div className={`col-10 ${styles.item} mx-auto`} key={i}>
                          <div className="col-4">
                            <div className="row d-flex align-items-center">
                              <div className="col-auto">
                                <div
                                  className={
                                    item.type === "お知らせ"
                                      ? styles.notice
                                      : styles.media
                                  }
                                >
                                  {item.type}
                                </div>
                              </div>

                              <div className="col-auto ms-1">
                                <div className={styles.date}>
                                  {moment(item.date).format("YYYY.MM.DD")}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-9 col-sm-8">
                            <div className={styles.detailsN}>{item.title}</div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>

                <div className="row d-flex justify-content-center mt-4 mt-sm-5">
                  <div className="col-12 text-center mt-3">
                    <Link to={"/news"} className={styles.gbtn}>
                      ニュース一覧
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.info}>
              <div className="container">
                <div className="row">
                  <div className={styles.items}>
                    <Link className="text-decoration-none" to={"/veterinarian"}>
                      <div className={styles.item1}>
                        <img src="/assets/img/art/stethoscope-c.png" />
                        <p>獣医師</p>
                      </div>
                    </Link>
                    <Link className="text-decoration-none" to={"/breeder"}>
                      <div className={styles.item2}>
                        <img src="/assets/img/art/home-c.png" />
                        <p>
                          <span className={styles.ls1}>
                            ブリー&nbsp;ダ&nbsp;ー&nbsp;/
                          </span>
                          <br />
                          <span style={{ letterSpacing: "0px" }}>
                            ペットショップ
                          </span>
                        </p>
                      </div>
                    </Link>

                    <Link className="text-decoration-none" to={"/owner"}>
                      <div className={styles.item3}>
                        <img src="/assets/img/art/avater-c.png" />
                        <p>飼い主</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
