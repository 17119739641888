import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import ProgressStep from "../../../components/ProgressStep";
export default function PersonalInfoConfirm() {
  localStorage.removeItem("stateEdit");
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-md-center">
          <Col lg={6} md={10}>
           
                <Row className="justify-content-center mb-4">
                  <ProgressStep
                    props={[
                      { value: "入力", link: "/mypage/personalinfo" },
                      { value: "確認", link: "#" },
                      { value: "終了", link: "#" },
                    ]}
                    activeNo={3}
                  />
                </Row>
                <Alert variant="success"  className="text-center">
                <p className="m-0">プロフィール変更完了</p>
              </Alert>
          </Col>
        </Row>
      </Container>
    </>
  );
}
