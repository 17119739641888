import moment from "moment";
import React, { useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useSetDoc } from "../hooks/useSetDoc";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFirebaseIncrement } from "../hooks/useFirebaseIncrement";
import { increment, Timestamp } from "firebase/firestore";
import { useAnalytics } from "../hooks/useAnalytics";
import { useToast } from "../hooks/useToast";
import MCDetailsTable from "./MCDetailsTable";

function DeathReportModal(props) {
  const { user } = useAuthContext();
  const { userAnalytics } = useAnalytics();
  const toast = useToast();
  const [isPend, setIsPend] = useState(false);

  const { firebaseSetDoc } = useSetDoc();
  const { firebaseIncrementMany } = useFirebaseIncrement();
  const currentOwnerName =
    props.data &&
    props.data.paymentsData &&
    props.data.paymentsData[props.data.paymentsData.length - 1].next_owner_name;

  const birthDay =
    (props.data && props.data.mcData && props.data.mcData.birthDay) ||
    "1970-01-01";

  let initialValues = {
    date: "",
    cause: "",
  };
  const schema = yup.object().shape({
    date: yup
      .date()
      .required("この欄は必須です")
      .min(
        moment(birthDay).add(0, "days"),
        "死亡日は誕生日よりも後の日付で設定してください。"
      )
      .max(new Date(), "死亡日は現在より過去の日付で設定してください"),
    cause: yup.string().required("この欄は必須です"),
  });
  const handleSubmit = async (values) => {
    //update microchip collections
    setIsPend(true);
    await firebaseSetDoc(
      "microchip",
      {
        death: true,
        deathDetails: {
          date: new Date(values.date),
          Cause: values.cause,
          reportBy: user.uid,
          reporterName: user.displayName,
        },
      },
      props.data.mccode.toString(),
      true
    );
    //update user/{uid}/microchip collection
    await firebaseIncrementMany(
      `users/${user.uid}/microchip`,
      props.data.id.toString(),
      {
        death: true,
        deathDetails: {
          date: new Date(values.date),
          Cause: values.cause,
          reportBy: user.uid,
          reporterName: user.displayName,
        },
      }
    );
    await firebaseIncrementMany(`users`, user.uid, {
      totalDeath: increment(1),
    });
    //update death analytics
    const yyyy = moment(values.date).format("YYYY");
    // const mmdd = moment(values.date).format("MMDD");
    const mm = moment(values.date).format("MM");
    const dd = moment(values.date).format("DD");
    // console.log(values, yyyy, mmdd);

    await userAnalytics(user.uid, props.data.mcData, yyyy, mm, dd, "D");

    props.onHide();
    setIsPend(false);

    props.getUpdatedMCDatabyID(props.data.id.toString())

    toast("success", "死亡日の設定完了");
  };
  return (
    props.data && (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {!props.data.unregistered ? "MC番号" : "ペットID"}
                <span className="text-nowrap">#{props.data.mccode}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    死亡日 <span className="text-danger">※</span>
                  </Form.Label>

                  <Form.Control
                    type="date"
                    format="YYYY-MM-DD"
                    min={moment(birthDay).add(0, "days").format("YYYY-MM-DD")}
                    max={moment().format("YYYY-MM-DD")}
                    placeholder="名"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.date}
                    name="date"
                    maxLength={100}
                    isInvalid={touched.date && errors.date}
                    isValid={touched.date && !errors.date}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    死因 <span className="text-danger">※</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="例: 狂犬病"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cause}
                    name="cause"
                    maxLength={100}
                    isInvalid={touched.cause && errors.cause}
                    isValid={touched.cause && !errors.cause}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cause}
                  </Form.Control.Feedback>
                </Form.Group>
                {!isPend && (
                  <Button variant="danger" type="submit">
                    保存 <FontAwesomeIcon icon={faCircleChevronRight} />
                  </Button>
                )}

                {isPend && (
                  <Button variant="danger" disabled type="button">
                    保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </Form>
              <div className="text-end mt-4"></div>

              <Table striped bordered responsive="sm">
                <tbody>
                  <tr>
                    <th>{!props.data.unregistered ? "MC番号" : "ペットID"}</th>
                    <td>{props.data.mccode}</td>
                  </tr>
                  <tr>
                    <th>登録日時</th>
                    <td>
                      {moment(
                        new Timestamp(
                          props.data.createdAt.seconds,
                          props.data.createdAt.nanoseconds
                        ).toDate()
                      ).format("YYYY/MM/DD HH:mm")}
                    </td>
                  </tr>
                  {props.data.relation === "TRANSFER" && (
                    <>
                      <tr>
                        <th>譲渡日時</th>
                        <td>
                          {moment(
                            new Timestamp(
                              props.data.transferAt.seconds,
                              props.data.transferAt.nanoseconds
                            ).toDate()
                          ).format("YYYY/MM/DD HH:mm")}
                        </td>
                      </tr>
                      {currentOwnerName && (
                        <tr>
                          <th>一般所有の名前</th>
                          <td>{currentOwnerName}</td>
                        </tr>
                      )}
                    </>
                  )}

                </tbody>
              </Table>
                  <MCDetailsTable data={props.data.mcData} fullData={props.data}/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide}>閉じる</Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    )
  );
}

export default DeathReportModal;
