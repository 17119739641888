import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import ProgressStep from "../../../components/ProgressStep";
import RecommendedPetDetails from "../../../components/RecommendedPetDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";

const PetDetails = () => {
  const navigate = useNavigate();
  const mccode = localStorage.getItem("recommended_mc_code");
  const data = JSON.parse(localStorage.getItem("recommended_mc_data"));

  useEffect(() => {
    if (!data) {
      navigate("/mypage/recommended-mc");
    }
  }, []);

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "ペットID", link: "/mypage/recommended-mc" },
              { value: "ペットの詳細", link: "#" },
              { value: "設定", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={2}
          />
        </Row>

        <RecommendedPetDetails mccode={mccode} petData={data} />

        <div className="text-center mt-3">
          <Button
            onClick={() => {
              navigate("/mypage/recommended-mc/");
            }}
            className="mb-2 mx-2"
            variant="danger"
            type="submit"
          >
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
          </Button>

          <Button
            onClick={() => {
              navigate("/mypage/recommended-mc/add-pet-ad-details");
            }}
            className="mb-2 mx-2"
            variant="primary"
            type="submit"
          >
            次へ <FontAwesomeIcon icon={faCircleChevronRight} />
          </Button>
        </div>
      </Container>
    </>
  );
};

PetDetails.propTypes = {};

export default PetDetails;
