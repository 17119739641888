import {
  faCircleArrowDown,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";

import { useGetDoc } from "../../hooks/useGetDoc";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function TransferListItem({ data, modalFun, batchCsvHandler, mcInfo }) {
  const [currentUser, setCurrentUser] = useState();
  const [nextUser, setNextUser] = useState();
  const { width } = useWindowDimensions();

  const {
    firebaseGetDoc,
    getSingleDocWithQuery,
    data: firebaseData,
    isPending,
    error,
  } = useGetDoc();

  useEffect(() => {
    firebaseGetDoc("users", data.currentOwnerUID).then((res) => {
      setCurrentUser(res);
    });

    firebaseGetDoc("users", data.transferToUID).then((res) => {
      setNextUser(res);
    });
  }, []);

  return (
    <>
      <tr>
        <td className="align-middle text-center">
          <Form.Check
            type="checkbox"
            value={data.mccode}
            onChange={(e) => {
              batchCsvHandler(e, nextUser);
            }}
            checked={_.has(mcInfo, data.id)}
          />
        </td>
        <td className="align-middle">{data.mccode}</td>
        <td className="align-middle">
          {currentUser && nextUser && (
            <Row className="justify-content-center position-relative">
              <FontAwesomeIcon
                icon={width > 576 ? faCircleArrowRight : faCircleArrowDown}
                size="2x"
                style={{
                  position: "absolute",
                  transform: "translateY(-50%)",
                  top: "50%",
                  background: "white",
                  width: "fit-content",
                  padding: "0",
                }}
              />
              <Col
                className={`text-lg-end text-center ${
                  width > 576 ? "pe-4" : "pb-4"
                }`}
              >
                <>
                  <span className="mt-1 mb-0">
                    {currentUser.type === "1" && (
                      <>
                        <ruby>
                          <h5>{currentUser.lastName}</h5>
                          <rt>{currentUser.lastKana}</rt>
                        </ruby>{" "}
                        <ruby>
                          <h5>{currentUser.firstName}</h5>
                          <rt>{currentUser.firstKana}</rt>
                        </ruby>
                      </>
                    )}
                    {currentUser.type === "2" && (
                      <ruby className="mt-4">
                        <h5>{currentUser.corporation_name}</h5>
                        <rt>{currentUser.corporation_kana_name}</rt>
                      </ruby>
                    )}
                  </span>
                  <br />
                    {currentUser.email}
                  <br />
                  {currentUser.fixed_phone && (
                    <>
                      {currentUser.fixed_phone}
                    </>
                  )}{" "}
                  {currentUser.phone && (
                    <>
                      {currentUser.phone}
                    </>
                  )}
                </>
              </Col>
              <Col
                className={`text-lg-start text-center ${
                  width > 576 ? "ps-4" : "pt-4"
                }`}
              >
                <>
                  <span className="mt-1 mb-0">
                    {nextUser.type === "1" && (
                      <>
                        <ruby>
                          <h5>{nextUser.lastName}</h5>
                          <rt>{nextUser.lastKana}</rt>
                        </ruby>{" "}
                        <ruby>
                          <h5>{nextUser.firstName}</h5>
                          <rt>{nextUser.firstKana}</rt>
                        </ruby>
                      </>
                    )}
                    {nextUser.type === "2" && (
                      <ruby className="mt-4">
                        <h5>{nextUser.corporation_name}</h5>
                        <rt>{nextUser.corporation_kana_name}</rt>
                      </ruby>
                    )}
                  </span>
                  <br />
                  {nextUser.email}
                  <br />
                  {nextUser.fixed_phone && nextUser.fixed_phone}{" "}
                  {nextUser.phone && nextUser.phone}
                </>
              </Col>
            </Row>
          )}
          {/* -------------------- */}
        </td>

        {/* <td className="align-middle">
          {nextUser && (
            <>
              <Badge bg="secondary" size={"sm"}>
                {nextUser.group === "breederpet" && "ブリーダー/ペットショップ"}
                {nextUser.group === "owner" && "飼い主"}
              </Badge>
              <br />
              <p className="mt-1 mb-0">
                {nextUser.type === "1" && (
                  <>
                    <ruby>
                      {nextUser.lastName}
                      <rt>{nextUser.lastKana}</rt>
                    </ruby>{" "}
                    <ruby>
                      {nextUser.firstName}
                      <rt>{nextUser.firstKana}</rt>
                    </ruby>
                  </>
                )}
                {nextUser.type === "2" && (
                  <ruby className="mt-4">
                    {nextUser.corporation_name}
                    <rt>{nextUser.corporation_kana_name}</rt>
                  </ruby>
                )}
              </p>
            </>
          )}
        </td> */}
        <td className="align-middle">
          <Button
            variant="secondary"
            onClick={() =>
              modalFun({
                mcData: {
                  ...data.transferMCData,
                  owner_type: nextUser.type,
                  lastName: nextUser.lastName,
                  firstName: nextUser.firstName,
                  lastKana: nextUser.lastKana,
                  firstKana: nextUser.firstKana,
                  corporation_name: nextUser.corporation_name,
                  corporation_kana_name: nextUser.corporation_kana_name,
                  corporation_representative_lastName:
                    nextUser.corporation_representative_lastName,
                  corporation_representative_firstName:
                    nextUser.corporation_representative_firstName,
                  owner_location_zip3: nextUser.zip3,
                  owner_location_zip4: nextUser.zip4,
                  owner_location_prefecture: nextUser.prefecture,
                  owner_location_addressLine1: nextUser.addressLine1,
                  owner_location_addressLine2: nextUser.addressLine2,
                  owner_location_addressLine3: nextUser.addressLine3,
                  owner_phone: nextUser.phone,
                  owner_email: nextUser.email,
                  // mccode: data.mccode
                },
              })
            }
          >
            詳細
          </Button>
        </td>
      </tr>
    </>
  );
}

export default TransferListItem;
