import styles from "./Header.module.css";

import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, Spinner } from "react-bootstrap";

import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";
import { Link, useLocation } from "react-router-dom";
import { useUserDetailsContext } from "../context/UserDetailsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { hasActivePlan } from "../utils/Utils";

// import "../main.css"

export default function Header({ setBg }) {
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();
  const { logout, isPending } = useLogout();
  const [showMenu, setShowMenu] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);

  const [loginBtn1, setLoginBtn1] = useState(false);
  const [loginBtn2, setLoginBtn2] = useState(false);
  const [loginBtn3, setLoginBtn3] = useState(false);

  const setNavExpand = () => {
    setNavExpanded(!navExpanded);
  };

  const closeNav = () => {
    setNavExpanded(false);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setBg(true);
    setShowMenu(false);
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return (
    <>
      {/* <Navbar fixed="top" expand="lg" bg="light" variant="light"  onToggle={setNavExpand} expanded={navExpanded}>
        <Container>
          <Navbar.Brand href="#home">PetNext</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {!user && <Nav.Link href="/login" onClick={closeNav}>ログイン</Nav.Link>}
            {user && <Nav.Link href="/mypage" onClick={closeNav}>マイページ</Nav.Link>}
            {user && <Nav.Link onClick={() => { closeNav(); logout();}}>ログアウト</Nav.Link>}
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      <div className={`${styles.navbar} navbar`}>
        <Link to={"/"}>
          <img src="/assets/img/logo.png" className={styles.logo} />
        </Link>
        {!showMenu && !user && (
          <div className={`${styles.menu} d-none d-sm-flex`}>
            <div className={`${styles.menuLink} ${styles.bgGreen}`}>
              <div className={styles.c1}>
                <img src="/assets/img/art/stethoscope.png" />
                <span className="text-nowrap">獣医師</span>
                <div className={`${styles.c2} ${styles.bgGreen}`}>
                  <Link to={"/login?group=vet"} className={styles.cbtn}>
                    ログイン
                  </Link>
                </div>
              </div>
            </div>

            <div className={`${styles.menuLink} ${styles.bgPink}`}>
              <div className={styles.c1}>
                <img src="/assets/img/art/home.png" />
                <span className="text-nowrap">ブリーダー/ペットショップ</span>
                <div className={`${styles.c2} ${styles.bgPink}`}>
                  <Link to={"/login?group=breederpet"} className={styles.cbtn}>
                    ログイン
                  </Link>
                </div>
              </div>
            </div>

            <div className={`${styles.menuLink} ${styles.bgOrange}`}>
              <div className={styles.c1}>
                <img src="/assets/img/art/avater.png" />
                <span className="text-nowrap">飼い主</span>
                <div className={`${styles.c2} ${styles.bgOrange}`}>
                  <Link to={"/login?group=owner"} className={styles.cbtn}>
                    ログイン
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {!showMenu && user && (
          <>
          {/* for breederpet with no plan or expaired */}
          {(userData &&
              userData.group === "breederpet" &&
              !hasActivePlan(userData) &&
              (pathname === "/mypage/plan/aid" ||
                pathname === "/mypage/plan" ||
                pathname === "/mypage/plan/lite" ||
                pathname === "/mypage/plan/standard" ||
                pathname === "/mypage/plan/premium")) ||
            pathname === "/mypage/plan/thankyou" ? (
              <div className={`${styles.menu} d-none d-sm-flex`}>
                <div className={`${styles.menu} d-none d-sm-flex fs-5 mx-auto`}>
                

                  {!isPending && (
                    <Button variant="link" className={`${styles.pathBtn}`} onClick={logout}>
                      ログアウト <FontAwesomeIcon icon={faSignOut} />
                    </Button>
                  )}

                  {isPending && (
                    <Button variant="link" className={`${styles.pathBtn}`}  disabled>
                      ログアウト <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        style={{
                          width: "20px",
                          height: "20px"
                        }}
                      />
                    </Button>
                  )}
                </div>
              </div>
            ) : (
            
            <div className={`${styles.menu} d-none d-sm-flex`}>
              <div
                className={`${styles.menuLink} 
                ${userData && userData.group === "vet" ? styles.bgGreen : ""}
                ${
                  userData && userData.group === "breederpet"
                    ? styles.bgPink
                    : ""
                }
                ${userData && userData.group === "owner" ? styles.bgOrange : ""}

            `}
              >
                <div className={styles.c1}>
                  {userData && userData.group === "vet" && (
                    <img src="/assets/img/art/stethoscope.png" />
                  )}
                  {userData && userData.group === "breederpet" && (
                    <img src="/assets/img/art/home.png" />
                  )}
                  {userData && userData.group === "owner" && (
                    <img src="/assets/img/art/avater.png" />
                  )}

                  <span className="text-nowrap">
                    {userData && userData.group === "vet" && "獣医師"}
                    {userData &&
                      userData.group === "breederpet" &&
                      "ブリーダー/ペットショップ"}
                    {userData && userData.group === "owner" && "飼い主"}
                  </span>
                  <div
                    className={`${styles.c2} 
                   ${userData && userData.group === "vet" ? styles.bgGreen : ""}
                   ${
                     userData && userData.group === "breederpet"
                       ? styles.bgPink
                       : ""
                   }
                   ${
                     userData && userData.group === "owner"
                       ? styles.bgOrange
                       : ""
                   }
                  d-flex`}
                  >
                    <Link
                      to={"/mypage"}
                      className={`${styles.cbtn} mx-1 text-nowrap`}
                    >
                      マイページ
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            )}
          </>
        )}
        <div
          className={showMenu ? styles.hamburgerOpen : styles.hamburger}
          onClick={() => setShowMenu(!showMenu)}
        >
          <a className={`${styles.menu__icon} ${styles.menu_shown}`}>
            <span></span>
          </a>
        </div>
      </div>
      {showMenu && (
        <div className={styles.menuContainer}>
          {/* <div className={styles.hamburgerOpen}>
          <a className={`${styles.menu__icon} ${styles.menu_shown}`}>
            <span></span>
          </a>
        </div> */}
          <div className={styles.menuContainerPosition}>
            <div className="container">
              {/* <img src="/assets/img/logo.png" className={styles.logo} /> */}

              <div className="row">
                <div className={`${styles.items} d-none d-sm-flex`}>
                  <Link
                    className="text-decoration-none"
                    to={"/veterinarian"}
                    onClick={() => setShowMenu(false)}
                  >
                    <div className={styles.item1}>
                      <img src="/assets/img/art/stethoscope-c.png" />
                      <p>獣医師</p>
                    </div>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to={"/breeder"}
                    onClick={() => setShowMenu(false)}
                  >
                    <div className={styles.item2}>
                      <img src="/assets/img/art/home-c.png" />
                      <p>
                        ブリーダー/
                        <br className="d-none d-sm-block" />
                        ペットショップ
                      </p>
                    </div>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to={"/owner"}
                    onClick={() => setShowMenu(false)}
                  >
                    <div className={styles.item3}>
                      <img src="/assets/img/art/avater-c.png" />
                      <p>飼い主</p>
                    </div>
                  </Link>
                </div>
                {!user && (
                  <div className={`${styles.items} d-flex d-sm-none`}>
                    <div
                      className={`${styles.item1} ${
                        loginBtn1 ? styles.active : ""
                      }`}
                      onClick={() => {
                        setLoginBtn1(!loginBtn1);
                        setLoginBtn2(false);
                        setLoginBtn3(false);
                      }}
                    >
                      <div className="d-flex w-100">
                        <img src="/assets/img/art/stethoscope-c.png" />
                        <p>獣医師</p>
                      </div>
                      <div className={`w-100 ${styles.loginBtn}`}>
                        <Link
                          className="text-decoration-none"
                          to={"/login?group=vet"}
                          onClick={() => setShowMenu(false)}
                        >
                          ログイン
                        </Link>
                      </div>
                    </div>

                    <div
                      className={`${styles.item2} ${
                        loginBtn2 ? styles.active : ""
                      }`}
                      onClick={() => {
                        setLoginBtn1(false);
                        setLoginBtn2(!loginBtn2);
                        setLoginBtn3(false);
                      }}
                    >
                      <div className="d-flex w-100">
                        <img src="/assets/img/art/home-c.png" />
                        <p>
                          ブリーダー/
                          <br className="d-none d-sm-block" />
                          ペットショップ
                        </p>
                      </div>

                      <div className={`w-100 ${styles.loginBtn}`}>
                        <Link
                          className="text-decoration-none"
                          to={"/login?group=breederpet"}
                          onClick={() => setShowMenu(false)}
                        >
                          ログイン
                        </Link>
                      </div>
                    </div>
                    <div
                      className={`${styles.item3} ${
                        loginBtn3 ? styles.active : ""
                      }`}
                      onClick={() => {
                        setLoginBtn1(false);
                        setLoginBtn2(false);
                        setLoginBtn3(!loginBtn3);
                      }}
                    >
                      <div className="d-flex w-100">
                        <img src="/assets/img/art/avater-c.png" />
                        <p>飼い主</p>
                      </div>

                      <div className={`w-100 ${styles.loginBtn}`}>
                        <Link
                          className="text-decoration-none"
                          to={"/login?group=owner"}
                          onClick={() => setShowMenu(false)}
                        >
                          ログイン
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {user && userData && userData.group === "vet" && (
                  <div className={`${styles.items} d-flex d-sm-none`}>
                    <div
                      className={`${styles.item1} ${
                        loginBtn1 ? styles.active : ""
                      }`}
                      onClick={() => {
                        setLoginBtn1(!loginBtn1);
                        setLoginBtn2(false);
                        setLoginBtn3(false);
                      }}
                    >
                      <div className="d-flex w-100">
                        <img src="/assets/img/art/stethoscope-c.png" />
                        <p>獣医師</p>
                      </div>
                      <div className={`w-100 ${styles.loginBtn}`}>
                        <Link
                          className="text-decoration-none"
                          to={"/mypage"}
                          onClick={() => setShowMenu(false)}
                        >
                          マイページ
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                {user && userData && userData.group === "breederpet" && (
                  <div className={`${styles.items} d-flex d-sm-none`}>
                    <div
                      className={`${styles.item2} ${
                        loginBtn2 ? styles.active : ""
                      }`}
                      onClick={() => {
                        setLoginBtn1(false);
                        setLoginBtn2(!loginBtn2);
                        setLoginBtn3(false);
                      }}
                    >
                      <div className="d-flex w-100">
                        <img src="/assets/img/art/home-c.png" />
                        <p>
                          ブリーダー/
                          <br className="d-none d-sm-block" />
                          ペットショップ
                        </p>
                      </div>

                      <div className={`w-100 ${styles.loginBtn}`}>
                        <Link
                          className="text-decoration-none"
                          to={"/mypage"}
                          onClick={() => setShowMenu(false)}
                        >
                          マイページ
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                {user && userData && userData.group === "owner" && (
                  <div className={`${styles.items} d-flex d-sm-none`}>
                    <div
                      className={`${styles.item3} ${
                        loginBtn3 ? styles.active : ""
                      }`}
                      onClick={() => {
                        setLoginBtn1(false);
                        setLoginBtn2(false);
                        setLoginBtn3(!loginBtn3);
                      }}
                    >
                      <div className="d-flex w-100">
                        <img src="/assets/img/art/avater-c.png" />
                        <p>飼い主</p>
                      </div>

                      <div className={`w-100 ${styles.loginBtn}`}>
                        <Link
                          className="text-decoration-none"
                          to={"/mypage"}
                          onClick={() => setShowMenu(false)}
                        >
                          マイページ
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                <div className={styles.itemLinks}>
                  {/* <Link to={"/ckc"}>私たちについて</Link> */}
                  <Link to={"/news"} >ニュース</Link>
                  <Link to={"/contactus"}>お問い合わせ</Link>
                </div>
              </div>
            </div>

            {/* <hr className={styles.modHr} /> */}

            <div className="container">
              <div className="row d-block d-sm-flex justify-content-center">
                <div className="col-12 d-block d-sm-flex flex-row justify-content-center mt-3 mx-auto">
                  <Link to="/company" className={`${styles.link2} ms-sm-0`}>
                    運営会社
                  </Link>
                  <Link to="/privacy-policy" className={styles.link2}>
                    プライバシーポリシー
                  </Link>
                  <Link to="/trams" className={styles.link2}>
                    会員規約および個人情報保護方針
                  </Link>
                  <Link to="/law" className={styles.link2}>
                    特定商取引法について
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
