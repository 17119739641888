import { createContext, useContext } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCollection } from "../hooks/useCollection";

const BreederCertificateContext = createContext();

export function BreederCertificateProvider({ children }) {
  const { user } = useAuthContext();
  // const { data: cerData, isPending } = useGetDocSnap("users",user ? user.uid : "NOTING");
  const {documents: cerData} = useCollection(`users/${user.uid}/mcinstall`, false, "createdAt", "desc")
  return (
    <BreederCertificateContext.Provider value={{ cerData }}>
      {children}
    </BreederCertificateContext.Provider>
  );
}

export const useBreederCertificateContext = () => useContext(BreederCertificateContext);
