import React, { useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { email } from "../utils/InputSchema";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useUpdateEmail } from "../hooks/useUpdateEmail";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
export default function ChangeEmail() {
  const { width } = useWindowDimensions();

  const { error, isPending, message, updateEmailFn } = useUpdateEmail();

  let initialValues = {
    email: "",
    emailConfirm: "",
  };

  const handelSubmit = (values) => {
    // console.log("values");
    updateEmailFn(values.email);
  };
  const schema = yup.object().shape({
    email: email(),
    emailConfirm: yup
      .string()
      .required("この欄は必須です")
      .oneOf([yup.ref("email"), null], "Eメールアドレスが一致しません"),
  });
  return (
    <>
      <Container className="pt-3">
        <Row className="justify-content-md-center">
          <Col lg={9} md={12}>
            {error && (
              <Alert variant="danger" dismissible className="text-center">
                {error && <p className="m-0">{error}</p>}
              </Alert>
            )}

            {message && (
              <Alert variant="success" dismissible className="text-center">
                {message && <p className="m-0">{message}</p>}
              </Alert>
            )}

            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handelSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start border-top`
                      } border-secondary p-2`}
                    >
                      新しいメールアドレス <span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${
                        width < 576 && `border-start border-end border-bottom`
                      } ${width >= 576 && `border`} p-1 border-secondary`}
                    >
                      <Form.Control
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                        maxLength={100}
                        isInvalid={touched.email && errors.email}
                        isValid={touched.email && !errors.email}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      新しいメールアドレス
                      <span className="text-nowrap">
                        (確認用) <span className="text-danger">※</span>
                      </span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${
                        width < 576 && `border-start border-end border-bottom`
                      } ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 border-secondary`}
                    >
                      <Form.Control
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.emailConfirm}
                        name="emailConfirm"
                        maxLength={150}
                        required
                        isInvalid={touched.emailConfirm && errors.emailConfirm}
                        isValid={touched.emailConfirm && !errors.emailConfirm}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emailConfirm}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <div className="text-center mt-3">
                    {!isPending && (
                      <Button className="mb-2" variant="primary" type="submit">
                        変更 <FontAwesomeIcon icon={faPenToSquare} />
                      </Button>
                    )}

                    {isPending && (
                      <Button className="mb-2" variant="primary" disabled>
                        変更 <FontAwesomeIcon icon={faPenToSquare} />
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
