import React, { useEffect, useState } from "react";

import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { useGetDoc } from "../../../hooks/useGetDoc";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { Link } from "react-router-dom";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressStep from "../../../components/ProgressStep";
import ReactQuill from "react-quill";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useToast } from "../../../hooks/useToast";

function SellingContractSettingsEdit() {
  const { userData } = useUserDetailsContext();

  const { user } = useAuthContext();

  const toast = useToast();

  const { firebaseSetDoc, isPending: isPending, error: error } = useSetDoc();
  const setToDBSellingContractPage1 = async (page1 = sellingContractPage) => {
    await firebaseSetDoc(
      "users",
      { sellingContractTemplatePage1: page1 },
      user.uid,
      true
    );

    //redirect to success page
    toast("success", "完了しました！");
  };
  const { firebaseGetDoc, isPending: isPending5, error: error5 } = useGetDoc();
  const [sellingContractPage, setSellingContractPage] = useState("");

  const getSellingContractPage1 = async () => {
    if (userData && userData.sellingContractTemplatePage1) {
      console.log(userData.sellingContractTemplatePage1);
      const processedHtml = reduceOnePBrP(
        userData.sellingContractTemplatePage1
      );
      // const processedHtml = userData.sellingContractTemplatePage1.replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");
      // console.log(userData.sellingContractTemplatePage1);

      setSellingContractPage(userData.sellingContractTemplatePage1);
    } else {
      const data = await firebaseGetDoc("admin", "sellingContractTemplate");
      if (data && data.page1) {
        // const processedHtml = reduceOnePBrP(data.page1);

        setSellingContractPage(data.page1);
      }
    }
  };

  function reduceOnePBrP(htmlString) {
    // This pattern matches a series of one or more consecutive <p><br></p>
    const pattern = /(<p><br><\/p>)+/g;

    // Replace the first series of <p><br></p> by removing one instance
    return htmlString.replace(pattern, (match) => {
      // Remove one <p><br></p> from the first matched series
      return match.replace("<p><br></p>", "");
    });
  }

  useEffect(() => {
    getSellingContractPage1();
  }, []);

  //this is the config for rich text editor
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
    ],
    
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "color",
    "background",
  ];

  return (
    <Container className="mt-3">
      <Row className="justify-content-center">
        <Col xl={10} md={12}>
          <div className="d-flex justify-content-between">
            <h4>販売契約書 (保証内容)</h4>
          </div>
          {sellingContractPage && (
            <ReactQuill
              theme="snow"
              modules={modules}
              value={sellingContractPage}
              formats={formats}
              onChange={(value, _, __, editor) => {
                setSellingContractPage(value);
                // console.log(value)
                // if (editor.getLength() === 1) {
                //   setFieldValue("sellPlatformDescription", "");
                // } else {
                //   setFieldValue("sellPlatformDescription", value);
                // }
              }}
              onBlur={() => {
                // setFieldTouched("sellPlatformDescription", true);
              }}
              // className={
              //   // touched.sellPlatformDescription &&
              //   // errors.sellPlatformDescription
              //   //   ? "border border-danger"
              //   //   : ""
              // }
            />
          )}

          <div className="text-center">
            {!isPending && (
              <Button
                variant="primary mt-2"
                onClick={() => setToDBSellingContractPage1()}>
                <FontAwesomeIcon icon={faSave} /> 保存
              </Button>
            )}

            {isPending && (
              <Button variant="primary mt-2" disabled>
                <FontAwesomeIcon icon={faSave} /> 保存
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}

export default SellingContractSettingsEdit;
