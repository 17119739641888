import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styles from "./RatingProgressBar.module.css";

const RatingProgressBar = ({ total_review, gained_review }) => {
  let t_progress = Math.round((gained_review / total_review) * 100);

  return (
    <div className="w-100 my-2">
      <ProgressBar
        now={t_progress}
        label={`${t_progress}%`}
        className={styles.RatingProgressBar}
      >
        <ProgressBar now={t_progress} key={1} variant="warning" />
      </ProgressBar>
    </div>
  );
};

export default RatingProgressBar;
