import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
function CSVFileComplete() {
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center my-5">
          <Col lg={6} md={8}>
            <Alert variant="success" className="text-center">
              完了しました！
            </Alert>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <div className="text-center ">
              <Link className="btn btn-secondary m-2" to="/mypage/csv">
                <>
                  環境省登録済み
                  <small >CSVアップロード</small>へ
                </>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CSVFileComplete;
