import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import ProgressStep from "../../components/ProgressStep";
import { Link, useNavigate } from "react-router-dom";
import AidContent from "../home/AidContent";

function AidScheme() {
  const navigate = useNavigate();

  const [acceptModalShow, setAcceptModalShow] = useState(false);
  const [rejectModalShow, setRejectModalShow] = useState(false);

  const [isSuccessButtonDisabled, setIsSuccessButtonDisabled] = useState(true);
  const modalBodyRef = useRef(null);

  const checkIfScrolledToBottom = () => {
    const { current } = modalBodyRef;
    if (current) {
      const isNearBottom =
        current.scrollHeight - current.scrollTop - current.clientHeight <= 100;
      if (isNearBottom) {
        setIsSuccessButtonDisabled(false);
      }
    }
  };

  // add aid fn
  const addAidFunction = (aid) => {
    localStorage.setItem("aidItem", aid);

    navigate("/mypage/plan");
  };
  return (
    <>
      <div>
        <Container className="position-relative">
          <Row className="justify-content-center mb-4 mt-5 mt-md-0">
            <ProgressStep
              props={[
                { value: "互助制度の選択", link: "#" },
                { value: "プラン選択", link: "#" },
                { value: "支払", link: "#" },
                { value: "終了", link: "#" },
              ]}
              activeNo={1}
              varient={"white"}
            />
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={8} className="">
              <Row className="justify-content-center">
                <Col sm={12}>
                  <h4 className="my-4 text-center">
                    先天性障害瑕疵保証互助制度
                  </h4>
                </Col>

                <Col sm={12} className="bg-white p-3">
                  <p>
                    令和2年（2020年）4月1日から施行された改正民法においては、{" "}
                    <br />
                    皆様が販売された犬猫について、見えざる瑕疵があった場合（既に罹患している感染症や将来発生する先天性障害など）契約不適合責任として、販売した犬猫の修補や
                    代替物の引き渡しなど追加請求、損害賠償請求、契約解除、代金減額請求ができるという規定に整理され、飼い主が販売者に請求等ができることとなりました。
                    <br />
                    また、債務不履行責任についても、販売業者が債務の本旨に従った履行をしないときには、それによって生じた損害の賠償を請求することができるに整理されました。{" "}
                    <br />
                      <br />
                    一般的に、消費者契約法では、上記の契約不適合（瑕疵保証）については、販売後原則１年保証することが明示されています。{" "}
                    <br />
                      <br />
                    勿論、販売契約書において、１年未満にすることも可能ですが、最近の判例では、３ヶ月に責任期間を短縮した場合、否認されるケースも出現しました。{" "}
                    <br />
                    <br />
                    そこで、「LOOKFOR」（子犬販売サイト）を利用していただく会員には{" "}
                    <br />
                    １年間の契約不適合の保証を、飼い主に提供していくことにご賛同いただきたくお願いいたします。{" "}
                    <br />
                    <br />
                    プロフェッショナル会員の皆様が、ご自身で保証していただくことも可能ですが、{" "}
                    <br />
                    この度「先天障害に関わる契約不適合互助会制度」を創設いたしました。{" "}
                    <br />
                      <br />
                    保証期間は販売後１年 <br />
                    保証対象の先天性障害は[別表Ⅰ]のとおりです。 <br />
                    保証料は、１頭あたり年間11.000円（税込） <br />
                    互助制度ですので、原則プロフェッショナル会員の皆様にはご参加いただきます。{" "}
                    <br />
                    （ただし、既に会員が同様の保証を提供されており、本会と同様もしくはそれを上回る制度を運営されている場合は、本互助制度の利用は任意となります。{" "}
                    <br />
                    各ブリーダー様およびペットショップ様毎に互助会を利用するか否かを予め決めて頂きます。互助会利用の場合は、そのブリーダー様およびペットショップ様がMCR/
                    LOOKFORで販売されるすべての生体に互助制度への加入をお願いいたします。）
                    <br />
                      <br />
                    是非とも趣旨ご理解の上、本互助制度への参画をお願いいたします。{" "}
                    <br />
                    <br />
                    [別表Ⅰ]【保証する先天性障害疾患の一覧】 <br />
                  </p>

                  <div className="d-inline w-100">
                    <div className="fit-width d-inline float-start pe-3 pt-3">
                      <small className="no-wrap">大動脈弁狭窄症</small> <br />
                      <small className="no-wrap">心房中隔欠損</small> <br />
                      <small className="no-wrap">短頭型症候群</small> <br />
                      <small className="no-wrap">心臓欠陥</small> <br />
                      <small className="no-wrap">キアリ様奇形</small> <br />
                      <small className="no-wrap">口唇裂</small> <br />
                      <small className="no-wrap">口蓋裂</small> <br />
                      <small className="no-wrap">コリー眼異常</small> <br />
                      <small className="no-wrap">先天性症状</small> <br />
                      <small className="no-wrap">睫毛重生</small> <br />
                      <small className="no-wrap">表皮異形成</small> <br />
                    </div>

                    <div className="fit-width  d-inline float-start pe-3 pt-3">
                      <small className="no-wrap">肝微小血管異形成</small> <br />
                      <small className="no-wrap">僧帽弁異形成</small> <br />
                      <small className="no-wrap">腎異形成</small> <br />
                      <small className="no-wrap">異所性睫毛</small> <br />
                      <small className="no-wrap">眼瞼外反</small> <br />
                      <small className="no-wrap">眼瞼内反</small> <br />
                      <small className="no-wrap">裂孔ヘルニア</small> <br />
                      <small className="no-wrap">無孔涙点</small> <br />
                      <small className="no-wrap">不正咬合</small> <br />
                      <br />
                      <br />
                    </div>

                    <div className="fit-width  d-inline float-start pe-3 pt-3">
                      <small className="no-wrap">重症筋無力症</small> <br />
                      <small className="no-wrap">膝蓋骨脱臼</small> <br />
                      <small className="no-wrap">動脈管開存</small> <br />
                      <small className="no-wrap">門脈体循環シャント</small>
                      <br />
                      <small className="no-wrap">外陰部低形成</small> <br />
                      <small className="no-wrap">網膜異形成</small> <br />
                      <small className="no-wrap">皺襞製皮膚炎</small> <br />
                      <small className="no-wrap">外鼻孔狭窄症</small> <br />
                      <small className="no-wrap">埋伏歯</small> <br />
                      <small className="no-wrap">睫毛乱生</small> <br />
                      <small className="no-wrap">血管輪奇形</small> <br />
                    </div>

                    <div className="fit-width  d-inline float-start pe-3 pt-3">
                      <small className="no-wrap">心室中隔欠損</small> <br />
                      <small className="no-wrap">涙管狭窄</small> <br />
                      <small className="no-wrap">肥大型心筋症</small> <br />
                      <small className="no-wrap">水頭症</small> <br />
                      <small className="no-wrap">肺動脈弁狭窄</small> <br />
                      <small className="no-wrap">若齢性白内障</small> <br />
                      <small className="no-wrap">銅関連性肝障害</small> <br />
                      <small className="no-wrap">角膜ジストロフィー</small>{" "}
                      <br />
                      <small className="no-wrap">肘関節形成異常</small> <br />
                      <small className="no-wrap">股関節形成不全</small> <br />
                      <br />
                    </div>

                    <div className="fit-width  d-inline float-start pe-3 pt-3">
                      <small className="no-wrap">緑内障</small> <br />
                      <small className="no-wrap">レッグペルテス病</small> <br />
                      <small className="no-wrap">離断性骨軟骨炎</small> <br />
                      <small className="no-wrap">血小板障害</small> <br />
                      <small className="no-wrap">進行性網膜萎縮症</small> <br />
                      <small className="no-wrap">
                        フォン・ウィルブランド病
                      </small>{" "}
                      <br />
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="bg-white text-center">
                  <hr />

                  <Button
                    variant="outline-danger"
                    className="me-3"
                    onClick={() => setRejectModalShow(true)}>
                    加入しない
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => setAcceptModalShow(true)}>
                    加入する
                  </Button>
                  {/* <Link to={`/mypage/plan`} className="btn btn-secondary">
                    
                  </Link> */}
                  <br />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
      </div>

      {/* accept modal */}
      <Modal
        show={acceptModalShow}
        onHide={() => setAcceptModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            重要事項説明-本互助制度の概要について-
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef} onScroll={checkIfScrolledToBottom}>
          <AidContent />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            setRejectModalShow
            onClick={() => setAcceptModalShow(false)}>
            閉じる
          </Button>
          <Button
            variant="success"
            onClick={() => addAidFunction(true)}
            disabled={isSuccessButtonDisabled}>
            同意する
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Reject modal */}
      <Modal
        show={rejectModalShow}
        onHide={() => setRejectModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable>
        <Modal.Body>
          あとからの加入や途中からの加入はできません。
          <br />
          それでもよろしいでしょうか？
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => setRejectModalShow(false)}>
            戻る
          </Button>
          <Button variant="success" onClick={() => addAidFunction(false)}>
            はい
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AidScheme;
