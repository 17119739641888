import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import { dog_breed, cat_breed, dog_color, cat_color } from "../utils/CatDogData";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useUserDetailsContext } from "../context/UserDetailsContext";

function MCDetailsTable({ data, death, deathDetails, fullData = false, transfer = false }) {
    const { width } = useWindowDimensions();
    const { userData } = useUserDetailsContext();

    const [breed, setBreed] = useState(null);
    const [color, setColor] = useState(null);

    const maxInsurance = 150000;

    useEffect(() => {
        // console.log(fullData)
        if (data.animal_group === "犬") {
            setBreed(dog_breed);
            setColor(dog_color);
        } else if (data.animal_group === "猫") {
            setBreed(cat_breed);
            setColor(cat_color);
        }
    }, []);
    return (
        <>

            {fullData && !fullData.unregistered && (<>
                {!(transfer || data.oldMC) && (
                    <Row className="justify-content-center">
                        <Col>
                            <h4 className="mt-4"> マイクロチップ装着証明書の入力</h4>
                            <Row className="px-lg-3">
                                <Col
                                    lg={3}
                                    className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                        width >= 576 && `border-bottom border-top border-start `
                                    } border-secondary p-2`}
                                >
                                    MCの装着日
                                </Col>
                                <Col
                                    lg={9}
                                    className={`${width < 576 && `border-start border-end`} ${
                                        width >= 576 && `border-top border-start border-bottom border-end`
                                    } p-1 border-end border-secondary`}
                                >
                                    {data.mcdate}
                                </Col>
                            </Row>
                            <Row className="px-lg-3">
                                <Col
                                    lg={3}
                                    className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                        width >= 576 && `border-bottom border-start `
                                    } border-secondary p-2`}
                                >
                                    MCを装着した施設名
                                </Col>
                                <Col
                                    lg={9}
                                    className={`${width < 576 && `border-start border-end border-bottom`} ${
                                        width >= 576 && `border-start border-bottom border-end`
                                    } p-1 border-secondary`}
                                >
                                    {data.vet_institute}
                                </Col>
                            </Row>

                            <Row className="px-lg-3">
                                <Col
                                    lg={3}
                                    className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                        width >= 576 && `border-bottom border-start `
                                    } border-secondary p-2`}
                                >
                                    MCを装着した施設の
                                    <span className="text-nowrap">所在地</span>
                                </Col>
                                <Col
                                    lg={9}
                                    className={`${width < 576 && `border-start border-end`} ${
                                        width >= 576 && `border-start border-bottom border-end`
                                    } p-1 border-secondary`}
                                >
                                    <>
                                        <p className="mb-0">
                                            〒{data.vet_location_zip3}-{data.vet_location_zip4}
                                        </p>
                                        <p className="mb-0">
                                            {data.vet_location_prefecture} {data.vet_location_addressLine1}
                                        </p>
                                        <p className="mb-0">{data.vet_location_addressLine2}</p>
                                        <p className="mb-0">{data.vet_location_addressLine3}</p>
                                    </>
                                </Col>
                            </Row>

                            <Row className="px-lg-3">
                                <Col
                                    lg={3}
                                    className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                        width >= 576 && `border-bottom border-start `
                                    } border-secondary p-2`}
                                >
                                    MCを装着した施設の
                                    <span className="text-nowrap">電話番号</span>
                                </Col>
                                <Col
                                    lg={9}
                                    className={`${width < 576 && `border-start border-end border-bottom`} ${
                                        width >= 576 && `border-start border-bottom border-end`
                                    } p-1 border-secondary`}
                                >
                                    {data.vet_phone}
                                </Col>
                            </Row>

                            <Row className="px-lg-3">
                                <Col
                                    lg={3}
                                    className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                        width >= 576 && `border-bottom border-start `
                                    } border-secondary p-2`}
                                >
                                    獣医師の氏名
                                </Col>
                                <Col
                                    lg={9}
                                    className={`${width < 576 && `border-start border-end border-bottom`} ${
                                        width >= 576 && `border-start border-bottom border-end`
                                    } p-1 border-secondary`}
                                >
                                    {data.vet_lastName} {data.vet_firstName}
                                </Col>
                            </Row>

                            {data.certificateDownloadLink && (
                                <Row className="px-lg-3">
                                    <Col
                                        lg={3}
                                        className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                            width >= 576 && `border-bottom border-start `
                                        } border-secondary p-2`}
                                    >
                                        装着証明書
                                    </Col>
                                    <Col
                                        lg={9}
                                        className={`${width < 576 && `border-start border-end border-bottom`} ${
                                            width >= 576 && `border-start border-bottom border-end`
                                        } p-1 border-secondary`}
                                    >
                                        <Row className="justify-content-center mb-4">
                                            <Col lg={3} md={4}>
                                                <a href={data.certificateDownloadLink} target="_blank">
                                                    <img src={data.certificateDownloadLink} alt="" className="w-100" />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                )}
                <Row className="justify-content-center">
                    <Col>
                        <h4 className="mt-4"> 所有者情報</h4>
                        <Row className="px-lg-3">
                            <Col
                                lg={3}
                                className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                    width >= 576 && `border-bottom border-top border-start `
                                } border-secondary p-2`}
                            >
                                個人または法人
                            </Col>
                            <Col
                                lg={9}
                                className={`${width < 576 && `border-start border-end`} ${
                                    width >= 576 && `border-top border-start border-bottom border-end`
                                } p-1 border-end border-secondary`}
                            >
                                {data.owner_type === "1" ? "個人" : "法人"}
                            </Col>
                        </Row>
                        {data.owner_type === "1" && (
                            <>
                                <Row className="px-lg-3">
                                    <Col
                                        lg={3}
                                        className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                            width >= 576 && `border-bottom border-start `
                                        } border-secondary p-2`}
                                    >
                                        氏名
                                    </Col>
                                    <Col
                                        lg={9}
                                        className={`${width < 576 && `border-start border-end border-bottom`} ${
                                            width >= 576 && `border-start border-bottom border-end`
                                        } p-1 border-secondary`}
                                    >
                                        {data.lastName} {data.firstName}
                                    </Col>
                                </Row>

                                <Row className="px-lg-3">
                                    <Col
                                        lg={3}
                                        className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                            width >= 576 && `border-bottom border-start `
                                        } border-secondary p-2`}
                                    >
                                        氏名（フリガナ）
                                    </Col>
                                    <Col
                                        lg={9}
                                        className={`${width < 576 && `border-start border-end border-bottom`} ${
                                            width >= 576 && `border-start border-bottom border-end`
                                        } p-1 border-secondary`}
                                    >
                                        {data.lastKana} {data.firstKana}
                                    </Col>
                                </Row>
                            </>
                        )}
                        {data.owner_type === "2" && (
                            <>
                                <Row className="px-lg-3">
                                    <Col
                                        lg={3}
                                        className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                            width >= 576 && `border-bottom border-start `
                                        } border-secondary p-2`}
                                    >
                                        法人名
                                    </Col>
                                    <Col
                                        lg={9}
                                        className={`${width < 576 && `border-start border-end border-bottom`} ${
                                            width >= 576 && `border-start border-bottom border-end`
                                        } p-1 border-secondary`}
                                    >
                                        {data.corporation_name}
                                    </Col>
                                </Row>

                                <Row className="px-lg-3">
                                    <Col
                                        lg={3}
                                        className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                            width >= 576 && `border-bottom border-start `
                                        } border-secondary p-2`}
                                    >
                                        法人名(フリガナ)
                                    </Col>
                                    <Col
                                        lg={9}
                                        className={`${width < 576 && `border-start border-end border-bottom`} ${
                                            width >= 576 && `border-start border-bottom border-end`
                                        } p-1 border-secondary`}
                                    >
                                        {data.corporation_kana_name}
                                    </Col>
                                </Row>

                                <Row className="px-lg-3">
                                    <Col
                                        lg={3}
                                        className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                            width >= 576 && `border-bottom border-start `
                                        } border-secondary p-2`}
                                    >
                                        代表者の氏名
                                    </Col>
                                    <Col
                                        lg={9}
                                        className={`${width < 576 && `border-start border-end border-bottom`} ${
                                            width >= 576 && `border-start border-bottom border-end`
                                        } p-1 border-secondary`}
                                    >
                                        {data.corporation_representative_lastName} {data.corporation_representative_firstName}
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Row className="px-lg-3">
                            <Col
                                lg={3}
                                className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                    width >= 576 && `border-bottom border-start `
                                } border-secondary p-2`}
                            >
                                住所
                                <br />
                                (法人にあっては、事務所の所在地)
                            </Col>
                            <Col
                                lg={9}
                                className={`${width < 576 && `border-start border-end`} ${
                                    width >= 576 && `border-start border-bottom border-end`
                                } p-1 border-secondary`}
                            >
                                <>
                                    <p className="mb-0">
                                        〒{data.owner_location_zip3}-{data.owner_location_zip4}
                                    </p>
                                    <p className="mb-0">
                                        {data.owner_location_prefecture} {data.owner_location_addressLine1}
                                    </p>
                                    <p className="mb-0">{data.owner_location_addressLine2}</p>
                                    <p className="mb-0">{data.owner_location_addressLine3}</p>
                                </>
                            </Col>
                        </Row>

                        <Row className="px-lg-3">
                            <Col
                                lg={3}
                                className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                    width >= 576 && `border-bottom border-start `
                                } border-secondary p-2`}
                            >
                                電話番号
                            </Col>
                            <Col
                                lg={9}
                                className={`${width < 576 && `border-start border-end border-bottom`} ${
                                    width >= 576 && `border-start border-bottom border-end`
                                } p-1 border-secondary`}
                            >
                                {data.owner_phone}
                            </Col>
                        </Row>

                        <Row className="px-lg-3">
                            <Col
                                lg={3}
                                className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                    width >= 576 && `border-bottom border-start `
                                } border-secondary p-2`}
                            >
                                電子メールアドレス
                            </Col>
                            <Col
                                lg={9}
                                className={`${width < 576 && `border-start border-end border-bottom`} ${
                                    width >= 576 && `border-start border-bottom border-end`
                                } p-1 border-secondary`}
                            >
                                {data.owner_email}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>)}


            <Row className="justify-content-center">
                <Col>
                    <h4 className="mt-4"> 犬猫情報</h4>
                    {!fullData.unregistered &&
                        (<Row className="px-lg-3">
                            <Col
                                lg={3}
                                className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                    width >= 576 && `border-bottom border-top border-start `
                                } border-secondary p-2`}
                            >
                                MC番号
                            </Col>
                            <Col
                                lg={9}
                                className={`${width < 576 && `border-start border-end`} ${
                                    width >= 576 && `border-top border-start border-bottom border-end`
                                } p-1 border-end border-secondary`}
                            >
                                {data.mccode}
                            </Col>
                        </Row>)
                    }
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${
                            width < 576 && `border`
                            } ${
                            width >= 576 && `border-bottom border-start ${fullData.unregistered && `border-top`}`
                            } border-secondary p-2`}
                        >
                            ペットID
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                            width >= 576 && `border-start border-bottom border-end ${fullData.unregistered && `border-top`}`
                            } p-1 border-secondary`}
                        >
                            {fullData.animalID || data.animalID}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            名前
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 border-secondary`}
                        >
                            {data.animal_name}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            犬or猫
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 py-2 border-secondary`}
                        >
                            {data.animal_group}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            性別
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 py-2 border-secondary`}
                        >
                            {data.animal_gender}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            品種
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 border-secondary`}
                        >
                            {breed &&
                                breed.map((item) => {
                                    if (item.value == data.animal_breed) {
                                        return item.label;
                                    }
                                })}

                            {data.animal_breed === "999" && <>{data.animal_breed_other}</>}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            毛色
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 border-secondary`}
                        >
                            {color &&
                                color.map((item) => {
                                    if (item.value == data.animal_color) {
                                        return item.label;
                                    }
                                })}

                            {data.animal_color === "99" && <>{data.animal_color_other}</>}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            生年月日
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 border-secondary`}
                        >
                            {data.animal_birthDay}

                            {death && (
                                <tr>
                                    <th>死亡日</th>
                                    <td>
                                        {moment(new Timestamp(deathDetails.date.seconds, deathDetails.date.nanoseconds).toDate()).format(
                                            "YYYY/MM/DD"
                                        )}
                                    </td>
                                </tr>
                            )}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            動物の所在地
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 border-secondary`}
                        >
                            {data.animalAddressCheck && data.animalAddressCheck[0] !== "on" && (
                                <>
                                    <p className="mb-0">
                                        〒{data.animal_location_zip3}-{data.animal_location_zip4}
                                    </p>
                                    <p className="mb-0">
                                        {data.animal_location_prefecture} {data.animal_location_addressLine1}
                                    </p>
                                    <p className="mb-0">{data.animal_location_addressLine2}</p>
                                    <p className="mb-0">{data.animal_location_addressLine3}</p>
                                </>
                            )}

                            {data.animalAddressCheck && data.animalAddressCheck[0] === "on" && (
                                
                                <>
                                    {!fullData.unregistered ? (
                                        <>
                                            <p className="mb-0">
                                                〒{data.owner_location_zip3}-{data.owner_location_zip4}
                                            </p>
                                            <p className="mb-0">
                                                {data.owner_location_prefecture} {data.owner_location_addressLine1}
                                            </p>
                                            <p className="mb-0">{data.owner_location_addressLine2}</p>
                                            <p className="mb-0">{data.owner_location_addressLine3}</p>
                                        </>
                                        ) : (
                                        <>
                                            <p className="mb-0">
                                            〒{userData.zip3}-{userData.zip4}
                                            </p>
                                            <p className="mb-0">
                                            {userData.prefecture} {userData.addressLine1}
                                            </p>
                                            <p className="mb-0">{userData.addressLine2}</p>
                                            <p className="mb-0">{userData.addressLine3}</p>
                                        </>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    {data.animal_group === "犬" && (
                        <Row className="px-lg-3">
                            <Col
                                lg={3}
                                className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                    width >= 576 && `border-bottom border-start `
                                } border-secondary p-2`}
                            >
                                鑑札
                            </Col>
                            <Col
                                lg={9}
                                className={`${width < 576 && `border-start border-end`} ${
                                    width >= 576 && `border-start border-bottom border-end`
                                } p-1 border-secondary`}
                            >
                                登録年月日: {data.dog_registration_date} <br />
                                登録番号: {data.dog_registration_number}
                            </Col>
                        </Row>
                    )}
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            備考
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end border-bottom`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 border-secondary`}
                        >
                            {data.animal_note}
                        </Col>
                    </Row>

                    {data.overseas && <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            海外出国
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end border-bottom`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1 border-secondary`}
                        >
                            {data.overseas === "1" ? "国間移動無し／国内に帰国" : "海外へ出国"}
                        </Col>
                    </Row>}

                    {/* {data.price && (
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                価格
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1  border-secondary`}
              >
                {data.price}円(税込)
              </Col>
            </Row>
          )} */}
                </Col>
            </Row>
            {!transfer && (
                <>
                    {data.ownership === "2" && (
                        <>
                            <Row className="justify-content-center">
                                <Col>
                                    <h4 className="mt-4"> 動物取扱業関連情報</h4>

                                    <Row className="px-lg-3">
                                        <Col
                                            lg={3}
                                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                                width >= 576 && `border-bottom border-top border-start `
                                            } border-secondary p-2`}
                                        >
                                            動物取扱業選択
                                        </Col>
                                        <Col
                                            lg={9}
                                            className={`${width < 576 && `border-start border-end`} ${
                                                width >= 576 && `border-top border-start border-bottom border-end`
                                            } p-1 border-end border-secondary`}
                                        >
                                            {data.animal_handler_business_type === "1" ? "第一種動物取扱業者" : "第二種動物取扱業者"}
                                        </Col>
                                    </Row>

                                    <Row className="px-lg-3">
                                        <Col
                                            lg={3}
                                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                                width >= 576 && `border-bottom border-start `
                                            } border-secondary p-2`}
                                        >
                                            動物取扱業の情報
                                        </Col>
                                        <Col
                                            lg={9}
                                            className={`${width < 576 && `border-start border-end border-bottom`} ${
                                                width >= 576 && `border-start border-bottom border-end`
                                            } p-1 border-secondary`}
                                        >
                                            {data.animal_handler_business_type === "1" && (
                                                <>
                                                    {_.includes(data.animal_handle_1, "1") && (
                                                        <>
                                                            販売: {data.animal_handler_business_type1_slot1}
                                                            <br />
                                                        </>
                                                    )}

                                                    {_.includes(data.animal_handle_1, "2") && (
                                                        <>
                                                            保管: {data.animal_handler_business_type1_slot2}
                                                            <br />
                                                        </>
                                                    )}

                                                    {_.includes(data.animal_handle_1, "3") && (
                                                        <>
                                                            貸出し: {data.animal_handler_business_type1_slot3}
                                                            <br />
                                                        </>
                                                    )}

                                                    {_.includes(data.animal_handle_1, "4") && (
                                                        <>
                                                            訓練: {data.animal_handler_business_type1_slot4}
                                                            <br />
                                                        </>
                                                    )}

                                                    {_.includes(data.animal_handle_1, "5") && (
                                                        <>
                                                            展示: {data.animal_handler_business_type1_slot5}
                                                            <br />
                                                        </>
                                                    )}

                                                    {_.includes(data.animal_handle_1, "6") && (
                                                        <>
                                                            競りあっせん: {data.animal_handler_business_type1_slot6}
                                                            <br />
                                                        </>
                                                    )}

                                                    {_.includes(data.animal_handle_1, "7") && (
                                                        <>
                                                            譲受飼養業: {data.animal_handler_business_type1_slot7}
                                                            <br />
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {data.animal_handler_business_type === "2" && (
                                                <>
                                                    <Row>
                                                        {_.includes(data.animal_handle_2, "1") && <Col xs={12}>譲渡し</Col>}
                                                        {_.includes(data.animal_handle_2, "2") && <Col xs={12}>保管</Col>}
                                                        {_.includes(data.animal_handle_2, "3") && <Col xs={12}>貸出し</Col>}
                                                        {_.includes(data.animal_handle_2, "4") && <Col xs={12}>訓練</Col>}
                                                        {_.includes(data.animal_handle_2, "5") && <Col xs={12}>展示</Col>}
                                                    </Row>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {data.animal_handler_business_type === "1" && _.includes(data.animal_handle_1, "1") && (
                                <>
                                    <Row className="justify-content-center">
                                        <Col>
                                            <h4 className="mt-4"> メス親情報</h4>
                                            <Row className="px-lg-3">
                                                <Col
                                                    lg={3}
                                                    className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                                        width >= 576 && `border-bottom border-top border-start `
                                                    } border-secondary p-2`}
                                                >
                                                    メス親の取得時期
                                                </Col>
                                                <Col
                                                    lg={9}
                                                    className={`${width < 576 && `border-start border-end`} ${
                                                        width >= 576 && `border-top border-start border-bottom border-end`
                                                    } p-1 border-end border-secondary`}
                                                >
                                                    {data.animal_mother_time === "1"
                                                        ? "令和4年6月1日以降に取得したメス親"
                                                        : "令和4年5月31日以前から所有しているメス親"}
                                                </Col>
                                            </Row>

                                            <Row className="px-lg-3">
                                                <Col
                                                    lg={3}
                                                    className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                                        width >= 576 && `border-bottom border-start `
                                                    } border-secondary p-2`}
                                                >
                                                    メス親のマイクロチップ識別番号
                                                </Col>
                                                <Col
                                                    lg={9}
                                                    className={`${width < 576 && `border-start border-end border-bottom`} ${
                                                        width >= 576 && `border-start border-bottom border-end`
                                                    } p-1 border-secondary`}
                                                >
                                                    {data.animal_mother_mccode}

                                                    {data.animal_mother_mccode_not_reason}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    )}
                </>
            )}

            {fullData && fullData.purchasePrice && (
                <>
                    <h4 className="mt-4"> 購入明細</h4>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-top border-start `
                            } border-secondary p-2`}
                        >
                            価格
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-top border-start border-bottom border-end`
                            } p-1 border-end border-secondary`}
                        >
                            {fullData.purchasePrice.toLocaleString("en-US")}円 (税込)
                        </Col>
                    </Row>

                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            保証
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1  border-secondary`}
                        >
                            {Math.ceil(parseInt(fullData.purchasePrice) / 2).toLocaleString("en-US")} 円 (税込)
                            {/* {parseInt(fullData.purchasePrice) > maxInsurance &&
                `${maxInsurance.toLocaleString("en-US")}円 (税込)`}
              {parseInt(fullData.purchasePrice) <= maxInsurance &&
                `${fullData.purchasePrice.toLocaleString("en-US")}円 (税込)`} */}
                            <br />
                            満了日:{" "}
                            <b>
                                {moment(
                                    moment(new Timestamp(fullData.createdAt.seconds, fullData.createdAt.nanoseconds).toDate())
                                        .add(1, "y")
                                        .subtract(1, "d")
                                ).format("YYYY/MM/DD")}
                            </b>
                            <br />
                            <small>※瑕疵保証は一年間、もしくはマイクロチップの所有権を他人へ譲渡した時までの短い方の期間を対象期間とします</small>
                        </Col>
                    </Row>
                </>
            )}

            {fullData && fullData.sellingPrice && (
                <>
                    <h4 className="mt-4"> 販売明細</h4>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-top border-start `
                            } border-secondary p-2`}
                        >
                            価格
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-top border-start border-bottom border-end`
                            } p-1 border-end border-secondary`}
                        >
                            {fullData.sellingPrice.toLocaleString("en-US")}円 (税込)
                        </Col>
                    </Row>

                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            保証
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1  border-secondary`}
                        >
                            {Math.ceil(parseInt(fullData.sellingPrice) / 2).toLocaleString("en-US")} 円 (税込)
                            {/* {parseInt(fullData.sellingPrice) > maxInsurance &&
                `${maxInsurance.toLocaleString("en-US")}円 (税込)`}
              {parseInt(fullData.sellingPrice) <= maxInsurance &&
                `${fullData.sellingPrice.toLocaleString("en-US")}円 (税込)`} */}
                            <br />
                            満了日:{" "}
                            <b>
                                {moment(
                                    moment(new Timestamp(fullData.transferAt.seconds, fullData.transferAt.nanoseconds).toDate())
                                        .add(1, "y")
                                        .subtract(1, "d")
                                ).format("YYYY/MM/DD")}
                            </b>
                            <br />
                            <small>※瑕疵保証は一年間、もしくはマイクロチップの所有権を他人へ譲渡した時までの短い方の期間を対象期間とします</small>
                        </Col>
                    </Row>
                    <br />
                </>
            )}

            {fullData && fullData.sellPlatform && (
                <>
                    <h4 className="mt-4"> LOOKFOR</h4>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-top border-start `
                            } border-secondary p-2`}
                        >
                            価格
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-top border-start border-bottom border-end`
                            } p-1 border-end border-secondary`}
                        >
                            {parseInt(fullData.sellPlatformPrice).toLocaleString("en-US")}円 (税込)
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            見学地域
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1  border-secondary`}
                        >
                            <p className="mb-0">
                                〒{fullData.sellPlatform_zip3}-{fullData.sellPlatform_zip4}
                            </p>
                            <p className="mb-0">
                                {fullData.sellPlatform_prefecture} {fullData.sellPlatform_addressLine1}
                            </p>
                            <p className="mb-0">{fullData.sellPlatform_addressLine2}</p>
                            <p className="mb-0">{fullData.sellPlatform_addressLine3}</p>
                        </Col>
                    </Row>

                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            生体保証
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1  border-secondary`}
                        >
                            {fullData.guarantee}
                        </Col>
                    </Row>

                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            アピールポイント
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1  border-secondary`}
                        >
                            {fullData.prPoint}
                        </Col>
                    </Row>
                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            成犬時予想
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1  border-secondary`}
                        >
                            体重: {fullData.weight_from}~{fullData.weight_to}kg　体高: {fullData.height_from}~{fullData.height_to} cm
                        </Col>
                    </Row>

                    <Row className="px-lg-3">
                        <Col
                            lg={3}
                            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
                                width >= 576 && `border-bottom border-start `
                            } border-secondary p-2`}
                        >
                            子犬紹介文
                        </Col>
                        <Col
                            lg={9}
                            className={`${width < 576 && `border-start border-end`} ${
                                width >= 576 && `border-start border-bottom border-end`
                            } p-1  border-secondary`}
                        >
                            <div className=" ql-editor" dangerouslySetInnerHTML={{ __html: fullData.sellPlatformDescription }}></div>
                        </Col>
                    </Row>
                    <h4 className="mt-4"> 写真</h4>
                    <Row>
                        {fullData.images &&
                            Object.values(fullData.images).map(
                                (img, i) =>
                                    img && (
                                        <Col md={6} sm={12} key={i} className="mb-3">
                                            <img src={img} className="w-100 shadow rounded" />
                                        </Col>
                                    )
                            )}
                    </Row>
                </>
            )}
        </>
    );
}

export default MCDetailsTable;
