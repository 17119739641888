import { useState } from "react";

export default function useBtnClickLoader() {
  const [isLoading, setIsLoading] = useState(false);
  const startLoadBtn = async (loadingTime = 5000) => {
    setIsLoading(true); // Set pending to true
    setTimeout(() => {
      setIsLoading(false); // Set pending to false after 5 seconds
    }, loadingTime);
  };
  return { isLoading, startLoadBtn };
}
