import { useState } from "react";
import { db } from "../firebase/config";

//firebase imports
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
  startAfter,
  where,
} from "firebase/firestore";

export const useMCList = () => {
  const [documents, setDocuments] = useState(null);
  //   const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false);
  const [lastDoc, setLastDoc] = useState(null)
  const [isLast, setIsLast] = useState(false)

  const getMCList = ({
    _collection,
    _query,
    orderByField,
    orderByOption = "desc",
    _limit,
    _startAfter,
  }) => {
    setDocuments(null)
    setIsLast(false)
    setIsPending(true)
    //set the query
    let ref = collection(db, _collection);
    if (_query) {
      _query.forEach((qitem) => {
        ref = query(ref, where(...qitem));
      });
    }
    if (orderByField) {
      ref = query(ref, orderBy(orderByField, orderByOption));
    }
    if (_limit) {
      ref = query(ref, limit(_limit));
    }

    if (_startAfter) {
      ref = query(ref, startAfter(_startAfter));
    }
    const unsub = onSnapshot(ref, (snapshot) => {

      const source = snapshot.metadata.hasPendingWrites ? "Local" : "Server";
      // console.log(source);


      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setIsPending(false);
      setDocuments(results);
      setLastDoc(snapshot.docs[snapshot.docs.length-1])

      if(snapshot.empty){
        setIsLast(true)
      }
    });

    return () => unsub();
  };
  return { documents, isPending, lastDoc, isLast, getMCList };
};
