import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import Scanner from "../../components/Scanner";
import styles from "./MCStyle.module.css";
import masImage from "./img/masking.png";
import beepSound from "./sound/Barcode-scanner-beep-sound.mp3";

function MCInputAnimalMother({ sectionError }) {
  const { width } = useWindowDimensions();
  const { handleChange, handleBlur, values, touched, errors, setFieldValue } =
    useFormikContext();
  const [show, setShow] = useState(false);
  const [sound, setSound] = useState(true);
  // const [customError, setCustomError] = useState(null);

  let audio = new Audio(beepSound);
  const beep = () => {
    if (sound) {
      audio.play();
    }
  };
  const _onDetected = (result) => {
    // setCustomError(null);
    if (result) {
      // sundMCCode(result.codeResult.code);
      setFieldValue("animal_mother_mccode", result.codeResult.code);
      beep();
      setShow(false);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (
      (touched.animal_mother_time && errors.animal_mother_time) ||
      (touched.animal_mother_mccode && errors.animal_mother_mccode) ||
      (touched.animal_mother_mccode_not_reason &&
        errors.animal_mother_mccode_not_reason)
    ) {
      sectionError(true);
    } else {
      sectionError(false);
    }
  }, [errors, touched]);

  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          メス親の取得時期
          <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          <Form.Check
            id="animal_mother_time1"
            name="animal_mother_time"
            label="令和4年6月1日以降に取得したメス親"
            type="radio"
            value="1"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.animal_mother_time === "1" ? true : false}
            isInvalid={touched.animal_mother_time && errors.animal_mother_time}
            isValid={touched.animal_mother_time && !errors.animal_mother_time}
          />
          <Form.Check
            id="animal_mother_time2"
            name="animal_mother_time"
            label="令和4年5月31日以前から所有しているメス親"
            type="radio"
            value="2"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.animal_mother_time === "2" ? true : false}
            isInvalid={touched.animal_mother_time && errors.animal_mother_time}
            isValid={touched.animal_mother_time && !errors.animal_mother_time}
          />
          {touched.animal_mother_time && errors.animal_mother_time && (
            <div className="text-danger">{errors.animal_mother_time}</div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          メス親のマイクロチップ識別番号
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <InputGroup className="mt-1" hasValidation>
            <FormControl
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.animal_mother_mccode}
              name="animal_mother_mccode"
              maxLength={150}
              required
              isInvalid={
                touched.animal_mother_mccode && errors.animal_mother_mccode
              }
              isValid={
                !values.animal_mother_mccode_not_reason &&
                touched.animal_mother_mccode &&
                !errors.animal_mother_mccode
              }
              disabled={
                touched.animal_mother_mccode_not_reason &&
                values.animal_mother_mccode_not_reason
              }
            />
            <Button variant="secondary" id="button-addon2" onClick={handleShow}>
              スキャン
            </Button>
            <Form.Control.Feedback type="invalid">
              {errors.animal_mother_mccode}
            </Form.Control.Feedback>
          </InputGroup>

          <Form.Group>
            メス親のマイクロチップ番号を入力できない場合は、その理由を入力してください
            <Form.Control
              as="textarea"
              rows={3}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.animal_mother_mccode_not_reason}
              name="animal_mother_mccode_not_reason"
              isInvalid={
                touched.animal_mother_mccode_not_reason &&
                errors.animal_mother_mccode_not_reason
              }
              isValid={
                !values.animal_mother_mccode &&
                touched.animal_mother_mccode_not_reason &&
                !errors.animal_mother_mccode_not_reason
              }
              disabled={
                touched.animal_mother_mccode && values.animal_mother_mccode
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.animal_mother_mccode_not_reason}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>スキャン</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show && (
            <>
              <Row className="justify-content-center">
                <div id={styles.soundContainer} className="text-end">
                  {sound ? (
                    <Button variant="light" onClick={() => setSound(false)}>
                      <FontAwesomeIcon icon={faVolumeHigh} />
                    </Button>
                  ) : (
                    <Button variant="light" onClick={() => setSound(true)}>
                      <FontAwesomeIcon icon={faVolumeXmark} />
                    </Button>
                  )}
                </div>
              </Row>

              <div id={styles.videoContainer}>
                <img
                  src={masImage}
                  alt="camera masking"
                  id={styles.cameraMasking}
                />
                <Scanner onDetected={(result) => _onDetected(result)} />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MCInputAnimalMother;
