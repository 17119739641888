import React, { useEffect } from "react";

import _ from "lodash";
import * as yup from "yup";
import { Formik } from "formik";
import DatePicker from "react-date-picker";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAd,
  faAdd,
  faCircleChevronLeft,
  faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";

import { dateString } from "../../../utils/Utils";
import ProgressStep from "../../../components/ProgressStep";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import "react-date-picker/dist/DatePicker.css";
import { useAddDocs } from "../../../hooks/useAddDocs";

const AddDetails = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();

  const mccode = localStorage.getItem("recommended_mc_code");

  useEffect(() => {
    if (!mccode) {
      navigate("/mypage/recommended-mc");
    }
  }, []);

  let initialValues = {
    display_places: "",
    hot_position: "",
    recommend_position: "",
    start_date: "",
    end_date: ""
  };

  const schema = yup.object().shape({
    display_places: yup
      .array()
      .of(yup.string().oneOf(["HOT", "RECOMMEND"], "Invalid value"))
      .min(1, "表示場所をお選びください")
      .required("この欄は必須です"),
    hot_position: yup.number().when("display_places", {
      is: (display_places) => _.includes(display_places, "HOT"),
      then: yup.number().required("この欄は必須です")
    }),
    recommend_position: yup.number().when("display_places", {
      is: (display_places) => _.includes(display_places, "RECOMMEND"),
      then: yup.number().required("この欄は必須です")
    }),
    start_date: yup.date().required("この欄は必須です"),
    end_date: yup.date().required("この欄は必須です")
  });

  const handleFormSubmit = async (values) => {
    // console.log({
    //   ...values,
    //   HOT: _.includes(values.display_places, "HOT"),
    //   RECOMMEND: _.includes(values.display_places, "RECOMMEND"),
    //   animalID: mccode
    // });
    await addADoc("recommended", {
      ...values,
      HOT: _.includes(values.display_places, "HOT"),
      RECOMMEND: _.includes(values.display_places, "RECOMMEND"),
      animalID: mccode,
      show: true,
      showADMIN: true
    });
    
    navigate("/mypage/recommended-mc/confirm");
  };
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "ペットID", link: "/mypage/recommended-mc" },
              {
                value: "ペットの詳細",
                link: "/mypage/recommended-mc/pet-details"
              },
              { value: "設定", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={3}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={7} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start border-top `
                      } border-secondary p-2`}
                    >
                      表示場所<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 py-2 border-secondary border-top`}
                    >
                      <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                          inline
                          name="display_places"
                          id="display_places1"
                          value="HOT"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={_.includes(values.display_places, "HOT")}
                          isInvalid={
                            touched.display_places && errors.display_places
                          }
                          isValid={
                            touched.display_places && !errors.display_places
                          }
                        />
                        <InputGroup.Text>HOT</InputGroup.Text>
                        <Form.Control
                          type="number"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.hot_position}
                          name="hot_position"
                          disabled={!_.includes(values.display_places, "HOT")}
                          required={_.includes(values.display_places, "HOT")}
                          isInvalid={
                            touched.hot_position && errors.hot_position
                          }
                          isValid={touched.hot_position && !errors.hot_position}
                        />
                        <InputGroup.Text>番目に表示</InputGroup.Text>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                          inline
                          name="display_places"
                          id="display_places2"
                          label="RECOMMEND"
                          value="RECOMMEND"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={_.includes(
                            values.display_places,
                            "RECOMMEND"
                          )}
                          isInvalid={
                            touched.display_places && errors.display_places
                          }
                          isValid={
                            touched.display_places && !errors.display_places
                          }
                        />

                        <InputGroup.Text>RECOMMEND</InputGroup.Text>
                        <Form.Control
                          type="number"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.recommend_position}
                          name="recommend_position"
                          disabled={
                            !_.includes(values.display_places, "RECOMMEND")
                          }
                          required={_.includes(
                            values.display_places,
                            "RECOMMEND"
                          )}
                          isInvalid={
                            touched.recommend_position &&
                            errors.recommend_position
                          }
                          isValid={
                            touched.recommend_position &&
                            !errors.recommend_position
                          }
                        />
                        <InputGroup.Text>番目に表示</InputGroup.Text>
                      </InputGroup>
                      {touched.display_places && errors.display_places && (
                        <div className="text-danger">
                          {errors.display_places}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      開始日<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 py-2 border-secondary`}
                    >
                      <DatePicker
                        className={`form-control ${
                          touched.start_date && errors.start_date
                            ? "is-invalid"
                            : ""
                        } ${
                          touched.start_date && !errors.start_date
                            ? "is-valid"
                            : ""
                        }`}
                        minDate={new Date()}
                        format="yyyy/MM/dd"
                        locale="ja-JP"
                        dayPlaceholder=" 日 "
                        monthPlaceholder=" 月 "
                        yearPlaceholder="年 "
                        onChange={(val) => {
                          setFieldValue("start_date", val);
                        }}
                        onFocus={() => {
                          setFieldTouched("start_date", true);
                        }}
                        value={values.start_date}
                        name="start_date"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.start_date}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      終了日<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 py-2 border-secondary`}
                    >
                      <DatePicker
                        className={`form-control ${
                          touched.end_date && errors.end_date
                            ? "is-invalid"
                            : ""
                        } ${
                          touched.end_date && !errors.end_date ? "is-valid" : ""
                        }`}
                        minDate={values.start_date}
                        format="yyyy/MM/dd"
                        locale="ja-JP"
                        dayPlaceholder=" 日 "
                        monthPlaceholder=" 月 "
                        yearPlaceholder="年 "
                        onChange={(val) => {
                          setFieldValue("end_date", val);
                        }}
                        onFocus={() => {
                          setFieldTouched("end_date", true);
                        }}
                        value={values.end_date}
                        name="end_date"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.end_date}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  <div className="text-center mt-3">
                    <Button
                      onClick={() => {
                        navigate("/mypage/recommended-mc/pet-details");
                      }}
                      className="mb-2 mx-2"
                      variant="danger"
                      type="submit"
                    >
                      <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
                    </Button>

                    <Button
                      className="mb-2 mx-2"
                      variant="primary"
                      type="submit"
                    >
                      保存 <FontAwesomeIcon icon={faCircleChevronRight} />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddDetails;
