import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import * as yup from "yup";
import { Formik } from "formik";
import valid from "card-validator";
import Cards from "react-credit-cards-2";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { convertToFullYear, formatCreditCardNumber, formatExpirationDate } from "./Util";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { useUpdateDoc } from "../../hooks/useUpdateDoc";
import { useAuthContext } from "../../hooks/useAuthContext";

import "react-credit-cards-2/dist/es/styles-compiled.css";

function CardRegistration({
  komoju_customer_id = null,
  returnUrl = null,
  isFinish = () => {},
  cancel = null,
  cancelFn = () => {}
}) {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 6001);

  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const { userData: userDataOriginal } = useUserDetailsContext();
  const [userData, setUserData] = useState(null);
  const { user } = useAuthContext();

  console.log(user);

  const { firebaseUpdateDoc, isConfirmed, error } = useUpdateDoc();
  const [komojuSessionID, setKomojuSessionID] = useState(null);
  const [komojuPublishKey, setKomojuPublishKey] = useState(process.env.REACT_APP_KOMOJU_PUBLISH_KEY);
  const location = useLocation();

  useEffect(() => {
    // Load the Komoju fields script
    const script = document.createElement("script");
    script.type = "module";
    script.src = "https://multipay.komoju.com/fields.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (userDataOriginal && !userData) {
      setUserData(userDataOriginal);
    }
  }, [userDataOriginal]);

  useEffect(() => {
    if (!userData) {
      return;
    }
    createSession();
    async function createSession() {
      localStorage.removeItem("komoju_redirect");
      if (userData.komoju_customer_seesion) {
        try {
          const getKomojuSessionDetails = httpsCallable(functions, "getKomojuSessionDetails");
          const result = await getKomojuSessionDetails({
            sessionId: userData.komoju_customer_seesion
          });
          if (!result.data.data.expired && result.data.data.status === "pending") {
            setKomojuSessionID(result.data.data.id);
            localStorage.setItem("komoju_redirect", returnUrl ? returnUrl : `mypage/plansetting`);
            return;
          } else {
            try {
              const cancelKomojuSession = httpsCallable(functions, "cancelKomojuSession");
              const result = await cancelKomojuSession({
                sessionId: userData.komoju_customer_seesion
              });
            } catch (e) {
              console.log(e);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      generateToken();
    }
  }, [userData]);

  async function generateToken() {
    try {
      const createKomojuSession = httpsCallable(functions, "createKomojuSession");
      const result = await createKomojuSession({
        customer_id: komoju_customer_id,
        email: user.email
      });

      setKomojuSessionID(result.data.data.id);
      firebaseUpdateDoc("users", { komoju_customer_seesion: result.data.data.id }, user.uid);
      localStorage.setItem("komoju_redirect", returnUrl ? returnUrl : `mypage/plansetting`);
      console.log(returnUrl);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Container className="pt-3">
        <Row className="justify-content-md-center">
          <Col lg={5} md={6}>
            <Row>
              <Col xs={12} className="position-relative" style={{ zIndex: 1 }}>
                {/* <Cards
                  locale={{ valid: "有効期限" }}
                  cvc={""}
                  expiry={""}
                  focused={""}
                  name={""}
                  number={""}
                /> */}
              </Col>
              <Col xs={12}>
                <Card className="" style={{ marginTop: "2rem" }}>
                  <Card.Body>
                    <br />
                    <br />
                    {/* {((data && data.err) || error) && (
                      <Alert variant="danger" dismissible className="text-center">
                        <p className="m-0">{(data && data.err && data.err.message) || error}</p>
                      </Alert>
                    )} */}

                    <Form>
                      {komojuSessionID && (
                        <komoju-fields
                          id="fields"
                          session-id={komojuSessionID}
                          publishable-key={komojuPublishKey}
                        ></komoju-fields>
                      )}
                      <div className="text-center">
                        {cancel && (
                          <Button className="mb-2 me-1" variant="danger" onClick={cancelFn}>
                            <FontAwesomeIcon icon={faTimesCircle} /> 取り消し
                          </Button>
                        )}
                        {komojuSessionID && (
                          <Button className="mb-2 ms-1" variant="primary" type="submit">
                            登録 <FontAwesomeIcon icon={faCircleArrowRight} />
                          </Button>
                        )}

                        {!komojuSessionID && (
                          <Button className="mb-2 ms-1" variant="primary" disabled>
                            登録 <FontAwesomeIcon icon={faCircleArrowRight} />
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </Button>
                        )}
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CardRegistration;
