import React from "react";

import { Link } from "react-router-dom";
import { Alert, Col, Container, Row } from "react-bootstrap";

import ProgressStep from "../../components/ProgressStep";
import { useUserDetailsContext } from "../../context/UserDetailsContext";

function TransferComplete() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || false;
  const { userData } = useUserDetailsContext();

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={
              userInfo.group === "owner"
                ? userData.plan === "LITE"
                  ? [
                      { value: "スキャン", link: "/mypage/transferstart" },
                      { value: "メール", link: "#" },
                      { value: "OTP", link: "#" },
                      { value: "入力", link: "#" },
                      { value: "確認", link: "#" },
                      { value: "支払", link: "#" },
                      { value: "終了", link: "#" }
                    ]
                  : [
                      { value: "スキャン", link: "/mypage/transferstart" },
                      { value: "メール", link: "#" },
                      { value: "OTP", link: "#" },
                      { value: "入力", link: "#" },
                      { value: "確認", link: "#" },
                      { value: "販売契約書", link: "#" },
                      { value: "支払", link: "#" },
                      { value: "終了", link: "#" }
                    ]
                : [
                    { value: "スキャン", link: "/mypage/transferstart" },
                    { value: "メール", link: "#" },
                    { value: "OTP", link: "#" },
                    { value: "入力", link: "#" },
                    { value: "確認", link: "#" },
                    { value: "終了", link: "#" }
                  ]
            }
            activeNo={
              userInfo.group === "owner"
                ? userData.plan === "LITE"
                  ? 7
                  : 8
                : 6
            }
          />
        </Row>
        <Row className="justify-content-center my-5">
          <Col lg={6} md={8}>
            <Alert variant="success" className="text-center">
              完了しました！
            </Alert>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <div className="text-center ">
              <Link to="/mypage/transfer" className="btn btn-primary">
                別な情報を譲渡する
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TransferComplete;
