import {
  faFileCsv,
  faFilter,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Table,
  ToastContainer,
  Toast,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import sjcl from "sjcl";
import DeathReportModal from "../../components/DeathReportModal";
import MCDetailsModal from "../../components/MCDetailsModal";
import MCFilter from "../../components/MCFilter";
import MCListItems from "../../components/MCListItems";
import PaymentDetailsModal from "../../components/PaymentDetailsModal";
import PINModal from "../../components/PINModal";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import useCsvDownload from "../../hooks/useCsvDownload";
import { useGetDoc } from "../../hooks/useGetDoc";
import { mc_reg_headers, info_change_headers } from "../../utils/CSVFormate";
import { customPaginationOptions, getNameByOwnerType } from "../../utils/Utils"

import DataTable from "react-data-table-component";
import MCDetailsModalAdmin from "./MCDetailsModalAdmin";

function MCListAdmin() {
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();

  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const { firebaseGetDoc, getSingleDocWithQuery } = useGetDoc();
  const [mcs, setMcs] = useState(false);
  const [showMCDetailsModal, setShowMCDetailsModal] = useState(false);
  const [showDeathsModal, setShowDeathModal] = useState(false);
  const [showPINsModal, setShowPINModal] = useState(false);
  const [showMCFilterModal, setShowMCFilterModal] = useState(false);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const [MCModalContent, setMCModalContent] = useState(null);
  const [deathContent, setDeathContent] = useState(null);
  const [PINContent, setPINContent] = useState(null);
  const [paymentModalContent, setPaymentModalContent] = useState(null);
  const [filters, setFilters] = useState(false);

  // const limit = false;

  const key = process.env.REACT_APP_SJCL_KEY;
  const mc_datas = localStorage.getItem("MC_DATA_1");
  const mc_datas_ex = localStorage.getItem("MC_DATA_1_Ex");
  const mc_datas_up = JSON.parse(localStorage.getItem("MC_DATA_1_Up"));

  useEffect(() => {
    if (documents) {
      let newmcs = mcs;
      documents.forEach((doc) => {
        newmcs = { ...newmcs, [doc.id]: doc };
      });
      setMcs(newmcs);
      const stringified = JSON.stringify(newmcs);

      const data = sjcl.encrypt(key, stringified);

      localStorage.setItem("MC_DATA_1", data);
      localStorage.setItem("MC_DATA_1_Ex", moment().format("YYYYMMDD"));
    }
  }, [documents]);

  useEffect(() => {
    localStorage.removeItem("mcDataEdit");
    localStorage.removeItem("mcDataEditId");

    let d_mc_datas;
    if (
      mc_datas &&
      mc_datas_ex &&
      parseInt(mc_datas_ex) === parseInt(moment().format("YYYYMMDD"))
    ) {
      d_mc_datas = JSON.parse(sjcl.decrypt(key, mc_datas));

      if (mc_datas_up) {
        let new_d_mc_datas = mcs || d_mc_datas;
        mc_datas_up.forEach(async (mccode) => {
          let data_Up = await getUpdatedMCDatabyMCCode(mccode);
          if (new_d_mc_datas[data_Up.id]) {
            new_d_mc_datas = {
              ...new_d_mc_datas,
              [data_Up.id]: { ...data_Up, id: data_Up.id },
            };
          } else {
            new_d_mc_datas = {
              [data_Up.id]: { ...data_Up, id: data_Up.id },
              ...new_d_mc_datas,
            };
          }
          setMcs(new_d_mc_datas);
          const stringified = JSON.stringify(new_d_mc_datas);
          const data = sjcl.encrypt(key, stringified);
          localStorage.setItem("MC_DATA_1", data);
        });
        localStorage.removeItem("MC_DATA_1_Up");
      } else {
        setMcs(d_mc_datas);
      }
      // console.log("+++++++++local+++++++++");
    } else {
      // console.log("---------server--------");
      setMcs([]);
      loadMore();
    }
  }, []);

  const getUpdatedMCDatabyID = async (id) => {
    const data = await firebaseGetDoc(`users/${user.uid}/microchip/`, id);
    let newmcs = { ...mcs, [id]: { ...data, id: id } };
    setMcs(newmcs);

    const stringified = JSON.stringify(newmcs);
    const new_data = sjcl.encrypt(key, stringified);
    localStorage.setItem("MC_DATA_1", new_data);
  };

  const getUpdatedMCDatabyMCCode = async (mccode) => {
    const data = await getSingleDocWithQuery(`users/${user.uid}/microchip/`, [
      ["mccode", "==", mccode.toString()],
    ]);

    return data[0];
  };

  const MCModalHandler = (props) => {
    setShowMCDetailsModal(true);
    setMCModalContent(props);
  };

  const paymentModalHandler = (props) => {
    setPaymentDetailsModal(true);
    setPaymentModalContent(props);
  };

  const deathModalHandler = (props) => {
    setShowDeathModal(true);
    setDeathContent(props);
  };

  const pinModalHandler = (props) => {
    setShowPINModal(true);
    setPINContent(props);
  };

  const [mcInfo, setMCInfo] = useState([]);
  const [pinCheck, setPinCheck] = useState(true);
  const batchCsvHandler = (e) => {
    if (e.target.checked) {
      setMCInfo([...mcInfo, e.target.value]);
    } else {
      setMCInfo(mcInfo.filter((mc) => mc !== e.target.value));
    }
    if (e.target.dataset.pin.toString() === "true") {
      setPinCheck(true);
    } else {
      setPinCheck(false);
    }
  };

  useEffect(() => {
    console.table("here", mcInfo, pinCheck, mcs);
  }, [mcInfo, pinCheck, mcs]);

  const loadMore = (ld) => {
    getMultipleDocs({
      _collection: `microchip`,
      _query: false,
      orderByField: "createdAt",
      orderByOption: "desc",
    });
  };

  const clearFilter = (key, value = "") => {
    if (key) {
      setFilters({ ...filters, [key]: value });
      // console.log('clearFilter', Object.values(_.omit(filters, ['startDate', 'endDate'])).length);
    } else {
      setFilters({});
    }
  };

  function intersectingKeys(...objects) {
    return objects
      .map((object) => Object.keys(object))
      .sort((a, b) => a.length - b.length)
      .reduce((a, b) => a.filter((key) => b.includes(key)));
  }

  let items = [];
  for (let i = 0; i < 10; i++) {
    items.push(
      <tr key={i} className="placeholder-glow">
        <td className="align-middle">
          <span className="placeholder col-8"></span>
        </td>
        <td className="text-center align-middle">
          <span className="placeholder col-8"></span>
        </td>
        <td className="text-center align-middle">
          <span className="placeholder col-2"></span>
        </td>
        <td className="text-center">
          <ButtonGroup className="me-2 col-5" aria-label="First group">
            <Button
              variant="secondary"
              className="placeholder"
              disabled
            ></Button>{" "}
            <Button variant="danger" className="placeholder" disabled></Button>{" "}
            <Button variant="success" className="placeholder" disabled></Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
  const { getCSVData } = useCsvDownload();
  const [csvData, setCsvData] = useState([]);
  //csvdata
  const getCsvData = async () => {
    const mcs_in_local = JSON.parse(sjcl.decrypt(key, mc_datas));

    await getCSVData(
      Object.values(mcs_in_local).filter(
        (doc) => _.includes(mcInfo, doc.id) && doc
      ),
      pinCheck
    ).then((res) => {
      setCsvData(res);
      setMCInfo([]);
    });
  };

  const columns = [
    {
      name: "MC番号",
      selector: (row) => row.mccode,
      sortable: true,
    },
    {
      name: "所有者",
      selector: (row) => getNameByOwnerType(row),
      sortable: true,
    },
    {
      name: "日付",
      selector: (row) =>
        moment(
          new Timestamp(
            row.createdAt.seconds,
            row.createdAt.nanoseconds
          ).toDate()
        ).format("YYYY/MM/DD"),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        <>
            <Button size="sm" variant="secondary" onClick={() => MCModalHandler(row)}>
              詳細
            </Button>
        </>
      ),
    },
  ];


  const conditionalRowStyles = [
    	{
    		when: row => row.death ,
    		style: {
    			backgroundColor: 'rgba(63, 195, 128, 0.1)',
    			color: 'black',
    			'&:hover': {
    				cursor: 'pointer',
    			},
    		},
    	},
    	{
    		when: row => row.transferStatus,
    		style: {
    			backgroundColor: 'rgba(248, 148, 6, 0.1)',
    			color: 'black',
    			'&:hover': {
    				cursor: 'pointer',
    			},
    		},
    	},
    ];
  return (
    <>
      <Container>
        {Object.values(mcs).length > 0 && (
          <>
            {
              Object.values(mcs) && (
                // Object.values(mcs).map((doc, i) => {
                // return (
                // <MCListItems
                //   key={i}
                //   doc={doc}
                //   modalFun={MCModalHandler}
                //   deathModalFun={deathModalHandler}
                //   pinModalFun={pinModalHandler}
                //   batchCsvHandler={batchCsvHandler}
                //   mcInfo={mcInfo}
                //   pinCheck={pinCheck}
                // />

                <DataTable
                  columns={columns}
                  progressPending={isPending}
                  progressComponent={
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  data={Object.values(mcs)}
                  conditionalRowStyles={conditionalRowStyles}
                  pagination
                  paginationComponentOptions={customPaginationOptions}
                />
              )
              // );
              // })
            }

            {isPending && items}
          </>
        )}

        {!Object.values(mcs).length && !isPending && (
          <Alert variant="warning" className="text-center">
            マイクロチップ履歴がありません
          </Alert>
        )}
      </Container>

      <MCDetailsModalAdmin
        data={MCModalContent}
        show={showMCDetailsModal}
        onHide={() => setShowMCDetailsModal(false)}
        paymentModalHandler={paymentModalHandler}
      />
      <PaymentDetailsModal
        data={paymentModalContent}
        mcData={MCModalContent}
        show={paymentDetailsModal}
        onHide={() => setPaymentDetailsModal(false)}
        modalFun={MCModalHandler}
      />

      {/* <DeathReportModal
        data={deathContent}
        show={showDeathsModal}
        getUpdatedMCDatabyID={getUpdatedMCDatabyID}
        onHide={() => setDeathContent(false)}
      /> */}

      {/* <PINModal
        data={PINContent}
        show={showPINsModal}
        getUpdatedMCDatabyID={getUpdatedMCDatabyID}
        onHide={() => setPINContent(false)}
      /> */}

      {/* <MCFilter
        show={showMCFilterModal}
        handleSearch={handleSearch}
        returnValue={filters}
        onHide={() => {
          setShowMCFilterModal(false);
        }}
      /> */}
      {mcInfo.length > 0 && !pinCheck && (
        <ToastContainer className="p-3" position="bottom-end">
          <Toast style={{ width: "unset" }}>
            <CSVLink
              className="btn btn-warning btn-lg"
              data={csvData}
              headers={mc_reg_headers}
              filename={`MC情報の登録_${moment(new Date()).format(
                "YYYYMMDD_HHMMSS"
              )}.csv`}
              enclosingCharacter={``}
              asyncOnClick={true}
              onClick={getCsvData}
            >
              <div className="d-flex align-middle">
                <FontAwesomeIcon icon={faFileCsv} size="3x" className="pe-2" />
                <p className="mb-0">
                  [MC情報の登録]
                  <br />
                  CSVをダウンロード
                </p>
              </div>
            </CSVLink>
          </Toast>
        </ToastContainer>
      )}

      {mcInfo.length > 0 && pinCheck && (
        <ToastContainer className="p-3" position="bottom-end">
          <Toast style={{ width: "unset" }}>
            <CSVLink
              className="btn btn-info btn-lg"
              data={csvData}
              headers={info_change_headers}
              filename={`登録項目の変更_${moment(new Date()).format(
                "YYYYMMDD_HHMMSS"
              )}.csv`}
              enclosingCharacter={``}
              asyncOnClick={true}
              onClick={getCsvData}
            >
              <div className="d-flex align-middle">
                <FontAwesomeIcon icon={faFileCsv} size="3x" className="pe-2" />
                <p className="mb-0">
                  [登録項目の変更]
                  <br />
                  CSVをダウンロード
                </p>
              </div>
            </CSVLink>

            {/* <Button variant="warning" onClick={csvdata}>csvdata</Button> */}
          </Toast>
        </ToastContainer>
      )}
    </>
  );
}

export default MCListAdmin;
