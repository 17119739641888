import React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Col, Container, Row, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { Formik, useFormikContext } from "formik";
import _ from "lodash";

import ProgressStep from "../../components/ProgressStep";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function CreateNews({ edit = false }) {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const backData = edit
    ? _.omit(JSON.parse(localStorage.getItem("newsDataEdit")), ["id"])
    : JSON.parse(localStorage.getItem("newsValue"));
  let initialValues = {
    type: "",
    title: "",
    date: "",
    description: "",
  };
  if (backData) {
    initialValues = {
      ...backData,
    };
  }

  const schema = yup.object().shape({
    title: yup
      .string()
      .required("この欄は必須です")
      .max(50, "最小50全角半角混在以上にてご入力ください"),
    date: yup.date().required("この欄は必須です"),
    description: yup
      .string()
      .required("この欄は必須です")
      .max(1500, "最小1500全角半角混在以上にてご入力ください"),
    type: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["お知らせ", "メディア"], "タイプをお選びください"),
  });

  const handleSubmit = (values) => {
    localStorage.setItem("newsValue", JSON.stringify(values));
    edit ? navigate("/mypage/newsEditCheck") : navigate("/mypage/newsCheck");
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={1}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-top border-start `
                      } border-secondary p-2`}
                    >
                      日付<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 &&
                        `border-top border-start border-bottom border-end`
                      } p-1 border-end border-secondary`}
                    >
                      <Form.Group>
                        <Form.Control
                          type="date"
                          placeholder="半角"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.date}
                          name="date"
                          required
                          isInvalid={touched.date && errors.date}
                          isValid={touched.date && !errors.date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.date}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      タイプ<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 py-2 border-secondary`}
                    >
                      <>
                        <Form.Check
                          inline
                          id="type1"
                          name="type"
                          label="お知らせ"
                          type="radio"
                          value="お知らせ"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.type === "お知らせ" ? true : false}
                          isInvalid={touched.type && errors.type}
                          isValid={touched.type && !errors.type}
                        />
                        <Form.Check
                          inline
                          id="type2"
                          name="type"
                          label="メディア"
                          type="radio"
                          value="メディア"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.type === "メディア" ? true : false}
                          isInvalid={touched.type && errors.type}
                          isValid={touched.type && !errors.type}
                        />
                        {touched.type && errors.type && (
                          <div className="text-danger">{errors.type}</div>
                        )}
                      </>
                    </Col>
                  </Row>

                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      タイトル<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 border-secondary`}
                    >
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="半角"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          name="title"
                          maxLength={160}
                          required
                          isInvalid={touched.title && errors.title}
                          isValid={touched.title && !errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      詳細<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 border-secondary`}
                    >
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          name="description"
                          isInvalid={touched.description && errors.description}
                          isValid={touched.description && !errors.description}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.description}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="text-center mt-4">
                    <Button className="mb-3" variant="primary" type="submit">
                      <b>
                        次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                      </b>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
