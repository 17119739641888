import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";

import { useGetDoc } from "../../../hooks/useGetDoc";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { Link } from "react-router-dom";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SellingContractSettings() {
  const { userData } = useUserDetailsContext();

  const { firebaseGetDoc, isPending: isPending5, error: error5 } = useGetDoc();
  const [sellingContractPage, setSellingContractPage] = useState("");

  const getSellingContractPage1 = async () => {
    if (userData && userData.sellingContractTemplatePage1) {
      setSellingContractPage(userData.sellingContractTemplatePage1);
    } else {
      const data = await firebaseGetDoc("admin", "sellingContractTemplate");
      if (data && data.page1) {
        setSellingContractPage(data.page1);
      }
    }
  };

  useEffect(() => {
    getSellingContractPage1();
  }, []);

  return (
    <Container className="mt-3">
      {/* <Row className="justify-content-center mb-4">
        <ProgressStep
          props={[
            { value: "入力", link: "#" },
            { value: "確認", link: "#" },
            { value: "終了", link: "#" },
          ]}
          activeNo={6}
        />
      </Row> */}

      <Row className="justify-content-center">
        <Col xl={10} md={12}>
          <div className="d-flex justify-content-between">
            <h4>販売契約書 (保証内容)</h4>
            <Link
              className="btn btn-primary mb-3"
              to="/mypage/hanbaikeiyakusho/edit">
              <FontAwesomeIcon icon={faEdit} /> 編集
            </Link>
          </div>
          {sellingContractPage && (
            <div className="ql-snow border p-3">
              <div
                className="ql-editor ql-container"
                dangerouslySetInnerHTML={{
                  __html: sellingContractPage,
                }}></div>
            </div>
          )}

        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}

export default SellingContractSettings;
