import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import AddressInput from "../../components/AddressInput";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function MCInputOwner({ setOwnerLocationZipError, sectionError, PIN = false }) {
  const { width } = useWindowDimensions();

  const { handleChange, handleBlur, values, touched, errors } =
    useFormikContext();

  useEffect(() => {
    if (
      (touched.ownership && errors.ownership) ||
      (touched.ownership && errors.ownership) ||
      (touched.owner_type && errors.owner_type) ||
      (touched.owner_type && errors.owner_type) ||
      (touched.lastName && errors.lastName) ||
      (touched.firstName && errors.firstName) ||
      (touched.lastKana && errors.lastKana) ||
      (touched.firstKana && errors.firstKana) ||
      (touched.corporation_name && errors.corporation_name) ||
      (touched.corporation_kana_name && errors.corporation_kana_name) ||
      (touched.corporation_representative_lastName &&
        errors.corporation_representative_lastName) ||
      (touched.corporation_representative_firstName &&
        errors.corporation_representative_firstName) ||
      (touched.owner_fixed_phone && errors.owner_fixed_phone) ||
      (touched.owner_phone && errors.owner_phone) ||
      (touched.owner_email && errors.owner_email) ||
      (touched.owner_location_zip3 && errors.owner_location_zip3) ||
      (touched.owner_location_zip4 && errors.owner_location_zip4) ||
      (touched.owner_location_prefecture && errors.owner_location_prefecture) ||
      (touched.owner_location_addressLine1 &&
        errors.owner_location_addressLine1) ||
      (touched.owner_location_addressLine2 &&
        errors.owner_location_addressLine2) ||
      (touched.owner_location_addressLine3 &&
        errors.owner_location_addressLine3)
    ) {
      sectionError(true);
    } else {
      sectionError(false);
    }
  }, [errors, touched]);

  return (
    <>
      {/* <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          所有者種別<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          <Form.Check
            inline
            id="ownership1"
            name="ownership"
            label="一般の所有者"
            type="radio"
            value="1"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.ownership === "1" ? true : false}
            isInvalid={touched.ownership && errors.ownership}
            isValid={touched.ownership && !errors.ownership}
          />
          <Form.Check
            inline
            id="ownership2"
            name="ownership"
            label="動物取扱業者"
            type="radio"
            value="2"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.ownership === "2" ? true : false}
            isInvalid={touched.ownership && errors.ownership}
            isValid={touched.ownership && !errors.ownership}
          />
          {touched.ownership && errors.ownership && (
            <div className="text-danger">{errors.ownership}</div>
          )}
        </Col>
      </Row> */}

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          個人または法人 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          {PIN ? (
            <p className="mb-0 p-1">
              {values.owner_type === "1" ? "個人" : "法人"}
            </p>
          ) : (
            <>
              <Form.Check
                inline
                id="owner_type1"
                name="owner_type"
                label="個人"
                type="radio"
                value="1"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.owner_type === "1" ? true : false}
                isInvalid={touched.owner_type && errors.owner_type}
                isValid={touched.owner_type && !errors.owner_type}
              />
              <Form.Check
                inline
                id="owner_type2"
                name="owner_type"
                label="法人"
                type="radio"
                value="2"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.owner_type === "2" ? true : false}
                isInvalid={touched.owner_type && errors.owner_type}
                isValid={touched.owner_type && !errors.owner_type}
              />
              {touched.owner_type && errors.owner_type && (
                <div className="text-danger">{errors.owner_type}</div>
              )}
            </>
          )}
        </Col>
      </Row>
      {values.owner_type === "1" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {PIN ? (
                <>
                  {values.lastName} {values.firstName}
                </>
              ) : (
                <>
                  <Row>
                    <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
                      <Form.Control
                        type="text"
                        placeholder="姓"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        name="lastName"
                        maxLength={14}
                        isInvalid={touched.lastName && errors.lastName}
                        isValid={touched.lastName && !errors.lastName}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Col>

                    <Col lg={6} className="ps-sm-1">
                      <Form.Control
                        type="text"
                        placeholder="名"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        name="firstName"
                        maxLength={14}
                        isInvalid={touched.firstName && errors.firstName}
                        isValid={touched.firstName && !errors.firstName}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              氏名（フリガナ） <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {PIN ? (
                <>
                  {values.lastKana} {values.firstKana}
                </>
              ) : (
                <>
                  <Row>
                    <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
                      <Form.Control
                        type="text"
                        placeholder="セイ"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastKana}
                        name="lastKana"
                        maxLength={14}
                        isInvalid={touched.lastKana && errors.lastKana}
                        isValid={touched.lastKana && !errors.lastKana}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastKana}
                      </Form.Control.Feedback>
                    </Col>

                    <Col lg={6} className="ps-sm-1">
                      <Form.Control
                        type="text"
                        placeholder="メイ"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstKana}
                        name="firstKana"
                        maxLength={14}
                        isInvalid={touched.firstKana && errors.firstKana}
                        isValid={touched.firstKana && !errors.firstKana}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstKana}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
      {values.owner_type === "2" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              法人名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {PIN ? (
                <p className="mb-0 p-1">{values.corporation_name}</p>
              ) : (
                <>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_name}
                    name="corporation_name"
                    maxLength={30}
                    isInvalid={
                      touched.corporation_name && errors.corporation_name
                    }
                    isValid={
                      touched.corporation_name && !errors.corporation_name
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_name}
                  </Form.Control.Feedback>
                </>
              )}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              法人名(フリガナ) <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {PIN ? (
                <p className="mb-0 p-1">{values.corporation_kana_name}</p>
              ) : (
                <>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_kana_name}
                    name="corporation_kana_name"
                    maxLength={30}
                    isInvalid={
                      touched.corporation_kana_name &&
                      errors.corporation_kana_name
                    }
                    isValid={
                      touched.corporation_kana_name &&
                      !errors.corporation_kana_name
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_kana_name}
                  </Form.Control.Feedback>{" "}
                </>
              )}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              代表者の氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Row>
                <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
                  <Form.Control
                    type="text"
                    placeholder="姓"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_representative_lastName}
                    name="corporation_representative_lastName"
                    maxLength={14}
                    isInvalid={
                      touched.corporation_representative_lastName &&
                      errors.corporation_representative_lastName
                    }
                    isValid={
                      touched.corporation_representative_lastName &&
                      !errors.corporation_representative_lastName
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_representative_lastName}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="ps-sm-1">
                  <Form.Control
                    type="text"
                    placeholder="名"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_representative_firstName}
                    name="corporation_representative_firstName"
                    maxLength={14}
                    isInvalid={
                      touched.corporation_representative_firstName &&
                      errors.corporation_representative_firstName
                    }
                    isValid={
                      touched.corporation_representative_firstName &&
                      !errors.corporation_representative_firstName
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_representative_firstName}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          住所<span className="text-danger">※</span>
          <br />
          (法人にあっては、事務所の所在地)
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <AddressInput
            prefix="owner_location_"
            setZipError={setOwnerLocationZipError}
          />
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          電話番号
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.owner_fixed_phone}
            name="owner_fixed_phone"
            maxLength={14}
            isInvalid={touched.owner_fixed_phone && errors.owner_fixed_phone}
            isValid={touched.owner_fixed_phone && !errors.owner_fixed_phone}
            placeholder="例: 03-6794-0967"
          />
          <Form.Control.Feedback type="invalid">
            {errors.owner_fixed_phone}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          携帯電話番号 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.owner_phone}
            name="owner_phone"
            maxLength={14}
            isInvalid={touched.owner_phone && errors.owner_phone}
            isValid={touched.owner_phone && !errors.owner_phone}
            placeholder="例: 090-0000-0000"
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.owner_phone}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          電子メールアドレス <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {PIN ? (
            <p className="mb-0 p-1">{values.owner_email}</p>
          ) : (
            <>
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.owner_email}
                name="owner_email"
                maxLength={100}
                isInvalid={touched.owner_email && errors.owner_email}
                isValid={touched.owner_email && !errors.owner_email}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.owner_email}
              </Form.Control.Feedback>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default MCInputOwner;
