import { useEffect, useState } from "react";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";
import { getKomojuError } from "../../utils/KomojuErrors";

export const usePaymentDetails = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [data, setData] = useState(null);

  const paymentInfo = (payment_id) => {
    setIsPending(true);
    setError(null);
    const paymentDetails = httpsCallable(functions, "paymentDetails");
    paymentDetails({ payment_id })
      .then((res) => {
        setIsPending(false);
        setIsConfirmed(true);
        if (res.data.error) {
          setError(
            getKomojuError(res.data.error.code) || res.data.error.message
          );
        } else {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(err);
      });
  };

  return { paymentInfo, isPending, isConfirmed, error, data };
};
