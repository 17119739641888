import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CardDiv from "../../../components/CardDiv";
import ProgressStep from "../../../components/ProgressStep";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { useAuthContext } from "../../../hooks/useAuthContext";
import CardRegistration from "../../creditcard/CardRegistration";

export default function SubsCard({ komoju_customer_id }) {
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();
  const navigate = useNavigate();
  const [changeCardDiv, setChangeCardDiv] = useState(false);
  const [cardError, setCardError] = useState(false);
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "サービス", link: "#" },
              { value: "確認", link: "#" },
              { value: "カードを登録", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={3}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          {komoju_customer_id && !changeCardDiv && (
            <Col lg={5} md={6}>
              <Card>
                <Card.Header>
                  <h5 className=" mb-0 text-center">支払い方法</h5>
                </Card.Header>
                <Card.Body>
                  <CardDiv
                    komoju_customer_id={komoju_customer_id}
                    errorInCard={setCardError}
                    minMonth="4"
                  />
                  <div className="text-center">
                    <Button
                      className="mt-3"
                      variant="warning"
                      onClick={() => setChangeCardDiv(!changeCardDiv)}
                    >
                      編集 <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}

          {(!komoju_customer_id || changeCardDiv) && (
            <>
              <CardRegistration
                komoju_customer_id={komoju_customer_id}
                returnUrl={null}
                isFinish={(status) => setChangeCardDiv(!status)}
              />
              {changeCardDiv && (
                <div className="text-center">
                  <Button
                    className="mt-3"
                    variant="danger"
                    onClick={() => setChangeCardDiv(!changeCardDiv)}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> 取り消し
                  </Button>
                </div>
              )}
            </>
          )}
          <div className="text-center mt-4">
            <Link
              className="btn btn-secondary m-2"
              to="/mypage/subscription-check"
            >
              <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
            </Link>
            {komoju_customer_id && !cardError && (
              <Button className="btn btn-primary m-2" onClick={() => {}}>
                登録 <FontAwesomeIcon icon={faCircleChevronRight} />
              </Button>
            )}

            {/* {(!komoju_customer_id || cardError) && (
              <Button className="btn btn-primary m-2" disabled>
                登録 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )} */}

            {/* {(error || error2 || error4) && (
            <Alert variant="danger" className="text-center">
              {error || error2 || error4}
            </Alert>
          )} */}
          </div>
        </Row>
      </Container>
    </>
  );
}
