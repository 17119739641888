import React from "react";
import SidebarBannerForm from "./SidebarBannerForm";

const SidebarBannerModal = (props) => {
  const hideModal = () => {
    props.onHide();
  };
  return (
    <>
      {props.data && (
        <SidebarBannerForm
          show={props.show}
          onHide={props.onHide}
          reloadData={props.reloadData}
          hideModal={hideModal}
          data={props.data}
        />
      )}
    </>
  );
};

export default SidebarBannerModal;
