import React from "react";
import { Button, Modal } from "react-bootstrap";
import NewsContent from "../../components/NewsContent";

export default function NewsDetailsModal(props) {
  return (
    <>
      {props.data && (
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <NewsContent data={props.data} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>閉じる</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
