import React from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import styles from "./Law.module.css";
import { getAidContent } from "../../utils/Utils";
import AidContent from "./AidContent";

function Aid() {
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>先天性障害瑕疵保証互助制度について</title>
        <meta
          property="og:title"
          content="先天性障害瑕疵保証互助制度について"
        />
        <meta name="description" content="先天性障害瑕疵保証互助制度について" />
      </Helmet>
      <div className="main">
        <div className={styles.translaw}>
          <div className="container">
            <p className={styles.title}>先天性障害瑕疵保証互助制度について</p>

            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-sm-11 col-lg-10">
                  <div className={styles["m-card"]}>
                    <h5 className="text-center">
                      重要事項説明-本互助制度の概要について-
                    </h5>
                    <br />
                    <br />
                    <AidContent />
                  </div>
                </div>
              </div>
              <img src="/assets/img/asset9.png" alt="" className={styles.dog} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aid;
