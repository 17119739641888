import React from "react";
import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";

import ProgressStep from "../../components/ProgressStep";
import { mc_reg_headers, info_change_headers } from "../../utils/CSVFormate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function MCRegistrationFromPDFComplete() {
  const data = JSON.parse(localStorage.getItem("mcData"));
  let PIN = true;
  let csvdata = [];
  if(PIN){
    csvdata = [
      {
        mccode: data.mccode,
        secretcode: data.PIN,
        ownership:
          data.ownership === "1"
            ? "1"
            : data.animal_handler_business_type === "1"
            ? "2"
            : "3",
        owner_type: data.owner_type,
        lastName: data.owner_type === "1" ? data.lastName : data.corporation_name,
        firstName: data.firstName ? data.firstName : "",
        lastKana:
          data.owner_type === "1" ? data.lastKana : data.corporation_kana_name,
        firstKana: data.firstKana ? data.firstKana : "",
        corporation_representative_lastName:
          data.corporation_representative_lastName
            ? data.corporation_representative_lastName
            : "",
        corporation_representative_firstName:
          data.corporation_representative_firstName
            ? data.corporation_representative_firstName
            : "",
        owner_location_zip: `${data.owner_location_zip3}-${data.owner_location_zip4}`,
        owner_location_prefecture: data.owner_location_prefecture,
        owner_location_addressLine1: data.owner_location_addressLine1,
        owner_location_addressLine2: `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`,
        owner_phone: data.owner_fixed_phone,
        owner_phone_2: data.owner_phone,
        owner_email: data.owner_email,
        animal_name: data.animal_name,
        animal_group: data.animal_group === "犬" ? "1" : "2",
        animal_breed: data.animal_breed,
        animal_breed_other: data.animal_breed_other,
        animal_color: data.animal_color,
        animal_color_other: data.animal_color_other,
        animal_birthDay: moment(data.animal_birthDay).format("YYYY/MM/DD"),
        animal_gender: data.animal_gender === "オス" ? "1" : "2",
        animal_location_zip:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? `${data.owner_location_zip3}-${data.owner_location_zip4}`
            : `${data.animal_location_zip3}-${data.animal_location_zip4}`,
        animal_location_prefecture:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? data.owner_location_prefecture
            : data.animal_location_prefecture,
        animal_location_addressLine1:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? data.owner_location_addressLine1
            : data.animal_location_addressLine1,
        animal_location_addressLine2:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`
            : `${data.animal_location_addressLine2} ${data.animal_location_addressLine3}`,
        animal_note: data.animal_note,
        dog_registration_date: data.dog_registration_date
          ? data.dog_registration_date
          : "",
        dog_registration_number: data.dog_registration_number
          ? data.dog_registration_number
          : "",
        animal_handler_business_type1_slot1:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "1") &&
          data.animal_handler_business_type1_slot1
            ? data.animal_handler_business_type1_slot1
            : "",
        animal_handler_business_type1_slot2:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "2") &&
          data.animal_handler_business_type1_slot2
            ? data.animal_handler_business_type1_slot2
            : "",
        animal_handler_business_type1_slot3:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "3") &&
          data.animal_handler_business_type1_slot3
            ? data.animal_handler_business_type1_slot3
            : "",
        animal_handler_business_type1_slot4:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "4") &&
          data.animal_handler_business_type1_slot4
            ? data.animal_handler_business_type1_slot4
            : "",
        animal_handler_business_type1_slot5:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "5") &&
          data.animal_handler_business_type1_slot5
            ? data.animal_handler_business_type1_slot5
            : "",
        animal_handler_business_type1_slot6:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "6") &&
          data.animal_handler_business_type1_slot6
            ? data.animal_handler_business_type1_slot6
            : "",
        animal_handler_business_type1_slot7:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "7") &&
          data.animal_handler_business_type1_slot7
            ? data.animal_handler_business_type1_slot7
            : "",
        animal_handler_business_type2_slot1:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "1")
            ? "1"
            : "0",
        animal_handler_business_type2_slot2:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "2")
            ? "1"
            : "0",
        animal_handler_business_type2_slot3:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "3")
            ? "1"
            : "0",
        animal_handler_business_type2_slot4:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "4")
            ? "1"
            : "0",
        animal_handler_business_type2_slot5:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "5")
            ? "1"
            : "0",
        overseas: data.overseas,
        kanrikomoku: "",
      },
    ];
  }else{
    csvdata = [
      {
        mccode: data.mccode,
        mcdate: moment(data.mcdate).format("YYYY/MM/DD"),
        vet_institute: data.vet_institute,
        vet_location_zip: `${data.vet_location_zip3}-${data.vet_location_zip4}`,
        vet_location_prefecture: data.vet_location_prefecture,
        vet_location_addressLine1: data.vet_location_addressLine1,
        vet_location_addressLine2: data.vet_location_addressLine2,
        vet_phone: data.vet_phone,
        vet_name: `${data.vet_lastName} ${data.vet_firstName}`,
        ownership:
          data.ownership === "1"
            ? "1"
            : data.animal_handler_business_type === "1"
            ? "2"
            : "3",
        owner_type: data.owner_type,
        lastName: data.owner_type === "1" ? data.lastName : data.corporation_name,
        firstName: data.firstName ? data.firstName : "",
        lastKana:
          data.owner_type === "1" ? data.lastKana : data.corporation_kana_name,
        firstKana: data.firstKana ? data.firstKana : "",
        corporation_representative_lastName:
          data.corporation_representative_lastName
            ? data.corporation_representative_lastName
            : "",
        corporation_representative_firstName:
          data.corporation_representative_firstName
            ? data.corporation_representative_firstName
            : "",
        owner_location_zip: `${data.owner_location_zip3}-${data.owner_location_zip4}`,
        owner_location_prefecture: data.owner_location_prefecture,
        owner_location_addressLine1: data.owner_location_addressLine1,
        owner_location_addressLine2: `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`,
        owner_phone: data.owner_fixed_phone,
        owner_phone_2: data.owner_phone,
        owner_email: data.owner_email,
        animal_name: data.animal_name,
        animal_group: data.animal_group === "犬" ? "1" : "2",
        animal_breed: data.animal_breed,
        animal_breed_other: data.animal_breed_other,
        animal_color: data.animal_color,
        animal_color_other: data.animal_color_other,
        animal_birthDay: moment(data.animal_birthDay).format("YYYY/MM/DD"),
        animal_gender: data.animal_gender === "オス" ? "1" : "2",
        animal_location_zip:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? `${data.owner_location_zip3}-${data.owner_location_zip4}`
            : `${data.animal_location_zip3}-${data.animal_location_zip4}`,
        animal_location_prefecture:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? data.owner_location_prefecture
            : data.animal_location_prefecture,
        animal_location_addressLine1:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? data.owner_location_addressLine1
            : data.animal_location_addressLine1,
        animal_location_addressLine2:
          data.animalAddressCheck && data.animalAddressCheck[0] === "on"
            ? `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`
            : `${data.animal_location_addressLine2} ${data.animal_location_addressLine3}`,
        animal_note: data.animal_note,
        dog_registration_date: data.dog_registration_date
          ? data.dog_registration_date
          : "",
        dog_registration_number: data.dog_registration_number
          ? data.dog_registration_number
          : "",
        animal_handler_business_type1_slot1:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "1") &&
          data.animal_handler_business_type1_slot1
            ? data.animal_handler_business_type1_slot1
            : "",
        animal_handler_business_type1_slot2:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "2") &&
          data.animal_handler_business_type1_slot2
            ? data.animal_handler_business_type1_slot2
            : "",
        animal_handler_business_type1_slot3:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "3") &&
          data.animal_handler_business_type1_slot3
            ? data.animal_handler_business_type1_slot3
            : "",
        animal_handler_business_type1_slot4:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "4") &&
          data.animal_handler_business_type1_slot4
            ? data.animal_handler_business_type1_slot4
            : "",
        animal_handler_business_type1_slot5:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "5") &&
          data.animal_handler_business_type1_slot5
            ? data.animal_handler_business_type1_slot5
            : "",
        animal_handler_business_type1_slot6:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "6") &&
          data.animal_handler_business_type1_slot6
            ? data.animal_handler_business_type1_slot6
            : "",
        animal_handler_business_type1_slot7:
          data.animal_handler_business_type === "1" &&
          _.includes(data.animal_handle_1, "7") &&
          data.animal_handler_business_type1_slot7
            ? data.animal_handler_business_type1_slot7
            : "",
        animal_handler_business_type2_slot1:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "1")
            ? "1"
            : "0",
        animal_handler_business_type2_slot2:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "2")
            ? "1"
            : "0",
        animal_handler_business_type2_slot3:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "3")
            ? "1"
            : "0",
        animal_handler_business_type2_slot4:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "4")
            ? "1"
            : "0",
        animal_handler_business_type2_slot5:
          data.animal_handler_business_type === "2" &&
          _.includes(data.animal_handle_2, "5")
            ? "1"
            : "0",
        animal_mother_time: data.animal_mother_time
          ? data.animal_mother_time
          : "",
        animal_mother_mccode: data.animal_mother_mccode
          ? data.animal_mother_mccode
          : "",
        animal_mother_mccode_not_reason: data.animal_mother_mccode_not_reason
          ? data.animal_mother_mccode_not_reason
          : "",
        kanrikomoku: "",
      },
    ];
  }
  

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "登録証明書", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={4}
          />
        </Row>
        <Row className="justify-content-center my-5">
          <Col lg={6} md={8}>
            <Alert variant="success" className="text-center">
              完了しました！
            </Alert>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <div className="text-center ">
              <Link className="btn btn-secondary m-2" to="/mypage/mclist">
              登録情報一覧へ
              </Link>

              {PIN ? (
              <CSVLink
                className="btn btn-warning"
                data={csvdata}
                headers={info_change_headers}
                filename={`登録項目の変更_${data.mccode}_${moment(new Date()).format(
                  "YYYYMMDD_HHMMSS"
                )}.csv`}
                enclosingCharacter={``}
              >
                <FontAwesomeIcon icon={faFileCsv} />[登録項目の変更]CSVをダウンロード
              </CSVLink>

              ) : (
                <CSVLink
                className="btn btn-warning"
                data={csvdata}
                headers={mc_reg_headers}
                filename={`MC情報の登録_${data.mccode}_${moment(new Date()).format(
                  "YYYYMMDD_HHMMSS"
                )}.csv`}
                enclosingCharacter={``}
              >
                <FontAwesomeIcon icon={faFileCsv} />[MC情報の登録]CSVをダウンロード
              </CSVLink>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MCRegistrationFromPDFComplete;
