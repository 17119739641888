import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  faKey,
  faSignOut,
  faUserCircle,
  faFileContract,
  faEnvelope,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet, HelmetData } from "react-helmet-async";

import ChangePassword from "../../components/ChangePassword";
import Logout from "../../components/Logout";
import MyPageHeader from "../../components/MyPageHeader";
import SideBar from "../../components/SideBar";
import styles from "./MyPage.module.css";

import PersonalInfo from "./personalinfo/PersonalInfo";
import PersonalInfoCheck from "./personalinfo/PersonalInfoCheck";
import PersonalInfoConfirm from "./personalinfo/PersonalInfoConfirm";

import { useUserDetailsContext } from "../../context/UserDetailsContext";
import VetMCInstallation from "../mcregistration/VetMCInstallation";
import VetMCInstallationInput from "../mcregistration/VetMCInstallationInput";
import VetMCInstallationInputCheck from "../mcregistration/VetMCInstallationInputCheck";
import VetMCInstallationComplete from "../mcregistration/VetMCInstallationComplete";
import VetMCInstallationList from "../VetMCInstallationList";
import ChangeEmail from "../../components/ChangeEmail";

export default function VetMyPage() {
  const [searchParams] = useSearchParams();
 
  const [sideBarShow, setSideBarShow] = useState(false);

  const [activeMenu, setActiveMenu] = useState("sochaku");
  const [pageTitle, setPageTitle] = useState(" 装着証明書");

  const menus = [
    { link: "sochaku", icon: faFileContract, text:" 装着証明書"},
    { link: "sochakulist", icon: faFileContract, text:" 装着証明書一覧"},
    { link: "personalinfo", icon: faUserCircle, text: "プロフィール編集" },
    { link: "changepassword", icon: faKey, text: "パスワード変更" },
    { link: "changeemail", icon: faEnvelope, text: "メールアドレス変更" },
    { link: "createUser", icon: faUserPlus, text: "新しい一般所有を登録" },
    { link: "logout", icon: faSignOut, text: "ログアウト" },
  ];
  const sideBarShowHandlerFn = () => {
    setSideBarShow(!sideBarShow);
  };

  let location = useLocation();

  useEffect(() => {
    const loc = location.pathname.replace(/\/mypage\//g, "");
    if (loc && loc !== "/mypage") {
      setActiveMenu(loc);
      menus.map((menu) => {
        if (menu.link === loc) {
          setPageTitle(menu.text);
        } else if (
          loc === "personalinfocheck" ||
          loc === "personalinfoconfirm"
        ) {
          setPageTitle("プロフィール編集");
          setActiveMenu("personalinfo");
        } else if (
          loc === "vetmcinstallinput" ||
          loc === "vetmcinstallinputcheck" ||
          loc === "VetMCInstallationComplete"
        ) {
          setPageTitle(" 装着証明書");
          setActiveMenu("sochaku");
        }
        return true;
      });
    }
  }, [location]);

  const helmetData = new HelmetData({});

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>{pageTitle}</title>
      </Helmet>
      <MyPageHeader
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        pageTitle={pageTitle}
      />
      <SideBar
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        activeMenu={activeMenu}
        menus={menus}
      />
      <div className={styles.mainBody}>
        <Routes>
          <Route path="/" element={<VetMCInstallation />} />
          <Route path="sochaku" element={<VetMCInstallation />} />
          <Route path="vetmcinstallinput" element={<VetMCInstallationInput />} />
          <Route path="vetmcinstallinputcheck" element={<VetMCInstallationInputCheck />} />
          <Route path="VetMCInstallationComplete" element={<VetMCInstallationComplete />} />

          <Route path="sochakulist" element={<VetMCInstallationList />} />

          <Route path="personalinfo" element={<PersonalInfo />} />
          <Route path="personalinfocheck" element={<PersonalInfoCheck />} />
          <Route path="personalinfoconfirm" element={<PersonalInfoConfirm />} />
          

          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="changeemail" element={<ChangeEmail />} />
          <Route path="logout" element={<Logout />} />
        </Routes>
      </div>
    </>
  );
}
