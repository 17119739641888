import _ from "lodash";
import moment from "moment";

export default function useCsvDownload() {
  const getCSVData = async (mcDatas, pinCheck) => {
    if (pinCheck) {
      return mcDatas.map((mc) => {
        let data = mc.mcData;
        return {
          mccode: data.mccode,
          secretcode: mc.PIN,
          ownership:
            data.ownership === "1"
              ? "1"
              : data.animal_handler_business_type === "1"
              ? "2"
              : "3",
          owner_type: data.owner_type,
          lastName:
            data.owner_type === "1" ? data.lastName : data.corporation_name,
          firstName: data.firstName ? data.firstName : "",
          lastKana:
            data.owner_type === "1"
              ? data.lastKana
              : data.corporation_kana_name,
          firstKana: data.firstKana ? data.firstKana : "",
          corporation_representative_lastName:
            data.corporation_representative_lastName
              ? data.corporation_representative_lastName
              : "",
          corporation_representative_firstName:
            data.corporation_representative_firstName
              ? data.corporation_representative_firstName
              : "",
          owner_location_zip: `${data.owner_location_zip3}-${data.owner_location_zip4}`,
          owner_location_prefecture: data.owner_location_prefecture,
          owner_location_addressLine1: data.owner_location_addressLine1,
          owner_location_addressLine2: `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`,
          owner_phone: data.owner_fixed_phone,
          owner_phone_2: data.owner_phone,
          owner_email: data.owner_email,
          animal_name: data.animal_name,
          animal_group: data.animal_group === "犬" ? "1" : "2",
          animal_breed: data.animal_breed,
          animal_breed_other: data.animal_breed_other,
          animal_color: data.animal_color,
          animal_color_other: data.animal_color_other,
          animal_birthDay: moment(data.animal_birthDay).format("YYYY/MM/DD"),
          animal_gender: data.animal_gender === "オス" ? "1" : "2",
          animal_location_zip:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? `${data.owner_location_zip3}-${data.owner_location_zip4}`
              : `${data.animal_location_zip3}-${data.animal_location_zip4}`,
          animal_location_prefecture:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? data.owner_location_prefecture
              : data.animal_location_prefecture,
          animal_location_addressLine1:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? data.owner_location_addressLine1
              : data.animal_location_addressLine1,
          animal_location_addressLine2:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`
              : `${data.animal_location_addressLine2} ${data.animal_location_addressLine3}`,
          animal_note: data.animal_note,
          dog_registration_date: data.dog_registration_date
            ? data.dog_registration_date
            : "",
          dog_registration_number: data.dog_registration_number
            ? data.dog_registration_number
            : "",
          animal_handler_business_type1_slot1:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "1") &&
            data.animal_handler_business_type1_slot1
              ? data.animal_handler_business_type1_slot1
              : "",
          animal_handler_business_type1_slot2:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "2") &&
            data.animal_handler_business_type1_slot2
              ? data.animal_handler_business_type1_slot2
              : "",
          animal_handler_business_type1_slot3:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "3") &&
            data.animal_handler_business_type1_slot3
              ? data.animal_handler_business_type1_slot3
              : "",
          animal_handler_business_type1_slot4:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "4") &&
            data.animal_handler_business_type1_slot4
              ? data.animal_handler_business_type1_slot4
              : "",
          animal_handler_business_type1_slot5:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "5") &&
            data.animal_handler_business_type1_slot5
              ? data.animal_handler_business_type1_slot5
              : "",
          animal_handler_business_type1_slot6:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "6") &&
            data.animal_handler_business_type1_slot6
              ? data.animal_handler_business_type1_slot6
              : "",
          animal_handler_business_type1_slot7:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "7") &&
            data.animal_handler_business_type1_slot7
              ? data.animal_handler_business_type1_slot7
              : "",
          animal_handler_business_type2_slot1:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "1")
              ? "1"
              : "0",
          animal_handler_business_type2_slot2:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "2")
              ? "1"
              : "0",
          animal_handler_business_type2_slot3:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "3")
              ? "1"
              : "0",
          animal_handler_business_type2_slot4:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "4")
              ? "1"
              : "0",
          animal_handler_business_type2_slot5:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "5")
              ? "1"
              : "0",
          overseas: data.overseas ? data.overseas : "1",
          kanrikomoku: "",
        };
      });
    } else {
      return mcDatas.map((mc) => {
        let data = mc.mcData;
        return {
          mccode: data.mccode,
          mcdate: moment(data.mcdate).format("YYYY/MM/DD"),
          vet_institute: data.vet_institute,
          vet_location_zip: `${data.vet_location_zip3}-${data.vet_location_zip4}`,
          vet_location_prefecture: data.vet_location_prefecture,
          vet_location_addressLine1: data.vet_location_addressLine1,
          vet_location_addressLine2: data.vet_location_addressLine2,
          vet_phone: data.vet_phone,
          vet_name: `${data.vet_lastName} ${data.vet_firstName}`,
          ownership:
            data.ownership === "1"
              ? "1"
              : data.animal_handler_business_type === "1"
              ? "2"
              : "3",
          owner_type: data.owner_type,
          lastName:
            data.owner_type === "1" ? data.lastName : data.corporation_name,
          firstName: data.firstName ? data.firstName : "",
          lastKana:
            data.owner_type === "1"
              ? data.lastKana
              : data.corporation_kana_name,
          firstKana: data.firstKana ? data.firstKana : "",
          corporation_representative_lastName:
            data.corporation_representative_lastName
              ? data.corporation_representative_lastName
              : "",
          corporation_representative_firstName:
            data.corporation_representative_firstName
              ? data.corporation_representative_firstName
              : "",
          owner_location_zip: `${data.owner_location_zip3}-${data.owner_location_zip4}`,
          owner_location_prefecture: data.owner_location_prefecture,
          owner_location_addressLine1: data.owner_location_addressLine1,
          owner_location_addressLine2: `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`,
          owner_phone: data.owner_fixed_phone,
          owner_phone_2: data.owner_phone,
          owner_email: data.owner_email,
          animal_name: data.animal_name,
          animal_group: data.animal_group === "犬" ? "1" : "2",
          animal_breed: data.animal_breed,
          animal_breed_other: data.animal_breed_other,
          animal_color: data.animal_color,
          animal_color_other: data.animal_color_other,
          animal_birthDay: moment(data.animal_birthDay).format("YYYY/MM/DD"),
          animal_gender: data.animal_gender === "オス" ? "1" : "2",
          animal_location_zip:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? `${data.owner_location_zip3}-${data.owner_location_zip4}`
              : `${data.animal_location_zip3}-${data.animal_location_zip4}`,
          animal_location_prefecture:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? data.owner_location_prefecture
              : data.animal_location_prefecture,
          animal_location_addressLine1:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? data.owner_location_addressLine1
              : data.animal_location_addressLine1,
          animal_location_addressLine2:
            data.animalAddressCheck && data.animalAddressCheck[0] === "on"
              ? `${data.owner_location_addressLine2} ${data.owner_location_addressLine3}`
              : `${data.animal_location_addressLine2} ${data.animal_location_addressLine3}`,
          animal_note: data.animal_note,
          dog_registration_date: data.dog_registration_date
            ? data.dog_registration_date
            : "",
          dog_registration_number: data.dog_registration_number
            ? data.dog_registration_number
            : "",
          animal_handler_business_type1_slot1:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "1") &&
            data.animal_handler_business_type1_slot1
              ? data.animal_handler_business_type1_slot1
              : "",
          animal_handler_business_type1_slot2:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "2") &&
            data.animal_handler_business_type1_slot2
              ? data.animal_handler_business_type1_slot2
              : "",
          animal_handler_business_type1_slot3:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "3") &&
            data.animal_handler_business_type1_slot3
              ? data.animal_handler_business_type1_slot3
              : "",
          animal_handler_business_type1_slot4:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "4") &&
            data.animal_handler_business_type1_slot4
              ? data.animal_handler_business_type1_slot4
              : "",
          animal_handler_business_type1_slot5:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "5") &&
            data.animal_handler_business_type1_slot5
              ? data.animal_handler_business_type1_slot5
              : "",
          animal_handler_business_type1_slot6:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "6") &&
            data.animal_handler_business_type1_slot6
              ? data.animal_handler_business_type1_slot6
              : "",
          animal_handler_business_type1_slot7:
            data.animal_handler_business_type === "1" &&
            _.includes(data.animal_handle_1, "7") &&
            data.animal_handler_business_type1_slot7
              ? data.animal_handler_business_type1_slot7
              : "",
          animal_handler_business_type2_slot1:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "1")
              ? "1"
              : "0",
          animal_handler_business_type2_slot2:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "2")
              ? "1"
              : "0",
          animal_handler_business_type2_slot3:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "3")
              ? "1"
              : "0",
          animal_handler_business_type2_slot4:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "4")
              ? "1"
              : "0",
          animal_handler_business_type2_slot5:
            data.animal_handler_business_type === "2" &&
            _.includes(data.animal_handle_2, "5")
              ? "1"
              : "0",
          animal_mother_time: data.animal_mother_time
            ? data.animal_mother_time
            : "",
          animal_mother_mccode: data.animal_mother_mccode
            ? data.animal_mother_mccode
            : "",
          animal_mother_mccode_not_reason: data.animal_mother_mccode_not_reason
            ? data.animal_mother_mccode_not_reason
            : "",
          kanrikomoku: "",
        };
      });
    }
  };

  const getCSVTransferData = async (mcDatas) => {
    return mcDatas.map((mc) => {
      let nextOwner = mc.nextOwner;
      let transferAnimal = mc.transferMCData;
      
      return {
        mccode: mc.mccode,
        secretcode: mc.PIN,
        ownership:
          nextOwner.group === "owner"
            ? "1"
            : nextOwner.animal_handler_business_type === "1"
            ? "2"
            : "3",
        owner_type: nextOwner.type,
        lastName:
          nextOwner.type === "1"
            ? nextOwner.lastName
            : nextOwner.corporation_name,
        firstName: nextOwner.firstName ? nextOwner.firstName : "",
        lastKana:
          nextOwner.type === "1"
            ? nextOwner.lastKana
            : nextOwner.corporation_kana_name,
        firstKana: nextOwner.firstKana ? nextOwner.firstKana : "",
        corporation_representative_lastName:
          nextOwner.corporation_representative_lastName
            ? nextOwner.corporation_representative_lastName
            : "",
        corporation_representative_firstName:
          nextOwner.corporation_representative_firstName
            ? nextOwner.corporation_representative_firstName
            : "",
        owner_location_zip: `${nextOwner.zip3}-${nextOwner.zip4}`,
        owner_location_prefecture: nextOwner.prefecture,
        owner_location_addressLine1: nextOwner.addressLine1,
        owner_location_addressLine2: `${nextOwner.addressLine2} ${nextOwner.addressLine3}`,
        owner_phone: nextOwner.fixed_phone,
        owner_phone_2: nextOwner.phone,
        owner_email: nextOwner.email,
        animal_name: transferAnimal.animal_name,
        animal_group: transferAnimal.animal_group === "犬" ? "1" : "2",
        animal_breed: transferAnimal.animal_breed,
        animal_breed_other: transferAnimal.animal_breed_other,
        animal_color: transferAnimal.animal_color,
        animal_color_other: transferAnimal.animal_color_other,
        animal_birthDay: moment(transferAnimal.animal_birthDay).format("YYYY/MM/DD"),
        animal_gender: transferAnimal.animal_gender === "オス" ? "1" : "2",
        animal_location_zip:
          transferAnimal.animalAddressCheck && transferAnimal.animalAddressCheck[0] === "on"
            ? `${nextOwner.zip3}-${nextOwner.zip4}`
            : `${transferAnimal.animal_location_zip3}-${transferAnimal.animal_location_zip4}`,
        animal_location_prefecture:
          transferAnimal.animalAddressCheck &&
          transferAnimal.animalAddressCheck[0] === "on"
            ? nextOwner.prefecture
            : transferAnimal.animal_location_prefecture,
        animal_location_addressLine1:
          transferAnimal.animalAddressCheck &&
          transferAnimal.animalAddressCheck[0] === "on"
            ? nextOwner.addressLine1
            : transferAnimal.animal_location_addressLine1,
        animal_location_addressLine2:
          transferAnimal.animalAddressCheck &&
          transferAnimal.animalAddressCheck[0] === "on"
            ? `${nextOwner.addressLine2} ${nextOwner.addressLine3}`
            : `${transferAnimal.animal_location_addressLine2} ${transferAnimal.animal_location_addressLine3}`,
        animal_note: transferAnimal.animal_note,
        dog_registration_date: transferAnimal.dog_registration_date
          ? transferAnimal.dog_registration_date
          : "",
        dog_registration_number: transferAnimal.dog_registration_number
          ? transferAnimal.dog_registration_number
          : "",
        animal_handler_business_type1_slot1:
          nextOwner.animal_handler_business_type === "1" &&
          _.includes(nextOwner.animal_handle_1, "1") &&
          nextOwner.animal_handler_business_type1_slot1
            ? nextOwner.animal_handler_business_type1_slot1
            : "",
        animal_handler_business_type1_slot2:
          nextOwner.animal_handler_business_type === "1" &&
          _.includes(nextOwner.animal_handle_1, "2") &&
          nextOwner.animal_handler_business_type1_slot2
            ? nextOwner.animal_handler_business_type1_slot2
            : "",
        animal_handler_business_type1_slot3:
          nextOwner.animal_handler_business_type === "1" &&
          _.includes(nextOwner.animal_handle_1, "3") &&
          nextOwner.animal_handler_business_type1_slot3
            ? nextOwner.animal_handler_business_type1_slot3
            : "",
        animal_handler_business_type1_slot4:
          nextOwner.animal_handler_business_type === "1" &&
          _.includes(nextOwner.animal_handle_1, "4") &&
          nextOwner.animal_handler_business_type1_slot4
            ? nextOwner.animal_handler_business_type1_slot4
            : "",
        animal_handler_business_type1_slot5:
          nextOwner.animal_handler_business_type === "1" &&
          _.includes(nextOwner.animal_handle_1, "5") &&
          nextOwner.animal_handler_business_type1_slot5
            ? nextOwner.animal_handler_business_type1_slot5
            : "",
        animal_handler_business_type1_slot6:
          nextOwner.animal_handler_business_type === "1" &&
          _.includes(nextOwner.animal_handle_1, "6") &&
          nextOwner.animal_handler_business_type1_slot6
            ? nextOwner.animal_handler_business_type1_slot6
            : "",
        animal_handler_business_type1_slot7:
          nextOwner.animal_handler_business_type === "1" &&
          _.includes(nextOwner.animal_handle_1, "7") &&
          nextOwner.animal_handler_business_type1_slot7
            ? nextOwner.animal_handler_business_type1_slot7
            : "",
        animal_handler_business_type2_slot1:
          nextOwner.animal_handler_business_type === "2" &&
          _.includes(nextOwner.animal_handle_2, "1")
            ? "1"
            : "0",
        animal_handler_business_type2_slot2:
          nextOwner.animal_handler_business_type === "2" &&
          _.includes(nextOwner.animal_handle_2, "2")
            ? "1"
            : "0",
        animal_handler_business_type2_slot3:
          nextOwner.animal_handler_business_type === "2" &&
          _.includes(nextOwner.animal_handle_2, "3")
            ? "1"
            : "0",
        animal_handler_business_type2_slot4:
          nextOwner.animal_handler_business_type === "2" &&
          _.includes(nextOwner.animal_handle_2, "4")
            ? "1"
            : "0",
        animal_handler_business_type2_slot5:
          nextOwner.animal_handler_business_type === "2" &&
          _.includes(nextOwner.animal_handle_2, "5")
            ? "1"
            : "0",
        kanrikomoku: "",
      };
    });
  };
  return { getCSVData, getCSVTransferData };
}
