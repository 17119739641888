import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import {
  faEnvelope,
  faHeart,
  faKey,
  faList,
  faSignOut,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { Helmet, HelmetData } from "react-helmet-async";

import MCList from "../MCList";
import styles from "./MyPage.module.css";
import PaymentList from "../PaymentList";
import Logout from "../../components/Logout";
import CardInfo from "../creditcard/CardInfo";
import SideBar from "../../components/SideBar";
import FavouriteList from "./Favourite/FavouriteList";
import PersonalInfo from "./personalinfo/PersonalInfo";
import ChangeEmail from "../../components/ChangeEmail";
import MyPageHeader from "../../components/MyPageHeader";
import PetShopMCOTP from "../mcregistration/PetShopMCOTP";
import PetShopMCPay from "../mcregistration/PetShopMCPay";
import ChangePassword from "../../components/ChangePassword";
import CardRegistration from "../creditcard/CardRegistration";
import PersonalInfoCheck from "./personalinfo/PersonalInfoCheck";
import PersonalInfoConfirm from "./personalinfo/PersonalInfoConfirm";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import PetShopMCRegistration from "../mcregistration/PetShopMCRegistration";
import PetShopMCTransferConfirm from "../mcregistration/PetShopMCTransferConfirm";
import PetShopMCRegistrationComplete from "../mcregistration/PetShopMCRegistrationComplete";

export default function OwnerMyPage() {
  const [searchParams] = useSearchParams();
  const { userData } = useUserDetailsContext();
  const [sideBarShow, setSideBarShow] = useState(false);
  const [activeMenu, setActiveMenu] = useState("mclist");
  const [pageTitle, setPageTitle] = useState("ペットリスト");
  const menus = [
    { link: "mclist", icon: faList, text: "ペットリスト" },
    // { link: "mcregistration", icon: faMicrochip, text: "MC情報・新しいペット登録" },
    // { link: "cardinfo", icon: faCreditCard, text: "カード情報" },
    // {
    //   link: "paymenthistory",
    //   icon: faMoneyCheckDollar,
    //   text: "決済履歴・詳細",
    // },
    { link: "personalinfo", icon: faUserCircle, text: "プロフィール編集" },
    { link: "changepassword", icon: faKey, text: "パスワード変更" },
    { link: "changeemail", icon: faEnvelope, text: "メールアドレス変更" },
    { link: "favourite-pets", icon: faHeart, text: "お気に入りリスト" },
    { link: "logout", icon: faSignOut, text: "ログアウト" }
  ];
  const sideBarShowHandlerFn = () => {
    setSideBarShow(!sideBarShow);
  };

  let location = useLocation();

  useEffect(() => {
    const loc = location.pathname.replace(/\/mypage\//g, "");
    if (loc && loc !== "/mypage") {
      setActiveMenu(loc);
      menus.map((menu) => {
        if (menu.link === loc) {
          setPageTitle(menu.text);
        }
        return true;
      });
    }
  }, [location]);

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>{pageTitle}</title>
      </Helmet>
      <MyPageHeader
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        pageTitle={pageTitle}
      />
      <SideBar
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        activeMenu={activeMenu}
        menus={menus}
      />
      <div className={styles.mainBody}>
        <Routes>
          <Route path="mcregistration" element={<PetShopMCRegistration />} />
          <Route path="mcotp" element={<PetShopMCOTP />} />
          <Route path="mcconfirm" element={<PetShopMCTransferConfirm />} />
          <Route
            path="mcpay"
            element={<PetShopMCPay komoju_customer_id={userData.komoju_customer_id || null} />}
          />
          <Route path="mcregistrationcomplete" element={<PetShopMCRegistrationComplete />} />

          <Route path="/" element={<MCList />} />
          <Route path="mclist" element={<MCList />} />
          <Route path="paymenthistory" element={<PaymentList />} />

          <Route path="personalinfo" element={<PersonalInfo />} />
          <Route path="personalinfocheck" element={<PersonalInfoCheck />} />
          <Route path="personalinfoconfirm" element={<PersonalInfoConfirm />} />

          <Route
            path="cardinfo"
            element={
              userData.komoju_customer_id ? (
                <CardInfo komoju_customer_id={userData.komoju_customer_id} />
              ) : (
                <CardRegistration returnUrl="/mypage/cardinfo" />
              )
            }
          />
          <Route
            path="changecardinfo"
            element={
              <CardRegistration
                komoju_customer_id={userData.komoju_customer_id}
                returnUrl={searchParams.get("returnUrl") || "/mypage/cardinfo"}
              />
            }
          />

          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="changeemail" element={<ChangeEmail />} />
          <Route path="favourite-pets" element={<FavouriteList />} />
          <Route path="logout" element={<Logout />} />
          {/* <Route path="*" element={<Navigate to="/404" />} /> */}
        </Routes>
      </div>
    </>
  );
}
