import React, { useState } from "react";
import { Alert, Table } from "react-bootstrap";
import BreederMCDetailsModal from "../../components/BreederMCDetailsModal";
import BreederMCInstallationListItem from "../../components/BreederMCInstallationListItem";
import VetMCDetailsModal from "../../components/VetMCDetailsModal";

export default function CertificateList({ certificateRequests }) {
  const [MCModalContent, setMCModalContent] = useState(null);
  const [showMCDetailsModal, setShowMCDetailsModal] = useState(false);

  const MCModalHandler = (props) => {
    setShowMCDetailsModal(true);
    setMCModalContent(props);
  };

  return (
    <>
      {certificateRequests && (
        <Table striped bordered responsive="sm">
          <thead>
            <tr>
              <th>MC番号</th>
              <th className="">獣医師</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {certificateRequests.map((doc, i) => (
              <BreederMCInstallationListItem
                doc={doc}
                modalFun={MCModalHandler}
                key={i}
              />
            ))}
          </tbody>
        </Table>
      )}

      {!certificateRequests && (
        <Alert variant="warning" className="text-center">
            新装着証明書がありません
        </Alert>
      )}
      <BreederMCDetailsModal
        data={MCModalContent}
        show={showMCDetailsModal}
        onHide={() => setShowMCDetailsModal(false)}
      />
    </>
  );
}
