import { useSearchParams } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import RegEmailVarification from "./RegEmailVarification";
import VerifyOTP from "./VerifyOTP";

export default function VarifyEmail() {
  const [searchParams] = useSearchParams();
  return (
    <>
      {(searchParams.get("mode") === "verifyEmail" ||
        searchParams.get("mode") === "verifyAndChangeEmail" ||
        searchParams.get("mode") === "recoverEmail") && (
        <RegEmailVarification />
      )}
      {searchParams.get("mode") === "resetPassword" && <ResetPassword />}
      {searchParams.get("mode") === "verifyOTP" && <VerifyOTP />}
    </>
  );
}
