import { useState, useEffect } from "react";
//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import { applyActionCode, checkActionCode } from "firebase/auth";
import { useSetDoc } from "./useSetDoc";

export const useVarifyEmail = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const {
    firebaseSetDocQuery,
    isPending: isPendingUpdate,
    isConfirmed: isConfirmedUpdate,
    error: errorUpdate
  } = useSetDoc();

  const varifyEmail = (oobCode) => {
    setError(null);
    setMessage(null);
    setIsPending(true);
    checkActionCode(auth, oobCode)
      .then((info) => {
        // console.log(info);
        if (
          info["operation"] === "VERIFY_AND_CHANGE_EMAIL" ||
          info["operation"] === "RECOVER_EMAIL"
        ) {
          firebaseSetDocQuery(
            `users`,
            { email: info["data"]["email"] },
            [["email", "==", info["data"]["previousEmail"]]],
            true
          );
        }
        applyActionCode(auth, oobCode)
          .then(() => {
            setMessage("アカウントを有効にしました");
            setIsPending(false);
            setIsConfirmed(true);
          })
          .catch((err) => {
            setError(firebaseErrors[err.code] || err.message);
            // setError("アカウントを有効にすることが出来ません");
            setIsPending(false);
          });
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        // setError("Invalid action");
        setIsPending(false);
      });
  };
  return { error, isPending, message, isConfirmed, varifyEmail };
};
