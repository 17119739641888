import React from "react";

function AidContent() {
  return (
    <>
      これは、「プロフェッショナル会員」（第一種動物取扱業者）が「先天性障害」（別表Ⅰに記載の疾患に限る）による「契約不適合」の発生に伴い「パーソナル会員」（飼い主）に対する法律上の責任をプロフェッショナル会員同士で互助する制度です。{" "}
      <br />
        <br />
      【仕組み】 <br />
      （１）名称：先天性障害に関わる契約不適合互助制度 <br />
      （２）制度の仕組み <br />
      この制度は、日本国内において、プロフェッショナル会員が販売し、引き渡した動物に先天性・遺伝性の異常(別表1に記載の疾患に限る)が判明した時に、プロフェッショナル会員がパーソナル会員（飼い主）に対して契約不適合責任を履行したことにより実際に支出した費用(瑕疵が判明した動物と引き換えに代わりの動物を提供するための費用)、またはその費用に代わる損害賠償金を保証する制度です。{" "}
      <br />
      賠償金縮小支払割合および支払限度額は以下のとおりとなります。 <br />
      保険金支払限度額＝「被保険動物の販売価格×縮小支払割合50％」または「25万円」のいずれか低い額{" "}
      <br />
      （３）責任開始の始期および終期 <br />
      動物の引渡し日が責任開始日となり、保証期間は引渡し日から１年とし、保証期間の満了をもって終了します。{" "}
      <br />
      （４）対象の動物 <br />
      対象動物とは、日本国内において、マイクロチップ登録システムによりパーソナル会員に譲渡され環境省のデータベースに登録された動物(注)であって動物愛護管理法第22条の6第1項に規定する犬猫等の個体に関する帳簿に記載された動物に限ります。{" "}
      <br />
      (注)販売の用に供する犬または猫をいい、出生後90日を経過したものを除きます。{" "}
      <br />
      【保証内容について】 <br />
      （１）保証の対象となる費用 <br />
      1 パーソナル会員が契約不適合責任の履行により実際に支出した代替動物引渡しのために要した直接費用(注)。{" "}
      <br />
      ただし、当社が事前に認めた必要かつ妥当な費用に限ります。 <br />
      2 ①の代替動物の提供に代わり損害賠償金を支払う場合においては、その損害賠償金{" "}
      <br />
      (注) ①のうち、代替動物を再繁殖、再飼養または再調達するのに通常要する交配費、健康管理費、飼育費、人{" "}
      <br />
      件費、仕入れ代等の実費をいい、対象動物の販売価格を超過しないものとします。{" "}
      <br />
      （２）保証の対象とならない費用 <br />
      前項①のうち、代替動物を買い主に引渡すための交通費、宿泊費または運搬費等の費用{" "}
      <br />
      【請求の手続きについて】 <br />
      （１）保証金は、以下が確定したときからご請求いただけます。 <br />
      ①　保証金を支払う事由による損害の額 <br />
      3 プロフェッショナル会員とパーソナル会員との間の書面による合意または判決、調停もしくは裁判上の和解の{" "}
      <br />
      いずれかによって決定した額 <br />
      （２）保証金を請求する場合は、次表の書類または証拠のうち、当社が求めるものをご提出ください。{" "}
      <br />
      保証金請求に必要な書類または証拠 <br />
      1 当会所定の保証金請求書 <br />
      2 被保証動物の販売または引渡しがあったことが確認できる売買契約書の写しおよび帳簿または法定帳簿の写し等の客観的な書類{" "}
      <br />
      3 被保証動物の障害に対する獣医師が作成した診断書 <br />
      4 被保証動物の死亡に関して獣医師が作成した死亡診断書または死体検案書{" "}
      <br />
      5 代替動物の提供に係る保証金の請求に関しては、代替動物の引渡しがあったことおよびその価格{" "}
      <br />
      が確認できる客観的な書類ならびに代替動物の写真(画像データを含みます。){" "}
      <br />
      6 プロフェッショナル会員がパーソナル会員に対して履行する契約不適合責任の額を示す示談書および損害賠償金の支払またはパーソナル会員の承諾があったことを示す書類{" "}
      <br />
      7 その他当会が定める必要な事項の確認を行うために欠くことのできない書類または証拠として{" "}
      <br />
      保険互助会利用の際に当社が書面等において定めたもの <br />
      （３）当会は事故の内容および損害の額等に応じ、各会員に対して、（２）に掲げるもの以外の書類もしくは証拠の提出または当社が行う調査への協力を求めることがあります。この場合には、当会が求めた書類または証拠を速やかに提出し、必要な協力をお願いいたします。{" "}
      <br />
      （４）会員が正当な理由がなく（３）の規定に違反した場合または（２）もしくは（３）の書類に事実と異なる記載をし、もしくはその書類もしくは証拠を偽造しもしくは変造した場合、当会はそれによって被った損害の額を差し引いて保証金をお支払いします。{" "}
      <br />
      （５）保証金の請求権は、（１）に定める時の翌日から起算して３年を経過した場合は、時効によって消滅します。{" "}
      <br />
      いただいた書類から、審査および金額の確認を行った後、特別な場合を除き、保証金請求書類到着後30日以内にご指定{" "}
      <br />
      いただいた会員ご本人の口座にお振込いたします。ご郵送いただいた書類に不備等がありますと、保証金支払いが{" "}
      <br />
      遅れる場合がありますのでご注意ください。また、保証金の支払いに必要な事項の確認を行うため、特別な照会または{" "}
      <br />
      調査が不可欠な場合は上記の日数を超過することがあります。 <br />
      【保証金をお支払いできない場合】 <br />
      （１）当会は、直接であると間接であるとを問わず、次のいずれかに該当する事由によって生じた損害については、保証金をお支払いしません。{" "}
      <br />
      ①　会員またはそれらの者と雇用契約のある者の故意または重大な過失 <br />
      ②　会員と雇用契約のある者の故意または重大な過失による法令違反 <br />
      4 会員と雇用契約のある者の被保険動物の著しく不適切な繁殖、飼養または管理{" "}
      <br />
      5 被保証動物の生理的老化またはその他類似の事由 <br />
      6 被保証動物の契約不適合に起因して生じた他の動物の障害または被保証動物以外の財物の滅失、き損、汚損もしくは使用の阻害{" "}
      <br />
      ⑥　被保証動物の売買契約締結時において実用化されていた医療技術では予防することが不可能な現象、またはこれが原因で生じた事由{" "}
      <br />
      7 代替動物の契約不適合 <br />
      8 会員が被保証動物を転売した場合において、転売後に発見された被保証動物の契約不適合{" "}
      <br />
      9 被保証動物の輸送中または輸送に伴う保管中に生じた事故 <br />
      ⑩　地震、噴火、津波または高潮 <br />
      ⑪　戦争（宣戦の有無を問いません。）、変乱、暴動、騒じょうまたは労働争議{" "}
      <br />
      ⑫　核燃料物質もしくは核燃料物質によって汚染された物の放射性、爆発性その他の有害な特性またはこれらの特性による事故{" "}
      <br />
      （注）会員が法人である場合、その理事、取締役または法人の業務を遂行するその他の機関をいいます。{" "}
      <br />
      （２）当会は、会員と他人との間に損害賠償に関する特別の約定がある場合において、この普通保険約款で担保すべき契約不適合責任以上に加重された責任については、保証金をお支払いしません。{" "}
      <br />
      （３）当会は、被保証動物の回収または処分等に要した費用については、保証金をお支払いしません。{" "}
      <br />
      （４）当会は、会員が動物愛護管理法に定める第一種動物取扱業の登録を受けていない間に行った売買契約に関する一切の損害に対して、保証金をお支払いしません。{" "}
      <br />
      【保証料に関する事項】 <br />
      保証料は、１年の保証期間で11,000円（税込）となります。制度運営上、損害率が上昇した場合は、１か月前の告知をもって、当会が再算定した保証料に改定することがあります。{" "}
      <br />
      【保証料の払込方法について】 <br />
      保証料の全額を、保証期間の初日までに、クレジットカードで決済していただきます。{" "}
      <br />
      【満期返れい金・配当金に関する事項】 <br />
      この「先天性障害に関わる契約不適合互助制度」は、掛け捨てのため、満期返れい金・契約者配当はありません。{" "}
      <br />
      【解約に関する事項】 <br />
      （1） 会員は、契約日より８日以内にクーリングオフすることができます。{" "}
      <br />
      （2） 会員は契約日より９日以降は、本互助制度の脱会は可能ですが、保証料の返戻はいたしません。{" "}
      <br />
      【お客様のニーズ確認】 <br />
      この保険商品が、お客様のニーズに合致しているかどうかをご確認のうえ、お申込みください。{" "}
      <br />
      【告知義務等の内容】 <br />
      告知義務とは、会員が当制度を利用する際に、被保証動物に対する重要な事実をありのままに当会に申し出る義務、および重要な事項について事実と異なることを申し出てはならないという義務のことです。{" "}
      <br />
      会員が故意または重大な過失によって、事実を告知されなかったり、事実と異なることを告知されたりしますと、当会はご加入を解除することがあります。この場合、すでにお支払いいただいた保証料は払い戻しいたしません。{" "}
      <br />
      【通知義務等の内容】 <br />
      （１）制度利用の後、次のいずれかに該当する事実が発生した場合は、会員は、遅滞なく、その旨を当会に書面または電磁的方法によりご通知ください。ただし、その事実がなくなった場合は、当会への通知は必要ありません。{" "}
      <br />
      ①　第一種動物取扱業者の登録を取り消されたとき <br />
      ②　他の保険契約等を締結したこと <br />
      ③　①および②のほか、告知事項の内容に変更を生じさせる事実が発生したこと{" "}
      <br />
      （２）会員のご住所を変更された場合、遅滞なく、書面または電磁的方法により、その旨を当会にご通知ください。{" "}
      <br />
      （３）次の内容につきましては、変更できません。 <br />
      　　①　被保証動物の変更 <br />
      　　②　保証期間の変更 <br />
      <br />
      [別表Ⅰ]【保証する先天性障害疾患の一覧】
      <br />
      <div className="d-inline w-100">
        <div className="fit-width d-inline float-start pe-3 pt-3">
          <small className="no-wrap">大動脈弁狭窄症</small> <br />
          <small className="no-wrap">心房中隔欠損</small> <br />
          <small className="no-wrap">短頭型症候群</small> <br />
          <small className="no-wrap">心臓欠陥</small> <br />
          <small className="no-wrap">キアリ様奇形</small> <br />
          <small className="no-wrap">口唇裂</small> <br />
          <small className="no-wrap">口蓋裂</small> <br />
          <small className="no-wrap">コリー眼異常</small> <br />
          <small className="no-wrap">先天性症状</small> <br />
          <small className="no-wrap">睫毛重生</small> <br />
          <small className="no-wrap">表皮異形成</small> <br />
        </div>

        <div className="fit-width  d-inline float-start pe-3 pt-3">
          <small className="no-wrap">肝微小血管異形成</small> <br />
          <small className="no-wrap">僧帽弁異形成</small> <br />
          <small className="no-wrap">腎異形成</small> <br />
          <small className="no-wrap">異所性睫毛</small> <br />
          <small className="no-wrap">眼瞼外反</small> <br />
          <small className="no-wrap">眼瞼内反</small> <br />
          <small className="no-wrap">裂孔ヘルニア</small> <br />
          <small className="no-wrap">無孔涙点</small> <br />
          <small className="no-wrap">不正咬合</small> <br />
          <br />
          <br />
        </div>

        <div className="fit-width  d-inline float-start pe-3 pt-3">
          <small className="no-wrap">重症筋無力症</small> <br />
          <small className="no-wrap">膝蓋骨脱臼</small> <br />
          <small className="no-wrap">動脈管開存</small> <br />
          <small className="no-wrap">門脈体循環シャント</small>
          <br />
          <small className="no-wrap">外陰部低形成</small> <br />
          <small className="no-wrap">網膜異形成</small> <br />
          <small className="no-wrap">皺襞製皮膚炎</small> <br />
          <small className="no-wrap">外鼻孔狭窄症</small> <br />
          <small className="no-wrap">埋伏歯</small> <br />
          <small className="no-wrap">睫毛乱生</small> <br />
          <small className="no-wrap">血管輪奇形</small> <br />
        </div>

        <div className="fit-width  d-inline float-start pe-3 pt-3">
          <small className="no-wrap">心室中隔欠損</small> <br />
          <small className="no-wrap">涙管狭窄</small> <br />
          <small className="no-wrap">肥大型心筋症</small> <br />
          <small className="no-wrap">水頭症</small> <br />
          <small className="no-wrap">肺動脈弁狭窄</small> <br />
          <small className="no-wrap">若齢性白内障</small> <br />
          <small className="no-wrap">銅関連性肝障害</small> <br />
          <small className="no-wrap">角膜ジストロフィー</small> <br />
          <small className="no-wrap">肘関節形成異常</small> <br />
          <small className="no-wrap">股関節形成不全</small> <br />
          <br />
        </div>

        <div className="fit-width  d-inline float-start pe-3 pt-3">
          <small className="no-wrap">緑内障</small> <br />
          <small className="no-wrap">レッグペルテス病</small> <br />
          <small className="no-wrap">離断性骨軟骨炎</small> <br />
          <small className="no-wrap">血小板障害</small> <br />
          <small className="no-wrap">進行性網膜萎縮症</small> <br />
          <small className="no-wrap">フォン・ウィルブランド病</small> <br />
        </div>
      </div>
    </>
  );
}

export default AidContent;
