import {
  faCircleArrowDown,
  faCircleArrowRight,
  faCircleChevronRight,
  faDownload,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import MCDetailsTable from "../../components/MCDetailsTable";
import ProgressStep from "../../components/ProgressStep";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import useCsvDownload from "../../hooks/useCsvDownload";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { owner_change_headers } from "../../utils/CSVFormate";

export default function ChangeOwnerCSVDownload() {
  const { userData } = useUserDetailsContext();
  const { width } = useWindowDimensions();
  let initialValues = JSON.parse(localStorage.getItem("mcDataEdit"));

  let PIN =
    localStorage.getItem("PIN") === "false"
      ? false
      : localStorage.getItem("PIN");
  const { getCSVTransferData } = useCsvDownload();
  const [csvData, setCsvData] = useState([]);
  //csvdata
  const getCsvData = async () => {
    const data = [
      {
        mccode: initialValues.mccode,
        PIN: PIN,
        nextOwner: userData,
        transferMCData: initialValues
      }
    ];
    // console.log(data)

    await getCSVTransferData(data).then((res) => {
      setCsvData(res);
    });
  };
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "確認", link: "#" },
              { value: "CSVダウンロード", link: "#" },
              { value: "CSVアップロード", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={2}
          />
        </Row>
        <Row className="justify-content-center mb-4 position-relative">
          <FontAwesomeIcon
            icon={width > 576 ? faCircleArrowRight : faCircleArrowDown}
            size="2x"
            style={{
              position: "absolute",
              transform: "translateY(-50%)",
              top: "50%",
              background: "white",
              width: "fit-content",
              padding: "0",
            }}
          />
          <Col
            className={`text-lg-end text-center ${
              width > 576 ? "pe-4" : "pb-4"
            }`}
          >
            <>
              <span className="mt-1 mb-0">
                {initialValues.owner_type === "1" && (
                  <>
                    <ruby>
                      <h5>{initialValues.lastName}</h5>
                      <rt>{initialValues.lastKana}</rt>
                    </ruby>{" "}
                    <ruby>
                      <h5>{initialValues.firstName}</h5>
                      <rt>{initialValues.firstKana}</rt>
                    </ruby>
                  </>
                )}
                {initialValues.owner_type === "2" && (
                  <ruby className="mt-4">
                    <h5>{initialValues.corporation_name}</h5>
                    <rt>{initialValues.corporation_kana_name}</rt>
                  </ruby>
                )}
              </span>
              <br />
              <a href="mailto:initialValues.owner_email" className="mb-0">
                {initialValues.owner_email}
              </a>
              <br />
              {initialValues.owner_fixed_phone && (
                <a href="tel:initialValues.owner_fixed_phone">
                  {initialValues.owner_fixed_phone}
                </a>
              )}{" "}
              {initialValues.owner_phone && (
                <a href="tel:initialValues.owner_phone">
                  {initialValues.owner_phone}
                </a>
              )}
            </>
          </Col>
          <Col
            className={`text-lg-start text-center ${
              width > 576 ? "ps-4" : "pt-4"
            }`}
          >
            <>
              <span className="mt-1 mb-0">
                {userData.type === "1" && (
                  <>
                    <ruby>
                      <h5>{userData.lastName}</h5>
                      <rt>{userData.lastKana}</rt>
                    </ruby>{" "}
                    <ruby>
                      <h5>{userData.firstName}</h5>
                      <rt>{userData.firstKana}</rt>
                    </ruby>
                  </>
                )}
                {userData.type === "2" && (
                  <ruby className="mt-4">
                    <h5>{userData.corporation_name}</h5>
                    <rt>{userData.corporation_kana_name}</rt>
                  </ruby>
                )}
              </span>
              <br />
              {userData.email}
              <br />
              {userData.fixed_phone && userData.fixed_phone}{" "}
              {userData.phone && userData.phone}
            </>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={10} className="text-center">
            <CSVLink
              className="btn btn-info btn-lg"
              data={csvData}
              headers={owner_change_headers}
              filename={`所有者の変更登録_${moment(new Date()).format(
                "YYYYMMDD_HHMMSS"
              )}.csv`}
              enclosingCharacter={``}
              asyncOnClick={true}
              onClick={getCsvData}
            >
              <div className="d-flex align-middle">
                <FontAwesomeIcon icon={faFileCsv} size="3x" className="pe-2" />
                <p className="mb-0">
                  [所有者の変更登録]
                  <br />
                  CSVをダウンロード
                </p>
                <FontAwesomeIcon
                  icon={faDownload}
                  size="2x"
                  className="ps-2 pt-2"
                />
              </div>
            </CSVLink>
          </Col>
        </Row>

        <div className="text-center mt-4">
          <Link to={"/mypage/ChangeOwnerCSVUpload"} className="mt-1 btn btn-primary">
            <b>
              次へ <FontAwesomeIcon icon={faCircleChevronRight} />
            </b>
          </Link>
        </div>
        <br />
        <br />
        <br />
      </Container>
      {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}
    </>
  );
}
