
export default function useZip() {
  const zipFunction = async (
    zip3,
    zip4,
    prefix,
    setFieldValue,
    setFieldTouched,
    setZipError,
  ) => {
    fetch(`https://api.sugartaste.tokyo/api/yubin/zip/${zip3}${zip4}`)
      .then((data) => data.json())
      .then((data) => {
       
        if (data.city && data.town) {
          setFieldValue(`${prefix}prefecture`, data.prefecture);
          setFieldTouched(`${prefix}prefecture`);
          setFieldTouched(`${prefix}zip4`);
          setFieldValue(`${prefix}addressLine1`, `${data.city}${data.town}`);
          setFieldTouched(`${prefix}addressLine1`);
          setZipError(true);
        }
        if (data.hasOwnProperty("status") && !data.status) {
          setFieldTouched(`${prefix}zip4`);
          setFieldValue(`${prefix}prefecture`, "");
          setFieldValue(`${prefix}addressLine1`, "");
          setZipError(false);
        }
        // setFieldValue(`${prefix}zip3`, zip3);
        setFieldValue(`${prefix}zip4`, zip4);
      });
  };
  return { zipFunction };
}
