import * as yup from "yup";
import _ from "lodash";

const zip3Schema = (zipError) =>
  yup
    .string()
    .required("この欄は必須です")
    .length(3, "3文字以上ご入力ください")
    .test("invalid-zip", "こちらの郵便番号は無効です", function (val) {
      return zipError;
    });

const zip4Schema = (zipError) =>
  yup
    .string()
    .required("この欄は必須です")
    .length(4, "4文字以上ご入力ください")
    .test("invalid-zip", "こちらの郵便番号は無効です", function (val) {
      return zipError;
    });

const prefectureSchema = () =>
  yup
    .string()
    .required("この欄は必須です")
    .min(3, "少なくとも3全角文字以上にてご入力ください")
    .max(8, "最小8全角文字以上にてご入力ください");

const addressLine1Schema = () =>
  stringNumberMixedSchema(50).required("この欄は必須です");

const addressLine2Schema = () =>
  stringNumberMixedSchema(50).required("この欄は必須です");

const addressLine3Schema = () => stringNumberMixedSchema(50);

const phoneSchema = () =>
  yup
    .string()
    .required("この欄は必須です")
    .test("special-chars", "ハイフンあり", function (value) {
      return !/^(^0([789]0\d{4}\d{4}|[789]0-\d{4}\d{4}|[789]0\d{4}-\d{4})$)$/.test(
        value
      );
    })
    .matches(/^(^0[789]0-\d{4}-\d{4}$)$/, "こちらの電話番号は無効です");

const phoneSchemaBoth = () =>
  yup
    .string()
    .required("この欄は必須です")
    .test("special-chars", "ハイフンあり", function (value) {
      return String(value).split("-").length - 1 >= 2;
    })
    .matches(
      /^(0([1-9]{1}-?[1-9]\d{3}|[1-9]{2}-?\d{3}|[1-9]{2}\d{1}-?\d{2}|[1-9]{2}\d{2}-?\d{1})-?\d{4}|0[789]0-?\d{4}-?\d{4})$/,
      "こちらの電話番号は無効です"
    );

const fixedPhoneSchema = () =>
  yup
    .string()
    .matches(
      /^(0([1-9]{1}-?[1-9]\d{3}|[1-9]{2}-?\d{3}|[1-9]{2}\d{1}-?\d{2}|[1-9]{2}\d{2}-?\d{1})-?\d{4})$/,
      "こちらの電話番号は無効です"
    )
    .test("special-chars", "ハイフンあり", function (value) {
      return value ? String(value).split("-").length - 1 >= 2 : true;
    });

const email = () =>
  yup
    .string()
    .trim("先頭と末尾の空白は使用できません")
    .strict()
    .required("この欄は必須です")
    .email("こちらのアドレスは無効です")
    .max(100, "最小100半角英数字記号混在以上にてご入力ください");

const username = (length) =>
    yup
      .string()
      .trim("先頭と末尾の空白は使用できません")
      .strict()
      .required("この欄は必須です")
      .matches(/^[a-zA-Z0-9]+$/, "半角混在のみを入力してください")
      .max(length, `最小${length}半角混在以上にてご入力ください`);

const password = () =>
  yup
    .string()
    .trim("先頭と末尾の空白は使用できません")
    .strict()
    .min(8, "半角英数字含む8文字以上でご入力ください")
    .required("この欄は必須です")
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$)/,
      "半角英数字含む8文字以上でご入力ください"
    );

const passwordConfirm = (yupRef) =>
  yup
    .string()
    .trim("先頭と末尾の空白は使用できません")
    .strict()
    .required("この欄は必須です")
    .oneOf([yupRef, null], "パスワードが一致しません");

const lastName = (type) =>
  yup.string().when(type, {
    is: (t) => t === "1",
    then: stringOnlySchema(14).required("この欄は必須です")
  });

const firstName = (type) => {
  return yup.string().when(type, {
    is: (t) => t === "1",
    then: stringOnlySchema(14).required("この欄は必須です")
  });
};

const firstKana = (type) =>
  yup.string().when(type, {
    is: (t) => t === "1",
    then: kanaOnlySchema().required("この欄は必須です")
  });

const lastKana = (type) =>
  yup.string().when(type, {
    is: (t) => t === "1",
    then: kanaOnlySchema().required("この欄は必須です")
  });

const corporation_name = (type) =>
  yup.string().when(type, {
    is: (t) => t === "2",
    then: stringOnlySchema(30).required("この欄は必須です")
  });

const corporation_kana_name = (type) =>
  yup.string().when(type, {
    is: (t) => t === "2",
    then: kanaOnlySchema().required("この欄は必須です")
  });

const corporation_representative_lastName = (type) =>
  yup.string().when(type, {
    is: (t) => t === "2",
    then: stringOnlySchema(14).required("この欄は必須です")
  });

const corporation_representative_firstName = (type) =>
  yup.string().when(type, {
    is: (t) => t === "2",
    then: stringOnlySchema(14).required("この欄は必須です")
  });

const corporation_personincharge_lastName = (type) =>
  yup.string().when(type, {
    is: (t) => t === "2",
    then: stringOnlySchema(14).required("この欄は必須です")
  });

const corporation_personincharge_firstName = (type) =>
  yup.string().when(type, {
    is: (t) => t === "2",
    then: stringOnlySchema(14).required("この欄は必須です")
  });

const firstNameVet = () => stringOnlySchema(14).required("この欄は必須です");

const lastNameVet = () => stringOnlySchema(14).required("この欄は必須です");

const instituteVet = () => stringOnlySchema(14).required("この欄は必須です");

const kanaOnlySchema = (length) =>
  yup.string().matches(/^[ァ-ンーヴ]+$/, "全角カタカナのみを入力してください");

const stringOnlySchema = (length) =>
  yup
    .string()
    .trim("先頭と末尾の空白は使用できません")
    .strict()
    .matches(
      /^[一-龠ぁ-ゔァ-ヴーa-zA-Zａ-ｚＡ-Ｚ\s]+$/,
      "記号を含めず、文字のみで入力してください"
    )
    .max(length, `${length}文字以内で入力してください`);

const stringNumberMixedSchema = (length) =>
  yup
    .string()
    .trim("先頭と末尾の空白は使用できません")
    .strict()
    .matches(
      /^[〜~一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤ヶ”、；＜＞？￥’＆　 （）＃#-]+$/,
      "全角半角混在のみを入力してください"
    )
    .max(length, `${length}文字以内で入力してください`);

function noWhitespace() {
  return this.transform((value, originalValue) =>
    /\s/.test(originalValue) ? NaN : value
  );
}

yup.addMethod(yup.number, "noWhitespace", noWhitespace);
let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
const numberOnlySchema = (max = null, min = 1, integer = "INT") =>
  max
    ? yup
        .number()
        .when(integer, {
          is: (_) => integer === "INT",
          then: yup.number().integer("半角整数値でご入力ください"),
          otherwise: yup
            .number()
            .test("is-decimal", "小数点以下2桁までの数値", (val) => {
              if (val !== undefined) {
                return patternTwoDigisAfterComma.test(val);
              }
              return true;
            })
        })
        .typeError("半角整数値でご入力ください")
        .positive("半角整数値でご入力ください")
        .min(min, `${min}以上の値を選択して下さい`)
        .max(max, `${max}以下の値を選択して下さい`)

        .noWhitespace()
    : yup
        .number()
        .when(integer, {
          is: (int) => int === "INT",
          then: yup.number().integer("半角整数値でご入力くださいint")
        })
        .typeError("半角整数値でご入力ください")
        .positive("半角整数値でご入力ください")
        .min(min, `${min}以上の値を選択して下さい`)
        .noWhitespace();

function allAnimalSchema(animalLocationZipError) {
  return {
    animalID: yup.string().required("この欄は必須です"),
    animal_name: yup
      .string()
      .required("この欄は必須です")
      .trim("先頭と末尾の空白は使用できません")
      .strict()
      .matches(
        /^[一-龠ぁ-ゔァ-ヴーa-zA-Zａ-ｚＡ-Ｚ\s]+$/,
        "全角半角混在のみを入力してください"
      )
      .max(30, "最小30全角半角混在以上にてご入力ください"),
    animal_group: yup.string().required("この欄は必須です"),
    animal_gender: yup.string().required("この欄は必須です"),
    animal_breed: yup.string().required("この欄は必須です"),
    animal_breed_other: yup.string().when("animal_breed", {
      is: (animal_breed) => animal_breed === "999",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(26, "最小26全角半角混在以上にてご入力ください")
    }),
    animal_color: yup.string().required("この欄は必須です"),
    animal_color_other: yup.string().when("animal_color", {
      is: (animal_color) => animal_color === "99",
      then: yup
        .string()
        .required("この欄は必須です")
        .max(26, "最小26全角半角混在以上にてご入力ください")
    }),
    animal_birthDay: yup
      .date()
      .required("この欄は必須です")
      .max(new Date(), "未来の日付を選択できません"),

    animal_note: yup
      .string()
      .max(200, "最小200全角半角混在以上にてご入力ください"),
    animalAddressCheck: yup.array(),

    animal_location_zip3: yup.string().when("animalAddressCheck", {
      is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
      then: zip3Schema(animalLocationZipError)
    }),
    animal_location_zip4: yup.string().when("animalAddressCheck", {
      is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
      then: zip4Schema(animalLocationZipError)
    }),

    animal_location_prefecture: yup.string().when("animalAddressCheck", {
      is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
      then: prefectureSchema()
    }),
    animal_location_addressLine1: yup.string().when("animalAddressCheck", {
      is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
      then: addressLine1Schema()
    }),
    animal_location_addressLine2: yup.string().when("animalAddressCheck", {
      is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
      then: addressLine2Schema()
    }),
    animal_location_addressLine3: addressLine3Schema()
  };
}

export {
  firstKana,
  lastKana,
  firstName,
  lastName,
  corporation_name,
  corporation_kana_name,
  corporation_representative_lastName,
  corporation_representative_firstName,
  corporation_personincharge_lastName,
  corporation_personincharge_firstName,
  email,
  username,
  password,
  passwordConfirm,
  zip3Schema,
  zip4Schema,
  prefectureSchema,
  addressLine1Schema,
  addressLine2Schema,
  addressLine3Schema,
  phoneSchema,
  phoneSchemaBoth,
  fixedPhoneSchema,
  firstNameVet,
  lastNameVet,
  instituteVet,
  stringNumberMixedSchema,
  numberOnlySchema,
  stringOnlySchema,
  allAnimalSchema
};
