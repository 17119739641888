import { useState, useEffect } from "react";
import { useSetDoc } from "../hooks/useSetDoc";

//firebase imports
import { auth, firebaseErrors } from "../firebase/config";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile
} from "firebase/auth";
import { useLogout } from "./useLogout";

export const useSignup = () => {
  const [isPending, setIsPending] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState(null);
  const { firebaseSetDoc } = useSetDoc();
  const { logout } = useLogout();

  const signup = (email, password, extraData) => {
    setError(null);
    setIsPending(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        let displayName = `${extraData.lastName} ${extraData.firstName}`;
        if (extraData.type && extraData.type === "2") {
          displayName = `${extraData.corporation_representative_lastName} ${extraData.corporation_representative_firstName}`;
        }
        updateProfile(res.user, { displayName: displayName }).then(() => {
          firebaseSetDoc("users", extraData, res.user.uid);
          sendEmailVerification(res.user);
        });
        logout(false);
        setIsPending(false);
        setIsConfirmed(true);
      })
      .catch((err) => {
        setError(firebaseErrors[err.code] || err.message);
        setIsPending(false);
      });
  };

  return { error, isPending, isConfirmed, signup };
};
