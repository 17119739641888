import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import lozad from "lozad";
import moment from "moment";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import { Timestamp } from "firebase/firestore";

import styles from "./PetInfo.module.css";
import { useGetDoc } from "../hooks/useGetDoc";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  cat_breed,
  cat_color,
  dog_breed,
  dog_color
} from "../utils/CatDogData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFemale, faMale, faMars, faVenus } from "@fortawesome/free-solid-svg-icons";

const RecommendedPetDetails = ({ mccode, petData }) => {
  const [petDatils, setPetDetails] = useState(false);
  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);
  const [mainImg, setMainImg] = useState("/assets/img/no-img.png");
  const [mcData, setMcData] = useState({});
  const [birthDate, setBirthDate] = useState(null);
  const [allImages, setAllImages] = useState([]);
  const [renderedSmallImages, setRenderedSmallImages] = useState([]);
  const { width } = useWindowDimensions();
  const [fade, setFade] = useState(false);

  const [createDate, setCreateDate] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [mobileDescriptionExpand, setMobileDescriptionExpand] = useState(false);

  const descriptionContainerRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    updateMainImg(mainImg);
  }, [allImages]);

  const updateMainImg = (img) => {
    if (allImages && allImages.length > 0) {
      //Willuncomment
      // setRenderedSmallImages(allImages.filter((i) => i != img));
    }

    setFade(false);
    setTimeout(() => {
      setMainImg(img);
      setFade(true);
    }, 200);
  };

  useEffect(() => {
    if (petData) {
      setPetDetails(petData);
      setMcData(petData);
      setBirthDate(moment(petData.animal_birthDay).format("YYYY年MM月DD日"));
      setAllImages(petData.images);
      setRenderedSmallImages(petData.images);

      if (petData.images && petData.images.length > 0) {
        setMainImg(petData.images[0]);
      }
      if (petData.animal_group === "犬") {
        setBreed(dog_breed);
        setColor(dog_color);
      } else if (petData.animal_group === "猫") {
        setBreed(cat_breed);
        setColor(cat_color);
      }
    }
  }, []);

  return (
    <>
      <Container className="my-5">
        {!petData && (
          <Row>
            <Col sm={12} className="d-flex justify-content-center mt-5">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            </Col>
          </Row>
        )}

        {petData && (
          <>
            <Container className="mt-2 mt-sm-5">
              
              <Row className="mt-2 mt-sm-0">
                <Col sm={5}>
                  <div className="ratio ratio-1x1 my-2">
                    <img
                      src={mainImg}
                      className={`lozad img-fluid object-fit-cover ${
                        styles.mainImg
                      } ${fade ? styles.fadeIn : styles.fadeOut}`}
                    />
                  </div>
                  {renderedSmallImages && (
                    <div className={styles.smallIMGContainer}>
                      {renderedSmallImages.map((img, index) => {
                        return (
                          <div className={styles.smallIMG} key={index}>
                            <div className="ratio ratio-1x1 my-0 my-sm-2">
                              <img
                                src={img}
                                onClick={() => {
                                  updateMainImg(img);
                                }}
                                style={{ cursor: "pointer" }}
                                className="lozad img-fluid object-fit-cover"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Col>

                <Col
                  sm={7}
                  className="d-flex justify-content-center flex-column mt-4 mt-sm-0"
                >
                  <div className="w-100">
                    <table className={styles.table}>
                      <tbody>
                        <tr>
                          <td>犬種</td>
                          <td>
                            {mcData.animal_breed !== "999"
                              ? _.find(breed, ["value", mcData.animal_breed])
                                  ?.label
                              : mcData.animal_breed_other}
                          </td>
                        </tr>
                        <tr>
                          <td>見学地域</td>
                          <td>
                            {/* Span will bring this sign ">" */}
                            <div className={styles.address}>
                              {petDatils.sellPlatform_prefecture}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>価格</td>
                          <td>
                            {" "}
                            {parseInt(
                              petDatils.sellPlatformPrice
                            ).toLocaleString("en-US")}
                            円（税込）
                          </td>
                        </tr>
                        <tr>
                          <td>誕生日</td>
                          <td>{birthDate}生まれ</td>
                        </tr>
                        <tr>
                          <td>毛色</td>
                          <td>
                            {mcData.animal_color !== "99"
                              ? _.find(color, ["value", mcData.animal_color])
                                  ?.label
                              : mcData.animal_color_other}
                          </td>
                        </tr>
                        <tr>
                          <td>性別</td>
                          <td>
                            {mcData.animal_gender === "オス" ? (
                              <>
                                男の子 <FontAwesomeIcon icon={faMars} />
                              </>
                            ) : (
                              <>
                                女の子 <FontAwesomeIcon icon={faVenus} />
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>生体保証</td>
                          <td>{petDatils.guarantee}</td>
                        </tr>
                        <tr>
                          <td>アピールポイント</td>
                          <td>{petDatils.prPoint}</td>
                        </tr>
                        <tr>
                          <td>成犬時予想</td>
                          <td>
                            体重: {petDatils.weight_from}~{petDatils.weight_to}
                            kg　体高: {petDatils.height_from}~
                            {petDatils.height_to}cm
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>

                <Row>
                  <Col sm={12} className="mt-4">
                    <h4 className={styles.heading}>ブリーダーからの紹介</h4>
                  </Col>
                  <Col sm={12}>
                    <div
                      ref={descriptionContainerRef}
                      className={`w-100 bg-white rounded-3 ${
                        width <= 576 && !mobileDescriptionExpand
                          ? styles.mobileDescriptionCollaps
                          : styles.mobileDescriptionUnCollaps
                      }`}
                    >
                      <p ref={descriptionRef} className={styles.description}>
                        <div className="ql-snow">
                          <div
                            className=" ql-editor p-0"
                            dangerouslySetInnerHTML={{
                              __html: petDatils.sellPlatformDescription
                            }}
                          ></div>
                        </div>
                      </p>
                    </div>
                  </Col>
                </Row>
            </Container>
          </>
        )}
      </Container>
    </>
  );
};

export default RecommendedPetDetails;
