import React, { useEffect, useRef, useState } from "react";

import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { useGetDoc } from "../../../hooks/useGetDoc";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { Link } from "react-router-dom";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressStep from "../../../components/ProgressStep";
import ReactQuill from "react-quill";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useToast } from "../../../hooks/useToast";
import html2canvas from "html2canvas";

function SellingContractSettingsEdit() {

  const { firebaseGetDoc, isPending: isPending5, error: error5 } = useGetDoc();
  const [sellingContractPage, setSellingContractPage] = useState("");

  const [isPending2, setIsPending2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { userData } = useUserDetailsContext();

  const { user } = useAuthContext();

  const toast = useToast();

  const { firebaseSetDoc, isPending: isPending, error: error } = useSetDoc();
  const setToDBSellingContractPage1 = async (page1 = sellingContractPage) => {
    await firebaseSetDoc(
      "users",
      { sellingContractTemplatePage1: page1 },
      user.uid,
      true
    );

    //redirect to success page
    toast("success", "完了しました！");
  };

  const editorRef = useRef();
  const inputRef = useRef();

  const MAX_HEIGHT_PX = 1061; // A4 page height in pixels at 96 DPI

  const checkContentFitsA4 = async () => {
    const input = document.getElementById("reportDiv");
  
    // Create an invisible container to render content
    const iframe = document.createElement("div");
    iframe.style.width = "22.5cm"; // A4 width with margins
    iframe.innerHTML = sellingContractPage;
  
    input.appendChild(iframe);
  
    // Adjust for device pixel ratio
    const scale = window.devicePixelRatio || 1;
  
    // Render the content into a canvas
    const canvas = await html2canvas(iframe, {
      scale: scale, // Scale the canvas based on device pixel ratio
      useCORS: true, // Handle cross-origin resources if applicable
    });
  
    const canvasHeight = canvas.height / scale; // Normalize height to logical pixels
  
    // Remove the temporary container
    input.removeChild(iframe);
  
    console.log(`Canvas Height (Logical Pixels): ${canvasHeight}`);
    console.log(`Maximum Height Allowed: ${MAX_HEIGHT_PX}`);
  
    // Validate the rendered content size against A4 size
    return canvasHeight <= MAX_HEIGHT_PX;
  };
  

  const handleSave = async () => {
    setIsPending2(true);
    setErrorMessage("");

    const fitsA4 = await checkContentFitsA4();

    if (!fitsA4) {
      setErrorMessage(
        "行数が制限を超えました。行数を減らして再入力してください。"
      );
      setIsPending2(false);
      return;
    }

    // Proceed with saving
    try {
      // Replace this with your save logic
      // console.log("Saving content:", sellingContractPage);

      // Simulate success
      await setToDBSellingContractPage1();
    } catch (error) {
      setErrorMessage("エラーが発生しました。もう一度お試しください。");
    }
    setIsPending2(false);
  };



  const getSellingContractPage1 = async () => {
    if (userData && userData.sellingContractTemplatePage1) {
      // console.log(userData.sellingContractTemplatePage1);
      const processedHtml = reduceOnePBrP(
        userData.sellingContractTemplatePage1
      );
      // const processedHtml = userData.sellingContractTemplatePage1.replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");
      // console.log(userData.sellingContractTemplatePage1);

      setSellingContractPage(userData.sellingContractTemplatePage1);
    } else {
      const data = await firebaseGetDoc("admin", "sellingContractTemplate");
      if (data && data.page1) {
        // const processedHtml = reduceOnePBrP(data.page1);

        setSellingContractPage(data.page1);
      }
    }
  };

  function reduceOnePBrP(htmlString) {
    // This pattern matches a series of one or more consecutive <p><br></p>
    const pattern = /(<p><br><\/p>)+/g;

    // Replace the first series of <p><br></p> by removing one instance
    return htmlString.replace(pattern, (match) => {
      // Remove one <p><br></p> from the first matched series
      return match.replace("<p><br></p>", "");
    });
  }

  useEffect(() => {
    getSellingContractPage1();
  }, []);

  //this is the config for rich text editor
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5] }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "color",
    "background",
  ];

  return (
    <Container className="mt-3">
      <Row className="justify-content-center">
        <Col xl={10} md={12}>
          <div className="d-flex justify-content-between">
            <h4>販売契約書 (保証内容)</h4>
          </div>
          {errorMessage && (
            <Alert variant="danger" className="mt-2">
              {errorMessage}
            </Alert>
          )}
          {sellingContractPage && (
            <div ref={editorRef}>
              <ReactQuill
                theme="snow"
                modules={modules}
                value={sellingContractPage}
                formats={formats}
                onChange={(value, _, __, editor) => {
                  setSellingContractPage(value);
                  setIsPending2(false);
                  setErrorMessage("");
                  // console.log(value)
                  // if (editor.getLength() === 1) {
                  //   setFieldValue("sellPlatformDescription", "");
                  // } else {
                  //   setFieldValue("sellPlatformDescription", value);
                  // }
                }}
                onBlur={() => {
                  // setFieldTouched("sellPlatformDescription", true);
                }}
                // className={
                //   // touched.sellPlatformDescription &&
                //   // errors.sellPlatformDescription
                //   //   ? "border border-danger"
                //   //   : ""
                // }
              />
            </div>
          )}

          {errorMessage && (
            <Alert variant="danger" className="mt-2">
              {errorMessage}
            </Alert>
          )}

          <div className="text-center">
            {!isPending && !isPending2 && (
              <Button variant="primary mt-2" onClick={() => handleSave()}>
                <FontAwesomeIcon icon={faSave} /> 保存
              </Button>
            )}

            {(isPending || isPending2) && (
              <Button variant="primary mt-2" disabled>
                <FontAwesomeIcon icon={faSave} /> 保存
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
      <div
        className="ql-snow "
        style={{ position: "absolute", left: "-9999999px" }}>
        <div className="ql-editor ql-container">
          <div id="reportDiv"></div>
        </div>
      </div>
    </Container>
  );
}

export default SellingContractSettingsEdit;
