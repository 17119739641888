import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function VetMCDetailsModal(props) {
  const { width } = useWindowDimensions();
  return (
    <>
      {props.data && (
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              MC番号
              <span className="text-nowrap">#{props.data.mccode}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center mb-4">
              <Col>
                <h4 className="mt-4">
                  ブリーダー/
                  <span className="text-nowrap">ペットショップ</span>
                </h4>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-top border-start `
                    } border-secondary p-2`}
                  >
                    電子メールアドレス
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 &&
                      `border-top border-start border-bottom border-end`
                    } p-1 border-end border-secondary`}
                  >
                    {props.data.breederpet_email}
                  </Col>
                </Row>
                {props.data.breederpet.type === "1" && (
                  <>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}
                      >
                        氏名
                      </Col>
                      <Col
                        lg={9}
                        className={`pt-2  ${
                          width < 576 && `border-start border-end border-bottom`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}
                      >
                        {props.data.breederpet.lastName}{" "}
                        {props.data.breederpet.firstName}
                      </Col>
                    </Row>
                  </>
                )}

                {props.data.breederpet.type === "2" && (
                  <>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}
                      >
                        法人名
                      </Col>
                      <Col
                        lg={9}
                        className={`pt-2 ${
                          width < 576 && `border-start border-end border-bottom`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}
                      >
                        <ruby className="mt-4">
                          {props.data.breederpet.corporation_name}
                          <rt>{props.data.breederpet.corporation_kana_name}</rt>
                        </ruby>
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    携帯<span className="text-nowrap">電話番号</span>:
                  </Col>
                  <Col
                    lg={9}
                    className={`pt-2 ${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {props.data.breederpet.phone}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col>
                <h4 className="mt-4">所有者情報</h4>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-top border-start `
                    } border-secondary p-2`}
                  >
                    MCの装着日
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 &&
                      `border-top border-start border-bottom border-end`
                    } p-1 border-end border-secondary`}
                  >
                    {props.data.mcdate}
                  </Col>
                </Row>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    MCを装着した施設名
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {props.data.vet_institute}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    MCを装着した施設の
                    <span className="text-nowrap">所在地</span>
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    <>
                      <p className="mb-0">
                        〒{props.data.vet_location_zip3}-
                        {props.data.vet_location_zip4}
                      </p>
                      <p className="mb-0">
                        {props.data.vet_location_prefecture}{" "}
                        {props.data.vet_location_addressLine1}
                      </p>
                      <p className="mb-0">
                        {props.data.vet_location_addressLine2}
                      </p>
                      <p className="mb-0">
                        {props.data.vet_location_addressLine3}
                      </p>
                    </>
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    MCを装着した施設の
                    <span className="text-nowrap">電話番号</span>
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {props.data.vet_phone}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    獣医師の氏名
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {props.data.vet_lastName} {props.data.vet_firstName}
                  </Col>
                </Row>
              </Col>
            </Row>

            {props.data.certificateDownloadLink && (
              <Row className="justify-content-center mb-4">
                <Col>
                  <h4 className="mt-4">装着証明書</h4>
                  <Row className="justify-content-center mb-4">
                    <Col lg={3} md={4}>
                      <img
                        src={props.data.certificateDownloadLink}
                        alt=""
                        className="w-100"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>閉じる</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
