import { useEffect, useState } from "react";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";

export const useImageOCR = () => {
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const imageOCR = (mailOptions) => {
    setIsPending(true);
    setIsConfirmed(false);
    setError(null);
    const imageOCR = httpsCallable(functions, "imageOCR");
    return imageOCR({ ...mailOptions })
      .then((res) => {
        // console.log("cd2", res);
        setIsPending(false);
        setIsConfirmed(true);
        // console.log("cd", res);
        return res;
      })
      .catch((err) => {
        setIsPending(false);
        setIsConfirmed(false);
        setError(err);
        // console.log(err);

        return err;
      });
  };

  return { imageOCR, isPending, isConfirmed, error };
};
