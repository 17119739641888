import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import {
  Col,
  Container,
  Row,

} from "react-bootstrap";
import ProgressStep from "../../components/ProgressStep";
import { useNavigate } from "react-router-dom";

import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useToast } from "../../hooks/useToast";
import { useTimer } from "react-timer-hook";
import "animate.css";

function PetShopMCOTP() {
  const { user } = useAuthContext();
  const toast = useToast();
  const [OTPValue, setOTPValue] = useState(null);
  const [OTP, setOTP] = useState(null);
  const [error, setError] = useState(false);
  const [expaired, setExpaired] = useState(false);
  const [expiryTimestamp, setExpiryTimestamp] = useState(new Date());
  const { documents: otpcode } = useCollection("temp_otp", [
    "uid",
    "==",
    user.uid,
  ]);


  useEffect(() => {
    if (otpcode && otpcode[0].otp && otpcode[0].createdAt) {
      setOTP(otpcode[0].otp);

      const time = otpcode[0].createdAt.toDate();
      time.setSeconds(time.getSeconds() + 300);
      setError(false);
      setExpaired(false);
      setExpiryTimestamp(time);
      restart(time);
    }

    if (otpcode && otpcode[0].isVarified) {
      onVarify();
      toast("success", "認証されました！");
    }
  }, [otpcode]);


  useEffect(() => {
    setError(false);
    if (OTPValue && OTPValue.toString().length === 6) {
      if (parseInt(OTPValue) === OTP) {
        onVarify();
        toast("success", "ワンタイムパスワードを認証しました。");
        // console.log("passed");
      } else {
        setError(true);
        // console.log("field", OTP, OTPValue);
      }
    }
    // console.log(OTPValue);
  }, [OTPValue]);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300);

  const {
    seconds,
    minutes,
    restart,
  } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: () => {
      setExpaired(true);
    },
  });
  const navigate = useNavigate();
  const onVarify = () => {
    navigate("/mypage/mcconfirm");
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "#" },
              { value: "OTP", link: "#" },
              { value: "確認", link: "#" },
              { value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={2}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <div className="mx-auto mt-4 " style={{ width: "fit-content" }}>
              <h4 className="text-center">ワンタイムパスワード入力</h4>

              <OtpInput
                className={`${error && "animate__animated animate__headShake"}`}
                inputStyle={{
                  margin: "5px",
                  width: "40px",
                  height: "45px",
                  fontSize: "1.1rem",
                  border: "1px solid",
                  borderColor: "#ced4da",
                  borderRadius: "0.25rem",
                }}
                focusStyle={{
                  color: "#212529",
                  backgroundColor: "#fff",
                  borderColor: "#86b7fe",
                  outline: "0",
                  boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
                }}
                hasErrored={error || expaired}
                errorStyle={{ borderColor: "red" }}
                isInputNum={true}
                value={OTPValue}
                onChange={(otp) => setOTPValue(otp)}
                numInputs={6}
              />
              <p className="text-end">
                Expair in <span>{(minutes < 10 ? "0" : "") + minutes}</span>:
                <span>{(seconds < 10 ? "0" : "") + seconds}</span>
              </p>
              {error && (
                <p className="text-danger text-center">
                  ワンタイムパスワードが違います。
                </p>
              )}
              {expaired && <p className="text-danger text-center">Expaired</p>}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PetShopMCOTP;
