import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import MCDetailsTable from "../../components/MCDetailsTable";
import ProgressStep from "../../components/ProgressStep";
import UserDetailsTable from "../../components/UserDetailsTable";

function PetShopMCTransferConfirm() {
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("mcData")) || false
  );

  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo")) || false
  );
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/transferstart" },
              { value: "OTP", link: "#" },
              { value: "確認", link: "#" },
              { value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={3}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <h4>MCの詳細</h4>
            {data && (
              <Table striped bordered responsive="sm">
                <tbody>
                  <MCDetailsTable data={data[0]} />
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <h4>一般所有の詳細</h4>
            {userInfo && <UserDetailsTable userInfo={userInfo} />}
          </Col>
        </Row>

        <div className="text-center mt-4">
          {/* <Link className="btn btn-secondary m-2" to="/mypage/transferinput">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link> */}

          <Link className="btn btn-primary m-2" to="/mypage/mcpay">
            お支払 <FontAwesomeIcon icon={faCircleChevronRight} />
          </Link>
        </div>
      </Container>
    </>
  );
}

export default PetShopMCTransferConfirm;
