import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import styles from "./Admin.module.css";
import { useParams } from "react-router-dom";
import { useGetDoc } from "../../hooks/useGetDoc";
import { Timestamp } from "firebase/firestore";

export default function Userdetails() {
  const params = useParams();

  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } =
    useGetDoc();

  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    async function fetchData() {
      await firebaseGetDoc("users", params.uid).then((res) => {
        setUserDetails(res);
      });
    }
    fetchData();
  }, []);

  return (
    <>
      <Container>
        {userDetails && (
          <Row>
            <Col lg={4}>
              <div>
                {userDetails.group === "breederpet" && (
                  <Badge bg="primary" variant="sm" className="mb-1">
                    ブリーダー/ペットショップ
                  </Badge>
                )}
                {userDetails.group === "vet" && (
                  <Badge bg="warning" variant="sm" className="mb-1">
                    獣医師
                  </Badge>
                )}
                {userDetails.group === "owner" && (
                  <Badge bg="success" variant="sm" className="mb-1">
                    飼い主
                  </Badge>
                )}{" "}
                <br />
                <p className="mb-0">{userDetails.company}</p>
                <h5 className="mb-0 ">
                  {userDetails.type === "1" && (
                    <>
                      <ruby>
                        <h5>{userDetails.lastName}</h5>
                        <rt>{userDetails.lastKana}</rt>
                      </ruby>{" "}
                      <ruby>
                        <h5>{userDetails.firstName}</h5>
                        <rt>{userDetails.firstKana}</rt>
                      </ruby>
                    </>
                  )}
                  {userDetails.type === "2" && (
                    <ruby className="mt-4">
                      <h5>{userDetails.corporation_name}</h5>
                      <rt>{userDetails.corporation_kana_name}</rt>
                    </ruby>
                  )}
                </h5>
                <span>{userDetails.email}</span>
                <br />
                <Badge bg="secondary" variant="sm">
                  {moment(
                    new Timestamp(
                      userDetails.createdAt.seconds,
                      userDetails.createdAt.nanoseconds
                    ).toDate()
                  ).format("YYYY/MM/DD HH:mm")}
                </Badge>
              </div>
            </Col>

            <Col lg={4} className=" align-middle">
              〒{userDetails.zip3}-{userDetails.zip4} <br />
              {`${userDetails.prefecture} ${userDetails.addressLine1}`} <br />
              {userDetails.addressLine2} <br />
              {userDetails.addressLine3 && (
                <>
                  <span> {userDetails.addressLine3}</span>
                  <br />
                </>
              )}
              お電話番号: {userDetails.phone} <br />
              {userDetails.birthDay && (
                <>
                  <span> 生年月日: {userDetails.birthDay}</span>
                  <br />
                </>
              )}
              性別: {userDetails.gender}
            </Col>
          </Row>
        )}

        <hr />
      </Container>
    </>
  );
}
