import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import useZip from "../hooks/useZip";

function AddressInput({ prefix, setZipError }) {
  
  const { zipFunction } = useZip();
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();


  useEffect(() => {
    // console.log(values)
  }, [])

  const [zip, setZip] = useState(`${values[`${prefix}zip3`]}-${values[`${prefix}zip4`]}`);

  const FormikZip = (props) => {
    // console.log(values)
    
    var zip3 = values[`${prefix}zip3`];
    var zip4 = values[`${prefix}zip4`];

    useEffect(() => {
      if (zip !== `${zip3}-${zip4}`) {
        if (zip3.toString().length === 3) {
          document.getElementById(`${prefix}zip4`).focus();
        }

        if (zip3.toString().length === 3 && zip4.toString().length === 4) {
          async function fetchData() {
            await zipFunction(
              zip3,
              zip4,
              prefix,
              setFieldValue,
              setFieldTouched,
              setZipError
            );
          }
          fetchData();
        }


        setZip(`${zip3}-${zip4}`);
      }
    }, [zip3, zip4]);

    return <></>;
  };
  return (
    <>
      <FormikZip />
      <Form.Label className="m-0">
        郵便番号 <span className="text-danger">※</span>
      </Form.Label>

      <Row>
        <Col lg={6}>
          <InputGroup className="mt-1" hasValidation>
            <FormControl
              type="text"
              placeholder="半角"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[`${prefix}zip3`]}
              name={`${prefix}zip3`}
              id={`${prefix}zip3`}
              maxLength={3}
              isInvalid={touched[`${prefix}zip3`] && errors[`${prefix}zip3`]}
              isValid={touched[`${prefix}zip3`] && !errors[`${prefix}zip3`]}
              required
            />
            <InputGroup.Text id="basic-addon1">-</InputGroup.Text>
            <FormControl
              type="text"
              placeholder="半角"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[`${prefix}zip4`]}
              name={`${prefix}zip4`}
              id={`${prefix}zip4`}
              maxLength={4}
              isInvalid={touched[`${prefix}zip4`] && errors[`${prefix}zip4`]}
              isValid={touched[`${prefix}zip4`] && !errors[`${prefix}zip4`]}
              required
            />

            {errors[`${prefix}zip3`] && (
              <Form.Control.Feedback type="invalid">
                ハイフンの前には: {errors[`${prefix}zip3`]}
              </Form.Control.Feedback>
            )}

            {errors[`${prefix}zip4`] && (
              <Form.Control.Feedback type="invalid">
                ハイフンの後には: {errors[`${prefix}zip4`]}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Col>
      </Row>

      <Form.Label className="m-0">
        都道府県 <span className="text-danger">※</span>
      </Form.Label>
      <Form.Select
        className="mt-1"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[`${prefix}prefecture`]}
        name={`${prefix}prefecture`}
        isInvalid={
          touched[`${prefix}prefecture`] && errors[`${prefix}prefecture`]
        }
        isValid={
          touched[`${prefix}prefecture`] && !errors[`${prefix}prefecture`]
        }
        required
      >
        <option value="">▼選択してください</option>
        <option value="北海道">北海道</option>
        <option value="青森県">青森県</option>
        <option value="岩手県">岩手県</option>
        <option value="宮城県">宮城県</option>
        <option value="秋田県">秋田県</option>
        <option value="山形県">山形県</option>
        <option value="福島県">福島県</option>
        <option value="茨城県">茨城県</option>
        <option value="栃木県">栃木県</option>
        <option value="群馬県">群馬県</option>
        <option value="埼玉県">埼玉県</option>
        <option value="千葉県">千葉県</option>
        <option value="東京都">東京都</option>
        <option value="神奈川県">神奈川県</option>
        <option value="新潟県">新潟県</option>
        <option value="富山県">富山県</option>
        <option value="石川県">石川県</option>
        <option value="福井県">福井県</option>
        <option value="山梨県">山梨県</option>
        <option value="長野県">長野県</option>
        <option value="岐阜県">岐阜県</option>
        <option value="静岡県">静岡県</option>
        <option value="愛知県">愛知県</option>
        <option value="三重県">三重県</option>
        <option value="滋賀県">滋賀県</option>
        <option value="京都府">京都府</option>
        <option value="大阪府">大阪府</option>
        <option value="兵庫県">兵庫県</option>
        <option value="奈良県">奈良県</option>
        <option value="和歌山県">和歌山県</option>
        <option value="鳥取県">鳥取県</option>
        <option value="島根県">島根県</option>
        <option value="岡山県">岡山県</option>
        <option value="広島県">広島県</option>
        <option value="山口県">山口県</option>
        <option value="徳島県">徳島県</option>
        <option value="香川県">香川県</option>
        <option value="愛媛県">愛媛県</option>
        <option value="高知県">高知県</option>
        <option value="福岡県">福岡県</option>
        <option value="佐賀県">佐賀県</option>
        <option value="長崎県">長崎県</option>
        <option value="熊本県">熊本県</option>
        <option value="大分県">大分県</option>
        <option value="宮崎県">宮崎県</option>
        <option value="鹿児島県">鹿児島県</option>
        <option value="沖縄県">沖縄県</option>
      </Form.Select>
      {errors[`${prefix}prefecture`] && (
        <Form.Control.Feedback type="invalid">
          {errors[`${prefix}prefecture`]}
        </Form.Control.Feedback>
      )}

      <Form.Group className="mt-1">
        <Form.Label className="m-0">
          住所１(市区町村)
          <span className="text-danger">※</span>
        </Form.Label>
        <Form.Control
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[`${prefix}addressLine1`]}
          name={`${prefix}addressLine1`}
          maxLength={150}
          required
          isInvalid={
            touched[`${prefix}addressLine1`] && errors[`${prefix}addressLine1`]
          }
          isValid={
            touched[`${prefix}addressLine1`] && !errors[`${prefix}addressLine1`]
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors[`${prefix}addressLine1`]}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mt-1">
        <Form.Label className="m-0">
          住所２(番地)
          <span className="text-danger">※</span>
        </Form.Label>
        <Form.Control
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[`${prefix}addressLine2`]}
          id={`${prefix}addressLine2`}
          name={`${prefix}addressLine2`}
          maxLength={150}
          required
          isInvalid={
            touched[`${prefix}addressLine2`] && errors[`${prefix}addressLine2`]
          }
          isValid={
            touched[`${prefix}addressLine2`] && !errors[`${prefix}addressLine2`]
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors[`${prefix}addressLine2`]}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mt-1">
        <Form.Label className="m-0">住所３(建物名)</Form.Label>
        <Form.Control
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[`${prefix}addressLine3`]}
          id={`${prefix}addressLine3`}
          name={`${prefix}addressLine3`}
          maxLength={150}
          isInvalid={
            touched[`${prefix}addressLine3`] && errors[`${prefix}addressLine3`]
          }
          isValid={
            touched[`${prefix}addressLine3`] && !errors[`${prefix}addressLine3`]
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors[`${prefix}addressLine3`]}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

export default AddressInput;
