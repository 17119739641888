import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useUpdateCustomer } from "../hooks/komoju/useUpdateCustomer";
import { useUserDetailsContext } from "../context/UserDetailsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { useUpdateDoc } from "../hooks/useUpdateDoc";

const ValidateCard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();
  const functions = getFunctions();
  // connectFunctionsEmulator(functions, "localhost", 6001);
  const { firebaseUpdateDoc, isConfirmed, error } = useUpdateDoc();
  const [komojuCustomerID, setKomojuCustomerID] = useState(null);
  const navigate = useNavigate();

  const {
    updateCardInfo,
    isPending: isUpdateCustomerPending,
    isConfirmed: isKomojuUpdateConfirmed,
    error: customerUpdateError,
    data: customerUpdateData
  } = useUpdateCustomer();

  useEffect(() => {
    updateCustomer();
    async function updateCustomer() {
      const getKomojuSessionDetails = httpsCallable(functions, "getKomojuSessionDetails");
      const result = await getKomojuSessionDetails({
        sessionId: sessionId
      });

      if (result && result.data.data && result.data.data.status == "completed") {
        console.log(result.data.data);
        setKomojuCustomerID(result.data.data.customer_id);
      } else {
        navigate(`${process.env.REACT_APP_BASE_URL}mypage/changecardinfo`);
      }
    }
  }, []);

  useEffect(() => {
    if (komojuCustomerID && userData) {
      firebaseUpdateDoc(
        "users",
        { komoju_customer_id: komojuCustomerID, komoju_customer_seesion: sessionId },
        user.uid
      );
      const patchData = {
        metadata: {
          name: `${userData.firstName} ${userData.lastName}`
        }
      };
      updateCardInfo(komojuCustomerID, patchData);
    }
  }, [komojuCustomerID, userData]);

  useEffect(() => {
    if (customerUpdateData && customerUpdateData.id && isConfirmed) {
      let redirectData = localStorage.getItem("komoju_redirect");
      if (redirectData) {
        localStorage.removeItem("komoju_redirect");
        console.log(`${process.env.REACT_APP_BASE_URL}${redirectData}`);
        window.location.replace(`${process.env.REACT_APP_BASE_URL}${redirectData}`);
      } else {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}${"mypage/plansetting"}`);
      }
    }
  }, [customerUpdateData, isConfirmed]);

  return (
    <>
      <div className="position-absolute top-50 w-100 text-center">
        <Spinner className="" animation="grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </>
  );
};

export default ValidateCard;
