import React from "react";
import Select from 'react-select'
import {dog_breed} from "../utils/CatDogData"
function SS() {
   
  return (
    <>
      <Select options={dog_breed} />
    </>
  );
}

export default SS;


