import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { arrayRemove } from "firebase/firestore";

const FavRemoveButton = (props) => {
  const [buttonElement, setButtonElement] = useState(
    <>
      <FontAwesomeIcon icon={faHeart} /> お気に入りから削除
    </>
  );

  const [disableButton, setDisableButton] = useState(false);

  const { firebaseSetDoc, firebaseSetDocQuery, isPending, error } = useSetDoc();

  const removeFromFav = async () => {
    setButtonElement(
      <>
        <Spinner animation="border" size="sm" className="mr-2" />{" "}
      </>
    );
    setDisableButton(true);

    let petID = props.mccode;
    let user = props.user;

    await firebaseSetDoc(
      `microchip`,
      {
        favourite: arrayRemove(user.uid)
      },
      petID,
      true
    );
    await firebaseSetDocQuery(
      `users/${user.uid}/microchip/`,
      {
        mcData: {
          favourite: arrayRemove(user.uid)
        }
      },
      [["mccode", "==", petID]],
      true
    );
    await firebaseSetDoc(
      `users`,
      {
        favourite: arrayRemove(petID)
      },
      user.uid,
      true
    );

    await props.loadData();
  };

  return (
    <Button disabled={disableButton} variant="danger" onClick={removeFromFav}>
      {buttonElement}
    </Button>
  );
};

export default FavRemoveButton;
