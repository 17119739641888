import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import _ from "lodash";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useGetDoc } from "../../hooks/useGetDoc";
import {
  getName,
  json2array,
  date2monthAnal,
  getRepresentativeName,
} from "../../utils/Utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function Report() {
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();

  const [analyticsData, setAnalyticsData] = useState([]);
  const { firebaseGetDoc, data, isPending, error } = useGetDoc();

  const [prevTotalDog, setPrevTotalDog] = useState(0);
  const [prevTotalCat, setPrevTotalCat] = useState(0);

  const [currTotalDog, setCurrTotalDog] = useState(0);
  const [currTotalCat, setCurrTotalCat] = useState(0);

  const [thisYear, setThisYear] = useState(moment(new Date()).format("YYYY"));

  const getanal = async (startYear, endYear) => {
    let analData = [];
    for (let year = startYear; year <= endYear; year++) {
      // console.log(year);
      await firebaseGetDoc(`users/${user.uid}/analytics`, year.toString()).then(
        (res) => {
          if (res) {
            analData[year] = date2monthAnal(json2array(_.omit(res, ["y"])));
          }
          // setAnalyticsData(analyticsData)
          // console.log(analData);
        }
      );
    }
    // console.log(analData);
    setAnalyticsData(analData);
  };

  useEffect(() => {
    setAnalyticsData([]);
    const endYear = moment(new Date()).format("YYYY");
    const startYear =
      moment(new Date()).format("MM") <= 4
        ? moment(new Date()).format("YYYY") - 2
        : moment(new Date()).format("YYYY") - 1;
    getanal(startYear, endYear);
  }, []);

  useEffect(() => {
    prevYearTotal();
    currYearTotal();
  }, [analyticsData]);

  const prevYearTotal = () => {
    let newPrevTotalDog = 0;
    let newPrevTotalCat = 0;
    let start = "";
    if (moment(new Date()).format("MM") <= 4) {
      start = `${moment(new Date()).format("YYYY") - 2}/04/01`;
    } else {
      start = `${moment(new Date()).format("YYYY") - 1}/04/01`;
    }
    for (let i = 1; i <= 12; i++) {
      let year = moment(start).add(i, "months").format("YYYY");
      let month = parseInt(moment(start).add(i, "months").format("MM"));
      newPrevTotalDog +=
        analyticsData[year] && analyticsData[year][month]
          ? analyticsData[year][month].dm +
            analyticsData[year][month].df -
            analyticsData[year][month].dmt -
            analyticsData[year][month].dft -
            analyticsData[year][month].dmd -
            analyticsData[year][month].dfd
          : 0;

      newPrevTotalCat +=
        analyticsData[year] && analyticsData[year][month]
          ? analyticsData[year][month].cm +
            analyticsData[year][month].cf -
            analyticsData[year][month].cmt -
            analyticsData[year][month].cft -
            analyticsData[year][month].cmd -
            analyticsData[year][month].cfd
          : 0;
    }

    setPrevTotalCat(newPrevTotalCat);
    setPrevTotalDog(newPrevTotalDog);
  };

  const currYearTotal = () => {
    let newCurrTotalDog = 0;
    let newCurrTotalCat = 0;
    let start = "";
    if (moment(new Date()).format("MM") <= 4) {
      start = `${moment(new Date()).format("YYYY") - 1}/04/01`;
    } else {
      start = `${moment(new Date()).format("YYYY")}/04/01`;
    }

    setThisYear(parseInt(start));

    for (let i = 1; i <= 12; i++) {
      let year = moment(start).add(i, "months").format("YYYY");
      let month = parseInt(moment(start).add(i, "months").format("MM"));
      newCurrTotalDog +=
        analyticsData[year] && analyticsData[year][month]
          ? analyticsData[year][month].dm +
            analyticsData[year][month].df -
            analyticsData[year][month].dmt -
            analyticsData[year][month].dft -
            analyticsData[year][month].dmd -
            analyticsData[year][month].dfd
          : 0;

      newCurrTotalCat +=
        analyticsData[year] && analyticsData[year][month]
          ? analyticsData[year][month].cm +
            analyticsData[year][month].cf -
            analyticsData[year][month].cmt -
            analyticsData[year][month].cft -
            analyticsData[year][month].cmd -
            analyticsData[year][month].cfd
          : 0;
    }

    setCurrTotalCat(newCurrTotalCat);
    setCurrTotalDog(newCurrTotalDog);
  };

  const downloadPdfDocument = (rootElementId) => {
    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      // console.log(width, height);
      pdf.addImage(imgData, "JPEG", 30, 20, width - 60, height - 120);

      const input2 = document.getElementById("reportDiv2");
      html2canvas(input2).then((canvas2) => {
        const imgData2 = canvas2.toDataURL("image/png");
        pdf.addPage("a4", "p");
        pdf.addImage(imgData2, "JPEG", 30, 20, width - 60, height - 40);
        pdf.save(
          `動物販売業者等定期報告届出書_${moment(new Date()).format(
            "YYYYMMDD_HHMMSS"
          )}.pdf`
        );
      });
    });
  };

  return (
    <>
      {/* {analyticsData &&
        analyticsData[thisYear+1] &&
        JSON.stringify(analyticsData , null, 2)} */}

      <Button
        variant="success"
        onClick={(e) => downloadPdfDocument("reportDiv")}
        className="m-4 "
      >
        PDFをダウンロード
      </Button>
      <div id="reportDiv" style={{ width: "1000px" }}>
        <p>様式第11の２（第10条の３第１項関係）</p>
        <p className="text-end">
          {moment(new Date()).format("YYYY年MM月DD日")}
        </p>
        <p>　　　　　　　　殿</p>
        <div className="ms-auto me-4" style={{ width: "fit-content" }}>
          届出者
          <Table borderless>
            <tbody>
              <tr>
                <td className="p-0 pe-2">氏　　名</td>
                <td className="p-0">{getRepresentativeName(userData)}</td>
              </tr>
              <tr>
                <td className="p-0 pe-2">住　　所</td>
                <td className="p-0">
                  <p className="mb-0">
                    〒{userData.zip3}-{userData.zip4}
                  </p>
                  <p className="mb-0">
                    {userData.prefecture} {userData.addressLine1}
                  </p>
                  <p className="mb-0">{userData.addressLine2}</p>
                  <p className="mb-0">{userData.addressLine3}</p>
                </td>
              </tr>
              <tr>
                <td className="p-0 pe-2">電話番号</td>
                <td className="p-0">
                  {userData.fixed_phone ? userData.fixed_phone : userData.phone}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <p className="text-center">
          動物販売業者等定期報告届出書 <br />
          動物の愛護及び管理に関する法律第21条の５第２項の規定に基づき、下記のとおり届け出ます。
          <br />
          <br />記
        </p>
        <Table bordered className="mb-0">
          <tbody>
            <tr>
              <td className="align-middle" style={{ width: "340px" }}>
                １. 事業所の名称
              </td>
              <td className="align-middle">{getName(userData)}</td>
            </tr>
            <tr>
              <td className="align-middle">２. 事業所の所在地</td>
              <td className="align-middle">
                <p className="mb-0">
                  〒{userData.zip3}-{userData.zip4}
                </p>
                <p className="mb-0">
                  {userData.prefecture} {userData.addressLine1}
                </p>
                <p className="mb-0">{userData.addressLine2}</p>
                <p className="mb-0">{userData.addressLine3}</p>
              </td>
            </tr>
            <tr>
              <td className="align-middle">３. 登録年月日</td>
              <td className="align-middle"></td>
            </tr>
            <tr>
              <td className="align-middle">４. 登録番号</td>
              <td className="align-middle"></td>
            </tr>
            <tr>
              <td className="align-middle">
                <div className="d-flex w-100">
                  <div className="pe-1">５.</div>
                  <div>
                    年度当初に所有し
                    <br />
                    ていた動物の合計数
                  </div>
                </div>
              </td>
              <td className="align-middle">
                犬： {prevTotalDog}頭、猫： {prevTotalCat}頭、
                <br />
                その他哺乳類： 0頭、
                <br />
                鳥類： 0羽、爬虫類： 0頭　
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <div className="d-flex w-100">
                  <div className="pe-1">６.</div>
                  <div>
                    年度中に新たに所有
                    <br />
                    するに至った動物の
                    <br />
                    月ごとの合計数
                  </div>
                </div>
              </td>
              <td className="p-0">
                <Table bordered className="mb-0">
                  <tbody>
                    <tr>
                      <td className="text-center align-middle"> </td>

                      <td className="text-center align-middle">４月</td>
                      <td className="text-center align-middle">５月</td>
                      <td className="text-center align-middle">６月</td>
                      <td className="text-center align-middle">７月</td>
                      <td className="text-center align-middle">８月</td>
                      <td className="text-center align-middle">９月</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">犬</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][4]
                          ? analyticsData[thisYear][4].dm +
                            analyticsData[thisYear][4].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][5]
                          ? analyticsData[thisYear][5].dm +
                            analyticsData[thisYear][5].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][6]
                          ? analyticsData[thisYear][6].dm +
                            analyticsData[thisYear][6].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][7]
                          ? analyticsData[thisYear][7].dm +
                            analyticsData[thisYear][7].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][8]
                          ? analyticsData[thisYear][8].dm +
                            analyticsData[thisYear][8].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][9]
                          ? analyticsData[thisYear][9].dm +
                            analyticsData[thisYear][9].df
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">猫</td>

                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][4]
                          ? analyticsData[thisYear][4].cm +
                            analyticsData[thisYear][4].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][5]
                          ? analyticsData[thisYear][5].cm +
                            analyticsData[thisYear][5].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][6]
                          ? analyticsData[thisYear][6].cm +
                            analyticsData[thisYear][6].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][7]
                          ? analyticsData[thisYear][7].cm +
                            analyticsData[thisYear][7].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][8]
                          ? analyticsData[thisYear][8].cm +
                            analyticsData[thisYear][8].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][9]
                          ? analyticsData[thisYear][9].cm +
                            analyticsData[thisYear][9].cf
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">
                        <small>
                          その他
                          <br />
                          哺乳類
                        </small>
                      </td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">鳥類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">爬虫類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>

                    <tr>
                      <td className="text-center align-middle"> </td>

                      <td className="text-center align-middle">10月</td>
                      <td className="text-center align-middle">11月</td>
                      <td className="text-center align-middle">12月</td>
                      <td className="text-center align-middle">1月</td>
                      <td className="text-center align-middle">2月</td>
                      <td className="text-center align-middle">3月</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">犬</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][10]
                          ? analyticsData[thisYear][10].dm +
                            analyticsData[thisYear][10].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][11]
                          ? analyticsData[thisYear][11].dm +
                            analyticsData[thisYear][11].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][12]
                          ? analyticsData[thisYear][12].dm +
                            analyticsData[thisYear][12].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][1]
                          ? analyticsData[thisYear + 1][1].dm +
                            analyticsData[thisYear + 1][1].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][2]
                          ? analyticsData[thisYear + 1][2].dm +
                            analyticsData[thisYear + 1][2].df
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][3]
                          ? analyticsData[thisYear + 1][3].dm +
                            analyticsData[thisYear + 1][3].df
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">猫</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][10]
                          ? analyticsData[thisYear][10].cm +
                            analyticsData[thisYear][10].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][11]
                          ? analyticsData[thisYear][11].cm +
                            analyticsData[thisYear][11].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][12]
                          ? analyticsData[thisYear][12].cm +
                            analyticsData[thisYear][12].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][1]
                          ? analyticsData[thisYear + 1][1].cm +
                            analyticsData[thisYear + 1][1].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][2]
                          ? analyticsData[thisYear + 1][2].cm +
                            analyticsData[thisYear + 1][2].cf
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][3]
                          ? analyticsData[thisYear + 1][3].cm +
                            analyticsData[thisYear + 1][3].cf
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">
                        <small>
                          その他
                          <br />
                          哺乳類
                        </small>
                      </td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">鳥類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">爬虫類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div id="reportDiv2" style={{ width: "1000px" }}>
        <Table bordered>
          <tbody>
            <tr>
              <td className="align-middle">
                <div className="d-flex w-100">
                  <div className="pe-1">７.</div>
                  <div>
                    年度中に販売若し
                    <br />
                    くは引渡しをした動物の
                    <br />
                    月ごとの合計数
                  </div>
                </div>
              </td>
              <td className="p-0">
                <Table bordered className="mb-0">
                  <tbody>
                    <tr>
                      <td className="text-center align-middle"> </td>

                      <td className="text-center align-middle">４月</td>
                      <td className="text-center align-middle">５月</td>
                      <td className="text-center align-middle">６月</td>
                      <td className="text-center align-middle">７月</td>
                      <td className="text-center align-middle">８月</td>
                      <td className="text-center align-middle">９月</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">犬</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][4]
                          ? analyticsData[thisYear][4].dmt +
                            analyticsData[thisYear][4].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][5]
                          ? analyticsData[thisYear][5].dmt +
                            analyticsData[thisYear][5].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][6]
                          ? analyticsData[thisYear][6].dmt +
                            analyticsData[thisYear][6].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][7]
                          ? analyticsData[thisYear][7].dmt +
                            analyticsData[thisYear][7].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][8]
                          ? analyticsData[thisYear][8].dmt +
                            analyticsData[thisYear][8].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][9]
                          ? analyticsData[thisYear][9].dmt +
                            analyticsData[thisYear][9].dft
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">猫</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][4]
                          ? analyticsData[thisYear][4].cmt +
                            analyticsData[thisYear][4].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][5]
                          ? analyticsData[thisYear][5].cmt +
                            analyticsData[thisYear][5].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][6]
                          ? analyticsData[thisYear][6].cmt +
                            analyticsData[thisYear][6].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][7]
                          ? analyticsData[thisYear][7].cmt +
                            analyticsData[thisYear][7].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][8]
                          ? analyticsData[thisYear][8].cmt +
                            analyticsData[thisYear][8].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][9]
                          ? analyticsData[thisYear][9].cmt +
                            analyticsData[thisYear][9].cft
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">
                        <small>
                          その他
                          <br />
                          哺乳類
                        </small>
                      </td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">鳥類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">爬虫類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>

                    <tr>
                      <td className="text-center align-middle"> </td>

                      <td className="text-center align-middle">10月</td>
                      <td className="text-center align-middle">11月</td>
                      <td className="text-center align-middle">12月</td>
                      <td className="text-center align-middle">1月</td>
                      <td className="text-center align-middle">2月</td>
                      <td className="text-center align-middle">3月</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">犬</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][10]
                          ? analyticsData[thisYear][10].dmt +
                            analyticsData[thisYear][10].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][11]
                          ? analyticsData[thisYear][11].dmt +
                            analyticsData[thisYear][11].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][12]
                          ? analyticsData[thisYear][12].dmt +
                            analyticsData[thisYear][12].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][1]
                          ? analyticsData[thisYear + 1][1].dmt +
                            analyticsData[thisYear + 1][1].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][2]
                          ? analyticsData[thisYear + 1][2].dmt +
                            analyticsData[thisYear + 1][2].dft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][3]
                          ? analyticsData[thisYear + 1][3].dmt +
                            analyticsData[thisYear + 1][3].dft
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">猫</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][10]
                          ? analyticsData[thisYear][10].cmt +
                            analyticsData[thisYear][10].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][11]
                          ? analyticsData[thisYear][11].cmt +
                            analyticsData[thisYear][11].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][12]
                          ? analyticsData[thisYear][12].cmt +
                            analyticsData[thisYear][12].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][1]
                          ? analyticsData[thisYear + 1][1].cmt +
                            analyticsData[thisYear + 1][1].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][2]
                          ? analyticsData[thisYear + 1][2].cmt +
                            analyticsData[thisYear + 1][2].cft
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][3]
                          ? analyticsData[thisYear + 1][3].cmt +
                            analyticsData[thisYear + 1][3].cft
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">
                        <small>
                          その他
                          <br />
                          哺乳類
                        </small>
                      </td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">鳥類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">爬虫類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>

            <tr>
              <td className="align-middle" style={{ width: "340px" }}>
                <div className="d-flex w-100">
                  <div className="pe-1">８.</div>
                  <div>
                    年度中に死亡の
                    <br />
                    事実が生じた動物の
                    <br />
                    月ごとの合計数
                  </div>
                </div>
              </td>
              <td className="p-0">
                <Table bordered className="mb-0">
                  <tbody>
                    <tr>
                      <td className="text-center align-middle"> </td>

                      <td className="text-center align-middle">４月</td>
                      <td className="text-center align-middle">５月</td>
                      <td className="text-center align-middle">６月</td>
                      <td className="text-center align-middle">７月</td>
                      <td className="text-center align-middle">８月</td>
                      <td className="text-center align-middle">９月</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">犬</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][4]
                          ? analyticsData[thisYear][4].dmd +
                            analyticsData[thisYear][4].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][5]
                          ? analyticsData[thisYear][5].dmd +
                            analyticsData[thisYear][5].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][6]
                          ? analyticsData[thisYear][6].dmd +
                            analyticsData[thisYear][6].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][7]
                          ? analyticsData[thisYear][7].dmd +
                            analyticsData[thisYear][7].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][8]
                          ? analyticsData[thisYear][8].dmd +
                            analyticsData[thisYear][8].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][9]
                          ? analyticsData[thisYear][9].dmd +
                            analyticsData[thisYear][9].dfd
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">猫</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][4]
                          ? analyticsData[thisYear][4].cmd +
                            analyticsData[thisYear][4].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][5]
                          ? analyticsData[thisYear][5].cmd +
                            analyticsData[thisYear][5].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][6]
                          ? analyticsData[thisYear][6].cmd +
                            analyticsData[thisYear][6].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][7]
                          ? analyticsData[thisYear][7].cmd +
                            analyticsData[thisYear][7].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][8]
                          ? analyticsData[thisYear][8].cmd +
                            analyticsData[thisYear][8].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][9]
                          ? analyticsData[thisYear][9].cmd +
                            analyticsData[thisYear][9].cfd
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">
                        <small>
                          その他
                          <br />
                          哺乳類
                        </small>
                      </td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">鳥類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">爬虫類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>

                    <tr>
                      <td className="text-center align-middle"> </td>

                      <td className="text-center align-middle">10月</td>
                      <td className="text-center align-middle">11月</td>
                      <td className="text-center align-middle">12月</td>
                      <td className="text-center align-middle">1月</td>
                      <td className="text-center align-middle">2月</td>
                      <td className="text-center align-middle">3月</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">犬</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][10]
                          ? analyticsData[thisYear][10].dmd +
                            analyticsData[thisYear][10].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][11]
                          ? analyticsData[thisYear][11].dmd +
                            analyticsData[thisYear][11].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][12]
                          ? analyticsData[thisYear][12].dmd +
                            analyticsData[thisYear][12].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][1]
                          ? analyticsData[thisYear + 1][1].dmd +
                            analyticsData[thisYear + 1][1].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][2]
                          ? analyticsData[thisYear + 1][2].dmd +
                            analyticsData[thisYear + 1][2].dfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][3]
                          ? analyticsData[thisYear + 1][3].dmd +
                            analyticsData[thisYear + 1][3].dfd
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">猫</td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][10]
                          ? analyticsData[thisYear][10].cmd +
                            analyticsData[thisYear][10].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][11]
                          ? analyticsData[thisYear][11].cmd +
                            analyticsData[thisYear][11].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear] && analyticsData[thisYear][12]
                          ? analyticsData[thisYear][12].cmd +
                            analyticsData[thisYear][12].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][1]
                          ? analyticsData[thisYear + 1][1].cmd +
                            analyticsData[thisYear + 1][1].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][2]
                          ? analyticsData[thisYear + 1][2].cmd +
                            analyticsData[thisYear + 1][2].cfd
                          : 0}
                      </td>
                      <td className="text-center align-middle">
                        {analyticsData[thisYear + 1] &&
                        analyticsData[thisYear + 1][3]
                          ? analyticsData[thisYear + 1][3].cmd +
                            analyticsData[thisYear + 1][3].cfd
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">
                        <small>
                          その他
                          <br />
                          哺乳類
                        </small>
                      </td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">鳥類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                    <tr>
                      <td className="text-center align-middle">爬虫類</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                      <td className="text-center align-middle">0</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <div className="d-flex w-100">
                  <div className="pe-1">９.</div>
                  <div>
                    年度末に所有して
                    <br />
                    いた動物の合計数
                  </div>
                </div>
              </td>
              <td className="align-middle">
                犬： {currTotalDog}頭、猫： {currTotalCat}頭、
                <br />
                その他哺乳類： 0頭、
                <br />
                鳥類： 0羽、爬虫類： 0頭　
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <div className="d-flex w-100">
                  <div className="pe-1">10.</div>
                  <div>
                    犬猫以外の動物に
                    <br />
                    含まれる品種等
                  </div>
                </div>
              </td>
              <td className="align-middle"></td>
            </tr>
            <tr>
              <td className="align-middle">
                <div className="d-flex w-100"></div>11. 備　　　　考
              </td>
              <td className="align-middle"></td>
            </tr>
          </tbody>
        </Table>
        備　考
        <ol>
          <li>
            年度途中に登録を受けた場合には、５については登録を受けた時点の頭数を、６から８までについては、登録を受けた日以降の月ごとの合計頭数を記載すること。
          </li>
          <li>
            令和２年６月１日現在で、既に第一種動物取扱業の登録を受けている者は、令和２年度に係る報告については、５については令和２年６月１日時点の頭数、６から８までについては令和２年６月以降の月ごとの合計数を記載すること。
          </li>
          <li>
            この届出に係る事務担当者が届出者と異なる場合は、「11
            備考」欄に事務担当者の氏名及び電話番号を記入すること。
          </li>
          <li>この届出書の用紙の大きさは、日本産業規格Ａ４とすること。</li>
        </ol>
      </div>
    </>
  );
}
