import React from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import styles from "./Terms.module.css";
import TramsText from "./TramsText";

function Terms() {
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>会員規約および個人情報保護方針</title>
        <meta property="og:title" content="CKC" />
        <meta name="description" content="CKC" />
      </Helmet>
      <div className="main">
        <div className={styles.privacyPolicy}>
          <div className="container position-relative">
            <img src="assets/img/asset7.png" alt="" className={styles.topArt} />
            <div className="row d-flex justify-content-center d-block d-sm-none mb-3">

            </div>
            <p className={styles.title}>
              会員規約および
              <br className="d-block d-sm-none" />
              個人情報保護方針
            </p>


            <div className="row d-flex justify-content-center">
              <div className="col-12 col-sm-10 col-lg-9">
                <div className={styles["m-card"]}>
                 <TramsText />
                  <img
                    src="assets/img/asset5.png"
                    alt=""
                    className={styles.cat}
                  />
                </div>
              </div>
            </div>
            <img
              src="assets/img/asset8.png"
              alt=""
              className={styles.bottomArt}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br className="d-none d-sm-block"/>
          <br className="d-none d-sm-block"/>
          <br className="d-none d-sm-block"/>
          <br className="d-none d-sm-block"/>
          <br className="d-none d-sm-block"/>
          <br className="d-none d-sm-block"/>
          <br className="d-none d-sm-block"/>
          <br className="d-none d-sm-block"/>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Terms;
