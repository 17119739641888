import { useState } from "react";
import { useGetDoc } from "./useGetDoc";

export const usePlan = () => {
  const [planDetail, setplanDetail] = useState(null)
  const { firebaseGetDoc, isPending, isConfirmed, error } = useGetDoc();

  const planDetails = async (planID) => {
    const plan = await firebaseGetDoc("plans", planID);
    setplanDetail(plan)
  };

  return { planDetails, planDetail, isPending, isConfirmed, error };
};
