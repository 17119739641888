import React, { useEffect, useState } from "react";
import { useGetDoc } from "../hooks/useGetDoc";
import { getName } from "../utils/Utils";
import { doc } from "firebase/firestore";

export const UserNameById = (props) => {
  const { firebaseGetDoc, isPending, error } = useGetDoc();
  const [name, setName] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      let data = await firebaseGetDoc(`users`, props.id);
      if (data) {
        setName(data);
      }
    };

    loadData();
  }, []);
  return (
    <>
      {!name && <>&nbsp;</>}

      {name && <>{getName(name)}</>}
    </>
  );
};
