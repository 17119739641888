import React from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import ProgressStep from "../../components/ProgressStep";

function PetShopMCRegistrationComplete() {
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/mcregistration" },
              { value: "OTP", link: "#" },
              { value: "確認", link: "#" },
              { value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={5}
          />
        </Row>
        <Row className="justify-content-center my-5">
          <Col lg={6} md={8}>
            <Alert variant="success" className="text-center">
              完了しました！
            </Alert>
          </Col>
        </Row>

        <Row  className="justify-content-center mb-4">
          <Col lg={6} md={8}>
            <div className="text-center ">
              <Button variant="primary">別な情報を登録する</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PetShopMCRegistrationComplete;
