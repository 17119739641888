import { db } from "../firebase/config";

//firebase imports
import { doc, updateDoc, increment, setDoc } from "firebase/firestore";

export const useFirebaseIncrement = () => {
  const firebaseIncrement = async (c, id, field, incrementBy) => {
    const ref = doc(db, c, id);
    await updateDoc(ref, {
      [field]: increment(incrementBy),
    });
  };

  const firebaseIncrementMany = async (c, id, incrementJSON) => {
    const ref = doc(db, c, id);
    return await updateDoc(ref, incrementJSON).then(
      (res) => true,
      async (err) => {
        await setDoc(ref, { y: id }, { merge: true }).then(
          async (res) => {
            await updateDoc(ref, incrementJSON).then(
              (res) => true,
              (e) => false
            );
          },
          (err2) => false
        );
      }
    );
  };
  return { firebaseIncrement, firebaseIncrementMany };
};
