import {
  faCircleChevronLeft,
  faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

//component
import ProgressStep from "../../../components/ProgressStep";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useFirebaseUpdateProfile } from "../../../hooks/useFirebaseUpdateProfile";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useUpdateDoc } from "../../../hooks/useUpdateDoc";
import SignupInputCheck from "../../signup/SignupInputCheck";
import SignupInputCheckVet from "../../signup/SignupInputCheckVet";
import { storage } from "../../../firebase/config";
import { getRandomString } from "../../../utils/Utils";

export default function PersonalInfoCheck() {
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();
  const { firebaseUpdateProfile } = useFirebaseUpdateProfile();
  // const { firebaseSetDoc, isPending, isConfirmed, error } = useSetDoc();
  const { firebaseUpdateDoc, isPending, isConfirmed, error } = useUpdateDoc();
  const navigate = useNavigate();
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("stateEdit")) || false
  );
  async function uploadToStorage(url) {
    const imgRef = ref(storage, `petnext/image/breeder/${getRandomString(23)}`);
    await uploadString(imgRef, url, "data_url");
    return await getDownloadURL(imgRef);
  }

  useEffect(() => {
    if (!data) {
      navigate("/personalinfocheck");
    }
  }, []);

  //editUser
  const editUser = async () => {
    if (data.img && data.img.substring(0, 4) == "data") {
      data.img = await uploadToStorage(data.img);
    }
    firebaseUpdateDoc("users", data, user.uid);

    let displayName = `${data.lastName} ${data.firstName}`;
    if (data.type && data.type === "2") {
      displayName = `${data.corporation_representative_lastName} ${data.corporation_representative_firstName}`;
    }

    const profile = {
      displayName: displayName
    };
    firebaseUpdateProfile(profile);
    // console.log(isConfirmed);
  };

  useEffect(() => {
    if (isConfirmed) {
      navigate("/mypage/personalinfoconfirm");
    }
  }, [isConfirmed]);
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-md-center">
          <Col lg={9} md={10}>
            <Row className="justify-content-center mb-4">
              <ProgressStep
                props={[
                  { value: "入力", link: "/mypage/personalinfo" },
                  { value: "確認", link: "#" },
                  { value: "終了", link: "#" }
                ]}
                activeNo={2}
              />
            </Row>
            {error && (
              <Alert variant="danger" dismissible className="text-center">
                {error && <p className="m-0">{error}</p>}
              </Alert>
            )}
            {userData && userData.group === "vet" && (
              <SignupInputCheckVet data={data} ownership={"3"} edit={true} />
            )}
            {userData && userData.group === "owner" && (
              <SignupInputCheck data={data} ownership={"1"} edit={true} />
            )}
            {userData && userData.group === "breederpet" && (
              <SignupInputCheck data={data} ownership={"2"} edit={true} />
            )}

            <div className="text-center mt-4">
              <Link
                to="/mypage/personalinfo"
                className="m-2 btn btn-lg btn-secondary"
              >
                <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
              </Link>

              {!isPending && (
                <Button
                  size="lg"
                  className="m-2"
                  variant="primary"
                  onClick={editUser}
                >
                  更新 <FontAwesomeIcon icon={faCircleChevronRight} />
                </Button>
              )}

              {isPending && (
                <Button size="lg" className="m-2" variant="primary" disabled>
                  更新 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
