import React, { useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";

import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Helmet, HelmetData } from "react-helmet-async";
import { useResetPassword } from "../../hooks/useResetPassword";
import { useToast } from "../../hooks/useToast";
import { password, passwordConfirm } from "../../utils/InputSchema";



export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const navigate = useNavigate();

  const { error, isPending, message, isConfirmed, resetPassword } =
    useResetPassword();
  let initialValues = {
    password: "",
    passwordConfirm: "",
  };
  const schema = yup.object().shape({
    password: password(),
    passwordConfirm: passwordConfirm(yup.ref("password"))
  });

  const handelSubmit = (values) => {
    resetPassword(searchParams.get("oobCode"), values.password);
  };

  useEffect(() => {
    if (isConfirmed) {
      toast("success", message);
      navigate("/login");
    }
  }, [isConfirmed]);

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>パスワードの再発行</title>
      </Helmet>
      <Container className="my-5">
        <h1 className="text-center">パスワードの再発行</h1>
        <Row className="justify-content-md-center">
          <Col lg={6} md={8}>
            <Card className="shadow-sm">
              <Card.Body>
                {error && (
                  <Alert variant="danger" dismissible className="text-center">
                    {error && <p className="m-0">{error}</p>}
                  </Alert>
                )}

                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={handelSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          新しいパスワード{" "}
                          <span className="text-danger">※</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="半角英数字・記号・8文字以上"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          name="password"
                          required
                          isInvalid={touched.password && errors.password}
                          isValid={touched.password && !errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          新しいパスワード(確認用){" "}
                          <span className="text-danger">※</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="半角英数字・記号・8文字以上"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.passwordConfirm}
                          name="passwordConfirm"
                          isInvalid={
                            touched.passwordConfirm && errors.passwordConfirm
                          }
                          isValid={
                            touched.passwordConfirm && !errors.passwordConfirm
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.passwordConfirm}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="text-center">
                        {!isPending && (
                          <Button
                            className="mb-3  btn-lg"
                            variant="primary"
                            type="submit"
                          >
                            変更 <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                        )}

                        {isPending && (
                          <Button
                            className="mb-3 btn-lg"
                            variant="primary"
                            disabled
                          >
                            変更 <FontAwesomeIcon icon={faPenToSquare} />
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
