import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

const MetaTagDeleteButton = (props) => {
  const [buttonContent, setButtonContent] = useState("削除");
  const [buttonDisable, setButtonDisable] = useState(false);
  const buttonClickAction = async () => {
    setButtonDisable(true);
    setButtonContent(
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
    await props.deleteItem(props.data);
  };
  return (
    <Button
      onClick={buttonClickAction}
      variant="danger"
      disabled={buttonDisable}
    >
      {buttonContent}
    </Button>
  );
};

export default MetaTagDeleteButton;
