import React, { useEffect, useState } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ContactUs.module.css";

function ContactUsCheckComplete() {
  useEffect(() => {
    localStorage.removeItem("contactUsForm");
  }, []);

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>お問い合わせ</title>
        <meta property="og:title" content="CKC" />
        <meta name="description" content="CKC" />
      </Helmet>
      <div className="main">
        <div className={styles.contactUs}>
          <div className={`container position-relative`}>
            <div className={`row d-flex justify-content-center ${styles.con}`}>
              <div className="col-12 col-sm-10">
                <div className={styles["contact-card"]}>
                  <p className={styles.title}>お問い合わせ完了</p>
                  <p className={`${styles.subtitle} text-start`}>
                    ご入力いただいたメールアドレス宛にお問い合わせメールをお送りしましたのでご確認ください。
                    <br />
                    <br />
                    この度は、弊社お問い合わせフォームよりご連絡をいただき、ありがとうございます。
                    <br />
                    <br />
                    確認メールが届いていない場合には、メールアドレスが誤っているか、確認メールが迷惑メールフォルダ等に振り分けられている可能性がありますので、再度ご確認をお願いいたします。
                    <br />
                    <br />
                    万一、ご回答メールが届かない場合は、送信トラブル等の可能性もありますので、大変お手数ではございますが、もう一度フォームよりお問い合わせいただくか、お電話にてお問い合わせをお願いいたします。
                  </p>
                </div>
              </div>
            </div>
            <img src="/assets/img/asset12.png" alt="" className={styles.dog} />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsCheckComplete;
