import React, { useState, useEffect } from "react";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";

import ProgressStep from "../../components/ProgressStep";
import CardDiv from "../../components/CardDiv";
import CardRegistration from "../creditcard/CardRegistration";
import { useCreatePayment } from "../../hooks/komoju/useCreatePayment";
import { useAddDocs } from "../../hooks/useAddDocs";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useSetDoc } from "../../hooks/useSetDoc";
import { useFirebaseIncrement } from "../../hooks/useFirebaseIncrement";
import _ from "lodash";
import { arrayUnion, increment } from "firebase/firestore";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";
import { useGetDoc } from "../../hooks/useGetDoc";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import moment from "moment";
import { useAnalytics } from "../../hooks/useAnalytics";

function PetShopMCPay({ komoju_customer_id }) {
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();
  const navigate = useNavigate();
  const [changeCardDiv, setChangeCardDiv] = useState(false);
  const paymentCost = 1500;

  const { makePayment, isPending, error } = useCreatePayment();
  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();

  const {
    firebaseSetDocQuery,
    firebaseSetDoc,
    isPending: isPending4,
    error: error4,
  } = useSetDoc();
  const { firebaseIncrement, firebaseIncrementMany } = useFirebaseIncrement();
  const { userAnalytics } = useAnalytics();
  const { deleteDocument } = useDeleteDocs();
  const { getSingleDocWithQuery } = useGetDoc();

  const mcregistrationHandler = async () => {
    let mcData = JSON.parse(localStorage.getItem("mcData"))[0];
    const date = moment();
    const yyyy = moment(date).format("YYYY");
    //const mmdd = moment(date).format("MMDD");
    const mm = moment(date).format("MM");
    const dd = moment(date).format("DD");

    const ownersData = {
      mccode: mcData.mccode.toString(),
      relation: "OWNER",
      mcData: {
        birthDay: mcData.birthDay,
        breed: mcData.breed,
        color: mcData.color,
        gender: mcData.gender,
        group: mcData.group,
        note: mcData.note,
        species: mcData.species,
      },
    };
    let prevOwner = JSON.parse(localStorage.getItem("userInfo"));
    let paymentsData = {};
    //make payment and get payment ID
    await makePayment(komoju_customer_id, paymentCost).then((res) => {
      //get the mc datas and attached the payment id
      if (res.id) {
        let transfer_type = "";
        if(userData.group === "2"){
          transfer_type= "B2P"
        }else if(userData.group === "3"){
          transfer_type = "C2C"
        }
        paymentsData = {
          uid: user.uid,
          amount: paymentCost,
          payment_id: res.id,
          payment_case: "MC_REGISTRATION",
          mccode: mcData.mccode.toString(),
          transfer_from_uid: prevOwner.id,
          transfer_to_uid: user.uid,
          transfer_type: transfer_type,
          next_owner_name: `${user.displayName}`,
        };
      }
    });

    //write paymentsData in firestore /payments
    await addADoc("payments", paymentsData);

    //add mc in owner list
    await addADoc(`users/${user.uid}/microchip`, {
      ...ownersData,
      paymentsData: arrayUnion({ ...paymentsData }),
    }).then(() => {
      // firebaseIncrement("users", user.uid, "totalMC", 1);
      firebaseIncrementMany("users", user.uid, {
        totalMC: increment(1),
        registrationMNY: increment(paymentCost),
      });
    });

    //update relation to TRANSFER in breeder list

    await getSingleDocWithQuery(`users/${prevOwner.id}/microchip`, [
      ["mccode", "==", mcData.id.toString()],
    ]).then(async (doc) => {
      // console.log(prevOwner.id, mcData.id, doc);
      await firebaseSetDoc(
        `users/${prevOwner.id}/microchip`,
        {
          relation: "TRANSFER",
          transferAt: new Date(),
        },
        doc[0].id,
        true
      ).then(() => {
        firebaseIncrement("users", prevOwner.id, "transferMC", 1);

        userAnalytics(prevOwner.id, mcData, yyyy, mm, dd, "T");
      });
    });

    //change Current owner in microchip collection
    await firebaseSetDoc(
      "microchip",
      {
        currentOwnerUID: user.uid,
        owners: arrayUnion({
          uid: user.uid,
          name: `${userData.firstName} ${userData.lastName}`,
          group: userData.group,
          time: new Date(),
        }),
        paymentsData: arrayUnion({ ...paymentsData }),
      },
      mcData.mccode.toString(),
      true
    );

    userAnalytics(user.uid, mcData, yyyy, mm, dd, "R", paymentCost, false);

    //delete
    localStorage.removeItem("mcData");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("mccode");
    //navigate
    await deleteDocument("temp_otp", user.uid).then(() => {
      navigate("/mypage/mcregistrationcomplete");
    });
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/mcregistration" },
              { value: "入力", link: "/mypage/mcinput" },
              { value: "確認", link: "/mypage/mcinputcheck" },
              { value: "支払", link: "#" },
              { value: "終了", link: "#" },
            ]}
            activeNo={4}
          />
          {error && <p className="text-danger">{error}</p>}
          {error2 && <p className="text-danger">{error2}</p>}
          <h1 className="text-center my-5">{paymentCost.toLocaleString()}円 (税込)</h1>
          {komoju_customer_id && !changeCardDiv && (
            <Col lg={5} md={6}>
              <Card>
                <Card.Header>
                  <h5 className=" mb-0 text-center">支払い方法</h5>
                </Card.Header>
                <Card.Body>
                  <CardDiv komoju_customer_id={komoju_customer_id} />
                  <div className="text-center">
                    <Button
                      className="mt-3"
                      variant="warning"
                      onClick={() => setChangeCardDiv(!changeCardDiv)}
                    >
                      編集 <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}

          {(!komoju_customer_id || changeCardDiv) && (
            <>
              <CardRegistration
                komoju_customer_id={komoju_customer_id}
                returnUrl={null}
                isFinish={(status) => setChangeCardDiv(!status)}
              />
              {changeCardDiv && (
                <div className="text-center">
                  <Button
                    className="mt-3"
                    variant="danger"
                    onClick={() => setChangeCardDiv(!changeCardDiv)}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> 取り消し
                  </Button>
                </div>
              )}
            </>
          )}
          <div className="text-center mt-4">
            <Link className="btn btn-secondary m-2" to="/mypage/mcinputcheck">
              <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
            </Link>
            {komoju_customer_id && !isPending && !isPending2 && !isPending4 && (
              <Button
                className="btn btn-primary m-2"
                onClick={mcregistrationHandler}
              >
                お支払 <FontAwesomeIcon icon={faCircleChevronRight} />
              </Button>
            )}

            {(!komoju_customer_id || isPending || isPending2 || isPending4) && (
              <Button className="btn btn-primary m-2" disabled>
                お支払 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}

            {(error || error2 || error4) && (
              <Alert variant="danger" className="text-center">
                {error || error2 || error4}
              </Alert>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PetShopMCPay;
