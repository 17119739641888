import React from "react";
import { Table } from "react-bootstrap";

function UserDetailsTable({ userInfo }) {
  return (
    <Table striped bordered>
      <tbody>
        <tr>
          <td>氏名</td>
          <td>
            {userInfo.firstName} {userInfo.lastName}
          </td>
        </tr>

        <tr>
          <td>氏名(フリガナ)</td>
          <td>
            {userInfo.firstKana} {userInfo.lastKana}
          </td>
        </tr>

        <tr>
          <td>会社名</td>
          <td>{userInfo.company}</td>
        </tr>

        <tr>
          <td>郵便番号</td>
          <td>
            {userInfo.zip3}-{userInfo.zip4}
          </td>
        </tr>

        <tr>
          <td>都道府県</td>
          <td>{userInfo.prefecture}</td>
        </tr>

        <tr>
          <td>住所１(市区町村)</td>
          <td>{userInfo.addressLine1}</td>
        </tr>

        <tr>
          <td>住所２(番地)</td>
          <td>{userInfo.addressLine2}</td>
        </tr>

        {userInfo.addressLine3 && (
          <tr>
            <td>住所３(建物名)</td>
            <td>{userInfo.addressLine3}</td>
          </tr>
        )}

        <tr>
          <td>お電話番号</td>
          <td>{userInfo.phone}</td>
        </tr>

        <tr>
          <td>生年月日</td>
          <td>{userInfo.birthDay}</td>
        </tr>

        <tr>
          <td>性別</td>
          <td>{userInfo.gender}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default UserDetailsTable;
