import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CSVFileChangeOwner from "../../components/CSVFileChangeOwner";

export default function CSVFileUpload() {
  return (
    <Container>
      <Row className="justify-content-center mb-4">
        <Col lg={10}>
          <CSVFileChangeOwner
            mcWhiteList={JSON.parse(localStorage.getItem("whitelist"))}
            redirect="/mypage/transferlist"
          />
        </Col>
      </Row>

      <br />
      <br />
      <br />
    </Container>
  );
}
