import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import MCDetailsModal from "../components/MCDetailsModal";
import PaymentDetailsModal from "../components/PaymentDetailsModal";
import PaymentListItem from "../components/PaymentListItem";
import { useAuthContext } from "../hooks/useAuthContext";
import { useGetDoc } from "../hooks/useGetDoc";
import { useMCList } from "../hooks/useMCList";

function PaymentList() {
  const { user } = useAuthContext();

  const { documents, isPending, lastDoc, isLast, getMCList } = useMCList();

  const [mcs, setMcs] = useState([]);


  const [showMCDetailsModal, setShowMCDetailsModal] = useState(false);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const [MCModalContent, setMCModalContent] = useState(null);
  const [paymentModalContent, setPaymentModalContent] = useState(null);

  const limit = 10;
  useEffect(() => {
    if (documents) {
      let newmcs = mcs
      documents.forEach((doc) => {
        newmcs = {...newmcs, [doc.id]: doc};
      });
      setMcs(newmcs);
    }
  }, [documents]);

  useEffect(() => {
    setMcs([]);
    loadMore();
  }, []);


  const { getSingleDocWithQuery, data, isPending: newP, error } = useGetDoc();
  const MCModalHandler = async (props, code) => {
    // console.log("MCModalHandler", props);
    // console.log("MCModalHandlerprops", props, code)
    if(props && props.mccode === code){
      setShowMCDetailsModal(true);
      setMCModalContent(props);
    }else{
      try {
        getSingleDocWithQuery(`users/${user.uid}/microchip`, [
          ["mccode", "==", code],
        ]).then((data) => {
          // console.log("MCModalHandler", data)
          if(data){
            setShowMCDetailsModal(true);
            setMCModalContent(data[0]);
          }
        });
      } catch (error) {}
    }
  };

  const paymentModalHandler = (props) => {
    // console.log(props);
    setPaymentDetailsModal(true);
    setPaymentModalContent(props);
  };

  const loadMore = () => {
    getMCList({
      _collection: `payments`,
      _query: [["uid", "==", user.uid]],
      orderByField: "createdAt",
      orderByOption: "desc",
      _limit: limit,
      _startAfter: lastDoc || false,
    });
  };

  let items = [];
  for (let i = 0; i < limit; i++) {
    items.push(
      <tr key={i} className="placeholder-glow">
        <td className="align-middle">
          <span className="placeholder col-8"></span>
          <span className="placeholder placeholder-sm col-5"></span>
        </td>

        <td className="text-end align-middle">
          <span className="placeholder placeholder-lg col-7"></span>
        </td>
        <td className="text-center  align-middle">
          <ButtonGroup className="me-6 col-7" aria-label="First group">
            <Button
              variant="secondary"
              className="placeholder"
              disabled
            ></Button>{" "}
          </ButtonGroup>
        </td>
      </tr>
    );
  }
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={8}>
            {Object.values(mcs).length > 0 && (
              <>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(mcs) &&
                      Object.values(mcs).map((doc, i) => {
                        return (
                          <PaymentListItem
                            key={i}
                            doc={doc}
                            modalFun={paymentModalHandler}
                          />
                        );
                      })}

                    {isPending && items}
                  </tbody>
                </Table>
                <div className="my-4 text-center">
                  {!isLast && !isPending && (
                    <Button variant="secondary" onClick={loadMore}>
                      もっと見る
                    </Button>
                  )}

                  {!isLast && isPending && (
                    <Button variant="secondary" disabled onClick={loadMore}>
                      もっと見る{" "}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  )}
                </div>
              </>
            )}

            {!Object.values(mcs).length && !isPending && (
              <Alert variant="warning" className="text-center">
                支払い履歴がありません
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
      <MCDetailsModal
        data={MCModalContent}
        show={showMCDetailsModal}
        onHide={() => setShowMCDetailsModal(false)}
        paymentModalHandler={paymentModalHandler}
        hideOther = {() => {}}
      />
      <PaymentDetailsModal
        data={paymentModalContent}
        mcData={MCModalContent}
        show={paymentDetailsModal}
        onHide={() => setPaymentDetailsModal(false)}
        modalFun={MCModalHandler}
        
      />
    </>
  );
}

export default PaymentList;
