import React, { useEffect, useState } from "react";

import _ from "lodash";
import DataTable from "react-data-table-component";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import { arrayRemove } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faTrash } from "@fortawesome/free-solid-svg-icons";

import FavRemoveButton from "./FavRemoveButton";
import { useGetDoc } from "../../../hooks/useGetDoc";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { dog_breed } from "../../../utils/CatDogData";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { customPaginationOptions } from "../../../utils/Utils";

const FavouriteList = () => {
  const { firebaseGetDoc, getSingleDocWithQuery, isPending, error } =
    useGetDoc();
  const { user } = useAuthContext();
  const [favList, setFavList] = useState([]);
  const [favText, setFavText] = useState("お気に入りから削除");

  const {
    firebaseSetDoc,
    firebaseSetDocQuery,
    isPending: isPending3,
    error: error4
  } = useSetDoc();

  const loadData = async () => {
    let data = await firebaseGetDoc(`users`, user.uid);
    if (data && data.favourite) {
      data = data.favourite;
    } else {
      data = [];
    }

    let favPets = [];

    for (let i = 0; i < data.length; i++) {
      await firebaseGetDoc(`microchip`, data[i]).then((res) => {
        if (res) {
          favPets.push(res);
        }
      });
    }
    // console.log(favPets);

    setFavList(favPets);
  };

  const columns = [
    {
      name: "品種",
      selector: (row) =>
        row.animal_breed !== "999"
          ? _.find(dog_breed, ["value", row.animal_breed])?.label
          : row.animal_breed_other,
      sortable: true,
      style: {
        padding: "2px 0"
      },
      maxWidth: "200px"
    },
    {
      name: "画像",
      selector: (row) => {
        if (row.images && row.images.length > 0) {
          return <img src={row.images[0]} className="w-100" />;
        } else {
          return <img src="/assets/img/no-img.png" className="w-100" />;
        }
      },
      maxWidth: "200px",
      style: {
        padding: "2px 0"
      }
    },
    {
      name: <span className="text-center w-100">ペットID</span>,
      selector: (row) => row.animalID,
      maxWidth: "200px",
      style: {
        padding: "2px 0",
        display: "flex",
        justifyContent: "center"
      }
    },
    {
      selector: (row) => (
        <FavRemoveButton user={user} loadData={loadData} mccode={row.mccode} />
      )
    }
  ];

  useEffect(() => {
    loadData();
  }, []);
  return (
    <Container>
      {favList && (
        <Row>
          <Col sm={12}>
            <DataTable
              columns={columns}
              progressPending={isPending}
              progressComponent={
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              }
              data={favList}
              pagination
              paginationComponentOptions={customPaginationOptions}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FavouriteList;
