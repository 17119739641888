import React from "react";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import moment from "moment";

function SignupInputCheckVet({ data, ownership, edit = false }) {
  const { width } = useWindowDimensions();

  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          氏名
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          {data.lastName} {data.firstName}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          氏名（フリガナ）
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.lastKana} {data.firstKana}
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          施設名
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.vet_institute}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          施設の
          <span className="text-nowrap">所在地</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <>
            <p className="mb-0">
              〒{data.zip3}-{data.zip4}
            </p>
            <p className="mb-0">
              {data.prefecture} {data.addressLine1}
            </p>
            <p className="mb-0">{data.addressLine2}</p>
            <p className="mb-0">{data.addressLine3}</p>
          </>
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          施設の
          <span className="text-nowrap">電話番号</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.phone}
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          性別
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.gender}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          生年月日
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {data.birthDay ? moment(data.birthDay).format("YYYY/MM/DD") : ""}
        </Col>
      </Row>
      {!edit && (
        <Row className="px-lg-3">
          <Col
            lg={3}
            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
              width >= 576 && `border-bottom border-start `
            } border-secondary p-2`}
          >
            Eメールアドレス
          </Col>
          <Col
            lg={9}
            className={`${
              width < 576 && `border-start border-end border-bottom`
            } ${
              width >= 576 && `border-start border-bottom border-end`
            } p-1 border-secondary`}
          >
            {data.email}
          </Col>
        </Row>
      )}
    </>
  );
}

export default SignupInputCheckVet;
