import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetDoc } from "./hooks/useGetDoc";
import { useAddDocs } from "./hooks/useAddDocs";

const UrlListner = () => {
  let location = useLocation();
  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } = useGetDoc();
  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();

  useEffect(() => {
    async function fetchData() {
      getSingleDocWithQuery("petnext_urls", [["url", "==", location.pathname]]).then(async (res) => {
        if (res[0] === undefined) {
          if (location.pathname.includes("newsDetails")) {
            let news = await firebaseGetDoc("news", location.pathname.split("/")[2]);
            let data = {
              url: location.pathname,
              title: news.title,
              description: news.description
            };
            addADoc("petnext_urls", data).then((res) => {});
          }
        }
      });
    }
    fetchData();
  }, [location]);
  return <></>;
};

export default UrlListner;
