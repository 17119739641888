import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Helmet, HelmetData } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

//component
import ProgressStep from "../../components/ProgressStep";
import { useSignup } from "../../hooks/useSignup";
import SignupInputCheck from "./SignupInputCheck";
import SignupInputCheckVet from "./SignupInputCheckVet";
import moment from "moment";

export default function SignupCheck() {
  const navigate = useNavigate();
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("state")) || false
  );

  const { signup, isPending, error, isConfirmed } = useSignup();
  const group = ["ブリーダー", "ペットショップ", "一般所有者"];

  useEffect(() => {
    if (!data) {
      // setData(false)
      navigate("/signup");
    }
  }, []);

  //register
  function registerUser() {
    const extraData = {
      ..._.omit(data, ["password", "emailConfirm", "passwordConfirm"]),
      birthDay: moment(data.birthDay).format("YYYY/MM/DD"),
      totalMC: 0,
      transferMC: 0,
    };
    signup(data.email, data.password, extraData);
  }

  useEffect(() => {
    if (isConfirmed) {
      navigate("/signupconfirm");
    }
  }, [isConfirmed]);
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>ご登録情報のご確認</title>
      </Helmet>
      <div className="main pt-5">
        <Container className="mb-5">
        <h1 className="text-center pb-3">ご登録情報のご確認</h1>

        <Row className="justify-content-md-center">
          <Col lg={9} md={10}>
          <Card className="pt-4 px-4">
            <Row className="justify-content-center mb-4">
              <ProgressStep
                props={[
                  { value: "入力", link: "#" },
                  { value: "確認", link: "#" },
                  { value: "終了", link: "#" },
                ]}
                activeNo={2}
              />
            </Row>
            {error && (
              <Alert variant="danger" dismissible className="text-center">
                {error && <p className="m-0">{error}</p>}
              </Alert>
            )}
            {data.group === "vet" && (
              <SignupInputCheckVet data={data} ownership={"3"} />
            )}
            {data.group === "owner" && (
              <SignupInputCheck data={data} ownership={"1"} />
            )}
            {data.group === "breederpet" && (
              <SignupInputCheck data={data} ownership={"2"} />
            )}

            <div className="text-center mt-4">
              <Link
                className="m-2 btn-secondary btn btn-lg"
                to={`/signup?group=${data.group}`}
              >
                <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
              </Link>

              {!isPending && (
                <Button
                  size="lg"
                  className="m-2"
                  variant="primary"
                  onClick={registerUser}
                >
                  登録 <FontAwesomeIcon icon={faCircleChevronRight} />
                </Button>
              )}

              {isPending && (
                <Button size="lg" className="m-2" variant="primary" disabled>
                  登録 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
