import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  Modal,
  ProgressBar,
  Spinner
} from "react-bootstrap";

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
  deleteObject
} from "firebase/storage";
import { storage } from "../../firebase/config";
import { useUpdateDoc } from "../../hooks/useUpdateDoc";
import { useAddDocs } from "../../hooks/useAddDocs";
import { Formik } from "formik";
import * as yup from "yup";
import { getRandomString } from "../../utils/Utils";

const SidebarBannerForm = ({ data, hideModal, show, onHide, reloadData }) => {
  const [image, setImage] = useState(null);
  const [img, setImg] = useState(data.image || "/assets/img/no-img.png");
  const type = data.type || "";
  const {
    firebaseUpdateDoc,
    isPending: isPendingUpdate,
    error: errorUpdate
  } = useUpdateDoc();
  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();
  let initialValues = {
    url: data.url || "",
    memo: data.memo || "",
    image: data.image || ""
  };

  const [isDataUpdating, setIsDataUpdating] = useState(null);

  const schema = yup.object().shape({
    url: yup
      .string()
      .required("この欄は必須です")
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "有効なURLを入力してください。"
      ),
    memo: yup
      .string()
      .matches(/.*\S.*/, "この欄は必須です")
      .required("この欄は必須です"),
    image: yup.string().required("バナー画像は必須です")
  });

  const formSubmit = async (values) => {
    setIsDataUpdating(true);
    if (image != null) {
      let prevImg = data.image;

      values.image = await uploadToStorage(image);
      if (prevImg) {
        await deleteImage(prevImg);
      }
    }

    await updateOrAddData(values, type);
  };

  async function uploadToStorage(url) {
    const imgRef = ref(storage, `petnext/banner/${getRandomString(23)}`.trim());
    await uploadString(imgRef, url, "data_url");
    return await getDownloadURL(imgRef);
  }

  const updateOrAddData = async (values, type) => {
    values.url = encodeURI(values.url).toString();
    if (type == "new") {
      await addADoc(`sidebar_banners`, values).then((res) => {});
    } else {
      await firebaseUpdateDoc(`sidebar_banners`, values, data.id).then(
        (res) => {}
      );
    }

    hideModal();
    reloadData();
    setImg("/assets/img/no-img.png");
    setIsDataUpdating(false);
  };

  const handleImg = (e) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImage(reader.result?.toString());
      setImg(reader.result?.toString());
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const deleteImage = async (url) => {
    const desertRef = ref(storage, url);
    await deleteObject(desertRef);
  };

  useEffect(() => {
    if (data.image) {
      setImg(data.image);
    }
  }, [data]);

  const hideAction = () => {
    onHide();
    setImg("/assets/img/no-img.png");
    setIsDataUpdating(false);
  };

  return (
    <Modal
      scrollable={true}
      style={{ overflow: "auto" }}
      show={show}
      onHide={hideAction}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton></Modal.Header>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={formSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="overflow-auto">
            <Modal.Body>
              <FormGroup>
                <FormLabel>URL</FormLabel>
                <Form.Control
                  type="text"
                  placeholder="url"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.url}
                  name="url"
                  required
                  isInvalid={touched.url && errors.url}
                  isValid={touched.url && !errors.url}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.url}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>メモ</FormLabel>
                <Form.Control
                  type="text"
                  placeholder="memo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.memo}
                  name="memo"
                  required
                  isInvalid={touched.memo && errors.memo}
                  isValid={touched.memo && !errors.memo}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.memo}
                </Form.Control.Feedback>
              </FormGroup>

              <div className="d-flex justify-content-center my-3">
                <Image src={img} className="img-fluid w-50" />
              </div>
              <FormGroup>
                <FormLabel>バナー画像</FormLabel>

                <Form.Control
                  type="hidden"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.image}
                  name="image"
                  isInvalid={touched.image && errors.image}
                  isValid={touched.image && !errors.image}
                />

                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleImg(e);
                    setFieldTouched("image", true);
                    setFieldValue("image", img);
                  }}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.image}
                </Form.Control.Feedback>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={hideAction} type="button">
                閉じる
              </Button>
              {!isDataUpdating && (
                <Button variant="success" type="submit">
                  保存
                </Button>
              )}

              {isDataUpdating && (
                <Button variant="success" type="submit" disabled>
                  <Spinner size="sm" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>{" "}
                  保存
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SidebarBannerForm;
