import {
  faEnvelope,
  faFilePdf,
  faHeart,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Badge, Button, ButtonGroup, Form, Spinner } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import { useUserDetailsContext } from "../context/UserDetailsContext";
import { useSetDoc } from "../hooks/useSetDoc";
import { useAuthContext } from "../hooks/useAuthContext";
import useFavourite from "../hooks/useFavourite";
import { downloadPdfDocument } from "../utils/SellingContract/SellingContractFormatter";
import { useGetDoc } from "../hooks/useGetDoc";

function MCListItems({
  doc,
  modalFun,
  deathModalFun,
  pinModalFun,
  batchCsvHandler,
  getUpdatedMCDatabyID,
  favModal,
  mcInfo,
  pinCheck,
  reviewModal
}) {
  const { firebaseSetDoc, firebaseSetDocQuery, isPending } = useSetDoc();
  const {
    firebaseGetDoc,
    isPending: getPending
  } = useGetDoc();
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();
  const {
    isReady: isFavReady,
    isPending: isFavPending,
    totalFav
  } = useFavourite(doc.mccode);

  const navigate = useNavigate();

  const [setsellingContractPDFDownloadPending, setSetsellingContractPDFDownloadPending] = useState(false);
  const transferMC = (mccode) => {
    localStorage.setItem(
      "mccode",
      JSON.stringify({ mccode: mccode.toString() })
    );
    localStorage.setItem("mcData", JSON.stringify(doc));
    localStorage.setItem("PIN", doc.PIN);
    navigate("/mypage/transferinput");
  };

  const addtolist = (mccode) => {
    localStorage.setItem(
      "mccode",
      JSON.stringify({ mccode: mccode.toString() })
    );
    localStorage.setItem("mcData", JSON.stringify(doc));
    navigate("/mypage/addtolistinput");
  };

  const removefromlist = async (mccode) => {
    const setData = {
      sellPlatform: false
    };
    //upload values in microchip collection
    await firebaseSetDoc("microchip", setData, mccode.toString(), true).then(
      async () => {
        //upload values in user/microchip collection
        await firebaseSetDocQuery(
          `users/${user.uid}/microchip`,
          setData,
          [["mccode", "==", mccode.toString()]],
          true
        );
        //update and show
        getUpdatedMCDatabyID(doc.id);
      }
    );
  };

  const editMC = () => {
    localStorage.setItem("mcDataEditId", JSON.stringify(doc.id));
    localStorage.setItem("mcDataEdit", JSON.stringify(doc.mcData));
    localStorage.setItem("mcFullDataEdit", JSON.stringify(doc));
    localStorage.setItem("PIN", doc.PIN || false);
    navigate(`/mypage/mcedit/${doc.mccode}`);
  };

  const changeOwnerHandler = () => {
    localStorage.setItem("mcDataEditId", JSON.stringify(doc.id));
    localStorage.setItem("mcDataEdit", JSON.stringify(doc.mcData));
    localStorage.setItem(
      "prevTransferDetails",
      JSON.stringify(doc.prevTransferDetails)
    );
    localStorage.setItem("PIN", doc.PIN);
    navigate(`/mypage/changeOwner/${doc.mccode}`);
  };

  const addMCInfo = () => {
    localStorage.setItem("animalID", doc.animalID);
    localStorage.setItem("mcData", JSON.stringify(doc.mcData));

    navigate(`/mypage/mcregistration?type=addMCInfo`);
  };

  const sellingContractPDFDownload = async () => {
    setSetsellingContractPDFDownloadPending(true)

    await firebaseGetDoc("users", userData.group === "breederpet" ? doc.nextTransferDetails.nextOwnerId : doc.prevTransferDetails.prevOwnerId).then(
      async (userInfo) => {
        let base64String = await getBase64FromFirebaseURL(
          doc.sellingContractData.signature
        );

        await downloadPdfDocument(
          doc,
          userData.group === "breederpet" ? userData : userInfo,
          userData.group === "breederpet" ? userInfo : userData,
          {
            ...doc.sellingContractData,
            signature: base64String
          },
          "DOWNLOAD"
        );
      }
    );
    setSetsellingContractPDFDownloadPending(false)
  };

  async function getBase64FromFirebaseURL(imageURL) {
    return fetch(imageURL, { method: "GET", mode: "cors" })
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      })
      .then((base64String) => {
        return base64String;
      })
      .catch((error) => {
        console.error("Error fetching or converting image:", error);
      });
  }
  return (
    <tr key={doc.id}>
      <td className="align-middle text-center">
        {(mcInfo.length === 0 ||
          (mcInfo.length > 0 &&
            ((doc.PIN && pinCheck) || (!doc.PIN && !pinCheck)))) &&
          !doc.unregistered && (
            <Form.Check
              type="checkbox"
              value={doc.id}
              data-pin={doc.PIN ? true : false}
              onChange={batchCsvHandler}
              checked={_.includes(mcInfo, doc.id)}
            />
          )}
      </td>
      <td className="align-middle">
        <small>
          {doc.createdAt && doc.createdAt.seconds && (
            <Badge bg="secondary" className="text-white">
              {moment(
                new Timestamp(
                  doc.createdAt.seconds,
                  doc.createdAt.nanoseconds
                ).toDate()
              ).format("YYYY/MM/DD HH:mm")}
            </Badge>
          )}
        </small>
        <br />
        {doc.mccode}
        {doc.PIN && doc.relation === "OWNER" && userData.group === "owner" && (
          <>
            <br />
            <Button
              size="sm"
              className="text-nowrap"
              variant="warning"
              onClick={() => reviewModal(doc)}
            >
              {doc.review ? "レビューを表示" : "レビューを書く"}
            </Button>
          </>
        )}
        {userData.group === "breederpet" && doc.sellPlatform && (
          <>
            <br />
            <Button
              variant="outline-secondary"
              onClick={() => {
                favModal(doc.mccode);
              }}
              className=" rounded-pill py-0 mx-1 text-nowrap"
            >
              <FontAwesomeIcon className="text-danger" icon={faHeart} />
              <Badge bg="transperent" className="text-dark pe-0">
                {isFavPending && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}

                {!isFavPending && <>{totalFav}</>}
              </Badge>
            </Button>
            {/* <Button
              variant="outline-secondary"
              className=" rounded-pill py-0 mx-1 text-nowrap"
            >
              <FontAwesomeIcon className="" icon={faEnvelope} />
              <Badge bg="transperent" className="text-dark pe-0">
                0
              </Badge>
            </Button> */}
          </>
        )}
      </td>
      <td className="text-center align-middle">
        {!doc.unregistered && !doc.PIN && doc.relation === "OWNER" && (
          <>
            <Button
              size="sm"
              variant="warning"
              onClick={() => pinModalFun(doc)}
            >
              入力
            </Button>
          </>
        )}

        {doc.PIN &&
          (doc.relation === "OWNER" || doc.relation === "TRANSFER_OWNER") && (
            <>
              <p className="text-nowrap m-0">{doc.PIN}</p>
            </>
          )}
      </td>
      {/* {userData.group === "breederpet" && ( */}
      <td className="text-center align-middle">
        {doc.death && (
          <span className="p-1 d-inline-block border border-danger rounded text-danger  text-nowrap">
            死亡
          </span>
        )}
        {!doc.unregistered &&
          !doc.death &&
          doc.relation === "OWNER" &&
          !doc.sellPlatform && (
            <>
              {" "}
              {userData.group === "breederpet" ? (
                <FontAwesomeIcon icon={faXmark} />
              ) : (
                <b>○</b>
              )}
            </>
          )}
        {!doc.unregistered && !doc.death && doc.relation === "TRANSFERED" && (
          <b>○</b>
        )}
        {!doc.unregistered && !doc.death && doc.relation === "TRANSFERING" && (
          <span className="p-1 m-1 d-inline-block border border-info rounded text-info text-nowrap">
            譲渡中
          </span>
        )}

        {doc.unregistered && (
          <span className="p-1 m-1 d-inline-block border border-secondary rounded text-secondary text-nowrap">
            MC未登録
          </span>
        )}

        {!doc.death && doc.sellPlatform && (
          <span className="p-1 m-1 d-inline-block border border-dark rounded text-dark text-nowrap">
            公開中
          </span>
        )}
        {userData.group === "breederpet" && (
          <>
            {!doc.death && doc.relation === "TRANSFER_OWNER" && (
              <Button
                variant="info"
                className="text-white"
                onClick={changeOwnerHandler}
              >
                所有権を変更する
              </Button>
            )}
          </>
        )}
      </td>
      <td className="text-lg-start text-center">
        {/* <ButtonGroup className="me-2" aria-label="First group"> */}
        <Button
          className="m-1 text-nowrap"
          variant="secondary"
          onClick={() => modalFun(doc)}
        >
          詳細
        </Button>
        {!doc.death &&
          (doc.relation === "OWNER" || doc.relation === "TRANSFER_OWNER") && (
            <>
              {userData.group === "breederpet" && (
                <Button
                  className="m-1 text-nowrap"
                  variant="primary"
                  onClick={editMC}
                >
                  編集
                </Button>
              )}

              <Button
                className="m-1 text-nowrap"
                variant="danger"
                onClick={() => deathModalFun(doc)}
              >
                死亡後
              </Button>
              {doc.PIN && userData.group === "breederpet" && (
                <Button
                  className="m-1 text-nowrap"
                  variant="success"
                  onClick={() => transferMC(doc.mccode)}
                >
                  譲渡
                </Button>
              )}
              {userData.group === "breederpet" && doc.unregistered && (
                <Button
                  className="m-1 text-nowrap"
                  variant="info"
                  onClick={() => addMCInfo()}
                >
                  MC情報登録
                </Button>
              )}
            </>
          )}

        {!doc.death &&
          (doc.relation === "OWNER") && (
            <>
              {userData.group === "breederpet" &&
                doc.mcData.animal_group === "犬" && (
                  <ButtonGroup className="m-1" aria-label="First group">
                    <Button
                      className="text-nowrap"
                      variant="dark"
                      onClick={() => addtolist(doc.mccode)}
                    >
                      公開開始・編集
                    </Button>
                    {!isPending &&
                      userData.group === "breederpet" &&
                      doc.sellPlatform && (
                        <Button
                          className="text-nowrap"
                          variant="outline-dark"
                          onClick={() => removefromlist(doc.mccode)}
                        >
                          公開停止
                        </Button>
                      )}
                    {isPending &&
                      userData.group === "breederpet" &&
                      doc.sellPlatform && (
                        <Button
                          className="text-nowrap"
                          variant="outline-dark"
                          disabled
                        >
                          公開停止
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      )}
                  </ButtonGroup>
                )}
            </>
          )}

        {doc.sellingContractData && (
          <>
            {!getPending && !setsellingContractPDFDownloadPending && (
              <Button
                className="text-nowrap"
                variant="primary"
                onClick={sellingContractPDFDownload}
              >
                販売契約書 <FontAwesomeIcon icon={faFilePdf} />
              </Button>
            )}
            {(getPending || setsellingContractPDFDownloadPending) && (
              <Button className="text-nowrap" variant="primary" disabled>
                販売契約書 <FontAwesomeIcon icon={faFilePdf} />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </>
        )}
        {/* </ButtonGroup> */}
      </td>
    </tr>
  );
}

export default MCListItems;
