import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet, HelmetData } from "react-helmet-async";
import { Alert, Card, Col, Container, Row, Spinner } from "react-bootstrap";

import { useVarifyEmail } from "../../hooks/useVarifyEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useCollection } from "../../hooks/useCollection";
import { useSetDoc } from "../../hooks/useSetDoc";
import { useGetDoc } from "../../hooks/useGetDoc";

function VerifyOTP() {
  const [searchParams] = useSearchParams();

  // const [isPending, setIsPending] = useState(true);
  const [error2, setError2] = useState(null);
  const [message, setMessage] = useState(null);
  // const { documents: otpcode } = useCollection("temp_otp", [
  //   "oobCode",
  //   "==",
  //   searchParams.get("oobCode"),
  // ]);

  const {
    getSingleDocWithQuery,
    data: otpcode,
    isPending,
    error,
  } = useGetDoc();
  const { firebaseSetDoc } = useSetDoc();

  useEffect(() => {
    getSingleDocWithQuery("temp_otp", [
      ["oobCode", "==", searchParams.get("oobCode")],
    ]);
  }, []);

  useEffect(() => {
    // console.log(otpcode);
    if (otpcode && otpcode[0] && otpcode[0].createdAt) {
      const timeExpairy = otpcode[0].createdAt.toDate();
      timeExpairy.setSeconds(timeExpairy.getSeconds() + 300);
      const currentTime = new Date();
      if (otpcode[0].isVarified === false) {
        if (timeExpairy > currentTime) {
          firebaseSetDoc(
            "temp_otp",
            { ...otpcode[0], isVarified: true },
            otpcode[0].id
          );

          setMessage("認証されました！");
        } else {
          setError2("is expaired");
        }
      } else {
        setMessage("OTP is already varified!");
      }
    }
  }, [otpcode]);

  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>メール確認</title>
      </Helmet>
      <Container className="my-5">
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <Card className="shadow-sm">
              <Card.Body>
                {isPending && (
                  <div className="text-center m-4">
                    <Spinner animation="border" />
                  </div>
                )}
                {error && (
                  <Alert className="text-center" variant="danger">
                    {error === "NO_DOC" ? "invalid URL" : error}
                  </Alert>
                )}

                {error2 && (
                  <Alert className="text-center" variant="danger">
                    { error2}
                  </Alert>
                )}

                {message && (
                  <Alert className="text-center" variant="success">
                    {message}
                  </Alert>
                )}

                <div className="text-center mt-3">
                  <Link className="btn btn-secondary" to="/login">
                    <b>ログインへ</b>　<FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VerifyOTP;
