import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styles from "./SideBar.module.css";
import StarRatings from "react-star-ratings";

import { useUserDetailsContext } from "../context/UserDetailsContext";
import { Badge, Spinner } from "react-bootstrap";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { getName, hasActivePlan } from "../utils/Utils";
import { usePlan } from "../hooks/usePlan";
import { useAggregation } from "../hooks/useAggregation";
import { useAuthContext } from "../hooks/useAuthContext";

export default function SideBar({
  sideBarShow,
  sideBarShowHandler,
  activeMenu,
  menus,
  certificateRequests = null,
}) {
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();
  const {
    averageWithQuery,
    countWithQuery,
    isPending: reviewPending,
  } = useAggregation();
  const {
    planDetails,
    isPending: isPlanPending,
    planDetail,
    isConfirmed,
    error,
  } = usePlan();

  useEffect(() => {
    if (userData && userData.plan) {
      planDetails(userData.plan);
    }
    aggre();
  }, []);

  const [rating, setRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [totalMC, setTotalMC] = useState(0)

  async function aggre() {
    if (
      userData &&
      (userData.group === "breederpet" || userData.group === "owner")
    ) {
      const mcs = await countWithQuery(
        `users/${user.uid}/microchip/`,
        [["relation", "!=", "TRANSFERED"]]
      );

      const mcsd = await countWithQuery(
        `users/${user.uid}/microchip/`,
        [["death", "==", true]]
      );
      setTotalMC(mcs - mcsd)
    }
    if (userData && userData.group === "breederpet") {
      const ratingAve = await averageWithQuery(
        `users/${user.uid}/reviews/`,
        false,
        "rating"
      );

      const totalReview = await countWithQuery(
        `users/${user.uid}/reviews/`,
        false
      );
      setRating(ratingAve);
      setReviewCount(totalReview);
    }
  }

  return (
    <>
      <div
        className={`${
          sideBarShow ? styles.bgWrapperActive : styles.bgWrapper
        } d-lg-none`}
        onClick={sideBarShowHandler}></div>
      <div
        className={`${
          sideBarShow ? styles.sidebarWrapperActive : ""
        } bg-light shadow-sm`}
        id={styles.sidebarWrapper}>
        {userData &&
          userData.group === "breederpet" &&
          hasActivePlan(userData) && (
            <>
              <div className="my-2 d-flex">
                <img
                  src={userData.img ? userData.img : "/assets/img/art/p2.png"}
                  alt="profile"
                  className={`${styles.avater} img-fluid rounded-circle mx-3 border shadow-sm`}
                />
                <div>
                  <Badge pill bg="primary">
                    {userData.plan && isPlanPending && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {userData.plan &&
                      !isPlanPending &&
                      planDetail &&
                      planDetail.name}
                  </Badge>
                  <p className="mb-0 fw-bold">
                    {getName(userData, 10)}様{" "}
                    <small>({userData.lookforusername})</small>
                  </p>
                  <StarRatings
                    rating={rating || 0}
                    starDimension="20px"
                    starSpacing="0px"
                    starRatedColor="#FFC415"
                  />

                  <small className="fw-bold fs-6 text-warning ps-1 pe-2">
                    {rating ? parseFloat(rating).toFixed(1) : "0.0"}
                  </small>

                  <small className="fw-bold">
                    <Link to={"/mypage/reviews"} className="text-reset">
                      {reviewCount}件
                    </Link>
                  </small>
                </div>
              </div>
            </>
          )}
        <hr className="m-0" />

        {menus.map((menu, i) => {
          return (
            <div key={i}>
              <div
                className={`${styles.listGroup} list-group  list-group-flush`}
                onClick={sideBarShowHandler}>
                {menu.link === "createUser" ? (
                  <Link
                    to="/createUser"
                    target="_blank"
                    className={`${styles.link} list-group-item list-group-item-action bg-light`}>
                    <FontAwesomeIcon icon={faUserPlus} />
                    &nbsp;&nbsp; 新しい一般所有を登録
                  </Link>
                ) : (
                  <Link
                    to={menu.link}
                    id={menu.link}
                    className={`${styles.link} ${
                      menu.link === activeMenu ? styles.sidemenuactive : ""
                    }  list-group-item list-group-item-action bg-light`}>
                    <FontAwesomeIcon icon={menu.icon} />
                    &nbsp;&nbsp;
                    <span
                      dangerouslySetInnerHTML={{
                        __html: menu.text,
                      }}></span>{" "}
                    {menu.link === "mclist" && (
                      <Badge bg="secondary">
                        {totalMC}数
                      </Badge>
                    )}
                    {menu.link === "sochakulist" && (
                      <Badge bg="secondary">
                        {parseInt(userData.totalMC ? userData.totalMC : 0)}数
                      </Badge>
                    )}
                    {menu.link === "breedershochakulist" &&
                      certificateRequests && (
                        <Badge bg="secondary">
                          {certificateRequests.length}数
                        </Badge>
                      )}
                  </Link>
                )}
              </div>
              <hr className="m-0" />
            </div>
          );
        })}
        {/* {userData.group === "vet" && (
          <div
            className={`${styles.listGroup} list-group  list-group-flush`}
            onClick={sideBarShowHandler}
          >
            <Link
              to="/createUser"
              target="_blank"
              className={`${styles.link} list-group-item list-group-item-action bg-light`}
            >
              <FontAwesomeIcon icon={faUserPlus} />
              &nbsp;&nbsp; 新しい一般所有を登録
            </Link>
          </div>
        )} */}
      </div>
    </>
  );
}
