import React from "react";
import { Link } from "react-router-dom";
import styles from "./Common.module.css";

export default function Veterinarian() {
  return (
    <div className="main">
      <div className={styles.Veterinarian}>
        <div className="container" style={{overflow: "hidden"}}>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6">
              <div className={`${styles.circle} ${styles["bg-green"]}`}>
                <img src="/assets/img/art/stethoscope-c.png" />
                <div className={styles.text}>
                  <p className={styles.title}>獣医師</p>
                  <p className={styles.subtitle}>For Veterinarian</p>
                </div>
              </div>

              <p className={styles.subtext}>
                マイクロチップ装着証明書を
                <br className="d-block d-sm-none" />
                作成し、
                <br className="d-none d-sm-block" />
                マイページで管理が
                <br className="d-block d-sm-none" />
                できます！
              </p>

              <div className={styles.greenList}>
                <ul>
                  <li>装着証明書をかんたん作成</li>
                  <li>
                    マイページ機能でデータ
                    <br className="d-block d-sm-none" />
                    管理
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-8 offset-6 offset-sm-0 col-sm-4">
              <img
                src="/assets/img/art/Veterinarian.png"
                className={styles[`d-img`]}
              />
            </div>
          </div>

          <div className={`${styles.path} mb-5`}>
            <div className="row">
              <div className="col-12 d-flex align-items-center flex-column">
                <Link to={"/login?group=vet"} className={styles.pathBtn}>
                  ログイン
                </Link>
                <Link to={"/signup?group=vet"} className={styles.pathBtn}>
                  新規登録
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
