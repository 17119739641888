import moment from "moment";
import React from "react";
import styles from "./NewsContent.module.css";

export default function NewsContent({ data }) {
  return (
    <>
      <div className={styles["m-card"]}>
        <div className="row d-flex justify-content-center">
          <div className="col-6 text-start">
            <p className={styles.date}>{moment(data.date).format("YYYY.MM.DD")}</p>
          </div>
          <div className="col-6 text-end">
            <p className={`${data.type === "お知らせ" ? styles.newsType : styles.newsType2}`}>
              {data.type}
            </p>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <p className={styles.title}>{data.title}</p>
            <p className={styles.details}>
              {data.description.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
